import React from "react";
import StyledNotification from "./StyledNotification";
import { IoMdInformation } from "react-icons/io";

const styleProps = {
  background: "white",
  iconBackground: "#ffc107"
};

const SuccessNotification = props => {
  return (
    <StyledNotification {...styleProps}>
      <div className="icon">
        <IoMdInformation />
      </div>
      <div className="content">
        <div className="title">warning</div>
        <div className="text">{props.title}</div>
      </div>
      {/* <button onClick={() => props.close(props.notifId)}>x</button> */}
    </StyledNotification>
  );
};

export default SuccessNotification;
