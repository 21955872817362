import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactPaginate from "react-paginate";
import {
  FaLongArrowAltLeft,
  FaLongArrowAltRight,
  FaPlus,
} from "react-icons/fa";

import ProfileCard from "./ProfileCard";
import Button from "../common/Button";
import PlaceholderUserCard from "../../components/Placeholder/PlaceholderUserCard";
import SearchUser from "./SearchUser";
import NotFound from "../../components/Errors/NotFound";
import searchIcon from "../../assets/img/search.png";
import { colors } from "../themes/base";

const ManageUsersWrapper = styled.div`
  font-family: ${(props) => props.fontFamily};
  margin: 0 5rem;
  .loader {
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .paginate {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 3rem;
    color: #43546a;

    .info {
      font-size: 1.4rem;
      opacity: 0.5;
    }

    .react-pagination {
      list-style: none;
      display: flex;

      li {
        padding: 0.3rem 1rem;
        font-size: 1.4rem;
        opacity: 0.5;
        margin: 0 1rem;
        cursor: pointer;

        a {
          outline: none;
        }
      }

      li.previous,
      li.next {
        font-size: 1.8rem;
      }

      li.active {
        border: 1px solid ${colors.brand};
        border-radius: 3px;
        opacity: 1;
      }
    }
  }
`;

const Heading = styled.div`
  font-family: ${(props) => props.fontFamily};
  color: #142945;
  font-size: 2.2rem;
  font-weight: bold;
  letter-spacing: 0.1.8rem;
  line-height: 2.8rem;
  flex-grow: 2;
`;

const HeadingWrapper = styled.div`
  display: flex;
  margin: 2rem 0;

  .search-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 26rem;
    border: 2px solid ${colors.brand};
    border-radius: 10em;
    background: white;
  }

  .search-button {
    display: inline;
    background: ${colors.brand};
    border: none;
    cursor: pointer;
    height: 3.3rem;
    width: 3.3rem;
    border-radius: 50%;
    margin: 0.2rem;

    &:focus {
      outline: 0;
    }

    img {
      height: 2rem;
      width: 2rem;
    }
  }

  input {
    outline: none;
  }

  input[type="search"] {
    background-color: rgb(237, 237, 237);
    padding: 9px 10px 9px 32px;
    background-position: 90% !important;
    border: none;
    width: 22rem !important;
    border-radius: 10em !important;
    padding-left: 20px !important;
    color: #000 !important;
    background-color: #fff !important;
    cursor: auto !important;
    font-size: 1.4rem;
    &::-webkit-input-placeholder {
      color: #777;
    }
  }
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  div {
    margin-right: 1.4rem;
    &:last-child {
      margin-right: 0rem;
    }
  }
`;

const calcaulateAge = (dob) => {
  var birthdate = new Date(dob);
  var cur = new Date();
  var diff = cur - birthdate;
  var age = Math.floor(diff / 31557600000);

  return age;
};

const getDetails = (user) => {
  const { dob, gender } = user;
  const age = calcaulateAge(dob);

  return `${age}yrs, ${gender}`;
};

const NoUsersFound = (props) => (
  <NotFound
    title="No Users"
    message="When you add users. They will appear here."
    button={{
      text: "Add",
      handleButtonClick: () => props.history.push("/facesearch/watchlist/add"),
    }}
  />
);

const PlaceHolderCards = () => (
  <CardWrapper>
    {Array.from(Array(10).keys()).map((_, i) => (
      <PlaceholderUserCard key={i} />
    ))}
  </CardWrapper>
);

const Paginate = (props) => {
  return (
    <div className="paginate">
      <ReactPaginate
        previousLabel={<FaLongArrowAltLeft />}
        nextLabel={<FaLongArrowAltRight />}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={props.pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={(data) => {
          props.handlePageClick(data);
          props.setCurrentPage(data.selected);
          return;
        }}
        containerClassName={"react-pagination"}
        activeClassName={"active"}
        forcePage={props.currentPage}
      />
    </div>
  );
};

const ManageUsersCards = (props) => {
  return props.loading ? (
    <PlaceHolderCards />
  ) : (
    <>
      <CardWrapper>
        {props.usersOnPage.map((user) => (
          <ProfileCard
            key={user._id}
            profilePic={user.profilePic}
            name={`${user.firstname} ${user.lastname}`}
            details={getDetails(user)}
            place={user.loc}
            lastUpdated={"18hrs ago"}
            handleEditProfile={() => props.handleEditProfile(user._id)}
          />
        ))}
      </CardWrapper>
      <Paginate
        pageCount={props.pageCount}
        handlePageClick={props.showUsers}
        setCurrentPage={props.setCurrentPage}
        currentPage={props.currentPage}
      />
    </>
  );
};

const ManageUsers = (props) => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [query, setQuery] = React.useState("");
  if (
    !props.loading &&
    (props.users === null || props.usersOnPage.length === 0)
  ) {
    return <NoUsersFound {...props} />;
  }

  const submitQuery = (e) => {
    if (e) {
      e.preventDefault();
    }

    props.queryUser(query);
  };

  const changeHandler = (e) => {
    e.preventDefault();
    setQuery(e.target.value);
  };

  return (
    <ManageUsersWrapper {...props}>
      <HeadingWrapper>
        <Heading>Watchlist</Heading>
        {/* <Button
        boxShadow="0 2px 10px 0 rgba(44,137,255,0.06)"
        width="103"
        height="36"
        background="#fff"
        color="#223539"
        fontSize="1.4rem"
        borderRadius="3rem"
        style={{
          textTransform: "none",
          marginRight: "1rem"
        }}
      >
        <div>Filter</div> &nbsp;{" "}
        <MdFilterList
          style={{
            color: colors.brand,
            fontSize: "2rem",
            marginLeft: "0.5rem"
          }}
        />
      </Button> */}
        <form onSubmit={submitQuery}>
          <div className="search-container">
            <input
              type="search"
              className="focused"
              onChange={(e) => changeHandler(e)}
              placeholder="Search"
            />
            <button className="search-button">
              <img src={searchIcon} alt="" />
            </button>
          </div>
        </form>
        <Button
          boxShadow="0 2px 10px 0 rgba(44,137,255,0.06)"
          height="36"
          width="103"
          color="#fff"
          background={colors.brand}
          fontSize="1.4rem"
          margin="0rem 1rem"
          borderRadius="3rem"
          style={{ textTransform: "none" }}
          onClick={props.addProfile}
        >
          <FaPlus /> &nbsp; <div style={{ marginLeft: "0.5rem" }}>Add User</div>
        </Button>
      </HeadingWrapper>
      {props.query === "" ? (
        <ManageUsersCards
          {...props}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : (
        <SearchUser
          filteredUsers={props.filteredUsers}
          filteredUsersPageCount={props.filteredUsersPageCount}
          handleEditProfile={props.handleEditProfile}
          getDetails={getDetails}
          loading={props.loading}
          handlePageClick={props.showFilteredUsers}
        />
      )}
    </ManageUsersWrapper>
  );
};

export default ManageUsers;

ManageUsers.propTypes = {
  fontFamily: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      profilePic: PropTypes.string,
      name: PropTypes.string,
      details: PropTypes.string,
      place: PropTypes.string,
      lastUpdated: PropTypes.string,
    })
  ),
  usersOnPage: PropTypes.arrayOf(
    PropTypes.shape({
      profilePic: PropTypes.string,
      name: PropTypes.string,
      details: PropTypes.string,
      place: PropTypes.string,
      lastUpdated: PropTypes.string,
    })
  ),
  addProfile: PropTypes.func,
  handleEditProfile: PropTypes.func,
};

ManageUsers.defaultProps = {
  fontFamily: '"Muli", sans-serif',
  mdBreakpoint: 900,
};
