import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import InputField from "../../components/common/Form/InputField";
import TextAreaField from "../../components/common/Form/TextAreaField";
import Dropzone from "../../components/common/Form/Dropzone";
import Button from "../../components/common/Button";
import {
  updateProfile,
  deleteProfile,
  deleteImage,
  fetchProfile,
  fetchProfiles,
} from "../../actions";
import TextSelect from "../../components/common/Form/TextSelect";
import LoadingOverlay from "../../components/Loader/LoadingOverlay";
import DeleteProfileModal from "../../components/Modal/DeleteProfileModal";
import DateInputField from "../../components/common/Form/DateInputField";

import "react-datepicker/dist/react-datepicker.css";
import { colors } from "../../components/themes/base";

const buttonProps = {
  width: "140px",
  height: "48px",
  fontSize: "1.6rem",
  background: colors.brand,
  boxShadow: "0 10px 20px 0 rgba(124, 160, 73, 0.15)",
  color: "#FFFFFF",
  textTransform: "uppercase",
};

const FormWrapper = styled.div`
  background: ${(props) => props.background};
  width: 90%;
  border-radius: 3px;
  margin: 2rem auto;
  margin-bottom: 0;
  padding: 3rem;

  form {
    width: ${(props) => props.width};
    margin: 0 auto;
    font-family: ${(props) => props.fontFamily};
    color: ${(props) => props.fontColor};
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 0 10rem;
    box-sizing: border-box;

    .buttons {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      .update-btn {
        display: flex;
        justify-content: flex-end;
      }

      button {
        font-size: 1.6rem;
      }
    }

    .formFields {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      div {
        flex: 1 1 45%;
        &:nth-child(1),
        &:nth-child(3) {
          margin-right: 2rem;
        }
      }
    }
    .buttons {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const validateInput = (validationInput, setError) => {
  const items = Object.entries(validationInput);
  const error = {};

  items.forEach(([label, item]) => {
    if (!item || !item.length) {
      error[label] = "Please fill this field";
    }
  });

  if (Object.keys(error).length > 0) {
    setError(error);
    return false;
  }

  return true;
};

const genderList = ["Male", "Female", "Other"];

const UpdateProfile = (props) => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [dob, setDob] = useState(new Date().toDateString());
  const [gender, setGender] = useState("");
  const [location, setLocation] = useState("");
  const [notes, setNotes] = useState("");
  const [files, setFiles] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loaderMessage, setLoaderMessage] = useState("Loading Profile Data...");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletingUser, setDeletingUser] = useState(false);
  const [error, setError] = useState({});

  useEffect(() => {
    async function fetchData() {
      const { id } = props.match.params;
      const profile = await fetchProfile(id);
      if (profile) {
        const { firstname, lastname, loc, notes, dob, gallery, gender } =
          profile;

        const formattedDob = dob
          ? new Date(dob).toDateString()
          : new Date().toDateString();

        setFirstName(firstname);
        setLastName(lastname);
        setLocation(loc);
        setNotes(notes);
        setDob(formattedDob);
        setGallery(gallery);
        setGender(gender);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    fetchData();
    /* eslint-disable-next-line */
  }, []);

  const setSelectedFiles = (addtionalFiles) => {
    setFiles([...files, ...addtionalFiles]);
  };

  return (
    <LoadingOverlay isActive={loading} loaderMessage={loaderMessage}>
      <DeleteProfileModal
        isVisible={deleteModal}
        userName={`${firstname} ${lastname}`}
        handleCancel={() => setDeleteModal(false)}
        isDeleting={deletingUser}
        handleDelete={async () => {
          setDeletingUser(true);
          const res = await deleteProfile(props._id);
          if (!res) {
            setDeleteModal(false);
            setDeletingUser(false);
            return;
          }
          props.fetchProfiles();
          props.history.push("/facesearch/watchlist/");
        }}
      />
      <div>
        <div
          style={{
            color: "#142945",
            fontFamily: "Muli",
            fontSize: "2.2rem",
            fontWeight: "700",
            letterSpacing: "0.18px",
            margin: "2.5rem 5rem",
          }}
        >
          Edit Profile
        </div>
        <FormWrapper {...props}>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="formFields">
              <InputField
                width={510}
                label="First Name*"
                id="first-name"
                name="first-name"
                value={firstname}
                handleChange={(e) => {
                  setFirstName(e.target.value);
                  setError({});
                }}
                error={error.firstname}
              />
              <InputField
                width={510}
                label="Last Name*"
                id="last-name"
                name="last-name"
                value={lastname}
                handleChange={(e) => {
                  setLastName(e.target.value);
                  setError({});
                }}
                error={error.lastname}
              />
              <DateInputField
                width={510}
                type="date"
                label="Date of Birth"
                id="dob"
                name="dob"
                value={dob}
                handleChange={(date) => {
                  setDob(date);
                  setError({});
                }}
                error={error.dob}
              />
              <TextSelect
                options={genderList}
                handleSelect={setGender}
                selectedOption={gender}
                label="Gender*"
              />
            </div>
            <InputField
              label="Location"
              id="location"
              name="location"
              value={location || ""}
              handleChange={(e) => {
                setLocation(e.target.value);
                setError({});
              }}
              error={error.location}
              required={false}
            />
            <TextAreaField
              label="Notes"
              id="notes"
              name="notes"
              value={notes}
              handleChange={(e) => {
                setNotes(e.target.value);
                setError({});
              }}
              error={error.notes}
              required={false}
            />
            <Dropzone
              files={files}
              selectedFiles={gallery}
              handleDrop={setSelectedFiles}
              setFiles={setFiles}
              accept="image/*"
              thumbWidth={15}
              thumbHeight={15}
              handleDelete={async (id) => {
                const newGallery = gallery.filter((image) => image._id !== id);
                setGallery(newGallery);
                props.deleteImage(props._id, id);
              }}
              error={error.files}
            />
          </form>
        </FormWrapper>
        <div
          className="buttons"
          style={{
            display: "flex",
            backgroundColor: "#fff",
            padding: "2rem 4rem",
            position: "sticky",
            bottom: "0",
          }}
        >
          <div className="delete-btn">
            <Button
              {...buttonProps}
              color="#FF3B30"
              border="1px solid #FF3B30"
              background="transparent"
              boxShadow="none"
              onClick={() => setDeleteModal(true)}
            >
              Delete
            </Button>
          </div>
          <div
            className="update-btn"
            style={{ flex: 2, display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              {...buttonProps}
              background="transparent"
              color="#777"
              boxShadow="none"
              border="none"
              onClick={() => props.history.push("/facesearch/watchlist/")}
            >
              Cancel
            </Button>
            <Button
              {...buttonProps}
              onClick={async (e) => {
                e.preventDefault();
                const validationInput = {
                  firstname,
                  lastname,
                  gender,
                  files: [...files, ...gallery],
                };
                if (!validateInput(validationInput, setError)) {
                  return;
                }
                setLoaderMessage("Updating Profile...");
                setLoading(true);
                const { id } = props.match.params;
                const formData = new FormData();
                for (var x = 0; x < files.length; x++) {
                  formData.append("files", files[x]);
                }

                const data = {
                  firstname,
                  lastname,
                  dob,
                  gender,
                  location,
                  notes,
                };
                const result = await updateProfile(id, formData, data);
                setLoading(false);
                if (result.status) {
                  props.history.push("/facesearch/watchlist/");
                }
              }}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

UpdateProfile.propTypes = {
  fontFamily: PropTypes.string,
  fontColor: PropTypes.string,
  background: PropTypes.string,
  width: PropTypes.string,
  handleUpdate: PropTypes.func,
  handleCancel: PropTypes.func,
  handleDelete: PropTypes.func,
};

UpdateProfile.defaultProps = {
  fontFamily: '"Muli", sans-serif',
  fontColor: "#142945",
  background: "#FFFFFF",
  width: "100%",
};

function mapStateToProps({ wuser }) {
  return wuser;
}

export default connect(mapStateToProps, { deleteImage, fetchProfiles })(
  UpdateProfile
);
