import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import InputField from "../../components/common/Form/InputField";
import TextAreaField from "../../components/common/Form/TextAreaField";
import Dropzone from "../../components/common/Form/Dropzone";
import Button from "../../components/common/Button";
import { fetchCase, updateCase, deleteCase } from "../../actions/case.actions";
import { fetchCases, setProgress } from "../../actions/search.case.actions";
import {
  successNotification,
  warningNotification,
  errorNotification,
} from "../../actions";
import LoadingOverlay from "../../components/Loader/LoadingOverlay";
import DeleteCaseModal from "../../components/Modal/DeleteCaseModal";

import keys from "../../config/keys";
import { colors } from "../../components/themes/base";
const { SERVER_URL } = keys;

const buttonProps = {
  width: "140px",
  height: "48px",
  fontSize: "1.6rem",
  background: colors.brand,
  boxShadow: "0 10px 20px 0 rgba(124, 160, 73, 0.15)",
  color: "#FFFFFF",
  textTransform: "uppercase",
};

const FormWrapper = styled.div`
  background: ${(props) => props.background};
  width: 90%;
  border-radius: 3px;
  margin: 2rem auto;
  padding: 2rem;

  form {
    min-height: calc(100vh - 21rem);
    display: flex;
    flex-direction: column;
    width: ${(props) => props.width};
    font-family: ${(props) => props.fontFamily};
    color: ${(props) => props.fontColor};
    padding: 0 10rem;
    box-sizing: border-box;

    .buttons {
      flex: 1;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .update-btn {
        display: flex;
        justify-content: flex-end;
      }

      button {
        font-size: 1.6rem;
      }
    }
  }
`;

const validateInput = (validationInput, setError) => {
  const items = Object.entries(validationInput);
  const error = {};

  items.forEach(([label, item]) => {
    if (!item || !item.length) {
      error[label] = "Please fill this field";
    }
  });

  if (Object.keys(error).length > 0) {
    setError(error);
    return false;
  }

  return true;
};

class EditCase extends Component {
  state = {
    caseName: "",
    caseNotes: "",
    caseFiles: [],
    selectedFiles: [],
    deleteList: [],
    loading: true,
    loaderMessage: "Loading Case Data...",
    deleteModal: false,
    deletingCase: false,
    error: {},
  };

  async componentDidMount() {
    const caseId = this.props.match.params.id;
    const data = await this.props.fetchCase(caseId);
    if (data) {
      this.setState({
        caseName: data.name,
        caseNotes: data.notes,
        caseFiles: data.imgsUrl,
        loading: false,
        loaderMessage: "Loading Case Data...",
      });
    } else {
      this.setState({ loading: false });
      this.props.errorNotification({
        title: "Failed to load case data",
        autoDismiss: 1,
      });
    }
  }

  setError = (error) => {
    this.setState({ error });
  };

  setFiles = (files) => {
    this.setState({ selectedFiles: files });
  };

  setSelectedFiles = (files) => {
    this.setState((prevState) => {
      return { selectedFiles: [...prevState.selectedFiles, ...files] };
    });
  };

  handleDelete = (file) => {
    this.setState((prevstate) => ({
      caseFiles: prevstate.caseFiles.filter((exFile) => exFile !== file),
      deleteList: [...prevstate.deleteList, file],
    }));
  };

  handleDeleteCase = async () => {
    // set deleteCase to true
    this.setState({ deletingCase: true });

    const caseId = this.props.match.params.id;
    const res = await this.props.deleteCase(caseId);

    if (res) {
      this.props.fetchCases();
      this.props.history.push("/facesearch/cases");
      this.props.successNotification({
        title: "Deleted Successfully",
        autoDismiss: 1,
      });
    } else {
      this.setState({ deletingCase: false, deleteModal: false });
      this.props.errorNotification({
        title: "Something went wrong.Please try again later",
        autoDismiss: 1,
      });
    }
  };

  handleUpdate = async () => {
    const { caseName, caseNotes, caseFiles, selectedFiles, deleteList } =
      this.state;

    const validationInput = {
      caseName,
      selectedFiles: [...caseFiles, ...selectedFiles],
    };
    if (!validateInput(validationInput, this.setError)) {
      return;
    }

    this.setState({
      loading: true,
      loaderMessage: "Updating Case...",
    });

    const caseId = this.props.match.params.id;

    if (caseName.replace(/\s/g, "").length) {
      if (caseFiles.length > 0 || selectedFiles.length > 0) {
        const formData = new FormData();
        formData.append("name", caseName);
        formData.append("notes", caseNotes);
        let probeIds = [];
        let probeNames = [];
        deleteList.map((file) => {
          probeIds.push(file._id);
          return probeNames.push(file.name);
        });
        if (probeIds.length === 0) {
          formData.append("probeIds", "null");
          formData.append("probeNames", "null");
        } else {
          formData.append("probeIds", probeIds);
          formData.append("probeNames", probeNames);
        }
        selectedFiles.map((file) => {
          return formData.append("files", file);
        });

        const res = await this.props.updateCase(formData, caseId);

        if (res) {
          this.setState({ loading: false });
          this.props.successNotification({
            title: "Successfully updated",
            autoDismiss: 1,
          });
          this.props.fetchCases();
          this.props.setProgress(caseId, null);
          this.props.history.push("/facesearch/cases");
        } else {
          this.setState({ loading: false });
          this.props.errorNotification({
            title: "Failed to update case",
            autoDismiss: 1,
          });
        }
      } else {
        this.setState({ loading: false });
        this.props.warningNotification({
          title: "Please select some images",
          autoDismiss: 1,
        });
      }
    } else {
      this.setState({ loading: false });
      this.props.warningNotification({
        title: "Please fill the Case Name",
        autoDismiss: 1,
      });
    }
  };

  render() {
    const {
      caseName,
      caseFiles,
      caseNotes,
      selectedFiles,
      loading,
      loaderMessage,
      deleteModal,
      deletingCase,
    } = this.state;

    return (
      <LoadingOverlay isActive={loading} loaderMessage={loaderMessage}>
        <DeleteCaseModal
          isVisible={deleteModal}
          caseName={caseName}
          isDeleting={deletingCase}
          handleCancel={() => this.setState({ deleteModal: false })}
          handleDelete={this.handleDeleteCase}
        />
        <div>
          <div
            style={{
              color: "#142945",
              fontFamily: "Muli",
              fontSize: "2.2rem",
              fontWeight: "700",
              letterSpacing: "0.18px",
              margin: "2.5rem 5rem",
              padding: "0 2rem",
            }}
          >
            <span style={{ opacity: "0.5", marginRight: "0rem" }}>
              Manage Cases > Edit Case >
            </span>{" "}
            {this.state.caseName}
          </div>
          <FormWrapper {...this.props}>
            <form onSubmit={(e) => e.preventDefault()}>
              <InputField
                label="Case Name*"
                id="case-name"
                name="case-name"
                value={caseName}
                handleChange={(e) =>
                  this.setState({ caseName: e.target.value, error: {} })
                }
                error={this.state.error.caseName}
              />
              <TextAreaField
                label="Notes"
                id="note"
                name="note"
                value={caseNotes}
                handleChange={(e) =>
                  this.setState({ caseNotes: e.target.value })
                }
                required={false}
              />
              <Dropzone
                files={selectedFiles}
                setFiles={this.setFiles}
                handleDelete={(id) => {
                  const file = caseFiles.filter((e) => e._id === id)[0];
                  this.handleDelete(file);
                }}
                selectedFiles={caseFiles.map((x) => ({
                  ...x,
                  url: `${SERVER_URL}/images/${x.url}`,
                }))}
                handleDrop={this.setSelectedFiles}
                accept="image/*"
                thumbWidth={21.2}
                thumbHeight={15}
                error={this.state.error.selectedFiles}
              />
            </form>
          </FormWrapper>
          <div
            className="buttons"
            style={{
              display: "flex",
              backgroundColor: "#fff",
              padding: "2rem 5rem",
              position: "sticky",
              bottom: "0",
            }}
          >
            <div className="delete-btn">
              <Button
                {...buttonProps}
                color="#FF3B30"
                border="1px solid #FF3B30"
                background="transparent"
                boxShadow="none"
                onClick={() => this.setState({ deleteModal: true })}
              >
                Delete
              </Button>
            </div>
            <div
              className="update-btn"
              style={{ flex: 2, display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                {...buttonProps}
                background="transparent"
                color="#777"
                boxShadow="none"
                border="none"
                onClick={() => this.props.history.push("/facesearch/cases")}
              >
                Cancel
              </Button>
              <Button {...buttonProps} onClick={this.handleUpdate}>
                Update
              </Button>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

EditCase.propTypes = {
  fontFamily: PropTypes.string,
  fontColor: PropTypes.string,
  background: PropTypes.string,
  width: PropTypes.number,
  handleUpdate: PropTypes.func,
  handleCancel: PropTypes.func,
};

EditCase.defaultProps = {
  fontFamily: '"Muli", sans-serif',
  fontColor: "#142945",
  background: "#FFFFFF",
  width: 1040,
};

export default connect(null, {
  fetchCase,
  updateCase,
  deleteCase,
  warningNotification,
  errorNotification,
  successNotification,
  fetchCases,
  setProgress,
})(EditCase);
