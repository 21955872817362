import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const InputWrapper = styled.div`
  width: ${(props) => `${props.width}`};

  label {
    width: 100%;
    color: rgba(20, 41, 69, 0.5);
    line-height: 3rem;
    font-size: 1.4rem;
    display: block;
    margin-bottom: 1.8rem;

    input {
      font-family: ${(props) => props.fontFamily};
      box-sizing: border-box;
      height: 4.8rem;
      width: 100%;
      padding-left: 1.6rem;
      line-height: 2rem;
      font-size: 1.6rem;
      border-radius: 3px;
      border: ${(props) => props.border};
      &:focus {
        outline: 0;
      }
    }
  }
  div {
    color: #f00;
    font-size: 1.2rem !important;
  }
`;

const InputField = (props) => {
  return (
    <InputWrapper {...props}>
      <label htmlFor={props.name}>
        {props.label}
        <input
          type={props.type}
          id={props.id}
          name={props.name}
          placeholder={props.placeholder}
          required={props.required}
          value={props.value}
          onChange={props.handleChange}
        />
        <div>{props.error}</div>
      </label>
    </InputWrapper>
  );
};

InputField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  handleChange: PropTypes.func,
  width: PropTypes.string,
  border: PropTypes.string,
  error: PropTypes.string,
  fontFamily: PropTypes.string,
};

InputField.defaultProps = {
  type: "text",
  required: true,
  width: "100%",
  border: "1px solid #edeff2",
  error: "",
  fontFamily: '"Muli", sans-serif',
};

export default InputField;
