import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import Button from "../../components/common/Button";
import { editProfile } from "../../actions";
import Carousal from "../../containers/Carousal";
import Loader from "../Loader/Loader";
import { colors } from "../themes/base";

const ViewBlock = styled.div`
  background: ${(props) => props.background};
  padding: 20px;
  width: 90%;
  min-height: 73vh;
  border-radius: 3px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const FormWrapper = styled.form`
  width: 50%;
  padding: 2.5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .formFields {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    height: 7rem;

    .labelNames {
      opacity: 0.5;
      color: #142945;
      font-family: ${(props) => props.fontFamily};
      font-size: 14px;
      line-height: 1.8rem;
      flex-basis: 30%;
    }

    .labelValues {
      font-size: 1.6rem;
      color: #142945;
      flex-basis: 70%;

      p {
        margin: 0;
      }
    }

    &:last-child {
      border-bottom: 0;
      height: 100%;
      margin-top: 2.5rem;
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;

    .update-btn {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

const ManageUsersWrapper = styled.div`
  font-family: ${(props) => props.fontFamily};
  background-color: #f7f8fb;
  margin: 0 auto;
  padding: 2.5rem 5rem;
`;

const HeadingBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  .heading {
    opacity: 0.5;
    color: #142945;
    font-family: ${(props) => props.fontFamily};
    font-size: 2.2rem;
    font-weight: 700;
    letter-spacing: 0.18px;
    margin-right: 1rem;
  }
  .subheading {
    color: #142945;
    font-family: ${(props) => props.fontFamily};
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0.18px;
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ViewProfile = (props) => {
  if (props.loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader />
      </div>
    );
  }
  return (
    <>
      <ManageUsersWrapper {...props}>
        <HeadingWrapper>
          <HeadingBlock>
            <div className="heading">Manage Watchlist > </div>
            <div className="subheading">{`${props.firstname} ${props.lastname}`}</div>
          </HeadingBlock>

          <Button
            boxShadow="0 2px 10px 0 rgba(44,137,255,0.06)"
            height="36"
            width="75"
            color="#fff"
            background={colors.brand}
            fontSize="1.4rem"
            margin="0px 10px"
            borderRadius="3rem"
            onClick={() => {
              props.editProfile(props._id);
              props.history.push(`/facesearch/watchlist/edit/${props._id}`);
            }}
          >
            Edit
          </Button>
        </HeadingWrapper>
      </ManageUsersWrapper>
      <ViewBlock {...props}>
        <Carousal images={props.gallery} />
        <FormWrapper {...props}>
          <div className="formFields">
            <div className="labelNames">Full Name*</div>
            <label className="labelValues">{`${props.firstname} ${props.lastname}`}</label>
          </div>
          <div className="formFields">
            <div className="labelNames">Date Of Birth</div>
            <label className="labelValues">
              {props.dob ? props.dob.slice(0, 10) : "--/--/--"}
            </label>
          </div>
          <div className="formFields">
            <div className="labelNames">Gender</div>
            <label className="labelValues">{props.gender}</label>
          </div>
          <div className="formFields">
            <div className="labelNames">Location</div>
            <label className="labelValues">
              {props.loc ? props.loc : "N/A"}
            </label>
          </div>
          <div className="formFields notes">
            <div className="labelNames">Notes</div>
            <label className="labelValues">
              <p>{props.notes ? props.notes : "N/A"}</p>
            </label>
          </div>
        </FormWrapper>
      </ViewBlock>
    </>
  );
};

ViewProfile.propTypes = {
  fontFamily: PropTypes.string,
  fontColor: PropTypes.string,
  background: PropTypes.string,
  width: PropTypes.number,
  handleUpdate: PropTypes.func,
  handleCancel: PropTypes.func,
  handleDelete: PropTypes.func,
};

ViewProfile.defaultProps = {
  fontFamily: '"Muli", sans-serif',
  fontColor: "#142945",
  background: "#FFFFFF",
  width: 1040,
};

export default connect(null, { editProfile })(ViewProfile);
