import {
  SET_VIDEO_FILES,
  SET_OFFICER_NAME,
  SET_VIDEO_REDACTION_PURPOSE,
  APPEND_VIDEO_FILES,
  SET_RAW_VIDEOS,
  SET_REDACTED_VIDEOS,
  RESET_FORM,
  SET_UPLOADING,
  SET_FILES_UPLOADING_PROGRESS,
  SET_AUDIO_FILES,
  APPEND_AUDIO_FILES,
} from "../actions/types";

const INITIAL_STATE = {
  officerName: "",
  redactionPurpose: "",
  comment: "",
  videosList: [],
  audioList: [],
  rawVideos: [],
  redactedVideos: [],
  uploading: false,
  uploadingProgress: 0,
};

const redactionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_OFFICER_NAME:
      return { ...state, officerName: action.payload };
    case SET_VIDEO_REDACTION_PURPOSE:
      return { ...state, redactionPurpose: action.payload };
    case SET_VIDEO_FILES:
      return { ...state, videosList: action.payload };
    case APPEND_VIDEO_FILES:
      return { ...state, videosList: [...state.videosList, ...action.payload] };
    case SET_AUDIO_FILES:
      return { ...state, audioList: action.payload };
    case APPEND_AUDIO_FILES:
      return { ...state, audioList: [...state.audioList, ...action.payload] };
    case SET_RAW_VIDEOS:
      return { ...state, rawVideos: action.payload };
    case SET_REDACTED_VIDEOS:
      return { ...state, redactedVideos: action.payload };
    case RESET_FORM:
      return {
        ...state,
        officerName: "",
        redactionPurpose: "",
        comment: "",
        videosList: [],
      };
    case SET_UPLOADING:
      return { ...state, uploading: action.payload };
    case SET_FILES_UPLOADING_PROGRESS: {
      return {
        ...state,
        uploadingProgress: action.payload,
      };
    }
    default:
      return state;
  }
};
export default redactionReducer;
