import { combineReducers } from "redux";
import { reducer as notifications } from "react-notification-system-redux";
import { userConstants } from "../constants/user.constants";
import UserReducer from "./user.reducer";
import CaseReducer from "./case.reducer";
import WUserReducer from "./wuser.reducer";
import WUsersReducer from "./wusers.reducer";
import SearchCaseReducer from "./search.case.reducer";
import loaderReducer from "./loader.reducer";
import redactionReducer from "./redaction.reducer";
import notificationReducer from "./notification.reducer";
import requestReducer from "./request.reducer";
import encodeReducer from "./encode.reducer";

const appReducer = combineReducers({
  notifications,
  user: UserReducer,
  case: CaseReducer,
  search: SearchCaseReducer,
  wuser: WUserReducer,
  wusers: WUsersReducer,
  loader: loaderReducer,
  redaction: redactionReducer,
  notification: notificationReducer,
  request: requestReducer,
  encode: encodeReducer
});

const rootReducer = (state, action) => {
  if (action.type === userConstants.LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
