import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const InputContainer = styled.div`
  font-weight: bolder;

  input {
    border: 0;
    width: 18px;
    font-size: 15px;
    text-align: center;
    font-weight: 400;
    background: transparent;

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    [type="number"] {
      -moz-appearance: textfield;
    }

    :focus {
      outline: 0;
    }
  }

  .sec-input {
    width: 38px;
    text-align: start;
  }
`;

function TimeInput(props) {
  useEffect(() => {
    const hrInput = document.getElementById(`${props.id}_hr`);
    const minInput = document.getElementById(`${props.id}_min`);
    const secInput = document.getElementById(`${props.id}_sec`);

    hrInput.addEventListener("keypress", avoidEventPropagation);
    minInput.addEventListener("keypress", avoidEventPropagation);
    secInput.addEventListener("keypress", avoidEventPropagation);

    return () => {
      hrInput.addEventListener("keypress", avoidEventPropagation);
      minInput.addEventListener("keypress", avoidEventPropagation);
      secInput.addEventListener("keypress", avoidEventPropagation);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const avoidEventPropagation = (e) => {
    e.stopPropagation();
  };

  const handleTimeChange = (hr, min, sec) => {
    props.onChange(`${hr}:${min}:${sec}`);
  };

  return (
    <InputContainer>
      <input
        id={`${props.id}_hr`}
        type="number"
        min={0}
        step={1}
        max={props.duration.split(":")[0]}
        value={props.value.split(":")[0]}
        onChange={(e) =>
          handleTimeChange(
            e.target.value,
            props.value.split(":")[1],
            props.value.split(":")[2]
          )
        }
      />
      :
      <input
        id={`${props.id}_min`}
        type="number"
        min={0}
        step={1}
        max={props.duration.split(":")[1]}
        value={props.value.split(":")[1]}
        onChange={(e) =>
          handleTimeChange(
            props.value.split(":")[0],
            e.target.value,
            props.value.split(":")[2]
          )
        }
      />
      :
      <input
        id={`${props.id}_sec`}
        type="number"
        className="sec-input"
        min={0}
        step={0.01}
        value={props.value.split(":")[2]}
        onChange={(e) =>
          handleTimeChange(
            props.value.split(":")[0],
            props.value.split(":")[1],
            e.target.value
          )
        }
      />
    </InputContainer>
  );
}

export default TimeInput;

TimeInput.propTypes = {
  padding: PropTypes.string,
  justifyContent: PropTypes.string,
  duration: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

TimeInput.defaultProps = {
  padding: "0 1rem",
  justifyContent: "space-between",
  duration: "00:00:00.00",
  value: "00:00:00.00",
  onChange: () => {},
};
