import React, { useState } from "react";

import VolumeHigh from "../../assets/img/volume-high.png";
import VolumeLow from "../../assets/img/volume-low.png";
import { colors } from "../../components/themes/base";

function VolumeBar({ wavesurfer }) {
  const [volume, setValue] = useState(1);

  function isCurrentTimeARegion() {
    let isInside = false;

    const currentTime = wavesurfer.getCurrentTime();
    const regionIds = Object.keys(wavesurfer.regions.list);

    for (let id of regionIds) {
      const { start, end } = wavesurfer.regions.list[id];

      if (currentTime >= start && currentTime <= end) {
        isInside = true;
        break;
      }
    }

    return isInside;
  }

  function handleVolume(e) {
    if (!wavesurfer) {
      return;
    }

    // Checking if the current time is inside a region
    if (isCurrentTimeARegion()) {
      wavesurfer.setVolume(0);
    } else {
      wavesurfer.setVolume(e.target.value);
    }

    // Changing the state value
    setValue(e.target.value);
  }

  return (
    <div className="volume-container">
      <img className="volume-img" alt="" src={VolumeLow} />
      <input
        className="wavesurfer-volume-slider"
        id="wavesurfer-volume"
        style={{
          background: `linear-gradient(
            to right,
            ${colors.brand} 0%,
            ${colors.brand} ${volume * 100}%,
            rgb(189, 188, 188) ${volume * 100}%,
            rgb(189, 188, 188) 100%
          )`,
        }}
        type="range"
        min={0}
        max={1}
        value={volume}
        step={0.01}
        orientation="vertical"
        onChange={handleVolume}
      />
      <img className="volume-img" alt="" src={VolumeHigh} />
    </div>
  );
}

export default VolumeBar;
