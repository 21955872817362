import axios from "axios";
import {
  SET_ENCODING,
  SET_FILES_ENCODING_PROGRESS,
  SET_ENCODE_MODAL,
  SET_ENCODE_VIDEO_ID
} from "./types";
import store from "../store";
import keys from "../config/keys";
import { errorNotification, successNotification } from "./notification.actions";
import { removeRequest } from "./request.actions";
import { fetchVideos } from "./redaction.actions";
const { SERVER_URL } = keys;

export const setEncodingProgress = progress => ({
  type: SET_FILES_ENCODING_PROGRESS,
  payload: progress
});

export const setEncoding = status => ({
  type: SET_ENCODING,
  payload: status
});

export const setEncodeModal = value => ({
  type: SET_ENCODE_MODAL,
  payload: value
});

export const setEncodeVideoId = videoId => ({
  type: SET_ENCODE_VIDEO_ID,
  payload: videoId
});

export const encodeVideo = videoID => async () => {
  try {
    store.dispatch(setEncoding(true));

    const { data } = await axios.get(
      `${SERVER_URL}/redact/videos/${videoID}/encode`,
      { requestId: "encodeVideo" }
    );
    if (data.success) {
      getEnProgressInterval(data.redactionID);
    } else {
      store.dispatch(setEncoding(false));
      store.dispatch(setEncodeModal(false));
      store.dispatch(setEncodeVideoId(null));
      store.dispatch(
        errorNotification({
          title: "Encoding Failed",
          autoDismiss: 1
        })
      );
    }
  } catch (e) {
    store.dispatch(removeRequest("encodeVideo"));
    if (axios.isCancel(e)) {
      return true;
    } else {
      store.dispatch(setEncoding(false));
      store.dispatch(setEncodeModal(false));
      store.dispatch(setEncodeVideoId(null));
      store.dispatch(
        errorNotification({
          title: "Encoding Failed",
          autoDismiss: 1
        })
      );
      console.log(e);
      return false;
    }
  }
};

const getEncodeProgress = async redactionID => {
  try {
    const res = await axios.get(
      `${SERVER_URL}/redact/${redactionID}/encode/progress?buster=` +
        new Date().getTime(),
      { requestId: "getEncodeProgress" }
    );
    if (res.data.success) {
      if (res.data.status === "Failed") {
        return false;
      }
      return res.data;
    } else {
      return false;
    }
  } catch (e) {
    store.dispatch(removeRequest("getEncodeProgress"));
    if (axios.isCancel(e)) {
      return true;
    } else {
      store.dispatch(
        errorNotification({
          title: "failed to encode video.",
          autoDismiss: 1
        })
      );
      console.log(e);
      return false;
    }
  }
};

const getEnProgressInterval = redactionID => {
  const progressInterval = setInterval(async () => {
    const data = await getEncodeProgress(redactionID);
    if (data) {
      if (data.status === "Success") {
        store.dispatch(setEncoding(false));
        store.dispatch(setEncodeModal(false));
        store.dispatch(setEncodeVideoId(null));
        store.dispatch(setEncodingProgress(0));
        store.dispatch(fetchVideos());
        clearInterval(progressInterval);
        store.dispatch(
          successNotification({
            title: "Successfully Encoded",
            autoDismiss: 1
          })
        );
      } else {
        if (data.progress) store.dispatch(setEncodingProgress(data.progress));
      }
    } else {
      store.dispatch(setEncodingProgress(0));
      store.dispatch(setEncoding(false));
      store.dispatch(setEncodeModal(false));
      store.dispatch(setEncodeVideoId(null));
      clearInterval(progressInterval);
      store.dispatch(
        errorNotification({
          title: "failed to encode video.",
          autoDismiss: 1
        })
      );
    }
  }, 3000);
};
