import React, { useRef, useEffect } from "react";
import { Rect, Transformer } from "react-konva";

import { torgba } from "../utils";

function KonvaSquare(props) {
  const cRef = useRef();
  const tRef = useRef();

  useEffect(() => {
    if (props.selected && props.visible) {
      tRef.current.nodes([cRef.current]);
      tRef.current.getLayer().batchDraw();
    }
  }, [props.selected, props.visible]);

  return (
    <React.Fragment>
      <Rect
        {...props}
        draggable
        ref={cRef}
        strokeWidth={Number(props.outline)}
        fill={torgba(props.fillColor, props.fillOpacity)}
        stroke={torgba(props.outlineColor, props.outlineOpacity)}
        onDragMove={(e) => {
          const node = cRef.current;

          if (node.x() < 0) {
            e.target.position({ x: 0, y: node.y() });
          }

          if (node.y() < 0) {
            e.target.position({ x: node.x(), y: 0 });
          }

          if (node.x() + props.width * props.scaleX > props.videodata.width) {
            e.target.position({
              x: props.videodata.width - props.width * props.scaleX,
              y: node.y(),
            });
          }

          if (node.y() + props.height * props.scaleY > props.videodata.height) {
            e.target.position({
              x: node.x(),
              y: props.videodata.height - props.height * props.scaleY,
            });
          }
        }}
        onDragEnd={() => {
          const node = cRef.current;
          const x = node.x() / props.sX;
          const y = node.y() / props.sY;
          const scaleX = node.scaleX() / props.sX;
          const scaleY = node.scaleY() / props.sY;
          const rotation = node.rotation();

          props.storeChanges({
            ...props,
            id: props.id,
            text: false,
            x,
            y,
            scaleX,
            scaleY,
            rotation,
          });
          props.handleShapeDataChange(props.id, { x, y });
        }}
      />

      {props.selected && props.visible && (
        <Transformer
          ref={tRef}
          borderStrokeWidth={1.5}
          borderStroke="#F40050"
          borderDash={[3]}
          anchorFill="#F40050"
          anchorStroke="white"
          anchorStrokeWidth={1}
          anchorCornerRadius={50}
          anchorSize={7}
          rotateAnchorOffset={25}
          onTransform={(e) => {
            const node = cRef.current;
            if (node.x() < 0) {
              e.target.setPosition({ x: 0, y: node.y() });
              node.setAttrs({ width: props.x + props.width });
              node.scaleX(1);
            }

            if (node.y() < 0) {
              e.target.setPosition({ x: node.x(), y: 0 });
              node.scaleY(1);
              node.setAttrs({
                height: props.y + props.height * props.scaleY,
              });
            }

            if (
              node.x() + node.width() * node.scaleX() >
              props.videodata.width
            ) {
              console.log(props.videodata.width, props.x);
              node.scaleX(1);
              node.setAttrs({
                x: props.x,
                width: props.videodata.width - props.x,
              });
            }

            if (
              node.y() + node.height() * node.scaleY() >
              props.videodata.height
            ) {
              node.scaleY(1);
              node.setAttrs({
                y: props.y,
                height: props.videodata.height - props.y,
              });
            }
          }}
          onTransformEnd={() => {
            const node = cRef.current;
            const x = node.x() / props.sX;
            const y = node.y() / props.sY;
            const scaleX = node.scaleX() / props.sX;
            const scaleY = node.scaleY() / props.sY;
            const rotation = node.rotation();
            props.storeChanges({
              ...props,
              id: props.id,
              text: false,
              x,
              y,
              scaleX,
              scaleY,
              rotation,
            });
            props.handleShapeDataChange(props.id, {
              x,
              y,
              scaleX,
              scaleY,
              rotation,
            });
          }}
        />
      )}
    </React.Fragment>
  );
}

export default KonvaSquare;
