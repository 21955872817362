import React from "react";
import PropTypes from "prop-types";
import selected_ic from "../../../assets/img/selected_ic.png";

import styled from "styled-components";
import Image from "../Image";

const CustomLabelWrapper = styled.div`
  height: ${props => `${props.height}rem`};
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .avatar {
    flex: 1;
    height: 4rem;
    width: 4rem;
    img {
      height: 4rem;
      width: 4rem;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .username {
    flex: 3;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.8rem;
    font-family: ${props => props.fontFamily};
    color: #223539;
  }
  .gender {
    flex: 3;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.5rem;
    opacity: 0.6;
    font-family: ${props => props.fontFamily};
    color: #223539;
  }
  .location {
    flex: 10;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.5rem;
    opacity: 0.6;
    font-family: ${props => props.fontFamily};
    color: #223539;
  }
  .added {
    flex: 1;
    height: 3.5rem;
    width: 3.5rem;
    padding: 1.4rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

const CustomLabel = props => {
  return (
    <CustomLabelWrapper {...props}>
      <div className="avatar">
        <Image src={props.imgUrl} alt="avatar" />
      </div>
      <div className="username">{props.name}</div>
      <div className="gender">
        {props.age}, {props.gender}
      </div>
      <div className="location">{props.location}</div>
      <div className="added">
        {props.selectedUsers.filter(e => e.id === props.id).length > 0 ? (
          <img src={selected_ic} alt="selected" />
        ) : null}
      </div>
    </CustomLabelWrapper>
  );
};

export default CustomLabel;

CustomLabel.propTypes = {
  height: PropTypes.number,
  fontFamily: PropTypes.string,
  userName: PropTypes.string,
  userDetails: PropTypes.string,
  userPlace: PropTypes.string,
  avatar: PropTypes.element,
  age: PropTypes.string,
  location: PropTypes.string
};

CustomLabel.defaultProps = {
  height: 5,
  fontFamily: '"Muli", sans-serif',
  age: "22yrs",
  location: "Fairfax, AK"
};
