import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { errorNotification } from "../../actions";
import { colors } from "../../components/themes/base";

const ProgressWrapper = styled.div`
  text-align: center;

  .loading-text {
    margin-top: 2rem;
    font-size: 1.4rem;
    font-weight: 600;
    color: #142945;
  }

  .search-progress {
    margin-top: 1rem;
    position: relative;
    width: 30rem;
    height: 0.7rem;
    background: #eee;
    border-radius: 100px;

    .completed {
      height: 100%;
      border-radius: 100px;
      position: absolute;
      top: 0;
      left: 0;
      background: ${colors.brand};
    }
  }
`;

function SearchProgress(props) {
  let progressInterval;
  let pushed = 1;

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    fetchProgress();

    return () => {
      clearInterval(progressInterval);
    };
    // eslint-disable-next-line
  }, []);

  function fetchProgress() {
    progressInterval = setInterval(async () => {
      if (!props.searchID) {
        return;
      }

      const progData = await props.getSearchProgress(props.searchID);
      if (!progData) {
        clearInterval(progressInterval);
        props.errorNotification({
          title: "Failed to fetch progress",
          autoDismiss: 1,
        });
        return;
      }

      if (progData.progress && progress !== progData.progress) {
        setProgress(progData.progress);
      }

      if (progData.progress === 100) {
        clearInterval(progressInterval);
        if (pushed) {
          pushed = 0;
          props.history.push(
            `/facesearch/searchcase/${props.searchID}/${props.jobID}/result`
          );
        }
      }
    }, 3000);
  }

  return (
    <ProgressWrapper>
      <div className="loading-text">{progress}% Completed</div>
      <div className="search-progress">
        <div
          className="completed"
          style={{
            width: `${progress * 0.3}rem`,
          }}
        />
      </div>
    </ProgressWrapper>
  );
}

const SearchProgressWithRouter = withRouter(SearchProgress);
export default connect(null, { errorNotification })(SearchProgressWithRouter);
