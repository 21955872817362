import React from "react";

import EditorTools from "../../components/EditorTools/EditorTools";
import VideoProperty from "../../components/EditorTools/VideoProperty";
import TextProperty from "../../components/EditorTools/TextProperty";
import ShapeProperty from "../../components/EditorTools/ShapeProperty";
import ContrastProperty from "../../components/EditorTools/ContrastProperty";
// import EffectsProperty from "../../components/EditorTools/EffectsProperty";
import AudioProperty from "../../components/EditorTools/AudioProperty";
import SpeedProperty from "../../components/EditorTools/SpeedProperty";
import KeyboardShortcuts from "../../components/EditorTools/Keyboardshortcuts";
import Report from "../../components/EditorTools/Report";
import { toHHMMSS } from "./utils";

const ToolsRenderer = ({
  jobProgress,
  videoName,
  selectedtool,
  applying,
  openTools,
  toggle,
  setTool,
  fullScreenMode,
  handleApply,
  videoContext,
  videodata,
  contrast,
  effects,
  text,
  textProps,
  createdTextOrShape,
  deletedTextOrShape,
  setSelected,
  selectedShapeID,
  shape,
  shapeProps,
  audio,
  speed,
  report,
  audiodata,
  caseID,
}) => {
  if (audiodata && audiodata.url) {
    return (
      <EditorTools
        jobProgress={jobProgress}
        applying={applying}
        handleApply={handleApply}
        audiodata={audiodata}
      >
        <AudioProperty {...audio} audiodata={audiodata} />
      </EditorTools>
    );
  }

  return (
    <React.Fragment>
      {selectedtool !== "report" && (
        <EditorTools
          jobProgress={jobProgress}
          applying={applying}
          openTools={openTools}
          toggle={toggle}
          selected={selectedtool}
          onSelect={setTool}
          fullScreenMode={fullScreenMode}
          handleApply={handleApply}
          audiodata={audiodata}
        >
          {selectedtool === "video" && (
            <VideoProperty
              {...videoContext}
              resolution={`${videodata.videoWidth}x${videodata.videoHeight}`}
              duration={toHHMMSS(videodata.duration)}
              fullScreenMode={fullScreenMode}
            />
          )}
          {selectedtool === "contrast" && <ContrastProperty {...contrast} />}
          {/* {selectedtool === "effect" && (
            <EffectsProperty
              {...effects}
              videodata={videodata}
              fullScreenMode={fullScreenMode}
            />
          )} */}
          {selectedtool === "text" && (
            <TextProperty
              {...text}
              {...textProps}
              handleAdd={() => {
                const newText = text.handleAdd();
                createdTextOrShape(true, newText);
              }}
              handleDelete={(id) => {
                deletedTextOrShape(true, id);
                text.handleDelete(id);
              }}
              setSelected={setSelected}
              selectedShapeID={selectedShapeID}
              fullScreenMode={fullScreenMode}
            />
          )}
          {selectedtool === "shape" && (
            <ShapeProperty
              {...shape}
              {...shapeProps}
              handleAdd={(shp) => {
                const newShape = shape.handleAdd(shp);
                createdTextOrShape(false, newShape);
              }}
              handleDelete={(id) => {
                deletedTextOrShape(false, id);
                shape.handleDelete(id);
              }}
              outline={Number(shapeProps.outline)}
              setSelected={setSelected}
              selectedShapeID={selectedShapeID}
              fullScreenMode={fullScreenMode}
            />
          )}

          {selectedtool === "speed" && <SpeedProperty {...speed} />}

          {selectedtool === "keyboard" && (
            <KeyboardShortcuts setTool={setTool} />
          )}
        </EditorTools>
      )}
      {selectedtool === "report" && (
        <Report
          caseID={caseID}
          videoName={videoName}
          setTool={setTool}
          videodata={videodata}
          comments={report.comments}
          addComment={report.addComment}
          deleteComment={report.deleteComment}
          slctCmtID={report.slctCmtID}
          setCmtID={report.setCmtID}
          handleCommentChange={report.handleCommentChange}
          fullScreenMode={fullScreenMode}
        />
      )}
    </React.Fragment>
  );
};

export default ToolsRenderer;
