import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import axios from "axios";
import { withRouter } from "react-router-dom";

import CustomSelectUsers from "./CustomSelectUsers";
import CustomSelectField from "../common/Form/CustomSelectField";
import Button from "../common/Button";
import {
  successNotification,
  warningNotification,
  errorNotification,
} from "../../actions";
import {
  fetchCases,
  fetchUsers,
  filterUsers,
} from "../../actions/search.case.actions";
import Image from "../common/Image";
import cancel from "../../assets/img/cancel.svg";
import keys from "../../config/keys";
import { colors } from "../../components/themes/base";

const buttonProps = {
  width: "140px",
  height: "48px",
  fontSize: "1.6rem",
  background: colors.brand,
  boxShadow: "0 1rem 2rem 0 rgba(124, 160, 73, 0.15)",
  color: "#FFFFFF",
};

const GroupFaceWrapper = styled.div`
  min-height: 73vh;
  max-width: 90%;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(44, 137, 255, 0.06);
  padding: 2rem 10%;
  margin: 0 5rem;
  display: flex;
  flex-direction: column;

  .info {
    opacity: 0.6;
    color: #142945;
    font-family: ${(props) => props.fontFamily};
    font-size: 1.4rem;
    line-height: 2.2rem;
    text-align: center;
    margin-bottom: 3.5rem;
  }

  .selectWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    max-width: 100%;

    .selectedPics {
      margin-top: 0.5rem;
      display: flex;
      flex-wrap: wrap;
      .userPic {
        position: relative;
        width: 6.5rem;
        height: 5rem;
        margin-bottom: 1rem;
        .user {
          margin: 0.8rem;
          top: 0;
          left: 0;
          height: 5rem;
          width: 5rem;
          position: absolute;
          object-fit: cover;
          border-radius: 50%;
        }
        .cancel {
          position: absolute;
          top: 5px;
          right: 5px;
          height: 1.6rem;
          width: auto;
          z-index: 2;
          cursor: pointer;
        }
      }
    }
  }

  .label {
    opacity: 0.5;
    color: #142945;
    font-family: ${(props) => props.fontFamily};
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-bottom: 0.8rem;
  }

  .slider_value {
    opacity: 0.5;
    color: #142945;
    font-family: ${(props) => props.fontFamily};
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  .buttons {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

class GroupFaces extends React.Component {
  state = {
    selectedCase: {},
    selectedUsers: [],
    grouping: false,
  };

  componentDidMount = () => {
    this.selectedAll = false;
    this.props.fetchCases();
    this.props.fetchUsers();
  };

  setSelectedCase = (selectedCase) => {
    this.setState({ selectedCase });
  };

  addSelectedUsers = (user) => {
    const userSelected = this.state.selectedUsers.find(
      (sUser) => sUser.id === user.id
    );
    if (userSelected) {
      return;
    }

    this.setState({ selectedUsers: [...this.state.selectedUsers, user] });
  };

  removeSelectedUser = (user) => {
    const newSelectedUsers = this.state.selectedUsers.filter(
      (sUser) => sUser.id !== user.id
    );
    this.setState({
      selectedUsers: newSelectedUsers,
    });

    this.selectedAll = false;
  };

  handleSelectAll = () => {
    if (this.props.userList) {
      this.setState({ selectedUsers: this.props.userList });
    }

    this.selectedAll = true;
  };

  handleGroup = async () => {
    if (!this.state.selectedCase || !this.state.selectedCase.id) {
      this.props.warningNotification({
        title: "Please select a case to group",
      });
      return;
    }

    if (!this.state.selectedUsers || !this.state.selectedUsers.length) {
      this.props.warningNotification({
        title: "Please select the users to group",
      });
      return;
    }

    this.setState({ grouping: true });
    try {
      let usersIDs = [];
      if (!this.selectedAll) {
        usersIDs = this.state.selectedUsers.map((sUser) => sUser.id);
      }

      const { data } = await axios.post(`${keys.SERVER_URL}/group`, {
        caseID: this.state.selectedCase.id,
        users: usersIDs,
      });

      if (!data.groupID) {
        this.props.errorNotification({
          title: "Uanble to group faces.Please try again",
        });
        return;
      }

      this.props.history.push(`/facesearch/group/${data.groupID}/progress`);
    } catch (err) {
      this.setState({ grouping: false });
      this.props.errorNotification({
        title: "Uanble to group faces.Please try again",
      });
    }
  };

  render() {
    const { props, state } = this;
    const caseList = props.caseList === null ? [] : props.caseList;
    const userList = props.userList === null ? [] : props.userList;

    return (
      <div>
        <div
          style={{
            color: "#142945",
            fontFamily: "Muli",
            fontSize: "2.2rem",
            fontWeight: "700",
            letterSpacing: "0.18px",
            margin: "2.5rem 5rem",
          }}
        >
          <span style={{ opacity: "0.5" }}>Group Faces</span>
          {state.selectedCase &&
            state.selectedCase.name &&
            " > " + state.selectedCase.name}
        </div>
        <GroupFaceWrapper {...props}>
          <div className="info" />
          <div className="selectWrapper">
            <div className="label">{props.searchCaseLabel}</div>
            <CustomSelectField
              onCaseSelect={this.setSelectedCase}
              options={caseList}
              selectedCase={state.selectedCase}
              disabled={state.grouping}
            />
          </div>

          <div className="selectWrapper">
            <div className="label">Select Users*</div>
            <CustomSelectUsers
              onSelectAll={this.handleSelectAll}
              onUserSelect={this.addSelectedUsers}
              options={userList}
              selectedUsers={state.selectedUsers}
              filterUsers={props.filterUsers}
            />

            <div className="selectedPics">
              {state.selectedUsers.map((user) => (
                <div key={`userPicDiv${user.id}`} className="userPic">
                  <Image
                    className="user"
                    src={user.imgUrl}
                    alt="selectedUser"
                  />
                  <img
                    className="cancel"
                    src={cancel}
                    alt="cancel"
                    onClick={() => this.removeSelectedUser(user)}
                  />
                </div>
              ))}
            </div>
          </div>
        </GroupFaceWrapper>
        <div
          className="buttons"
          style={{
            display: "flex",
            backgroundColor: "#fff",
            justifyContent: "flex-end",
            padding: "2rem 5rem",
            position: "sticky",
            bottom: "0",
            zIndex: 10,
          }}
        >
          <Button
            {...buttonProps}
            background="transparent"
            color="#777"
            boxShadow="none"
            border="none"
            onClick={() =>
              props.history.push({
                pathname: `/facesearch/cases`,
              })
            }
            disabled={state.grouping}
          >
            Cancel
          </Button>
          <Button
            {...buttonProps}
            disabled={state.grouping}
            onClick={this.handleGroup}
          >
            Group
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  caseList: state.search.caseList,
  userList: state.search.filteredUsers,
});
const GroupFacesWithRouter = withRouter(GroupFaces);
export default connect(mapStateToProps, {
  fetchCases,
  fetchUsers,
  filterUsers,
  warningNotification,
  errorNotification,
  successNotification,
})(GroupFacesWithRouter);

GroupFacesWithRouter.propTypes = {
  fontFamily: PropTypes.string,
  onCaseSelect: PropTypes.func,
  caseOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  searchCaseLabel: PropTypes.string.isRequired,
  selectedCase: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

GroupFacesWithRouter.defaultProps = {
  fontFamily: '"Muli", sans-serif',
  searchCaseLabel: "Select Case*",
};
