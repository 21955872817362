import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";

const sampledata = [];

const Heading = styled.div`
  color: #142945;
  font-family: Muli;
  font-size: 2.2rem;
  font-weight: 700;
  letter-spacing: 0.18px;
  margin: 2.5rem 5rem;
`;

const Wrapper = styled.div`
  min-height: 73vh;
  max-width: 94%;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(44, 137, 255, 0.06);
  margin: 0 5rem;
  display: flex;
  font-family: Muli;
  flex-direction: row;
  max-height: 85vh;

  .photos-container {
    width: 75%;

    .main-image-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 90.5%;

      .boxes-container {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .details-container {
    width: 25%;
    border-left: 1px solid #e4e4e4;

    .heading {
      display: flex;
      font-size: 1.8rem;
      align-items: center;
      height: 36px;
      padding: 10px;
      border-bottom: 1px solid #e4e4e4;
    }

    .details-section {
      padding: 20px;
      max-height: 75vh;
      overflow: scroll;

      .details {
        margin-top: 40px;

        .detail {
          display: flex;
          font-size: 1.5rem;
          justify-content: space-between;
        }
      }

      .sub-heading {
        display: flex;
        height: 25px;
        font-size: 1.5rem;
        justify-content: space-between;
        padding: 0;
        margin: 5px 0;
        opacity: 0.5;
        font-family: "sofia-pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
        line-height: 17px;
        font-size: 11px;
        color: #030303;
        text-transform: uppercase;
      }

      .sub-images {
        margin: auto;
        width: 20vw;
        overflow-x: scroll;
        white-space: nowrap;
        vertical-align: middle;
      }
    }
  }
`;

const Divider = styled.div`
  height: 1px;
  border-bottom: 1px solid #e4e4e4;
`;

const SelectorImageComponent = styled.div`
  display: flex;
  margin: ${(props) => props.margin || "0 10px"};
  align-items: center;
  justify-content: space-between;

  .scroll-component {
    margin: auto;
    width: ${(props) => props.width || "500px"};
    overflow-x: scroll;
    white-space: nowrap;
    vertical-align: middle;
  }
`;

const SelectorImage = styled.button`
  margin: ${(props) => props.margin || "10px"};
  height: ${(props) => props.height || "50px"};
  width: ${(props) => props.width || "50px"};
  padding: 2px;
  display: inline-block;
  cursor: pointer;
  object-fit: cover;
  background: url(${(props) => props.url});
  background-size: cover;
  background-repeat: no-repeat;
  border: ${(props) => (props.selected ? "3px solid #149df2" : "none")};
`;

const Box = (props) => (
  <div
    style={{
      position: "absolute",
      top: `${props.top * props.scaleY}px`,
      left: `${props.left * props.scaleX}px`,
    }}
  >
    <div
      style={{
        top: 0,
        left: 0,
        width: "50px",
        alignItems: "center",
        height: "10px",
        lineHeight: 0,
        display: "flex",
        justifyContent: "space-between",
        background: "#149df2",
        color: "#ffffff",
        position: "absolute",
        transform: "translateY(-100%)",
        padding: "1px 3px",
        borderRadius: "2px 2px 0 0",
      }}
    >
      <span style={{ lineHeight: "0.75rem", fontSize: "1rem" }}>39</span>
      <span style={{ lineHeight: "0.75rem", fontSize: "0.95rem" }}>0.69</span>
    </div>
    <div
      className="box"
      style={{
        height: `${props.height * props.scaleY}px`,
        width: `${props.width * props.scaleX}px`,
        border: "3px solid #149df2",
      }}
    ></div>
  </div>
);

let initialWidth = 600;
let initialHeight = 400;

class Demograph extends React.Component {
  state = {
    result: [],
    imagedata: {
      top: 0,
      left: 0,
      width: 600,
      height: 400,
      scaleX: 1,
      scaleY: 1,
    },
    selectedImage: sampledata[0],
    selectedFace: sampledata[0]?.faces[0],
  };

  componentDidMount() {
    const imageEle = document.getElementById("main-img");
    if (!imageEle) {
      return;
    }

    window.addEventListener("resize", () => {
      this.setImagedata(imageEle);
    });
  }

  setImagedata = (ele) => {
    const data = {
      top: ele.offsetTop,
      left: ele.offsetLeft,
      height: ele.offsetHeight,
      width: ele.offsetWidth,
      scaleX: ele.offsetWidth / initialWidth,
      scaleY: ele.offsetHeight / initialHeight,
    };

    this.setState({ imagedata: data });
  };

  handleImageLoad = (e) => {
    initialWidth = e.target.offsetWidth;
    initialHeight = e.target.offsetHeight;

    this.setImagedata(e.target);
  };

  render() {
    const { props, state } = this;

    console.log(this.state.selectedImage);

    return (
      <div>
        <Heading>
          <span style={{ opacity: "0.5" }}>Demographics Result</span>
          {state.selectedCase &&
            state.selectedCase.name &&
            " > " + state.selectedCase.name}
        </Heading>
        <Wrapper {...props}>
          <div className="photos-container">
            <div className="main-image-container">
              <div
                className="boxes-container"
                id="boxes-container"
                style={{
                  top: `${this.state.imagedata.top}px`,
                  left: `${this.state.imagedata.left}px`,
                  height: `${this.state.imagedata.height}px`,
                  width: `${this.state.imagedata.width}px`,
                }}
              >
                {this.state.selectedImage.faces.map((face) => (
                  <Box
                    key={face.id}
                    top={face.top}
                    left={face.left}
                    height={face.height}
                    width={face.width}
                    scaleX={this.state.imagedata.scaleX}
                    scaleY={this.state.imagedata.scaleY}
                  />
                ))}
              </div>
              <img
                alt=""
                id="main-img"
                src={this.state.selectedImage.url}
                onLoad={this.handleImageLoad}
              />
            </div>

            <SelectorImageComponent width="55vw" margin="0 40px">
              <FaLongArrowAltLeft
                size={20}
                title="Left Most"
                cursor="pointer"
                color="#43546a"
                opacity={0.5}
                onClick={() => {
                  document.getElementById("person-selector").scrollLeft = 0;
                }}
              />

              <div className="scroll-component" id="person-selector">
                {sampledata.map((img) => (
                  <SelectorImage
                    key={img.id}
                    url={img.url}
                    onClick={() =>
                      this.setState({
                        selectedImage: img,
                        selectedFace: img.faces[0],
                      })
                    }
                    selected={this.state.selectedImage.id === img.id}
                  />
                ))}
              </div>

              <FaLongArrowAltRight
                size={20}
                title="Right Most"
                cursor="pointer"
                color="#43546a"
                opacity={0.5}
                onClick={() => {
                  const ele = document.getElementById("person-selector");
                  ele.scrollLeft = ele.scrollWidth;
                }}
              />
            </SelectorImageComponent>
          </div>

          <div className="details-container">
            <div className="heading">Demographics</div>

            <div className="details-section">
              <div className="sub-heading">Faces Detected</div>
              <SelectorImageComponent width="15vw">
                <FaLongArrowAltLeft
                  size={20}
                  title="Left Most"
                  cursor="pointer"
                  color="#43546a"
                  opacity={0.5}
                  onClick={() => {
                    document.getElementById("face-selector").scrollLeft = 0;
                  }}
                />

                <div className="scroll-component" id="face-selector">
                  {this.state.selectedImage.faces.map((face) => (
                    <SelectorImage
                      key={face.id}
                      height="35px"
                      width="35px"
                      margin="5px"
                      url={face.thumburl}
                      onClick={() => this.setState({ selectedFace: face })}
                      selected={this.state.selectedFace.id === face.id}
                    />
                  ))}
                </div>

                <FaLongArrowAltRight
                  size={20}
                  title="Right Most"
                  cursor="pointer"
                  color="#43546a"
                  opacity={0.5}
                  onClick={() => {
                    const ele = document.getElementById("face-selector");
                    ele.scrollLeft = ele.scrollWidth;
                  }}
                />
              </SelectorImageComponent>

              <div className="details">
                <div className="sub-heading">
                  <p>Gender Appearence</p>
                  <p>Probability</p>
                </div>
                <div className="detail">
                  <p>feminine</p>
                  <p>0.912</p>
                </div>
                <Divider />
                <div className="detail">
                  <p>masculine</p>
                  <p>0.112</p>
                </div>
                <Divider />
              </div>

              <div className="details">
                <div className="sub-heading">
                  <p>Age Appearence</p>
                  <p>Probability</p>
                </div>
                <div className="detail">
                  <p>39</p>
                  <p>0.69</p>
                </div>
                <Divider />
              </div>

              <div className="details">
                <div className="sub-heading">
                  <p>MULTICULTURAL APPEARANCE</p>
                  <p>Probability</p>
                </div>
                <div className="detail">
                  <p>asian</p>
                  <p>0.002</p>
                </div>
                <Divider />
                <div className="detail">
                  <p>native hawaiian or pacific islander</p>
                  <p>0.001</p>
                </div>
                <Divider />
                <div className="detail">
                  <p>white</p>
                  <p>0.002</p>
                </div>
                <Divider />
                <div className="detail">
                  <p>american indian or alaska native</p>
                  <p>0.003</p>
                </div>
                <Divider />
                <div className="detail">
                  <p>black or african american</p>
                  <p>0.001</p>
                </div>
                <Divider />
                <div className="detail">
                  <p>hispanic, latino, or spanish origin</p>
                  <p>0.002</p>
                </div>
                <Divider />
                <div className="detail">
                  <p>middle eastern or north african</p>
                  <p>0.003</p>
                </div>
                <Divider />
                <div className="detail">
                  <p>middle eastern or north african</p>
                  <p>0.003</p>
                </div>
                <Divider />
                <div className="detail">
                  <p>middle eastern or north african</p>
                  <p>0.003</p>
                </div>
                <Divider />
                <div className="detail">
                  <p>middle eastern or north african</p>
                  <p>0.003</p>
                </div>
                <Divider />
                <div className="detail">
                  <p>middle eastern or north african</p>
                  <p>0.003</p>
                </div>
                <Divider />
                <div className="detail">
                  <p>middle eastern or north african</p>
                  <p>0.003</p>
                </div>
                <Divider />
                <div className="detail">
                  <p>middle eastern or north african</p>
                  <p>0.003</p>
                </div>
                <Divider />
                <div className="detail">
                  <p>middle eastern or north african</p>
                  <p>0.003</p>
                </div>
                <Divider />
                <div className="detail">
                  <p>middle eastern or north african</p>
                  <p>0.003</p>
                </div>
                <Divider />
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
    );
  }
}

const DemographWithRouter = withRouter(Demograph);
export default DemographWithRouter;
