import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const StyledFormButton = styled.button`
  border: none;
  border-radius: 3px;
  padding: ${props => `${props.padding}rem`};
  margin-top: ${props => `${props.marginTop}rem`};
  margin-bottom: ${props => `${props.marginBottom}rem`};
  text-transform: ${props => props.textTransform};
  background-color: ${props => props.background};
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  cursor: pointer;
  font-family: "Muli", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = props => (
  <StyledFormButton {...props}>{props.children}</StyledFormButton>
);

export default Button;

Button.propTypes = {
  background: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  padding: PropTypes.number,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  textTransform: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string
};

Button.defaultProps = {
  background: "#edeff2",
  color: "#000000",
  marginTop: 2,
  padding: 2,
  textTransform: "uppercase",
  fontSize: "1.2rem",
  fontWeight: "bold"
};
