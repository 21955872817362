import React from "react";

import { colors } from "../../components/themes/base";

export default React.createContext({
  videodata: {
    src: "",
    top: 0,
    left: 0,
    height: 500,
    width: 800,
    duration: 100,
    videoHeight: 640,
    videoWidth: 820,
    loaded: false,
  },
  setVideodata: () => {},

  // AudioCrop
  audiocrop: {
    regions: [],
    setRegions: () => {},
    duration: 100,
    setDuration: () => {},
    cropType: "merge",
    setCropType: () => {},
    handleAudioCropDataChange: () => {},
  },

  // Report Context
  report: {
    slctCmtID: null,
    selector: {
      x: 100,
      y: 100,
      width: 100,
      height: 100,
      scaleX: 1,
      scaleY: 1,
    },
    comments: [
      {
        id: "12312-324-dfsf-234",
        time: "00:04:05",
        screenshots: [
          {
            id: "fsdf43543-345-5433mkl",
            x: 100,
            y: 100,
            height: 100,
            width: 100,
          },
        ],
        notes: "Sample Text",
      },
    ],
    setCmtID: () => {},
    addComment: () => {},
    deleteComment: () => {},
    handleCommentChange: () => {},
    handleSelectorChange: () => {},
  },

  audio: {
    audioName: "",
    regions: [],
    duration: 100,
    cropType: "Split",
    setAudioName: () => {},
    setDuration: () => {},
    setCropType: () => {},
    setRegions: () => {},
    handleAdd: () => {},
    handleDelete: () => {},
    handleDataChange: () => {},
  },

  videoContext: {
    bgColor: colors.palette[7],
    videoFit: "Fill Frame",
    resolution: "1280x720",
    duration: "04:33:0",
    bgOpacity: 1,
    cropType: "merge",
    regions: [
      {
        id: "23420-4234-4234df",
        name: "Region 1",
        start: 0,
        end: 100,
        mute: false,
      },
    ],
    setCropType: () => {},
    setRegions: () => {},
    setBgColor: () => {},
    setResolution: () => {},
    setVideoFit: () => {},
    setDuration: () => {},
    setBgOpacity: () => {},
    handleRegionAdd: () => {},
    handleRegionDelete: () => {},
    handleDataChange: () => {},
  },

  contrast: {
    brightness: 1,
    contrast: 1,
    saturation: 1,
    exposure: 0,
    setBrightness: () => {},
    setContrast: () => {},
    setSaturation: () => {},
    setExposure: () => {},
    handleReset: () => {},
  },

  effects: {
    effect: "none",
    setEffect: () => {},
  },

  text: {
    textInitialState: {},
    layers: [
      {
        id: "4353-3453-45-3453",
        name: "Layer1",
        notes: "Sample Text",
        start: 0,
        end: 100,
        x: 0,
        y: 0,
        scaleX: 1,
        scaleY: 1,
        rotation: 0,
        fontFamily: "helvetica",
        fontSize: 48,
        align: "start",
        fontStyle: "normal",
        letterSpace: "0.00",
        lineHeight: "1.00",
        fill: colors.palette[7],
        opacity: 1,
      },
    ],
    setLayers: () => {},
    handleAdd: () => {},
    handleDelete: () => {},
    handleLayerDataChange: () => {},
  },

  shape: {
    shapeInitialState: {},
    selectedShape: "square",
    setSelectedShape: () => {},
    shapes: [
      {
        id: "fsda-fsdf-f43r3-cv",
        name: "Shape1",
        type: "square",
        outline: 2,
        outlineOpacity: 1,
        outlineColor: colors.palette[0],
        fillOpacity: 1,
        fillColor: colors.palette[7],
        start: 0,
        end: 100,
        x: 100,
        y: 100,
        height: 100,
        width: 100,
        scaleX: 1,
        scaleY: 1,
        rotation: 0,
      },
    ],
    setShapes: () => {},
    handleAdd: () => {},
    handleDelete: () => {},
    handleShapeDataChange: () => {},
  },

  speed: {
    rate: 1,
    setRate: () => {},
    handleSpeedReset: () => {},
  },

  getContextData: () => {},
  setContextData: () => {},
});
