import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Redirect } from "react-router-dom";

import { resetPassword, errorNotification } from "../../actions";
import Background from "../../components/Form/Background";
import Button from "../../components/Form/Button";
import Input from "../../components/common/Form/MaterialInput";
import loader from "../../assets/img/loader.svg";
import makeRequest from "../../config/helpers";

const Wrapper = styled.div`
  display: flex;
  .bg {
    @media only screen and (min-width: ${(props) =>
        `${props.mdBreakpoint}px`}) {
      width: 68vw;
    }
    @media only screen and (max-width: ${(props) =>
        `${props.mdBreakpoint}px`}) {
      display: none;
    }
  }
  .resetPassword {
    background: ${(props) => props.background};
    width: 20vw;
    height: 100vh;
    font-family: ${(props) => props.fontFamily};
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: ${(props) => props.margin};
    div:first-child {
      @media only screen and (max-width: ${(props) =>
          `${props.mdBreakpoint}px`}) {
        width: 70vw;
      }
    }
    @media only screen and (max-width: ${(props) =>
        `${props.mdBreakpoint}px`}) {
      width: 70vw;
    }
  }

  .header {
    max-width: 100%;
    display: block !important;
    font-weight: 600;
    font-size: 3rem;
    margin-bottom: 5rem;
  }

  .center-text {
    text-align: center;

    a {
      font-size: 1.3rem;
      color: #00b2e6;
    }
  }

  .error-msg {
    color: red;
    font-size: 1.5rem;
  }
`;

class ResetPassword extends Component {
  state = {
    initialLoading: true,
    error: false,
    loading: false,
    name: "",
    newPassword: "",
    confirmPassword: "",
    token: "",
  };

  componentDidMount() {
    this.setState({ token: this.props.match.params.token });
    this.verifyToken(this.props.match.params.token);
  }

  verifyToken = async (token) => {
    const res = await makeRequest("GET", `/users/token/${token}`);
    if (res.status !== 200 || res.expired) {
      this.setState({ initialLoading: false, error: true });
    } else {
      this.setState({ initialLoading: false });
    }
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  resetPassword = async (e) => {
    e.preventDefault();
    const { name, newPassword, confirmPassword, token } = this.state;
    if (newPassword !== confirmPassword) {
      this.props.errorNotification({
        title: "Passwords do not match",
        autoDismiss: 1,
      });
    } else if (newPassword.length === 0) {
      this.props.errorNotification({
        title: "Password cannot be empty",
        autoDismiss: 1,
      });
    } else if (newPassword.length < 6) {
      this.props.errorNotification({
        title: "Password must have atleast 6 characters",
        autoDismiss: 1,
      });
    } else {
      this.setState({ loading: true });
      const res = await makeRequest("POST", `/reset/${token}`, null, {
        token: token,
        password: newPassword,
        name,
      });
      this.setState({ loading: false });

      if (res.status === 200) {
        this.props.history.push("/signin");
      } else {
        this.props.errorNotification({ title: "Failed to set password" });
      }
    }
  };

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    }

    return (
      <Wrapper {...this.props.styles}>
        <div className="bg">
          <Background />
        </div>

        {!this.state.initialLoading && !this.state.error && (
          <div className="resetPassword">
            <div className="header">Set Password</div>
            <Input
              label="Fullname"
              value={this.state.name}
              placeholder="Fullname"
              name="name"
              handleChange={this.onChange}
            />
            <Input
              label="New Password"
              type="password"
              value={this.state.newPassword}
              placeholder="New Password"
              name="newPassword"
              handleChange={this.onChange}
            />
            <Input
              label="Confirm Password"
              type="password"
              value={this.state.confirmPassword}
              placeholder="Confirm Password"
              name="confirmPassword"
              handleChange={this.onChange}
            />
            <Button
              background="#00B2E6"
              color="#fff"
              onClick={this.resetPassword}
              textTransform="none"
              fontSize="1.8rem"
              fontWeight="normal"
            >
              Submit&nbsp;&nbsp;
              {this.state.loading ? <img src={loader} alt="loader" /> : null}
            </Button>
          </div>
        )}

        {this.state.initialLoading && (
          <div className="resetPassword center-text">
            <div className="header">Loading...</div>
          </div>
        )}

        {!this.state.initialLoading && this.state.error && (
          <div className="resetPassword center-text">
            <div className="error-msg">
              Token expired or Invalid.
              <br />
              Please contact dev@suspecttech.com
            </div>
            <a href="/signin">Login</a>
          </div>
        )}
      </Wrapper>
    );
  }
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
});

ResetPassword.defaultProps = {
  styles: {
    background: "#FFFFFF",
    fontFamily: '"Muli", sans-serif',
    margin: "auto",
    mdBreakpoint: 900,
  },
};

export default connect(mapStateToProps, {
  resetPassword,
  errorNotification,
})(ResetPassword);
