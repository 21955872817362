import React from "react";
import "rc-notification/assets/index.css";
import Notification from "rc-notification";
import { v4 as uuid } from "uuid";
import SuccessNotification from "../components/Notifcations/SuccessNotification";
import WarningNotification from "../components/Notifcations/WarningNotification";
import ErrorNotification from "../components/Notifcations/ErrorNotification";

let notification = null;

// Create  a new notification instance
Notification.newInstance(
  {
    style: { top: 50, right: 0 }
  },
  n => (notification = n)
);

const notificationStyle = {
  padding: 0,
  marginBottom: 5,
  border: "none",
  boxShadow: "none"
};

const closeNotification = key => {
  notification.removeNotice(key);
};

export const successNotification = data => dispatch => {
  let { title, autoDismiss } = data;

  const key = uuid();
  notification.notice({
    content: (
      <SuccessNotification
        title={title}
        notifId={key}
        close={closeNotification}
      />
    ),
    duration: autoDismiss ? autoDismiss : 1,
    key,
    style: notificationStyle
  });
};

export const errorNotification = data => dispatch => {
  let { title, autoDismiss } = data;

  const key = uuid();
  notification.notice({
    content: (
      <ErrorNotification
        title={title}
        notifId={key}
        close={closeNotification}
      />
    ),
    duration: autoDismiss ? autoDismiss : 1,
    key,
    style: notificationStyle
  });
};

export const warningNotification = data => dispatch => {
  let { title, autoDismiss } = data;

  const key = uuid();
  notification.notice({
    content: (
      <WarningNotification
        title={title}
        notifId={key}
        close={closeNotification}
      />
    ),
    duration: autoDismiss ? autoDismiss : 1,
    key,
    style: notificationStyle
  });
};
