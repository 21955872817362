import React, { useState, useEffect } from "react";

import KonvaText from "./TextTool/KonvaText";
import KonvaCircle from "./ShapeTool/KonvaCircle";
import KonvaPentagon from "./ShapeTool/KonvaPentagon";
import KonvaRoundedSquare from "./ShapeTool/KonvaRoundedSquare";
import KonvaSquare from "./ShapeTool/KonvaSquare";
import KonvaStar from "./ShapeTool/KonvaStar";
import KonvaTriangle from "./ShapeTool/KonvaTriangle";

import { toSec } from "./utils";

function getVisibility(currentSecond, start, end, duration) {
  let startInSec = toSec(start);
  let endInSec = toSec(end);

  if (startInSec > endInSec) {
    startInSec = 0;
  }

  if (endInSec > duration) {
    endInSec = duration;
  }

  if (currentSecond >= startInSec && currentSecond <= endInSec) {
    return true;
  }

  return false;
}

function ShapeRenderer(props) {
  const [currentSecond, setSecond] = useState(0);

  useEffect(() => {
    const video = document.getElementById("ff-video");
    if (!video) {
      return;
    }

    video.addEventListener("timeupdate", () => {
      setSecond(video.currentTime);
    });
  }, [props.videodata.duration]);

  return (
    <React.Fragment>
      {props.textList.map((txt) => (
        <KonvaText
          key={txt.id}
          {...txt}
          x={txt.x * props.scaleXRef}
          y={txt.y * props.scaleYRef}
          scaleX={txt.scaleX * props.scaleXRef}
          scaleY={txt.scaleY * props.scaleYRef}
          sX={props.scaleXRef}
          sY={props.scaleYRef}
          text={txt.notes}
          lineHeight={Number(txt.lineHeight)}
          selected={props.selectedShapeID === txt.id}
          letterSpacing={Number(txt.letterSpace)}
          handleTextChange={props.handleTextDataChange}
          visible={getVisibility(
            currentSecond,
            txt.start,
            txt.end,
            props.videodata.duration
          )}
          videodata={props.videodata}
          storeChanges={props.storeChanges}
        />
      ))}

      {props.shapeList.map((shp) => {
        if (shp.type === "triangle") {
          return (
            <KonvaTriangle
              key={shp.id}
              {...shp}
              x={shp.x * props.scaleXRef}
              y={shp.y * props.scaleYRef}
              scaleX={shp.scaleX * props.scaleXRef}
              scaleY={shp.scaleY * props.scaleYRef}
              sX={props.scaleXRef}
              sY={props.scaleYRef}
              selected={props.selectedShapeID === shp.id}
              visible={getVisibility(
                currentSecond,
                shp.start,
                shp.end,
                props.videodata.duration
              )}
              videodata={props.videodata}
              storeChanges={props.storeChanges}
              handleShapeDataChange={props.handleShapeDataChange}
            />
          );
        } else if (shp.type === "circle") {
          return (
            <KonvaCircle
              key={shp.id}
              {...shp}
              x={shp.x * props.scaleXRef}
              y={shp.y * props.scaleYRef}
              scaleX={shp.scaleX * props.scaleXRef}
              scaleY={shp.scaleY * props.scaleYRef}
              sX={props.scaleXRef}
              sY={props.scaleYRef}
              selected={props.selectedShapeID === shp.id}
              visible={getVisibility(
                currentSecond,
                shp.start,
                shp.end,
                props.videodata.duration
              )}
              videodata={props.videodata}
              storeChanges={props.storeChanges}
              handleShapeDataChange={props.handleShapeDataChange}
            />
          );
        } else if (shp.type === "star") {
          return (
            <KonvaStar
              key={shp.id}
              {...shp}
              x={shp.x * props.scaleXRef}
              y={shp.y * props.scaleYRef}
              scaleX={shp.scaleX * props.scaleXRef}
              scaleY={shp.scaleY * props.scaleYRef}
              sX={props.scaleXRef}
              sY={props.scaleYRef}
              selected={props.selectedShapeID === shp.id}
              visible={getVisibility(
                currentSecond,
                shp.start,
                shp.end,
                props.videodata.duration
              )}
              videodata={props.videodata}
              storeChanges={props.storeChanges}
              handleShapeDataChange={props.handleShapeDataChange}
            />
          );
        } else if (shp.type === "pentagon") {
          return (
            <KonvaPentagon
              key={shp.id}
              {...shp}
              x={shp.x * props.scaleXRef}
              y={shp.y * props.scaleYRef}
              scaleX={shp.scaleX * props.scaleXRef}
              scaleY={shp.scaleY * props.scaleYRef}
              sX={props.scaleXRef}
              sY={props.scaleYRef}
              selected={props.selectedShapeID === shp.id}
              visible={getVisibility(
                currentSecond,
                shp.start,
                shp.end,
                props.videodata.duration
              )}
              videodata={props.videodata}
              storeChanges={props.storeChanges}
              handleShapeDataChange={props.handleShapeDataChange}
            />
          );
        } else if (shp.type === "roundedsquare") {
          return (
            <KonvaRoundedSquare
              key={shp.id}
              {...shp}
              x={shp.x * props.scaleXRef}
              y={shp.y * props.scaleYRef}
              scaleX={shp.scaleX * props.scaleXRef}
              scaleY={shp.scaleY * props.scaleYRef}
              sX={props.scaleXRef}
              sY={props.scaleYRef}
              selected={props.selectedShapeID === shp.id}
              visible={getVisibility(
                currentSecond,
                shp.start,
                shp.end,
                props.videodata.duration
              )}
              videodata={props.videodata}
              storeChanges={props.storeChanges}
              handleShapeDataChange={props.handleShapeDataChange}
            />
          );
        }

        return (
          <KonvaSquare
            key={shp.id}
            {...shp}
            x={shp.x * props.scaleXRef}
            y={shp.y * props.scaleYRef}
            scaleX={shp.scaleX * props.scaleXRef}
            scaleY={shp.scaleY * props.scaleYRef}
            sX={props.scaleXRef}
            sY={props.scaleYRef}
            selected={props.selectedShapeID === shp.id}
            visible={getVisibility(
              currentSecond,
              shp.start,
              shp.end,
              props.videodata.duration
            )}
            videodata={props.videodata}
            storeChanges={props.storeChanges}
            handleShapeDataChange={props.handleShapeDataChange}
          />
        );
      })}
    </React.Fragment>
  );
}

export default ShapeRenderer;
