import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";

import { icons, colors } from "../themes/base";

import Button from "../common/Button";
import SubProperty from "../common/SubProperty";
import ToolbarInput from "../common/ToolbarInput";
import ToolsHeaderText from "../common/ToolsHeaderText";
import ToolbarTimeInput from "../common/ToolbarTimeInput";
import ToolbarColorPicker from "../common/ToolbarColorPicker";
import ToolbarPropDivider from "../common/ToolbarPropDivider";

const SquareShape = styled.div`
  width: 34px;
  height: 34px;
  border: 0;
  background-color: ${colors.shape_tools.blue1};
`;

const RoundedSquare = styled.div`
  width: 34px;
  height: 34px;
  border: 0;
  border-radius: 10px;
  background-color: ${colors.shape_tools.red};
`;

const CircleShape = styled.div`
  width: 34px;
  height: 34px;
  border: 0;
  border-radius: 37px;
  background-color: ${colors.shape_tools.blue2};
`;

const StyledWrapper = styled.div`
  padding: ${(props) => props.padding || "0 10px"};
  border-left: ${(props) => props.borderLeft};

  .shape-text {
    color: rgba(20, 41, 69, 0.5);
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
    text-transform: capitalize;
  }

  .shape-input-container {
    display: flex;
    width: 134px;
    justify-content: space-between;
  }

  .header-container {
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;

    input {
      border: 1px solid transparent;
      color: ${colors.s_light};
      font-family: Muli;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 15px;

      :focus {
        outline: 0;
        border: 1px solid ${colors.s_extralight};
      }
    }
  }

  .error-msg {
    color: red;
    margin: 1rem;
    font-weight: 600;
  }
`;

const ShapesContainer = styled.div`
  @media only screen and (max-width: 1600px) {
    max-height: ${(props) => (props.fullScreenMode ? "53%" : "42%")};
  }

  @media only screen and (max-width: 1450px) {
    max-height: ${(props) => (props.fullScreenMode ? "51%" : "40%")};
  }

  @media only screen and (max-width: 1360px) {
    max-height: ${(props) => (props.fullScreenMode ? "48%" : "35%")};
  }

  max-height: ${(props) => (props.fullScreenMode ? "54%" : "45%")};
  overflow-y: scroll;
`;

const toSec = (timeFormat) => {
  return moment.duration(timeFormat).asSeconds();
};

const getBorderColor = (start, end, duration, selected) => {
  const s = toSec(start);
  const e = toSec(end);
  const d = toSec(duration);

  if (s > d || e > d || s > e) {
    return "6px solid red";
  }

  if (selected) {
    return `6px solid ${colors.primary}`;
  }

  return `6px solid ${colors.text_tools.border}`;
};

const RenderErrorMessage = ({ start, end, duration }) => {
  const s = toSec(start);
  const e = toSec(end);
  const d = toSec(duration);

  if (s > d) {
    return <p className="error-msg">Start time greater than video duration</p>;
  }

  if (e > d) {
    return <p className="error-msg">End time greater than video duration</p>;
  }

  if (s > e) {
    return <p className="error-msg">Start time greater than end time</p>;
  }

  return <p></p>;
};

function getBorder(shape, selectedShape) {
  if (shape === selectedShape) {
    return `2px solid ${colors.shape_tools.blue3}`;
  } else {
    return `1px solid ${colors.s_ultralight}`;
  }
}

function ShapeProperty(props) {
  const handleDataChange = (property, value) => {
    if (!props.id) {
      return;
    }

    props.handleShapeDataChange(props.id, {
      [property]: value,
    });
  };

  return (
    <React.Fragment>
      <StyledWrapper>
        <ToolsHeaderText>Shapes</ToolsHeaderText>
        <SubProperty
          style={{
            flexWrap: "wrap",
            justifyContent: "start",
            margin: "0 10px",
          }}
        >
          <Button
            title="Star"
            width="50px"
            height="50px"
            padding="0 6px"
            borderRadius="2px"
            margin="5px 10px 5px 0px"
            onClick={() => {
              props.setSelectedShape("star");
              props.handleAdd("star");
            }}
            border={getBorder("star", props.selectedShape)}
            background={`url(${icons.IC_Star}) center center / 40px 40px no-repeat`}
          />
          <Button
            title="Square"
            width="50px"
            height="50px"
            borderRadius="2px"
            margin="5px 10px 5px 0px"
            background="transparent"
            onClick={() => {
              props.setSelectedShape("square");
              props.handleAdd("square");
            }}
            border={getBorder("square", props.selectedShape)}
            padding={props.selectedShape === "square" ? "0 6px" : "0 7px"}
          >
            <SquareShape />
          </Button>
          <Button
            title="Rounded Square"
            width="50px"
            height="50px"
            borderRadius="2px"
            margin="5px 10px 5px 0px"
            background="transparent"
            onClick={() => {
              props.setSelectedShape("roundedsquare");
              props.handleAdd("roundedsquare");
            }}
            border={getBorder("roundedsquare", props.selectedShape)}
            padding={
              props.selectedShape === "roundedsquare" ? "0 6px" : "0 7px"
            }
          >
            <RoundedSquare />
          </Button>
          <Button
            title="Circle"
            width="50px"
            height="50px"
            borderRadius="2px"
            margin="5px 10px 5px 0px"
            background="transparent"
            onClick={() => {
              props.setSelectedShape("circle");
              props.handleAdd("circle");
            }}
            border={getBorder("circle", props.selectedShape)}
            padding={props.selectedShape === "circle" ? "0 6px" : "0 7px"}
          >
            <CircleShape />
          </Button>
          <Button
            title="Triangle"
            width="50px"
            height="50px"
            padding="7px"
            borderRadius="2px"
            margin="5px 10px 5px 0px"
            onClick={() => {
              props.setSelectedShape("triangle");
              props.handleAdd("triangle");
            }}
            border={getBorder("triangle", props.selectedShape)}
            background={`url(${icons.IC_Triangle}) center center / 40px 40px no-repeat`}
          />
          <Button
            title="Pentagon"
            width="50px"
            height="50px"
            padding="7px"
            borderRadius="2px"
            margin="5px 10px 5px 0px"
            onClick={() => {
              props.setSelectedShape("pentagon");
              props.handleAdd("pentagon");
            }}
            border={getBorder("pentagon", props.selectedShape)}
            background={`url(${icons.IC_Pentagon}) center center / 40px 40px no-repeat`}
          />
        </SubProperty>
        <ToolbarPropDivider />
        {props.shapes && props.shapes.length > 0 && (
          <>
            <SubProperty>
              <p className="shape-text">Fill</p>
              <ToolbarColorPicker
                tooltipID="shape-colorpicker1"
                selectedColor={props.fillColor}
                opacity={props.fillOpacity}
                setColor={(value) => handleDataChange("fillColor", value)}
                setOpacity={(value) => handleDataChange("fillOpacity", value)}
              />
            </SubProperty>
            <ToolbarPropDivider />
            <SubProperty>
              <p className="shape-text">Outline</p>
              <div className="shape-input-container">
                <ToolbarInput
                  value={props.outline}
                  onChange={(value) => handleDataChange("outline", value)}
                />
                <ToolbarColorPicker
                  tooltipID="shape-colorpicker2"
                  selectedColor={props.outlineColor}
                  opacity={props.outlineOpacity}
                  setColor={(value) => handleDataChange("outlineColor", value)}
                  setOpacity={(value) =>
                    handleDataChange("outlineOpacity", value)
                  }
                />
              </div>
            </SubProperty>
            <ToolbarPropDivider />
          </>
        )}
      </StyledWrapper>

      <ShapesContainer fullScreenMode={props.fullScreenMode}>
        {props.shapes.map((shp) => (
          <StyledWrapper
            key={shp.id}
            padding="0 5px"
            borderLeft={getBorderColor(
              shp.start,
              shp.end,
              props.duration,
              props.selectedShapeID === shp.id
            )}
          >
            <SubProperty>
              <div className="header-container">
                <input
                  value={shp.name}
                  onClick={() => props.setSelected(shp.id)}
                  onChange={(e) => {
                    props.handleShapeDataChange(shp.id, {
                      name: e.target.value,
                    });
                  }}
                />
                <Button
                  padding="7px"
                  background={`url(${icons.IC_Delete}) center center / 13px 13px no-repeat`}
                  onClick={() => props.handleDelete(shp.id)}
                />
              </div>
            </SubProperty>
            <SubProperty>
              <ToolbarTimeInput
                label="Start"
                duration={props.duration}
                value={shp.start}
                onClick={() => props.setSelected(shp.id)}
                onChange={(value) => {
                  props.handleShapeDataChange(shp.id, {
                    start: value,
                  });
                }}
              />
            </SubProperty>
            <SubProperty>
              <ToolbarTimeInput
                label="End"
                duration={props.duration}
                value={shp.end}
                onClick={() => props.setSelected(shp.id)}
                onChange={(value) => {
                  props.handleShapeDataChange(shp.id, {
                    end: value,
                  });
                }}
              />
            </SubProperty>
            <RenderErrorMessage
              start={shp.start}
              end={shp.end}
              duration={props.duration}
            />
          </StyledWrapper>
        ))}

        <StyledWrapper>
          <SubProperty>
            <Button
              fontSize="10px"
              fontWeight="600"
              color="#142945"
              padding="14px 5px 10px 30px"
              background={`url(${icons.IC_Add_Layer}) left center / 23px 23px no-repeat`}
              onClick={() => props.handleAdd()}
            >
              Add New Shape
            </Button>
          </SubProperty>
        </StyledWrapper>
      </ShapesContainer>
    </React.Fragment>
  );
}

export default ShapeProperty;

ShapeProperty.propTypes = {
  selectedShape: PropTypes.string,
  fillColor: PropTypes.string,
  outline: PropTypes.number,
  outlineColor: PropTypes.string,
  outlineOpacity: PropTypes.number,
  shapes: PropTypes.array,
  setSelectedShape: PropTypes.func,
  handleAdd: PropTypes.func,
  handleDelete: PropTypes.func,
  handleShapeDataChange: PropTypes.func,
  duration: PropTypes.string,
};

ShapeProperty.defaultProps = {
  selectedShape: "square",
  fillColor: colors.palette[7],
  fillOpacity: 1,
  outline: 2,
  outlineColor: colors.palette[0],
  outlineOpacity: 1,
  shapes: [],
  duration: "00:00:10.34",
  setSelectedShape: () => {},
  handleAdd: () => {},
  handleDelete: () => {},
  handleShapeDataChange: () => {},
};
