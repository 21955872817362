import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import ColorConverter from "color";

export const torgba = (hex, opacity = 1) => {
  if (hex && (hex.length === 4 || hex.length === 7)) {
    try {
      const colorObj = ColorConverter(hex).rgb().object();
      return `rgba(${colorObj.r},${colorObj.g},${colorObj.b},${opacity})`;
    } catch (err) {
      return hex;
    }
  }

  return hex;
};

export const toSec = (timeFormat) => {
  return moment.duration(timeFormat).asSeconds();
};

export const toHHMMSS = (sec = 100) => {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = Math.floor(sec - hours * 3600 - minutes * 60);
  const milliseconds = sec.toString().split(".")[1] || "0";

  const formatted =
    hours.toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0") +
    ":" +
    seconds.toString().padStart(2, "0") +
    "." +
    milliseconds;

  return formatted.slice(0, 11);
};

export const deepMerge = (regions = []) => {
  const mergedArray = [];

  for (let region of regions) {
    mergedArray.push(toSec(region.start));
    mergedArray.push(toSec(region.end));
  }

  return mergedArray;
};

export const mergeRegions = (regions = [], start, end) => {
  const arr = regions.map((region) => {
    const innerArray = [];
    innerArray.push(toSec(region.start));
    innerArray.push(toSec(region.end));
    innerArray.push(region.mute);

    return innerArray;
  });

  arr.push([start, end, false]);

  const result = arr.slice().sort((a, b) => a[0] - b[0]);

  let i = 0;
  while (i < result.length - 1) {
    const current = result[i];
    const next = result[i + 1];

    // check if there is an overlapping
    if (current[1] >= next[0]) {
      current[1] = Math.max(current[1], next[1]);
      // remove next
      result.splice(i + 1, 1);
    } else {
      // move to next
      i++;
    }
  }

  const mergedRegions = result.map((val, i) => {
    return {
      id: uuidv4(),
      name: `Region ${i + 1}`,
      start: toHHMMSS(val[0]),
      end: toHHMMSS(val[1]),
      mute: val[2],
    };
  });

  return mergedRegions;
};

export const checkStartAndEnd = (start = 0, end, duration) => {
  if (start < 0) {
    start = 0;
  }

  if (end > duration) {
    end = duration;
  }

  if (start >= end) {
    start = 0;
    end = duration;
  }

  return { start, end };
};

export const getBase64Image = (
  video,
  videodata,
  cropRegion = { x: 0, y: 0, height: 100, width: 100 },
  magnifier = false
) => {
  const canvas = document.createElement("canvas");

  if (magnifier) {
    canvas.width = videodata.width * 3;
    canvas.height = videodata.height * 3;
  } else {
    canvas.width = videodata.width;
    canvas.height = videodata.height;
  }

  let displayWidth = canvas.width;
  let displayHeight = canvas.height;

  if (magnifier) {
    displayWidth = canvas.width * 1.3;
    displayHeight = canvas.height * 1.3;
  }

  const ctx = canvas.getContext("2d");
  ctx.drawImage(
    video,
    cropRegion.x,
    cropRegion.y,
    cropRegion.width,
    cropRegion.height,
    0,
    0,
    displayWidth,
    displayHeight
  );
  const base64ImageData = canvas.toDataURL();
  return base64ImageData;
};

export function scrollZoom(container, max_scale, factor) {
  var target = container.children().first();
  var size = { w: target.width(), h: target.height() };
  var pos = { x: 0, y: 0 };
  var zoom_target = { x: 0, y: 0 };
  var zoom_point = { x: 0, y: 0 };
  var scale = 1;
  target.css("transform-origin", "0 0");
  target.on("mousewheel DOMMouseScroll", scrolled);

  function scrolled(e) {
    var offset = container.offset();
    zoom_point.x = e.pageX - offset.left;
    zoom_point.y = e.pageY - offset.top;

    e.preventDefault();
    var delta = e.delta || e.originalEvent.wheelDelta;
    if (delta === undefined) {
      //we are on firefox
      delta = e.originalEvent.detail;
    }
    delta = Math.max(-1, Math.min(1, delta)); // cap the delta to [-1,1] for cross browser consistency

    // determine the point on where the slide is zoomed in
    zoom_target.x = (zoom_point.x - pos.x) / scale;
    zoom_target.y = (zoom_point.y - pos.y) / scale;

    // apply zoom
    scale += delta * factor * scale;
    scale = Math.max(1, Math.min(max_scale, scale));

    // calculate x and y based on zoom
    pos.x = -zoom_target.x * scale + zoom_point.x;
    pos.y = -zoom_target.y * scale + zoom_point.y;

    // Make sure the slide stays in its container area when zooming out
    if (pos.x > 0) pos.x = 0;
    if (pos.x + size.w * scale < size.w) pos.x = -size.w * (scale - 1);
    if (pos.y > 0) pos.y = 0;
    if (pos.y + size.h * scale < size.h) pos.y = -size.h * (scale - 1);

    update();
  }

  function update() {
    target.css(
      "transform",
      "translate(" +
        pos.x +
        "px," +
        pos.y +
        "px) scale(" +
        scale +
        "," +
        scale +
        ")"
    );
  }
}
