import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import InputField from "../../components/common/Form/InputField";
import Dropzone from "../../containers/common/Form/Dropzone";
import Button from "../../components/common/Button";
import LoadingOverlay from "../../components/Loader/LoadingOverlay";
import { updateUserProfile } from "../../actions";
import { errorNotification } from "../../actions";
import Image from "../common/Image";
import { colors } from "../../components/themes/base";

const INITIAL_PASSWORD = "!@#$^&^%&$@#$$";

const buttonProps = {
  width: "140px",
  height: "48px",
  fontSize: "1.6rem",
  background: colors.brand,
  textTransform: "uppercase",
  boxShadow: "0 10px 20px 0 rgba(124, 160, 73, 0.15)",
  color: "#FFFFFF",
};

const FormWrapper = styled.div`
  background: ${(props) => props.background};
  width: 90%;
  border-radius: 3px;
  margin: 2rem auto;
  margin-bottom: 0;
  padding: 3rem;

  form {
    width: ${(props) => props.width};
    margin: 0 auto;
    font-family: ${(props) => props.fontFamily};
    color: ${(props) => props.fontColor};
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 0 10rem;
    box-sizing: border-box;

    .profileImage {
      margin-bottom: 3rem;
    }

    .buttons {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      .update-btn {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }

      button {
        font-size: 1.6rem;
      }
    }

    .formFields {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      div {
        flex: 1 1 45%;
        &:nth-child(1),
        &:nth-child(3) {
          margin-right: 2rem;
        }
      }
    }
    .buttons {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const validateInput = (validationInput, setError) => {
  const items = Object.entries(validationInput);
  const error = {};

  items.forEach(([label, item]) => {
    if (!item || !item.length) {
      error[label] = "Please fill this field";
    }
  });

  if (Object.keys(error).length > 0) {
    setError(error);
    return false;
  }

  return true;
};

const EditProfile = (props) => {
  const [fullName, setFullName] = useState(props.user.fullName);
  const [email, setEmail] = useState(props.user.email);
  const [password, setPassword] = useState(INITIAL_PASSWORD);
  const [confirmPassword, setconfirmPassword] = useState(INITIAL_PASSWORD);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState({});

  const submitProfile = () => {
    const validationInput = { fullName, email };
    if (!validateInput(validationInput, setError)) {
      return;
    }

    let userData = new FormData();
    let flag = 0;
    if (fullName !== "") {
      userData.append("fullName", fullName);
    }
    if (email !== "") {
      userData.append("email", email);
    }
    if (files.length !== 0) {
      userData.append("profileImage", files[0]);
    }
    if (password !== INITIAL_PASSWORD) {
      if (password !== confirmPassword) {
        flag = 1;
        props.errorNotification({
          title: "Passwords don't match",
          autoDismiss: 1,
        });
      } else {
        userData.append("password", password);
      }
    }
    if (flag === 0) props.updateUserProfile(userData, props.history);
  };
  const profileImage = props.user.profileImage ? [props.user.profileImage] : [];
  return (
    <LoadingOverlay
      isActive={props.updatingProfile}
      loaderMessage={"Updating Profile..."}
    >
      <div>
        <div
          style={{
            color: "#142945",
            fontFamily: "Muli",
            fontSize: "2.2rem",
            fontWeight: "700",
            letterSpacing: "0.18px",
            margin: "2.5rem 5rem",
          }}
        >
          Edit Profile
        </div>
        <FormWrapper {...props}>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="formFields">
              <InputField
                width="50%"
                label="Full Name*"
                name="full-name"
                value={fullName}
                handleChange={(e) => {
                  setFullName(e.target.value);
                  setError({});
                }}
                error={error.fullName}
              />
              <InputField
                width="50%"
                label="Email*"
                id="email"
                name="email"
                value={email}
                handleChange={(e) => {
                  setEmail(e.target.value);
                  setError({});
                }}
                error={error.email}
              />
            </div>
            <InputField
              type="password"
              label="Password*"
              id="password"
              name="password"
              value={password}
              handleChange={(e) => setPassword(e.target.value)}
              required={false}
            />
            <InputField
              type="password"
              label="Confirm Password*"
              id="confirmPassword"
              name="confirmPassword"
              value={confirmPassword}
              handleChange={(e) => setconfirmPassword(e.target.value)}
              required={false}
            />
            <Dropzone
              files={files}
              handleDrop={setFiles}
              setFiles={setFiles}
              accept="image/*"
              thumbWidth={15}
              thumbHeight={15}
              multiple={false}
            />
            {files.length === 0 && profileImage.length !== 0 ? (
              <div className="profileImage">
                <Image
                  height="130px"
                  width="auto"
                  src={profileImage[0]}
                  alt="profileImage"
                />
              </div>
            ) : null}
          </form>
        </FormWrapper>
        <div
          className="buttons"
          style={{
            display: "flex",
            backgroundColor: "#fff",
            justifyContent: "flex-end",
            padding: "2rem 4rem",
            position: "sticky",
            bottom: "0",
          }}
        >
          <Button
            {...buttonProps}
            background="transparent"
            color="#777"
            boxShadow="none"
            border="none"
            onClick={() => props.history.push("/video/raw/videos")}
          >
            Cancel
          </Button>
          <Button {...buttonProps} onClick={submitProfile}>
            Update
          </Button>
        </div>
      </div>
    </LoadingOverlay>
  );
};

EditProfile.propTypes = {
  fontFamily: PropTypes.string,
  fontColor: PropTypes.string,
  background: PropTypes.string,
  width: PropTypes.string,
  handleUpdate: PropTypes.func,
  handleCancel: PropTypes.func,
  handleDelete: PropTypes.func,
};

EditProfile.defaultProps = {
  fontFamily: '"Muli", sans-serif',
  fontColor: "#142945",
  background: "#FFFFFF",
  width: "100%",
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  updatingProfile: state.user.updatingProfile,
});

export default connect(mapStateToProps, {
  updateUserProfile,
  errorNotification,
})(EditProfile);
