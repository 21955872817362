import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { colors } from "../themes/base";

const SEARCH_PROGRESS_INTERVAL = 5000;

const ProgressBar = styled.div`
  display: flex;
  align-items: center;

  .search-loader {
    position: relative;
    width: 6rem;
    height: 0.4rem;
    background: #eee;
    border-radius: 100px;
    margin-right: 0.4rem;

    .search-completed {
      width: ${(props) => `${props.progress * 0.06}rem`};
      height: 100%;
      border-radius: 100px;
      position: absolute;
      top: 0;
      left: 0;
      background: ${colors.brand};
    }
  }
`;

function SearchStatus({ searchID, searchProgress, getSearchProgress }) {
  let progressInterval;
  const [progress, setProgress] = useState(searchProgress);

  useEffect(() => {
    if (progress === 0) {
      setProgressInterval();
    }

    return () => {
      clearInterval(progressInterval);
    };
    // eslint-disable-next-line
  }, [searchID]);

  function setProgressInterval() {
    progressInterval = setInterval(async () => {
      if (!searchID) {
        return;
      }

      const resData = await getSearchProgress(searchID);
      if (resData && resData.progress && progress !== resData.progress) {
        setProgress(resData.progress);
      }
    }, SEARCH_PROGRESS_INTERVAL);
  }

  if (progress === null || progress === undefined) {
    return <span style={{ color: "red" }}>Incomplete</span>;
  }

  if (progress === 100) {
    clearInterval(progressInterval);
    return <span style={{ color: "#4CD965" }}>Completed</span>;
  }

  return (
    <ProgressBar progress={progress}>
      <div className="search-loader">
        <div
          className="search-completed"
          style={{ width: `${progress * 0.06}rem` }}
        />
      </div>
      <div className="progress-text">{progress}%</div>
    </ProgressBar>
  );
}

export default SearchStatus;
