import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactSlider from "react-slider";
import { v4 as uuidv4 } from "uuid";

import Timeline from "./Timeline";

import { colors } from "../themes/base";

const secondsToTime = (sec = 100) => {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = Math.floor(sec - hours * 3600 - minutes * 60);
  const milliseconds = sec.toString().split(".")[1] || "0";

  const formatted =
    hours.toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0") +
    ":" +
    seconds.toString().padStart(2, "0") +
    "." +
    milliseconds;

  return formatted;
};

const MainContainer = styled.div`
  margin: 1rem 1rem 5rem 1rem;
  color: ${colors.secondary};
  font-size: 13px;
  font-weight: bold;
  font-family: "Muli", sans-serif;
  max-width: 62vw;
  display: ${(props) => (props.fullScreenMode ? "none" : "block")};
  .slider-container {
    height: 52px;
    max-height: 52px;
    width: 1000px;
    position: relative;

    .imgs-canvas {
      max-width: 62vw;
    }
  }
`;

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  max-width: 62vw;
  height: ${(props) => (props.height ? `${props.height - 2}px` : "50px")};
  max-height: 52px;
  top: 0px;
  left: 0px;
  position: absolute !important;
`;

const StyledThumb = styled.div`
  height: ${(props) => (props.height ? `${props.height - 2}px` : "50px")};
  width: 5px;
  max-height: 52px;
  background-color: red;
  border-radius: 4px;
  cursor: grab;

  &:focus {
    outline: 0;
  }
`;

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  height: ${(props) => `${props.height}px` || "52px"};
  max-height: 52px;
  box-sizing: border-box;
  background: transparent;
  border-radius: 4px;
  border: ${(props) => (props.index % 2 === 1 ? "2px solid red" : "none")};
`;

const Track = (props) => {
  return <StyledTrack {...props} index={props.index} />;
};

function CropSection(props) {
  const mounted = useRef();
  const { regions, regionsArr, setRegions, duration, videoSrc } = props;

  const [imgCanvasHeight, setHeight] = useState(52);

  React.useEffect(() => {
    mounted.current = true;
    extractFramesFromVideo(videoSrc);

    setTimeout(() => {
      setCropSecHeight();
      window.addEventListener("resize", setCropSecHeight);
    }, 1000);

    return () => {
      mounted.current = false;
      window.removeEventListener("resize", setCropSecHeight);
    };
  }, [videoSrc]);

  const setCropSecHeight = () => {
    const c = document.getElementById("imgs-canvas");
    if (c && mounted.current) {
      setHeight(c.offsetHeight);
    }
  };

  const handleChange = (val) => {
    const n = 2;
    const splittedArr = new Array(Math.ceil(val.length / n))
      .fill()
      .map((_) => val.splice(0, n));

    const newRegions = splittedArr.map((arr, i) => {
      return {
        id: uuidv4(),
        name: `Region ${i + 1}`,
        start: secondsToTime(arr[0]),
        end: secondsToTime(arr[1]),
        mute: regions[i].mute,
      };
    });

    setRegions(newRegions);
  };

  async function extractFramesFromVideo(videoUrl) {
    let video = document.createElement("video");

    let seekResolve;
    video.addEventListener("seeked", async function () {
      if (seekResolve) {
        seekResolve();
      }
    });

    video.src = videoUrl;

    while (
      (video.duration === Infinity || isNaN(video.duration)) &&
      video.readyState < 2
    ) {
      await new Promise((r) => setTimeout(r, 1000));
      video.currentTime = 10000000 * Math.random();
    }

    var canvas = document.getElementById("imgs-canvas");
    if (!canvas) {
      return;
    }

    var ctx = canvas.getContext("2d");
    canvas.width = 1000;
    canvas.height = 50;
    canvas.style.borderRadius = "4px";

    let x = 0;
    let currentTime = 0;
    let duration = video.duration;
    let interval = duration / 10;
    while (currentTime < duration) {
      video.currentTime = currentTime;
      // eslint-disable-next-line no-loop-func
      await new Promise((r) => {
        seekResolve = r;
      });

      ctx.drawImage(video, x, 0, 100, 50);
      x = x + 100;
      currentTime += interval;
    }
  }

  return (
    <MainContainer fullScreenMode={props.fullScreenMode}>
      <Timeline duration={duration} />
      <div className="slider-container">
        <canvas className="imgs-canvas" id="imgs-canvas" />
        {regionsArr.length !== 0 && (
          <StyledSlider
            snapDragDisabled
            pearling
            min={0}
            max={duration}
            step={0.1}
            minDistance={0.1}
            height={imgCanvasHeight}
            renderTrack={(trackprops, state) => (
              <Track
                {...props}
                {...trackprops}
                key={uuidv4()}
                height={imgCanvasHeight}
                value={state.value}
                index={state.index}
              />
            )}
            renderThumb={(thumbProps) => (
              <StyledThumb
                {...thumbProps}
                height={imgCanvasHeight}
              ></StyledThumb>
            )}
            value={regionsArr}
            onAfterChange={(val) => handleChange(val)}
          />
        )}
      </div>
    </MainContainer>
  );
}

export default CropSection;

CropSection.propTypes = {
  duration: PropTypes.number,
  videoSrc: PropTypes.string,
};

CropSection.defaultProps = {
  duration: 90,
  videoSrc: "",
};
