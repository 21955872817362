import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { icons, colors } from "../themes/base";

import ToolIcon from "../common/ToolIcon";

const StyledToolbar = styled.div`
  width: 60px;
  z-index: 9;
  background-color: ${colors.brand1};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Divider = styled.div`
  height: 1px;
  width: 60px;
  opacity: 0.15;
  background-color: ${colors.white};
`;

function Toolbar(props) {
  function getSrc(tool) {
    const selected = props.selected === tool;
    let src;

    switch (tool) {
      case "video": {
        src = selected ? icons.IC_Video_Blue : icons.IC_Video;
        break;
      }
      case "correction": {
        src = selected
          ? icons.IC_Color_Correction_Blue
          : icons.IC_Color_Correction;
        break;
      }
      case "contrast": {
        src = selected ? icons.IC_Contrast_Blue : icons.IC_Contrast;
        break;
      }
      case "effect": {
        src = selected ? icons.IC_Effects_Blue : icons.IC_Effects;
        break;
      }
      case "text": {
        src = selected ? icons.IC_Text_Blue : icons.IC_Text;
        break;
      }
      case "draw": {
        src = selected ? icons.IC_Draw_Blue : icons.IC_Draw;
        break;
      }
      case "shape": {
        src = selected ? icons.IC_Shape_Blue : icons.IC_Shape;
        break;
      }
      case "speed": {
        src = selected ? icons.IC_Speed_Blue : icons.IC_Speed;
        break;
      }
      case "audio": {
        src = icons.IC_Audio;
        break;
      }
      case "magnify": {
        src = selected ? icons.IC_Magnify : icons.IC_Magnify_Blue;
        break;
      }
      case "keyboard": {
        src = selected ? icons.IC_KeyboardWhite : icons.IC_Keyboard;
        break;
      }
      default: {
        src = selected ? icons.IC_Video_Blue : icons.IC_Video;
        break;
      }
    }

    return src;
  }

  if (props.audiodata && props.audiodata.url) {
    return (
      <StyledToolbar>
        <ToolIcon
          title="Video Tools"
          style={{ margin: "13px 0" }}
          selected={true}
          src={getSrc("audio")}
          backgroundSize="25px 25px"
        />
      </StyledToolbar>
    );
  }

  return (
    <StyledToolbar>
      {props.fullScreenMode && (
        <>
          <ToolIcon
            title="Toggle Menu"
            style={{ margin: "13px 0" }}
            backgroundSize={"20px 20px"}
            src={icons.IC_Hamburger}
            onClick={props.toggle}
          />
          <Divider />
        </>
      )}

      <ToolIcon
        title="Video Tools"
        style={{ margin: "13px 0" }}
        selected={props.selected === "video"}
        src={getSrc("video")}
        onClick={() => props.onSelect("video")}
      />

      {/* <ToolIcon
        selected={props.selected === "correction"}
        src={getSrc("correction")}
        onClick={() => props.onSelect("correction")}
      /> */}
      <ToolIcon
        title="Contrast Tools"
        selected={props.selected === "contrast"}
        src={getSrc("contrast")}
        onClick={() => props.onSelect("contrast")}
      />
      {/* <ToolIcon
        title="Effect Tools"
        selected={props.selected === "effect"}
        src={getSrc("effect")}
        onClick={() => props.onSelect("effect")}
      /> */}
      <ToolIcon
        title="Text Tools"
        selected={props.selected === "text"}
        src={getSrc("text")}
        onClick={() => props.onSelect("text")}
      />
      {/* <ToolIcon
        selected={props.selected === "draw"}
        src={getSrc("draw")}
        onClick={() => props.onSelect("draw")}
      /> */}
      <ToolIcon
        title="Shape Tools"
        selected={props.selected === "shape"}
        src={getSrc("shape")}
        onClick={() => props.onSelect("shape")}
      />
      <ToolIcon
        title="Video Speed"
        selected={props.selected === "speed"}
        src={getSrc("speed")}
        onClick={() => props.onSelect("speed")}
      />
      <ToolIcon
        title="Keyboard Shortcuts"
        selected={props.selected === "keyboard"}
        backgroundSize={"24px 24px"}
        src={getSrc("keyboard")}
        onClick={() => props.onSelect("keyboard")}
      />
    </StyledToolbar>
  );
}

Toolbar.propTypes = {
  selected: PropTypes.string,
  onSelect: PropTypes.func,
};

Toolbar.defaultProps = {
  selected: "video",
  onSelect: () => {},
};

export default Toolbar;
