import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import InputField from "../../components/common/Form/InputField";
import TextAreaField from "../../components/common/Form/TextAreaField";
import Dropzone from "../common/Form/Dropzone";
import Button from "../../components/common/Button";
import {
  successNotification,
  warningNotification,
  errorNotification,
} from "../../actions";
import {
  addCaseFiles,
  setCaseFiles,
  setCaseName,
  setCaseNotes,
  setCaseUploadStatus,
  uploadCase,
  resetForm,
} from "../../actions/case.actions";
import { fetchCases } from "../../actions/search.case.actions";
import LoadingOverlay from "../../components/Loader/LoadingOverlay";
import { colors } from "../../components/themes/base";

const buttonProps = {
  width: "140px",
  height: "48px",
  fontSize: "1.6rem",
  background: colors.brand,
  boxShadow: "0 10px 20px 0 rgba(124, 160, 73, 0.15)",
  color: "#FFFFFF",
  textTransform: "uppercase",
};

const FormWrapper = styled.div`
  background: ${(props) => props.background};
  width: calc(100% - 10rem); /* minus sidebar width */
  border-radius: 3px;
  margin: 2rem 5rem;
  padding-top: 3rem;
  margin-bottom: 0;
  display: flex;
  justify-content: center;

  form {
    width: 100%;
    min-height: calc(100vh - 175px);
    margin: 0 auto;
    padding: 0 10rem;
    font-family: ${(props) => props.fontFamily};
    color: ${(props) => props.fontColor};
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
`;

const handleRegister = async (
  caseName,
  caseNotes,
  caseFiles,
  uploadCase,
  resetForm,
  warningNotification,
  errorNotification,
  successNotification,
  props,
  setLoading
) => {
  if (caseName.replace(/\s/g, "").length) {
    if (caseFiles.length > 0) {
      const formData = new FormData();
      formData.append("name", caseName);
      formData.append("notes", caseNotes);
      caseFiles.map((file) => {
        return formData.append("files", file);
      });

      const res = await uploadCase(formData);
      if (res) {
        resetForm();
        props.fetchCases();
        successNotification({
          title: "Successfully Created",
          autoDismiss: 1,
        });
        props.history.push("/facesearch/cases");
      } else {
        setLoading(false);
        errorNotification({
          title: "Failed to create case",
          autoDismiss: 1,
        });
      }
    } else {
      warningNotification({
        title: "Please select some images",
        autoDismiss: 1,
      });
    }
  } else {
    warningNotification({
      title: "Please fill the Case Name",
      autoDismiss: 1,
    });
  }
};

const validateInput = (validationInput, setError) => {
  const items = Object.entries(validationInput);
  const error = {};

  items.forEach(([label, item]) => {
    if (!item || !item.length) {
      error[label] = "Please fill this field";
    }
  });

  if (Object.keys(error).length > 0) {
    setError(error);
    return false;
  }

  return true;
};

const CreateCase = (props) => {
  const {
    addCaseFiles,
    setCaseFiles,
    setCaseName,
    setCaseNotes,
    caseName,
    caseFiles,
    caseNotes,
    uploadCase,
    resetForm,
  } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  return (
    <LoadingOverlay isActive={loading} loaderMessage={"Creating Case..."}>
      <div>
        <div
          style={{
            color: "#142945",
            fontFamily: "Muli",
            fontSize: "2.2rem",
            fontWeight: "700",
            letterSpacing: "0.18px",
            margin: "2.5rem 5rem",
          }}
        >
          Create Case
        </div>
        <FormWrapper {...props}>
          <form onSubmit={(e) => e.preventDefault()}>
            <InputField
              label="Case Name*"
              id="case-name"
              name="case-name"
              value={caseName}
              handleChange={(e) => {
                setCaseName(e.target.value);
                setError({});
              }}
              error={error.caseName}
            />
            <TextAreaField
              label="Notes"
              id="note"
              name="note"
              value={caseNotes}
              handleChange={(e) => {
                setCaseNotes(e.target.value);
                setError({});
              }}
              error={error.caseNotes}
            />
            <Dropzone
              files={caseFiles}
              handleDrop={addCaseFiles}
              setFiles={setCaseFiles}
              accept="image/*,.zip"
              thumbWidth={21.2} // 19.7 for 5 in a row
              thumbHeight={15} // 14 for 5 in a row
              error={error.caseFiles}
            />
          </form>
        </FormWrapper>
        <div
          className="buttons"
          style={{
            display: "flex",
            backgroundColor: "#fff",
            justifyContent: "flex-end",
            padding: "2rem 5rem",
            position: "sticky",
            bottom: "0",
            fontSize: "1.6rem",
          }}
        >
          <Button
            {...buttonProps}
            background="transparent"
            color="#777"
            boxShadow="none"
            border="none"
            onClick={() => props.history.push("/facesearch")}
          >
            Cancel
          </Button>
          <Button
            {...buttonProps}
            onClick={(e) => {
              e.preventDefault();

              const validationInput = {
                caseName,
                caseFiles,
              };
              if (!validateInput(validationInput, setError)) {
                return;
              }
              setLoading(true);
              handleRegister(
                caseName,
                caseNotes,
                caseFiles,
                uploadCase,
                resetForm,
                props.warningNotification,
                props.errorNotification,
                props.successNotification,
                props,
                setLoading
              );
            }}
          >
            Register
          </Button>
        </div>
      </div>
    </LoadingOverlay>
  );
};

CreateCase.propTypes = {
  fontFamily: PropTypes.string,
  fontColor: PropTypes.string,
  background: PropTypes.string,
  width: PropTypes.string,
  handleRegister: PropTypes.func,
  handleCancel: PropTypes.func,
};

CreateCase.defaultProps = {
  fontFamily: '"Muli", sans-serif',
  fontColor: "#142945",
  background: "#FFFFFF",
  width: "100%",
};

const mapStateToProps = (state) => ({
  caseName: state.case.caseName,
  caseFiles: state.case.caseFiles,
  caseNotes: state.case.caseNotes,
  caseUploadStatus: state.case.caseUploadStatus,
});

export default connect(mapStateToProps, {
  addCaseFiles,
  setCaseFiles,
  setCaseName,
  setCaseNotes,
  setCaseUploadStatus,
  uploadCase,
  resetForm,
  warningNotification,
  errorNotification,
  successNotification,
  fetchCases,
})(CreateCase);
