import React, { useEffect, useState } from "react";
import { withRouter, Redirect } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import { FiUsers } from "react-icons/fi";
import { FiSearch } from "react-icons/fi";
import { FiUser } from "react-icons/fi";
import { FaPlus } from "react-icons/fa";

import { icons } from "../../components/themes/base";
import Button from "../../components/common/Button";

import HomeOverview from "../../components/HomeOverview/HomeOverview";
import makeRequest from "../../config/helpers";
import { successNotification, errorNotification } from "../../actions";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "../../components/common/Table/Table";
import PlaceholderCaseCard from "../../components/Placeholder/PlaceholderCaseCard";
import CellButton from "../../components/common/Button";
import DeleteUserModal from "../../components/Modal/DeleteUserModal";
import { colors as themecolors } from "../../components/themes/base";

const colors = [
  "#E4584B",
  "#2086dc",
  "#38b14d",
  "#F1A527",
  "#E71581",
  "#47BAFF",
  "#F0C80E",
];

const NameAvatar = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: ${(props) => props.bg || "#2086dc"};
  color: white;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-family: Muli;
  font-weight: 700;
`;

const MainWrapper = styled.div`
  font-family: Muli;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .headerWrapper {
    display: flex;
    justify-content: space-between;
    padding: 2.5rem 0;
    width: 94%;
    color: #142945;
    font-family: Muli;
    font-size: 2.2rem;
    font-weight: 700;
    letter-spacing: 0.18px;
    .buttons {
      display: flex;
    }
  }
`;

const TableWrapper = styled.div`
  background: #f7f8fb;
  margin: 0 5rem;

  table thead {
    outline: thin solid #f7f8fb;
    outline-width: 3px;
  }

  table tr {
    height: 7rem;
  }

  table tr {
    :hover .delete-btn {
      display: table-cell;
    }
  }

  table .image {
    width: 6rem;
  }
`;

const StyledCell = styled(TableCell)`
  text-align: center;

  .delete-btn {
    display: none;
  }
`;

const PlaceHolderCards = () => (
  <>
    {Array.from(Array(5).keys()).map((_, i) => (
      <PlaceholderCaseCard key={i} />
    ))}
  </>
);

function UsersTable(props) {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [orgCount, setOrgCount] = useState(0);

  const [ID, setID] = useState(null);
  const [userName, setName] = useState("");
  const [deleting, setdeleting] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchUsers() {
    const res = await makeRequest("GET", "/users");
    setLoading(false);

    if (res.status === 200) {
      setUsers(res.users);
      setOrgCount(res.orgCount);
    } else {
      props.errorNotification({ title: "Failed to fetch the data" });
    }
  }

  async function handleDelete() {
    if (!ID) {
      return;
    }

    setdeleting(true);
    const res = await makeRequest("DELETE", `/users/${ID}`);
    setdeleting(false);

    setName("");
    setID(null);
    setVisible(false);
    if (res.status === 200) {
      props.successNotification({ title: "Deleted Successfully" });
      fetchUsers();
    } else {
      props.errorNotification({ title: "Failed to delete the user" });
    }
  }

  function clickDelete(name, id) {
    setName(name);
    setID(id);
    setVisible(true);
  }

  const cards = {
    icon1: <FiUsers />,
    heading1: users.length.toString(),
    subheading1: "Users",

    icon2: <FiSearch />,
    heading2: orgCount.toString(),
    subheading2: "Organizations",

    icon3: <FiUser />,
    heading3: "0",
    subheading3: "Suspect Found",
  };

  if (!props.user || props.user.email !== process.env.REACT_APP_ADMIN) {
    return <Redirect to="/" />;
  }

  return (
    <React.Fragment>
      <DeleteUserModal
        isVisible={visible}
        userName={userName}
        isDeleting={deleting}
        handleCancel={() => setVisible(false)}
        handleDelete={handleDelete}
      />

      <MainWrapper>
        <div className="headerWrapper">
          <div className="heading">Users</div>
          <div className="buttons">
            <Button
              boxShadow="0 2px 10px 0 rgba(44,137,255,0.06)"
              height="36"
              width="103"
              color="#fff"
              background={themecolors.brand}
              fontSize="1.4rem"
              margin="0rem 1rem"
              borderRadius="3rem"
              style={{ textTransform: "none" }}
              onClick={() => props.history.push(`/users/invite`)}
            >
              <FaPlus /> &nbsp;{" "}
              <div style={{ marginLeft: "0.5rem" }}>Add User</div>
            </Button>
          </div>
        </div>
      </MainWrapper>

      <HomeOverview margin="0 5rem" {...cards} usersCount={users.length} />

      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell th first className="image">
                #
              </TableCell>
              <TableCell th>ID</TableCell>
              <TableCell th>Name</TableCell>
              <TableCell th>Email</TableCell>
              <TableCell th>Organization</TableCell>
              <TableCell th>Role</TableCell>
              <TableCell th />
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <PlaceHolderCards />
            ) : (
              users.map((userObj, i) => (
                <TableRow key={userObj._id}>
                  <TableCell first>
                    <NameAvatar bg={colors[i % colors.length]}>
                      {userObj && userObj.fullName
                        ? userObj.fullName.slice(0, 2)
                        : "NM"}
                    </NameAvatar>
                  </TableCell>
                  <TableCell>{userObj._id}</TableCell>
                  <TableCell>{userObj.fullName}</TableCell>
                  <TableCell>{userObj.email}</TableCell>
                  <TableCell>
                    {(userObj.org && userObj.org.name) || "NIL"}
                  </TableCell>
                  <TableCell>Collaborator</TableCell>

                  <StyledCell>
                    <CellButton
                      className="delete-btn"
                      height="34px"
                      width="34px"
                      title={`Delete ${userObj.fullName}`}
                      border="1px solid white"
                      background={`url(${icons.IC_Delete}) center center / 22px 22px no-repeat`}
                      onClick={() => clickDelete(userObj.fullName, userObj._id)}
                    />
                  </StyledCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableWrapper>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
});

const UsersTableWithRouter = withRouter(UsersTable);

export default connect(mapStateToProps, {
  successNotification,
  errorNotification,
})(UsersTableWithRouter);
