export const wuserConstants = {
  FETCH_WUSERS: "FETCH_WUSERS",
  SET_USERS_ON_PAGE: "SET_USERS_ON_PAGE",
  QUERY_USER: "QUERY_USER",
  SET_FILTERED_USERS_ON_PAGE: "SET_FILTERED_USERS_ON_PAGE",
  FETCH_WUSERS_ERROR: "FETCH_WUSERS_ERROR",
  FETCH_WUSER: "FETCH_WUSER",
  FETCH_WUSER_ERROR: "FETCH_WUSER_ERROR",
  SET_WUSER_LOADING: "SET_WUSER_LOADING",
  SET_WUSERS_LOADING: "SET_WUSERS_LOADING",
};
