import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { withRouter } from "react-router-dom";

import keys from "../../config/keys";
import searchIcon from "../../assets/img/search-icon.png";
import { colors } from "../../components/themes/base";

const StyleWrapper = styled.div`
  width: ${(props) => props.width};
  height: 85vh;
  font-family: ${(props) => props.fontFamily};
  background-color: #f7f8fb;
  display: flex;
  justify-content: center;
  align-items: center;

  .component {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0 2px 10px rgba(44, 137, 255, 0.06);
    border-radius: 3px;

    .search-icon {
      display: block;
      width: 5rem;
      height: 5rem;
      opacity: 0.7;
      transform: translateX(-0.5rem);
    }
  }
`;

const ProgressWrapper = styled.div`
  text-align: center;

  .loading-text {
    margin-top: 2rem;
    font-size: 1.4rem;
    font-weight: 600;
    color: #142945;
  }

  .search-icon {
    display: block;
    width: 5rem;
    height: 5rem;
    opacity: 0.7;
    transform: translateX(-0.5rem);
  }

  .search-progress {
    margin: 1rem auto;
    position: relative;
    width: 30rem;
    height: 0.7rem;
    background: #eee;
    border-radius: 100px;

    .completed {
      height: 100%;
      border-radius: 100px;
      position: absolute;
      top: 0;
      left: 0;
      background: ${colors.brand};
    }
  }
`;

function Progress(props) {
  let progressInterval;
  let notPushed = true;

  const [progress, setProgress] = useState(0);
  const [failsCount, setCount] = useState(0);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (
      props &&
      props.match &&
      props.match.params &&
      props.match.params.groupid
    ) {
      fetchProgress(props.match.params.groupid);
    }

    return () => {
      clearInterval(progressInterval);
    };
    // eslint-disable-next-line
  }, []);

  function fetchProgress(groupID) {
    progressInterval = setInterval(async () => {
      try {
        const res = await axios.get(
          `${keys.SERVER_URL}/group/${groupID}/progress`
        );

        const progData = res.data;
        if (!progData) {
          setCount((count) => count + 1);
          return;
        }

        if (progData.progress && progress !== progData.progress) {
          setProgress(progData.progress);
        }

        if (progData.progress === 100) {
          clearInterval(progressInterval);
          if (notPushed) {
            notPushed = false;
            props.history.push(`/facesearch/group/${groupID}/result`);
          }
        }
      } catch (err) {
        setCount((count) => count + 1);
      }
    }, 3000);
  }

  if (failsCount >= 3) {
    clearInterval(progressInterval);
    setError(true);
    setCount(0);
  }

  return (
    <div>
      <div
        style={{
          color: "#142945",
          fontFamily: "Muli",
          fontSize: "2.2rem",
          fontWeight: "700",
          letterSpacing: "0.18px",
          margin: "2.5rem 5rem",
          padding: "0 2rem",
        }}
      >
        <span style={{ opacity: "0.5" }}>Group Faces > </span>Group Progress
      </div>
      <StyleWrapper {...props}>
        <div className="component">
          <img className="search-icon" src={searchIcon} alt="searchIcon" />
          <ProgressWrapper>
            <div className="loading-text">{progress}% Completed</div>
            <div className="search-progress">
              <div
                className="completed"
                style={{
                  width: `${progress * 0.3}rem`,
                  background: error ? "red" : colors.brand,
                }}
              />
            </div>
            {error && (
              <div className="loading-text" style={{ color: "red" }}>
                Failed to group faces.Plese try again.
              </div>
            )}
          </ProgressWrapper>
        </div>
      </StyleWrapper>
    </div>
  );
}

export default withRouter(Progress);
