import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const StyledButton = styled.button`
  box-sizing: border-box;
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  background-color: ${(props) => props.background};
  box-shadow: ${(props) => props.boxShadow};
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
  font-family: ${(props) => props.fontFamily};
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Button = (props) => (
  <StyledButton {...props}>{props.children}</StyledButton>
);

export default Button;

Button.propTypes = {
  padding: PropTypes.string,
  background: PropTypes.string,
  border: PropTypes.string,
  boxShadow: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  fontFamily: PropTypes.string,
  onClick: PropTypes.func,
  margin: PropTypes.string,
  disabled: PropTypes.bool,
  borderRadius: PropTypes.string,
};

Button.defaultProps = {
  fontFamily: '"Muli", sans-serif',
  background: "#edeff2",
  color: "#000000",
  boxShadow: "0 1rem 2rem 0 rgba(124, 160, 73, 0.15)",
  padding: "1rem 1.5rem .9rem 1.5rem",
  fontSize: "1.2rem",
  border: "1px solid #EDEFF2",
  margin: "0px",
  disabled: false,
  borderRadius: "3px",
};
