import { wuserConstants } from "../constants";

const INITIAL_STATE = { gallery: [], loading: false };

export default function wuserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case wuserConstants.FETCH_WUSER:
      return { ...state, ...action.payload, loading: false };
    case wuserConstants.FETCH_WUSER_ERROR:
      return INITIAL_STATE;
    case wuserConstants.SET_WUSER_LOADING:
      return { ...state, loading: true };
    default:
      return state;
  }
}
