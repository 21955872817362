import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  successNotification,
  warningNotification,
  errorNotification,
} from "../../actions";

import {
  getSearchProgress,
  fetchCases,
} from "../../actions/search.case.actions";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "../../components/common/Table/Table";
import SearchStatus from "./SearchStatus";
import Button from "../../components/common/Button";
import loader from "../../assets/img/blueloader.svg";
import { ReactComponent as EmptySVG } from "../../assets/img/empty.svg";
import PlaceholderCaseCard from "../Placeholder/PlaceholderCaseCard";
import Image from "../../containers/common/Image";
import { colors } from "../themes/base";

const StyledNotFound = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    max-width: 30rem;
    transform: translateY(-20rem);
  }

  .message {
    transform: translateY(-45rem);
    text-align: center;

    h4 {
      font-size: 3rem;
      font-weight: bold;
      margin: 0;
    }

    p {
      font-size: 1.8rem;
      color: grey;
    }

    button {
      margin: 0 auto;
      background: ${colors.brand};
      font-size: 1.6rem;
      box-shadow: 0 1rem 2rem 0 rgba(124, 160, 73, 0.15);
      color: #ffffff;
      padding: 1rem 2rem;
      text-transform: uppercase;
    }
  }
`;

const TableWrapper = styled.div`
  background: #f7f8fb;

  table thead {
    outline: thin solid #f7f8fb;
    outline-width: 3px;
  }

  table tr {
    height: 7rem;
  }

  table tr:hover .edit-btn {
    display: inline-block;
  }

  table .image {
    width: 6rem;
  }

  table .case-id {
    width: 30rem;
  }

  table .case-desc {
    width: 38rem;
  }
`;

const StyledCell = styled(TableCell)`
  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .edit-btn {
      margin-left: 0.5rem;
      opacity: 0.5;
      display: none;

      svg {
        width: 1.6rem;
        height: 1.8rem;
      }
    }
  }
`;

const EditCell = (props) => (
  <StyledCell>
    <div style={{ justifyContent: "flex-end" }} className="container">
      <Button
        padding="8px 18px"
        boxShadow="none"
        background="#FFF"
        color={colors.button}
        borderRadius="3rem"
        style={{ fontWeight: "normal", textTransform: "none" }}
        onClick={() => props.history.push(props.editURI)}
      >
        Edit
      </Button>
    </div>
  </StyledCell>
);

const ButtonCell = (props) => {
  const [loading, setLoading] = React.useState(false);
  return (
    <StyledCell>
      <div className="container">
        <Button
          padding="8px 18px"
          boxShadow="none"
          background="#FFF"
          color={colors.button}
          borderRadius="3rem"
          style={{ fontWeight: "normal", textTransform: "none" }}
          onClick={() => {
            setLoading(true);
            props.history.push(props.uri);
            setLoading(false);
          }}
          disabled={props.status === 100 ? false : true}
        >
          {loading ? (
            <img src={loader} alt="loader" height="20px" width="20px" />
          ) : (
            "Results"
          )}
        </Button>
      </div>
    </StyledCell>
  );
};

const NoCasesFound = (props) => (
  <StyledNotFound>
    <EmptySVG />
    <div className="message">
      <h4>No Cases</h4>
      <p>When you upload cases. They will appear here.</p>
      {!props.hideAdd && (
        <Button
          height={"34px"}
          onClick={() => props.history.push("/facesearch/cases/add")}
        >
          Add
        </Button>
      )}
    </div>
  </StyledNotFound>
);

const PlaceHolderCards = () => (
  <>
    {Array.from(Array(5).keys()).map((_, i) => (
      <PlaceholderCaseCard key={i} />
    ))}
  </>
);

class DefaultTable extends Component {
  constructor(props) {
    super(props);
    this.fetchCasesInterval = null;
  }

  async componentDidMount() {
    const { caseList } = this.props;

    if (caseList === null || caseList.length === 0) {
      this.props.fetchCases();
    }
  }

  render() {
    if (
      !this.props.loading &&
      (this.props.caseList === null || this.props.caseList.length === 0)
    ) {
      return <NoCasesFound {...this.props} />;
    }

    return (
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell th first className="image">
                #
              </TableCell>
              <TableCell th>Name</TableCell>
              {this.props.showID ? (
                <TableCell th className="case-id">
                  Case ID
                </TableCell>
              ) : (
                <TableCell th className="case-desc">
                  Case Description
                </TableCell>
              )}
              <TableCell th>Status</TableCell>
              <TableCell th>Created</TableCell>
              <TableCell th />
              <TableCell th />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.loading ? (
              <PlaceHolderCards />
            ) : (
              this.props.caseList.map((caseObj) => (
                <TableRow key={caseObj.id}>
                  <TableCell first>
                    <Image
                      style={{ borderRadius: "50%", objectFit: "cover" }}
                      width="32"
                      height="32"
                      src={caseObj.imageUrl}
                      alt="Avatar"
                    />
                  </TableCell>
                  <TableCell>{caseObj.name}</TableCell>
                  {this.props.showID ? (
                    <TableCell>{caseObj.id}</TableCell>
                  ) : (
                    <TableCell>{caseObj.notes}</TableCell>
                  )}

                  <TableCell>
                    {/**
                     *status = null or undefined => Incomplete
                     *status = 0 - 99 => Progress bar
                     *status = 100 => Complete
                     */}
                    {caseObj && caseObj.search && caseObj.search._id ? (
                      <SearchStatus
                        searchID={caseObj.search._id}
                        searchProgress={caseObj.search.progress}
                        getSearchProgress={this.props.getSearchProgress}
                      />
                    ) : (
                      <SearchStatus
                        searchID={null}
                        searchProgress={null}
                        getSearchProgress={this.props.getSearchProgress}
                      />
                    )}
                  </TableCell>
                  <TableCell>{caseObj.createdAt}</TableCell>
                  <EditCell
                    editURI={`/facesearch/cases/edit/${caseObj.id}`}
                    history={this.props.history}
                  />
                  <ButtonCell
                    status={this.props.progress[caseObj.id]}
                    uri={
                      caseObj.search
                        ? `/facesearch/searchcase/${caseObj.search._id}/${caseObj.search.job}/result`
                        : null
                    }
                    history={this.props.history}
                  />
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  caseList: state.search.caseList,
  loading: state.search.loading,
  progress: state.search.progress,
});

export default withRouter(
  connect(mapStateToProps, {
    fetchCases,
    getSearchProgress,
    warningNotification,
    errorNotification,
    successNotification,
  })(DefaultTable)
);
