import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import cancel from "../../assets/img/cancel.svg";
import Image from "../../containers/common/Image";

const DropzoneWrapper = styled.section`
  font-family: ${props => props.fontFamily};
  margin-bottom: 22px;
`;

const Thumbnails = styled.aside`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 1rem 2rem;
  position: relative;

  div {
    width: ${props => `${props.thumbWidth}px`};
    margin: 0.5rem 1rem;
    position: relative;
    width: 15rem;
    height: 15rem;

    img {
      height: 13rem;
      width: 100%;
      max-width: 100%;
      object-fit: cover;
      border-radius: 3px;
      opacity: 1;
      transition: all 0.3s;
    }

    .delete-btn {
      position: absolute;
      right: -6px;
      top: -6px;
      width: 2rem;
      height: 2rem;
      cursor: pointer;
    }
  }
`;

const EditableCarousel = props => {
  const imageThumbs = props.images.map(image => (
    <div key={image._id}>
      <Image
        className="preview"
        type="case"
        src={image.url}
        alt="Upload Preview"
      />
      <Image
        className="delete-btn"
        src={cancel}
        onClick={e => props.handleDelete(image._id)}
        alt="delete"
      />
    </div>
  ));

  return (
    <DropzoneWrapper {...props}>
      <Thumbnails {...props}>{imageThumbs}</Thumbnails>
    </DropzoneWrapper>
  );
};

EditableCarousel.propTypes = {
  fontFamily: PropTypes.string,
  thumbWidth: PropTypes.number
};

EditableCarousel.defaultProps = {
  fontFamily: '"Muli", sans-serif',
  thumbWidth: 167
};

export default EditableCarousel;
