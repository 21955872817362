import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import {
  successNotification,
  errorNotification,
} from "../../components/Notifcations";

import {
  fetchRedactedVideos,
  deleteRedactedVideo,
} from "../../actions/redaction.actions.js";
import VideoCard from "../../components/VideoCard/VideoCard";
import DeleteVideoModal from "../../components/Modal/DeleteVideoModal";
import PlaceholderRawCard from "../../components/Placeholder/PlaceholderRawCard";
import NotFound from "../../components/Errors/NotFound";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 2rem;
  margin-left: 5rem;
  margin-right: 3rem;
  padding-bottom: 3rem;

  @media only screen and (min-width: 680px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media only screen and (min-width: 1020px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media only screen and (min-width: 1200px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;

const NoVideosFound = (props) => (
  <NotFound
    title="No Videos"
    message="When you redact videos. They will appear here."
    button={{
      text: "Raw Videos",
      handleButtonClick: () => props.history.push("/video/raw/videos"),
    }}
  />
);

const copyVideoLink = (url) => {
  const textField = document.createElement("textarea");
  textField.innerText = url;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
  successNotification("Link copied");
};

const handleDelete = async (props, videoID, setDeleteStatus) => {
  const data = await props.deleteRedactedVideo(videoID);
  if (data) {
    if (data.success) {
      props.fetchRedactedVideos();
      successNotification("Deleted Successfully");
    } else {
      setDeleteStatus(false);
      errorNotification("Unable to delete the video");
    }
  } else {
    setDeleteStatus(false);
    errorNotification("Unable to delete the video");
  }
};

const PlaceHolderCards = () => (
  <Grid>
    {Array.from(Array(10).keys()).map((_, i) => (
      <PlaceholderRawCard key={i} />
    ))}
  </Grid>
);

const RedactedVideos = (props) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [videoId, setvideoId] = useState(null);
  const [videoName, setvideoName] = useState("");
  const [deleteStatus, setDeleteStatus] = useState(false);
  if (props.redactedVideos === null) {
    return <NoVideosFound {...props} />;
  } else if (props.redactedVideos.length === 0) {
    const data = props.fetchRedactedVideos();
    data.then((result) => {
      if (!result) {
        errorNotification("Unable to fetch redacted videos");
      }
    });
    return (
      <div>
        <div
          style={{
            color: "#142945",
            fontFamily: "Muli",
            fontSize: "2.2rem",
            fontWeight: "700",
            letterSpacing: "0.18px",
            margin: "2.5rem 5rem",
          }}
        >
          Redacted Videos
        </div>
        <PlaceHolderCards />
      </div>
    );
  } else {
    return (
      <div className="">
        <DeleteVideoModal
          videoName={videoName}
          isVisible={deleteModal}
          handleCancel={() => setDeleteModal(false)}
          handleDelete={() => {
            setDeleteStatus(true);
            setDeleteModal(false);
            return handleDelete(props, videoId, setDeleteStatus);
          }}
        />
        <div
          style={{
            color: "#142945",
            fontFamily: "Muli",
            fontSize: "2.2rem",
            fontWeight: "700",
            letterSpacing: "0.18px",
            margin: "2.5rem 5rem",
          }}
        >
          Redacted Videos
        </div>
        <Grid>
          {props.redactedVideos.map((redactedVideo) => (
            <VideoCard
              key={redactedVideo._id}
              deleteStatus={
                redactedVideo._id === videoId ? deleteStatus : false
              }
              poster={redactedVideo.thumbURL}
              videoURL={redactedVideo.url}
              videoTitle={redactedVideo.name}
              timestamp={redactedVideo.last_modified}
              redacted={true}
              handleDelete={() => {
                setvideoId(redactedVideo._id.toString());
                setvideoName(redactedVideo.name);
                setDeleteModal(true);
                return;
              }}
              handleShareLink={copyVideoLink}
              href={redactedVideo.url}
            />
          ))}
        </Grid>
      </div>
    );
  }
};

RedactedVideos.propTypes = {
  redactedVideos: PropTypes.arrayOf(
    PropTypes.shape({
      videoURL: PropTypes.string,
      videoTitle: PropTypes.string,
      timestamp: PropTypes.string,
      handleSave: PropTypes.func,
      handleDelete: PropTypes.func,
    })
  ),
};

RedactedVideos.defaultProps = {};

const mapStateToProps = (state) => ({
  redactedVideos: state.redaction.redactedVideos,
});

export default connect(mapStateToProps, {
  fetchRedactedVideos,
  deleteRedactedVideo,
})(RedactedVideos);
