import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";

import { icons, colors } from "../themes/base";

import Button from "../common/Button";
import SubProperty from "../common/SubProperty";
import ToolbarDropdown from "../common/ToolbarDropdown";
import ToolsHeaderText from "../common/ToolsHeaderText";
import ToolbarColorPicker from "../common/ToolbarColorPicker";
import ToolbarPropDivider from "../common/ToolbarPropDivider";
import ToolbarTextInput from "../common/ToolbarTextInput";
import ToolbarTimeInput from "../common/ToolbarTimeInput";

const StyledWrapper = styled.div`
  padding: ${(props) => props.padding || "1px 10px"};
  border-left: ${(props) => props.borderLeft};

  .header-input {
    height: 34px;
    width: 150px;
    border: 1px solid transparent;
    color: ${colors.s_light};
    font-family: Muli;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 15px;

    :focus {
      outline: 0;
      border: 1px solid ${colors.s_extralight};
    }
  }

  .text-notes {
    border: 0;
    box-sizing: border-box;
    height: 120px;
    border-radius: 2px;
    background-color: ${colors.s_superlight};
    color: ${colors.secondary};
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    caret-color: ${colors.text_tools.caret};
    resize: none;
    padding: 10px;

    :focus {
      outline: 0;
    }
  }

  .duration-container {
    display: flex;
    justify-content: space-between;

    .duration-prop {
      display: flex;
      flex: 2;

      color: ${colors.s_light};
      font-family: Muli;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 0;

      .duration-input {
        border: 0;
        padding: 5px;
        color: ${colors.secondary};
        font-family: Muli;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 13px;
        width: 50px;

        :focus {
          outline: 0;
        }
      }
    }
  }

  .error-msg {
    color: red;
    margin: 1rem;
    font-weight: 600;
  }
`;

const LayersContainer = styled.div`
  max-height: ${(props) => (props.fullScreenMode ? "63%" : "54%")};
  overflow-y: scroll;
`;

const toSec = (timeFormat) => {
  return moment.duration(timeFormat).asSeconds();
};

const getBorderColor = (start, end, duration, selected) => {
  const s = toSec(start);
  const e = toSec(end);
  const d = toSec(duration);

  if (s > d || e > d || s > e) {
    return "6px solid red";
  }

  if (selected) {
    return `6px solid ${colors.primary}`;
  }

  return `6px solid ${colors.text_tools.border}`;
};

const RenderErrorMessage = ({ start, end, duration }) => {
  const s = toSec(start);
  const e = toSec(end);
  const d = toSec(duration);

  if (s > d) {
    return <p className="error-msg">Start time greater than video duration</p>;
  }

  if (e > d) {
    return <p className="error-msg">End time greater than video duration</p>;
  }

  if (s > e) {
    return <p className="error-msg">Start time greater than end time</p>;
  }

  return <p></p>;
};

function TextProperty(props) {
  const handleDataChange = (property, value) => {
    if (!props.id) {
      return;
    }

    props.handleLayerDataChange(props.id, {
      [property]: value,
    });
  };

  return (
    <React.Fragment>
      <StyledWrapper>
        <ToolsHeaderText>Text</ToolsHeaderText>
        {props.layers && props.layers.length > 0 && (
          <>
            <SubProperty>
              <ToolbarDropdown
                value={props.fontFamily.toString()}
                options={[
                  { label: "Sans-seriff", value: "sans-seriff" },
                  { label: "Helvetica", value: "helvetica" },
                  { label: "Vedata", value: "vedata" },
                  { label: "Muli", value: "muli" },
                  { label: "Timesnewroman", value: "timesnewroman" },
                ]}
                handleChange={(value) => handleDataChange("fontFamily", value)}
              />
              <ToolbarColorPicker
                tooltipID="text-colorpicker"
                selectedColor={props.fill}
                opacity={props.opacity}
                setColor={(value) => handleDataChange("fill", value)}
                setOpacity={(value) => handleDataChange("opacity", value)}
              />
            </SubProperty>
            <SubProperty justifyContent="flex-start">
              <ToolbarDropdown
                margin="0 0.5rem 0 0"
                width="87px"
                value={props.fontSize}
                options={[
                  { label: "2", value: 2 },
                  { label: "4", value: 4 },
                  { label: "8", value: 8 },
                  { label: "16", value: 16 },
                  { label: "18", value: 18 },
                  { label: "22", value: 22 },
                  { label: "24", value: 24 },
                  { label: "35", value: 35 },
                  { label: "48", value: 48 },
                  { label: "60", value: 60 },
                  { label: "72", value: 72 },
                ]}
                handleChange={(value) => handleDataChange("fontSize", value)}
              />

              <ToolbarDropdown
                margin="0 0 0 0.5rem"
                width="86px"
                fontSize="14px"
                fontWeight={600}
                value={props.fontStyle}
                options={[
                  { label: "B", value: "normal" },
                  { label: "I", value: "italic" },
                ]}
                handleChange={(value) => handleDataChange("fontStyle", value)}
              />
            </SubProperty>
            <ToolbarPropDivider />
            <SubProperty>
              <ToolbarTextInput
                label="Letter Spacing"
                value={props.letterSpace}
                onChange={(value) => handleDataChange("letterSpace", value)}
              />
            </SubProperty>
            <SubProperty>
              <ToolbarTextInput
                label="Line Height"
                value={props.lineHeight}
                onChange={(value) => handleDataChange("lineHeight", value)}
              />
            </SubProperty>
          </>
        )}
        <ToolbarPropDivider />
      </StyledWrapper>

      <LayersContainer fullScreenMode={props.fullScreenMode}>
        {props.layers.map((layer) => (
          <StyledWrapper
            key={layer.id}
            padding="0 5px"
            borderLeft={getBorderColor(
              layer.start,
              layer.end,
              props.duration,
              props.selectedShapeID === layer.id
            )}
          >
            <SubProperty style={{ flexDirection: "column" }}>
              <input
                className="header-input"
                value={layer.name}
                onClick={() => props.setSelected(layer.id)}
                onChange={(e) =>
                  props.handleLayerDataChange(layer.id, {
                    name: e.target.value,
                  })
                }
              />
              <textarea
                id={`textarea_${layer.id}`}
                className="text-notes"
                value={layer.notes}
                onClick={() => props.setSelected(layer.id)}
                onChange={(e) =>
                  props.handleLayerDataChange(layer.id, {
                    notes: e.target.value,
                  })
                }
              />
              <div className="duration-container">
                <div className="duration-prop">
                  <ToolbarTimeInput
                    label="Start:"
                    background="transparent"
                    justifyContent="flex-start"
                    padding="0"
                    duration={props.duration}
                    value={layer.start}
                    onClick={() => props.setSelected(layer.id)}
                    onChange={(value) =>
                      props.handleLayerDataChange(layer.id, {
                        start: value,
                      })
                    }
                  />

                  <ToolbarTimeInput
                    label="End:"
                    background="transparent"
                    justifyContent="flex-start"
                    padding="0"
                    duration={props.duration}
                    value={layer.end}
                    onClick={() => props.setSelected(layer.id)}
                    onChange={(value) =>
                      props.handleLayerDataChange(layer.id, {
                        end: value,
                      })
                    }
                  />
                </div>
                <Button
                  padding="7px"
                  background={`url(${icons.IC_Delete}) center center / 13px 13px no-repeat`}
                  onClick={() => props.handleDelete(layer.id)}
                />
              </div>
            </SubProperty>
            <RenderErrorMessage
              start={layer.start}
              end={layer.end}
              duration={props.duration}
            />
          </StyledWrapper>
        ))}

        <StyledWrapper>
          <SubProperty>
            <Button
              fontSize="10px"
              fontWeight="600"
              color="#142945"
              padding="14px 5px 10px 30px"
              background={`url(${icons.IC_Add_Layer}) left center / 23px 23px no-repeat`}
              onClick={props.handleAdd}
            >
              Add New Text
            </Button>
          </SubProperty>
        </StyledWrapper>
      </LayersContainer>
    </React.Fragment>
  );
}

export default TextProperty;

TextProperty.propTypes = {
  fontFamily: PropTypes.string,
  fontSize: PropTypes.number,
  align: PropTypes.string,
  fontStyle: PropTypes.string,
  letterSpace: PropTypes.string,
  lineHeight: PropTypes.string,
  selectedColor: PropTypes.string,
  opacity: PropTypes.number,
  layers: PropTypes.array,
  handleAdd: PropTypes.func,
  handleDelete: PropTypes.func,
  handleDataChange: PropTypes.func,
};

TextProperty.defaultProps = {
  fontFamily: "sans-seriff",
  fontSize: 48,
  align: "start",
  fontStyle: "normal",
  letterSpace: "0.00",
  lineHeight: "1.00",
  selectedColor: colors.palette[7],
  opacity: 1,
  layers: [],
  handleAdd: () => {},
  handleDelete: () => {},
  handleDataChange: () => {},
};
