import React, { useState, useEffect } from "react";
import $ from "jquery";

import Button from "../../components/common/Button";
import { colors } from "../../components/themes/base";

const buttonProps = {
  height: "48px",
  fontSize: "1.6rem",
  background: colors.brand,
  boxShadow: "0 1rem 2rem 0 rgba(124, 160, 73, 0.15)",
  color: "#FFFFFF",
  textTransform: "uppercase",
  padding: "5px",
};

function PlayAndPause({ wavesurfer }) {
  const [audioPlaying, setPlaying] = useState(false);

  useEffect(() => {
    if (!wavesurfer) {
      return;
    }

    // After finish playing, pausing the wavesurfer
    wavesurfer.on("finish", () => {
      pause();
    });

    document.addEventListener("click", clickListener);

    return () => {
      document.removeEventListener("keypress", keyboardPlayAndPause);
      document.removeEventListener("click", clickListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickListener = () => {
    if (document.activeElement.tagName === "BODY") {
      document.addEventListener("keypress", keyboardPlayAndPause);
    } else {
      document.removeEventListener("keypress", keyboardPlayAndPause);
    }
  };

  function pause() {
    if (!wavesurfer) {
      return;
    }

    wavesurfer.pause();
    setPlaying(false);
  }

  function keyboardPlayAndPause(e) {
    e.preventDefault();

    const c = String.fromCharCode(e.which);
    // Spacebar
    if (c === " ") {
      togglePlaying();
    }

    // While moving wavesufer with keys, pause it
    if (
      c === "a" ||
      c === "s" ||
      c === "d" ||
      c === "f" ||
      c === "g" ||
      c === "h"
    ) {
      if (wavesurfer.isPlaying()) {
        pause();
      }
    }
  }

  function togglePlaying() {
    // To avoid focus on the same element while clicking 'space' to play/pause
    $(document.activeElement).blur();

    // If wavesurfer is null or undefined, return
    if (!wavesurfer) {
      return;
    }

    if (wavesurfer.isPlaying()) {
      pause();
    } else {
      wavesurfer.play();
      setPlaying(true);
    }
  }

  return (
    <React.Fragment>
      {audioPlaying ? (
        <Button
          {...buttonProps}
          style={{ width: "10rem" }}
          onClick={togglePlaying}
        >
          Pause
        </Button>
      ) : (
        <Button
          {...buttonProps}
          style={{ width: "10rem" }}
          onClick={togglePlaying}
        >
          Play
        </Button>
      )}
    </React.Fragment>
  );
}

export default PlayAndPause;
