import styled from "styled-components";

const AudioNotesPDFStyled = styled.iframe`
  height: calc(90vh - 48px);
  width: 90vw;
  border: 0;
`;

const AudioNotesPDF = ({ iframeSrc }) => {
  if (!iframeSrc) return null;

  return <AudioNotesPDFStyled src={iframeSrc} title="PDF" />;
};

export default AudioNotesPDF;
