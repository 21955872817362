import React from "react";
import PropTypes from "prop-types";
import Dropdown from "react-dropdown";
import styled from "styled-components";
import "react-dropdown/style.css";

import { colors } from "../themes/base";

const StyledDropDown = styled.div`
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};

  .toolbar-dropdown {
    height: 34px;
    border: ${(props) => props.border};
    border-radius: 2px;
    background: ${(props) => props.background};
    color: ${(props) => props.color};
    font-family: Muli;
    font-size: ${(props) => props.fontSize};
    font-weight: ${(props) => props.fontWeight};
    letter-spacing: 0;
    line-height: 15px;

    &-menu {
      border: 0;
      border-radius: 2px;
      width: ${(props) => props.width};
      background: ${colors.white};
      color: ${colors.secondary};
      font-family: Muli;
      font-size: ${(props) => props.fontSize};
      font-weight: ${(props) => props.fontWeight};
      letter-spacing: 0;
      line-height: 15px;
    }

    .dropdown-arrow {
      top: 11px;
      box-sizing: border-box;
      height: 7px;
      width: 7px;
      border: 1px solid ${(props) => props.arrowColor};
      transform: rotate(132deg);
      border-bottom: 0px;
      border-left: 0px;
    }
  }
`;

function ToolbarDropdown(props) {
  return (
    <StyledDropDown {...props}>
      <Dropdown
        menuClassName="toolbar-dropdown-menu"
        controlClassName="toolbar-dropdown"
        arrowClassName="dropdown-arrow"
        options={props.options}
        value={props.value.toString()}
        onChange={({ value }) => props.handleChange(value)}
      />
    </StyledDropDown>
  );
}

export default ToolbarDropdown;

ToolbarDropdown.propTypes = {
  width: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.number,
  color: PropTypes.string,
  border: PropTypes.string,
  margin: PropTypes.string,
  background: PropTypes.string,
  arrowColor: PropTypes.string,
};

ToolbarDropdown.defaultProps = {
  border: "0",
  width: "246px",
  fontWeight: 400,
  fontSize: "12px",
  margin: "0",
  color: colors.secondary,
  background: colors.s_superlight,
  arrowColor: colors.primary,
};
