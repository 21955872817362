import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { colors } from "../themes/base";

const StyledItem = styled.li`
  font-size: 1.4rem;
  font-weight: 600;
  color: ${(props) => props.fontColor};
  padding: 0 2rem;
  height: 100%;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  @media (max-width: ${(props) => `${props.mdBreakpoint}px`}) {
    font-size: 1.2rem;
  }

  .__react_component_tooltip.type-light.place-bottom:after {
    display: none;
  }

  .tooltip {
    padding: 0 !important;
    pointer-events: auto;
    width: 16.3rem;

    background: white;
    border-radius: 3px;
    opacity: 1;
    .dropdown {
      font-family: ${(props) => props.fontFamily};
      font-weight: 600;
      padding: 0.5rem 1.5rem;
      box-shadow: 0px 0px 3px 0px #d8e0f9;
      a {
        text-decoration: none;
        color: #000;
      }
      .item {
        height: 3.9rem;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      hr {
        border-bottom: none;
        border-left: none;
        border-right: none;
        margin: 0;
        border-top: 1px solid #e0e0eb;
      }
    }
    .dropdown:after,
    .dropdown:before {
      bottom: 100%;
      left: 92%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    .dropdown:after {
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: white;
      border-width: 8px;
      margin-left: -8px;
    }
    .dropdown:before {
      border-color: rgba(194, 225, 245, 0);
      border-bottom-color: #d8e0f9;
      border-width: 9px;
      margin-left: -9px;
    }
  }
`;

const ItemWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Line = styled.div`
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  width: 100%;
  height: 3px;
  background-color: ${(props) => props.lineColor};
`;

const ChildWrapper = styled.div`
  flex: 10;
  display: flex;
  align-items: center;
`;

const HeaderItem = (props) => {
  return (
    <StyledItem {...props}>
      <ItemWrapper>
        <ChildWrapper>{props.children}</ChildWrapper>
        <Line lineColor={props.lineColor} isActive={props.isActive} />
      </ItemWrapper>
    </StyledItem>
  );
};

export default HeaderItem;

HeaderItem.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  fontColor: PropTypes.string,
  lineColor: PropTypes.string,
  mdBreakpoint: PropTypes.number,
  fontFamily: PropTypes.string,
};

HeaderItem.defaultProps = {
  isActive: false,
  fontColor: "#142945",
  lineColor: colors.brand,
  mdBreakpoint: 900,
  fontFamily: '"Muli", sans-serif',
};
