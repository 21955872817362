import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import axios from "axios";

import Image from "../common/Image";
import keys from "../../config/keys";

const { SERVER_URL } = keys;

const CardWrapper = styled.div`
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  .card {
    display: flex;
    flex-direction: column;
    /* width: calc((100% / (${props => props.matchedUsers.length}+1))); */
    width: 20%;
    img {
      height: 8.5rem;
      object-fit: cover;
    }

    #suspect-img {
      padding-right: 5px;
    }

    #suspect-name {
      margin-right: 5px;
    }

    .name,
    .match {
      padding: 1rem 0 0.9rem 0;
      text-align: center;
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: ${props => props.textColor};
      font-family: ${props => props.fontFamily};
      background-color: ${props => props.bgColor};
      font-size: 1.1rem;
      font-weight: 700;
    }
  }
  .no-matches {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${props => props.fontFamily};
    font-size: 1.4rem;
  }
`;

const fetchWUserName = async wuserID => {
  try {
    const res = await axios.get(`${SERVER_URL}/wusers/${wuserID}`);
    if (res.data.profile && res.data.profile.firstname) {
      return res.data.profile.firstname;
    }
    return null;
  } catch (e) {
    return null;
  }
};

const PersonCard = props => {
  const wuserID = props.user.wuserId;
  const [wName, setWName] = useState("");

  useEffect(() => {
    if (!wuserID) {
      return;
    }

    setWUserName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.wuserId]);

  const setWUserName = async () => {
    const wuserName = await fetchWUserName(wuserID);
    if (!wuserName) {
      return;
    }

    setWName(wuserName);
  };

  return (
    <div
      className="card"
      style={{ cursor: "pointer" }}
      onClick={() =>
        props.history.push(`/facesearch/watchlist/view/${wuserID}`)
      }
    >
      <Image src={props.user.id} alt="matched person" />
      <div className="match">
        {`${wName.substr(0, 3)} ${props.user.accuracy}%`}
      </div>
    </div>
  );
};

const PersonCardWithRouter = withRouter(PersonCard);

const TopMatchesCard = props => {
  return (
    <CardWrapper {...props}>
      <div className="card">
        <Image
          id="suspect-img"
          src={props.identifiedPerson}
          alt="Person in Pic"
        />
        <div id="suspect-name" className="name">
          Matches
        </div>
      </div>
      {props.matchedUsers.map((user, i) => (
        <PersonCardWithRouter key={i} user={user} />
      ))}
      {props.matchedUsers.length === 0 ? (
        <div className="no-matches">No Matches Found</div>
      ) : null}
    </CardWrapper>
  );
};

export default TopMatchesCard;

TopMatchesCard.propTypes = {
  fontFamily: PropTypes.string,
  matchedUsers: PropTypes.array,
  identfiedPerson: PropTypes.object,
  bgColor: PropTypes.string,
  textColor: PropTypes.string
};

TopMatchesCard.defaultProps = {
  fontFamily: '"Muli", sans-serif',
  bgColor: "#ff3b30",
  textColor: "#fff"
};
