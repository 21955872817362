import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import Carousal from "../Carousal";

import {
  setProgress,
  getSearchProgress,
  getSelectedUsers
} from "../../actions/search.case.actions";
import { errorNotification } from "../../actions";
import SearchProgress from "./SearchProgress";
import searchIcon from "../../assets/img/search-icon.png";

const StyledLoader = styled.div`
  width: ${props => props.width};
  height: 93vh;
  font-family: ${props => props.fontFamily};
  background-color: #f7f8fb;
  display: flex;
  justify-content: center;
  align-items: center;

  .component {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    box-shadow: 0 2px 10px rgba(44, 137, 255, 0.06);
    border-radius: 3px;
    .info-text {
      margin-top: 4rem;
      margin-bottom: 5rem;
      opacity: 0.6;
      color: #142945;
      font-family: ${props => props.fontFamily};
      font-size: 14px;
    }

    .search-icon {
      display: block;
      width: 5rem;
      height: 5rem;
      opacity: 0.7;
      transform: translateX(-0.5rem);
    }
  }
`;

function SearchLoader(props) {
  const [selectedObj, setSelectedObj] = useState({ users: [], caseName: "" });

  const jobId = props.match.params.jobId;
  const searchId = props.match.params.searchId;

  let progressInterval;

  useEffect(() => {
    fetchSelectedUsers();

    return () => {
      clearInterval(progressInterval);
    };
    // eslint-disable-next-line
  }, []);

  async function fetchSelectedUsers() {
    const obj = await props.getSelectedUsers(searchId);

    if (obj && obj.users && obj.caseName) {
      setSelectedObj(obj);
    }
  }

  const first3Users = selectedObj.users.splice(0, 3);
  const first3UserNames = first3Users.map(user => user.name);
  const first3UserImgs = first3Users.map(user => user.imgUrl);

  return (
    <div>
      <div
        style={{
          color: "#142945",
          fontFamily: "Muli",
          fontSize: "2.2rem",
          fontWeight: "700",
          letterSpacing: "0.18px",
          margin: "2.5rem 5rem",
          padding: "0 2rem"
        }}
      >
        <span style={{ opacity: "0.5" }}>Search Case > </span> Search Result
      </div>
      <StyledLoader {...props}>
        <div className="component">
          <div className="info-text">
            Searching <strong>{first3UserNames.join(",")}</strong>
            {selectedObj.users.length > 3 && (
              <strong> and {selectedObj.users.length - 3} more </strong>
            )}{" "}
            in the case{" "}
            {selectedObj.caseName && <strong>{selectedObj.caseName}</strong>}
          </div>
          <Carousal images={first3UserImgs} />
          <img className="search-icon" src={searchIcon} alt="searchIcon" />
          <SearchProgress
            searchID={searchId}
            jobID={jobId}
            getSearchProgress={props.getSearchProgress}
          />
        </div>
      </StyledLoader>
    </div>
  );
}

const mapStateToProps = state => ({
  progress: state.search.progress
});

export default connect(mapStateToProps, {
  setProgress,
  getSearchProgress,
  getSelectedUsers,
  errorNotification
})(SearchLoader);

SearchLoader.propTypes = {
  width: PropTypes.string,
  progress: PropTypes.object,
  fontFamily: PropTypes.string
};

SearchLoader.defaultProps = {
  progress: 0,
  fontFamily: '"Muli", sans-serif'
};
