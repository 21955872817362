import axios from "axios";
import store from "../store";
import { addRequest, removeRequest } from "../actions/request.actions";

const setAxiosConfig = () => {
  axios.interceptors.request.use(request => {
    if (request.requestId) {
      const cancelSource = axios.CancelToken.source();
      request.cancelToken = cancelSource.token;
      const requestPending = store.getState().request.requestPending;
      if (requestPending.indexOf(request.requestId) === -1) {
        store.dispatch(addRequest(request.requestId));
      } else {
        cancelSource.cancel();
      }
    }
    return request;
  });

  axios.interceptors.response.use(response => {
    if (response.config.requestId)
      store.dispatch(removeRequest(response.config.requestId));
    return response;
  });
};

export default setAxiosConfig;
