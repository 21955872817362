import axios from "axios";

import { removeRequest } from "./request.actions";
import store from "../store";

import keys from "../config/keys";
import {
  ADD_CASE_FILES,
  SET_CASE_FILES,
  SET_CASE_NAME,
  SET_CASE_NOTES,
  SET_CASE_UPLOAD_STATUS,
  RESET_CASE_FORM
} from "./types";

const { SERVER_URL } = keys;

export const setCaseName = text => ({
  type: SET_CASE_NAME,
  payload: text
});

export const setCaseNotes = text => ({
  type: SET_CASE_NOTES,
  payload: text
});

export const addCaseFiles = files => ({
  type: ADD_CASE_FILES,
  payload: files
});

export const setCaseFiles = files => ({
  type: SET_CASE_FILES,
  payload: files
});

export const setCaseUploadStatus = status => ({
  type: SET_CASE_UPLOAD_STATUS,
  payload: status
});

export const resetForm = () => ({
  type: RESET_CASE_FORM
});

export const uploadCase = formData => async () => {
  try {
    const uploadRes = await axios.post(`${SERVER_URL}/cases`, formData, {
      requestId: "uploadCase"
    });

    if (uploadRes.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    store.dispatch(removeRequest("uploadCase"));
    if (axios.isCancel(e)) {
      return true;
    } else {
      console.log(e);
      return false;
    }
  }
};

export const updateCase = (formData, caseId) => async () => {
  try {
    const updateRes = await axios.post(
      `${SERVER_URL}/cases/${caseId}`,
      formData,
      { requestId: "updateCase" }
    );
    if (updateRes.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    store.dispatch(removeRequest("updateCase"));
    if (axios.isCancel(e)) {
      return true;
    } else {
      console.log(e);
      return false;
    }
  }
};

export const fetchCase = caseId => async () => {
  try {
    const res = await axios.get(`${SERVER_URL}/cases/${caseId}`, {
      requestId: "fetchCase"
    });
    if (res.data) {
      return res.data;
    } else {
      return false;
    }
  } catch (e) {
    store.dispatch(removeRequest("fetchCase"));
    if (axios.isCancel(e)) {
      return null;
    } else {
      console.log(e);
      return false;
    }
  }
};

export const deleteCase = caseId => async () => {
  try {
    const res = await axios.delete(`${SERVER_URL}/cases/${caseId}`, {
      requestId: "deleteCase"
    });

    if (res.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    store.dispatch(removeRequest("deleteCase"));
    if (axios.isCancel(e)) {
      return true;
    } else {
      console.log(e);
      return false;
    }
  }
};
