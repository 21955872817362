import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import Button from "../../components/common/Button";
import { editProfile } from "../../actions";
import Carousal from "../../containers/Carousal";
import { colors } from "../../components/themes/base";

const ViewBlock = styled.div`
  background: ${(props) => props.background};
  padding: 20px;
  width: 90%;
  border-radius: 3px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
`;

const FormWrapper = styled.form`
  width: 50%;
  padding: 2.5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .formFields {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    height: 7rem;

    .labelNames {
      opacity: 0.5;
      color: #142945;
      font-family: ${(props) => props.fontFamily};
      font-size: 14px;
      line-height: 1.8rem;
      flex-basis: 30%;
    }

    .labelValues {
      font-size: 1.6rem;
      color: #142945;
      flex-basis: 70%;
      background-color: white;
      border: none;

      p {
        margin: 0;
      }
    }

    &:last-child {
      border-bottom: 0;
      height: 100%;
      margin-top: 2.5rem;
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;

    .update-btn {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

const ManageUsersWrapper = styled.div`
  font-family: ${(props) => props.fontFamily};
  background-color: #f7f8fb;
  margin: 0 auto;
  padding: 2.5rem 5rem;
`;

const HeadingBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  .heading {
    opacity: 0.5;
    color: #142945;
    font-family: ${(props) => props.fontFamily};
    font-size: 2.2rem;
    font-weight: 700;
    letter-spacing: 0.18px;
    margin-right: 1rem;
  }
  .subheading {
    color: #142945;
    font-family: ${(props) => props.fontFamily};
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0.18px;
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

class ViewProfileComp extends Component {
  render() {
    const profileImage = this.props.user.profileImage
      ? [this.props.user.profileImage]
      : [];

    return (
      <>
        <ManageUsersWrapper {...this.props}>
          <HeadingWrapper>
            <HeadingBlock>
              <div className="subheading">View Profile</div>
            </HeadingBlock>

            <Button
              boxShadow="0 2px 10px 0 rgba(44,137,255,0.06)"
              height="36"
              width="75"
              color="#fff"
              background={colors.brand}
              fontSize="1.4rem"
              margin="0px 10px"
              borderRadius="3rem"
              onClick={() => this.props.history.push("/profile/edit")}
            >
              Edit
            </Button>
          </HeadingWrapper>
        </ManageUsersWrapper>
        <ViewBlock {...this.props}>
          <Carousal images={profileImage} />
          <FormWrapper {...this.props}>
            <div className="formFields">
              <div className="labelNames">Full Name</div>
              <label className="labelValues">{this.props.user.fullName}</label>
            </div>
            <div className="formFields">
              <div className="labelNames">Email</div>
              <label className="labelValues">{this.props.user.email}</label>
            </div>
            <div className="formFields">
              <div className="labelNames">Password</div>
              <input
                className="labelValues"
                type="password"
                value="randomTextDisplayed"
                disabled
              ></input>
            </div>
          </FormWrapper>
        </ViewBlock>
      </>
    );
  }
}

ViewProfileComp.propTypes = {
  fontFamily: PropTypes.string,
  fontColor: PropTypes.string,
  background: PropTypes.string,
  width: PropTypes.number,
  handleUpdate: PropTypes.func,
  handleCancel: PropTypes.func,
  handleDelete: PropTypes.func,
};

ViewProfileComp.defaultProps = {
  fontFamily: '"Muli", sans-serif',
  fontColor: "#142945",
  background: "#FFFFFF",
  width: 1040,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps, { editProfile })(ViewProfileComp);
