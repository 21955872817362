import {
  SET_CASE_LIST,
  SET_USER_LIST,
  FILTER_USER_LIST,
  SET_SELECTED_CASE,
  SET_SELECTED_USERS,
  ADD_SELECTED_USER,
  REMOVE_SELECTED_USER,
  LOAD_MORE_SELECTED_USERS_TO_SHOW,
  SELECT_ALL,
  SET_PROGRESS,
  SET_RESULT,
  SET_LOADER,
  REMOVE_LOADER,
} from "../actions/types";

// Returns the selectedUsers to show given page no
function getSelectedUsersToShow(selectedUsers, page) {
  const USERS_PER_PAGE = 30;
  const totalUsers = page * USERS_PER_PAGE;
  return selectedUsers.slice(0, totalUsers);
}

const INITIAL_STATE = {
  caseList: [],
  filteredUsers: [],
  selectedCase: {},
  selectedUsers: [],
  selectedUsersPage: 5, // for infinite scroll
  selectedUsersToShow: [], // for infinite scroll,
  progress: {},
  result: {},
  loading: false,
  loaderMessage: "",
};

export default function searchReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CASE_LIST:
      return { ...state, caseList: action.payload, loading: false };
    case SET_USER_LIST:
      return {
        ...state,
        filteredUsers: action.payload,
        loading: false,
      };
    case FILTER_USER_LIST:
      return { ...state, filteredUsers: action.payload };
    case SET_SELECTED_CASE:
      return { ...state, selectedCase: action.payload, loading: false };
    case SET_SELECTED_USERS:
      let selectedUsersToShow = getSelectedUsersToShow(
        action.payload,
        state.selectedUsersPage
      );
      return {
        ...state,
        selectedUsers: action.payload,
        selectedUsersToShow,
        loading: false,
      };
    case ADD_SELECTED_USER: {
      let selectedUsers = [
        ...new Set([...state.selectedUsers, action.payload]),
      ];
      let selectedUsersToShow = getSelectedUsersToShow(
        selectedUsers,
        state.selectedUsersPage
      );
      return {
        ...state,
        selectedUsers,
        selectedUsersToShow,
        loading: false,
      };
    }
    case REMOVE_SELECTED_USER: {
      let selectedUsers = state.selectedUsers.filter(
        (user) => user !== action.payload
      );
      let selectedUsersToShow = getSelectedUsersToShow(
        selectedUsers,
        state.selectedUsersPage
      );
      return {
        ...state,
        selectedUsers,
        selectedUsersToShow,
        loading: false,
      };
    }
    case SELECT_ALL: {
      let selectedUsersToShow = getSelectedUsersToShow(
        action.payload,
        state.selectedUsersPage
      );
      return {
        ...state,
        selectedUsers: action.payload,
        selectedUsersToShow,
        loading: false,
      };
    }
    case SET_PROGRESS:
      return {
        ...state,
        progress: {
          ...state.progress,
          [action.payload.caseId]: action.payload.progress,
        },
        loading: false,
      };
    case SET_RESULT:
      return { ...state, result: action.payload, loading: false };
    case SET_LOADER:
      return { ...state, loading: true, loaderMessage: action.payload };
    case REMOVE_LOADER:
      return { ...state, loading: false };
    case LOAD_MORE_SELECTED_USERS_TO_SHOW: {
      let selectedUsersToShow = getSelectedUsersToShow(
        state.selectedUsers,
        action.payload
      );
      return {
        ...state,
        selectedUsersToShow,
        selectedUsersPage: action.payload,
      };
    }

    default:
      return state;
  }
}
