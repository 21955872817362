import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { FaPlus } from "react-icons/fa";
import { v4 } from "uuid";

import { successNotification, errorNotification } from "../../actions";
import {
  getIframeURL,
  saveMetadata,
  saveRedactedVideo,
  fetchRedactedVideos,
} from "../../actions/redaction.actions";
import Loader from "../../components/Loader/Loader";
import LoadingOverlay from "../../components/Loader/LoadingOverlay";
import Button from "../../components/common/Button";
import Table from "../../components/AudioComponents/AudioTable";
import AddNotesModal from "../../components/Modal/AddRegionModal";
import Pagination from "../../components/common/Pagination";
import { toSec } from "../../components/common/utils";
import PDFModal from "../../components/Modal/AudioPDFModal";
import IconButton from "../../components/common/Button";
import { colors, icons } from "../../components/themes/base";

const MAX_DURATION = 3 * 60 * 60; // 3 hrs

const NotesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5rem 10rem 0rem 10rem;
  color: #142945;
  font-family: Muli;
  font-size: 2.2rem;
  font-weight: 700;
  letter-spacing: 0.18px;

  .buttons-container {
    display: flex;
  }
`;

const styles = {
  buttonStyles: {
    position: "absolute",
    borderColor: "#677791",
    fontWeight: "bold",
    border: 0,
    height: "40px",
    cursor: "pointer",
    transition: ".4s",
    right: "270px",
    bottom: "50px",
    borderRadius: "2px",
    fontSize: "1.6rem",
    background: colors.brand,
    boxShadow: "0 1rem 2rem 0 rgba(124, 160, 73, 0.15)",
    color: "#FFFFFF",
    fontFamily: "muli",
  },
  button2Styles: {
    position: "absolute",
    borderColor: "#677791",
    fontWeight: "bold",
    border: 0,
    height: "40px",
    cursor: "pointer",
    transition: ".4s",
    right: "40px",
    bottom: "50px",
    borderRadius: "2px",
    fontSize: "1.6rem",
    background: colors.brand,
    boxShadow: "0 1rem 2rem 0 rgba(124, 160, 73, 0.15)",
    color: "#FFFFFF",
    fontFamily: "muli",
  },
};

const TABLE_ROWS = 10;

const LoaderContainer = styled.div`
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class ShowIframe extends Component {
  state = {
    iframeURL: null,
    videoLoaded: false,
    isRedacting: false,
    redactionProgress: 0,
    notes: [],
    currentPage: 0,
    showAddModal: false,
    pdfModal: false,
    videoName: "-",
  };

  async componentDidMount() {
    const data = await this.props.getIframeURL(this.props.match.params.id);
    if (data && data.iframeSrc) {
      this.setState({
        iframeURL: data.iframeSrc,
        notes: data.notes,
        videoName: data.name || "-",
      });
    } else {
      this.props.errorNotification({
        title: "Failed to redact the video",
        autoDismiss: 1,
      });
      this.props.history.push("/video/raw/videos");
    }
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.listener);
  }

  listener = async (event) => {
    if (event.data.toString().substring(3, 9) !== "action") {
      return;
    }

    const eventRes = JSON.parse(event.data);
    switch (eventRes.action) {
      case "returned_metadata": {
        const videoID = eventRes.video_id;
        let metadata = eventRes.metadata;
        metadata = JSON.parse(metadata);
        metadata.videoID = videoID;
        const res = await this.props.saveMetadata(
          this.props.match.params.id,
          metadata,
          this.state.notes
        );
        if (res?.success) {
          this.props.successNotification({
            title: "Saved Successfully",
            autoDismiss: 1,
          });
        } else {
          this.props.errorNotification({
            title: "Failed to save.Try again later",
            autoDismiss: 1,
          });
        }
        break;
      }
      case "returned_generate_redacted_video_progress": {
        const progress = parseInt(eventRes.progress, 10);
        this.setState({ redactionProgress: progress });
        if (progress !== 100) {
          break;
        }

        const data = await this.props.saveRedactedVideo(
          this.props.match.params.id
        );
        if (data?.success) {
          this.props.fetchRedactedVideos();
          this.props.history.push({
            pathname: "/video/redacted/videos",
          });
        } else {
          this.props.errorNotification({
            title: "Failed to generate the video",
            autoDismiss: 1,
          });
        }
        break;
      }
      case "video_is_loaded_result": {
        if (eventRes.result === true) {
          this.setState({ videoLoaded: true });
        }
        break;
      }
      default: {
        return false;
      }
    }
  };

  iframeDidMount = () => {
    window.addEventListener("message", this.listener);
  };

  renderButtonOnScreen() {
    if (this.state.videoLoaded === true) {
      return (
        <>
          <button
            style={styles.buttonStyles}
            onClick={() => this.sendPostMessage("save_metadata")}
          >
            Save Work
          </button>

          <button
            style={styles.button2Styles}
            onClick={() => {
              this.setState({ isRedacting: true });
              this.sendPostMessage("generate_redacted_video");
            }}
          >
            Generate Redacted Video
          </button>
        </>
      );
    }
  }

  sendPostMessage = (action) => {
    this.refs.mainFrame.contentWindow.postMessage(
      JSON.stringify({ action: action }),
      "*"
    );
  };

  handlePageClick = (data) => {
    this.setState({ currentPage: data.selected });
  };

  merge = (notes = []) => {
    const merged = notes
      .slice()
      .sort((a, b) => toSec(a.start) - toSec(b.start));

    let i = 0;
    while (i < merged.length - 1) {
      const curr = merged[i];
      const next = merged[i + 1];

      if (toSec(curr.end) < toSec(next.start)) {
        i++;
        continue;
      }

      if (next.notes && next.notes.length > 0) {
        curr.notes =
          curr.notes.length === 0 ? next.notes : `${curr.notes} ${next.notes}`;
      }

      curr.end = toSec(curr.end) > toSec(next.end) ? curr.end : next.end;
      merged.splice(i + 1, 1);
    }

    return merged;
  };

  handleAdd = (start, end, notes) => {
    const newNotes = [...this.state.notes, { id: v4(), start, end, notes }];
    const merged = this.merge(newNotes);
    this.setState({ notes: merged, showAddModal: false });
  };

  handleDelete = (id) => {
    const newNotes = this.state.notes.filter((note) => note.id !== id);
    this.setState({ notes: newNotes });
  };

  handleEdit = (id, start, end, notes) => {
    const newNotes = this.state.notes.map((note) => {
      if (note.id === id) return { id, start, end, notes };
      return note;
    });
    const merged = this.merge(newNotes);
    this.setState({ notes: merged });
  };

  render() {
    const { iframeURL, isRedacting, redactionProgress } = this.state;
    if (!iframeURL) {
      return (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      );
    }

    return (
      <LoadingOverlay
        isActive={isRedacting}
        loaderMessage={`Redacting ${redactionProgress}%`}
        background="rgba(0, 0, 0, 0.5)"
      >
        <div style={{ position: "relative", height: "calc(100vh - 6.8rem)" }}>
          <iframe
            title="video-redaction"
            id="mainFrame"
            ref="mainFrame"
            onLoad={() => this.iframeDidMount()}
            src={iframeURL}
            style={{
              height: "100%",
              width: "100%",
              border: "none",
            }}
          />
          {this.renderButtonOnScreen()}
        </div>

        {this.state.showAddModal && (
          <AddNotesModal
            isVisible={this.state.showAddModal}
            handleAdd={this.handleAdd}
            duration={MAX_DURATION}
            handleCancel={() => this.setState({ showAddModal: false })}
          />
        )}

        {this.state.pdfModal && (
          <PDFModal
            isVisible={this.state.pdfModal}
            regions={this.state.notes}
            name={this.state.videoName}
            duration={"-"}
            handleCancel={() => {
              this.setState({ pdfModal: false });
            }}
          />
        )}

        <NotesWrapper>
          <p>Notes</p>
          <Pagination
            currentPage={this.state.currentPage}
            pageCount={
              this.state.notes.length > TABLE_ROWS
                ? this.state.notes.length / TABLE_ROWS
                : 1
            }
            handlePageClick={this.handlePageClick}
          />
          <div className="buttons-container">
            <IconButton
              backgroundColor="white"
              margin="0 3rem"
              title="Generate PDF"
              onClick={() => {
                this.setState({ pdfModal: true });
              }}
              background={`url(${icons.IC_Printer}) center center / 20px 18px no-repeat`}
            />
            <Button
              boxShadow="0 2px 10px 0 rgba(44,137,255,0.06)"
              height="36"
              width="143"
              color="#fff"
              fontSize="1.4rem"
              margin="0rem 1rem"
              borderRadius="3rem"
              background={colors.brand}
              style={{ textTransform: "none" }}
              onClick={() => this.setState({ showAddModal: true })}
            >
              <FaPlus /> &nbsp;
              <div style={{ marginLeft: "0.5rem" }}>Add Notes</div>
            </Button>
          </div>
        </NotesWrapper>

        <Table
          regions={this.state.notes.slice(
            this.state.currentPage * TABLE_ROWS,
            this.state.currentPage * TABLE_ROWS + TABLE_ROWS
          )}
          handleDelete={this.handleDelete}
          handleEdit={this.handleEdit}
          duration={MAX_DURATION}
        />
      </LoadingOverlay>
    );
  }
}

export default connect(null, {
  getIframeURL,
  saveMetadata,
  saveRedactedVideo,
  fetchRedactedVideos,
  successNotification,
  errorNotification,
})(ShowIframe);
