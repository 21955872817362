import axios from "axios";

import { wuserConstants } from "../constants";
import keys from "../config/keys";
import { errorNotification, successNotification } from "./index.js";
import { removeRequest } from "./request.actions";
import store from "../store";
const { SERVER_URL } = keys;

export const editProfile = id => async dispatch => {
  try {
    dispatch({
      type: wuserConstants.SET_WUSER_LOADING
    });
    const result = await axios.get(`${SERVER_URL}/wusers/${id}`, {
      requestId: "editProfile"
    });

    const profile = result.data.profile;
    const orgName = result.data.orgName;

    profile.gallery.map(pic => {
      pic.url = `${SERVER_URL}/images/${orgName}/wusers/${profile._id}/${pic.name}`;
      return profile;
    });
    return dispatch({
      type: wuserConstants.FETCH_WUSER,
      payload: profile
    });
  } catch (e) {
    store.dispatch(removeRequest("editProfile"));
    if (axios.isCancel(e)) {
      return true;
    }
    dispatch(
      errorNotification({
        title: "Failed to fetch profile!",
        autoDismiss: 1
      })
    );
    return dispatch({
      type: wuserConstants.FETCH_WUSER_ERROR,
      payload: "error"
    });
  }
};

export const updateProfile = async (id, formData, data) => {
  try {
    let result = await axios.post(`${SERVER_URL}/wusers/${id}`, data, {
      requestId: "updateProfile1"
    });

    if (result.status) {
      result = await axios.post(`${SERVER_URL}/wusers/${id}/media`, formData, {
        requestId: "updateProfile2"
      });
    }
    store.dispatch(
      successNotification({
        title: "Profile updated!",
        autoDismiss: 1
      })
    );
    return result;
  } catch (e) {
    store.dispatch(removeRequest("updateProfile1"));
    store.dispatch(removeRequest("updateProfile2"));
    if (axios.isCancel(e)) {
      return {};
    }
    store.dispatch(
      errorNotification({
        title: "Failed to update profile!",
        autoDismiss: 1
      })
    );
    return {};
  }
};

export const deleteProfile = async id => {
  try {
    const result = await axios.delete(`${SERVER_URL}/wusers/${id}`, {
      requestId: "deleteProfile"
    });
    store.dispatch(
      successNotification({
        title: "Profile deleted!",
        autoDismiss: 1
      })
    );
    return result;
  } catch (e) {
    store.dispatch(removeRequest("deleteProfile"));
    if (axios.isCancel(e)) {
      return false;
    }
    store.dispatch(
      errorNotification({
        title: "Failed to delete user!",
        autoDismiss: 1
      })
    );
    return false;
  }
};

export const deleteImage = (id, gid) => async dispatch => {
  try {
    let result = await axios.delete(`${SERVER_URL}/wusers/${id}/media/${gid}`, {
      requestId: "deleteImage1"
    });
    if (result.status)
      result = await axios.get(`${SERVER_URL}/wusers/${id}`, {
        requestId: "deleteImage2"
      });

    const profile = result.data.profile;
    const orgName = result.data.orgName;

    profile.gallery.map(pic => {
      pic.url = `${SERVER_URL}/images/${orgName}/wusers/${profile._id}/${pic.name}`;
      return profile;
    });
    dispatch({
      type: wuserConstants.FETCH_WUSER,
      payload: profile
    });
    return true;
  } catch (e) {
    store.dispatch(removeRequest("deleteImage1"));
    store.dispatch(removeRequest("deleteImage2"));
    if (axios.isCancel(e)) {
      return true;
    }
    return false;
  }
};

export const fetchProfiles = () => async dispatch => {
  try {
    dispatch({ type: wuserConstants.SET_WUSERS_LOADING });
    const result = await axios.get(`${SERVER_URL}/wusers/`, {
      requestId: "fetchProfiles"
    });

    const profiles = result.data.profiles;
    const orgName = result.data.orgName;
    const profilesCount = result.data.profilesCount;

    profiles.map(profile => {
      if (profile.gallery && profile.gallery[0]) {
        profile.profilePic = `${SERVER_URL}/images/${orgName}/wusers/${profile._id}/${profile.gallery[0].name}`;

        profile.gallery.map(pic => {
          pic.url = `${SERVER_URL}/images/${orgName}/wusers/${profile._id}/${pic.name}`;
          return profile;
        });
      }
      return profiles;
    });

    return dispatch({
      type: wuserConstants.FETCH_WUSERS,
      payload: { profiles, profilesCount }
    });
  } catch (e) {
    store.dispatch(removeRequest("fetchProfiles"));
    if (axios.isCancel(e)) {
      return true;
    } else {
      dispatch({
        type: wuserConstants.FETCH_WUSERS_ERROR
      });
      dispatch(
        errorNotification({
          title: "Failed to fetch users!",
          autoDismiss: 1
        })
      );
      return {};
    }
  }
};

export const showUsers = page => dispatch => {
  dispatch({
    type: wuserConstants.SET_WUSERS_LOADING
  });
  const pageNum = page.selected;
  const usersPerPage = 40;
  const startNum = pageNum * usersPerPage;
  const endNum = pageNum * usersPerPage + usersPerPage;
  axios
    .get(`${SERVER_URL}/wusers?startNum=${startNum}&endNum=${endNum}`)
    .then(res => {
      const profiles = res.data.profiles;
      const orgName = res.data.orgName;
      profiles.map(profile => {
        if (profile.gallery && profile.gallery[0]) {
          profile.profilePic = `${SERVER_URL}/images/${orgName}/wusers/${profile._id}/${profile.gallery[0].name}`;
          profile.gallery.map(pic => {
            pic.url = `${SERVER_URL}/images/${orgName}/wusers/${profile._id}/${pic.name}`;
            return profile;
          });
        }
        return profiles;
      });
      dispatch({
        type: wuserConstants.SET_USERS_ON_PAGE,
        payload: profiles
      });
    });
};

export const showFilteredUsers = page => dispatch => {
  dispatch({
    type: wuserConstants.SET_FILTERED_USERS_ON_PAGE,
    payload: page.selected
  });
};

// PROFILE MANAGEMENT
export const addProfile = data => async dispatch => {
  try {
    const result = await axios.post(`${SERVER_URL}/wusers/`, data, {
      requestId: "addProfile"
    });
    if (result.status) {
      dispatch(
        successNotification({
          title: "Profile created successfully!",
          autoDismiss: 1
        })
      );
      return dispatch(fetchProfiles());
    } else return false;
  } catch (e) {
    store.dispatch(removeRequest("addProfile"));
    dispatch(
      errorNotification({
        title: "Failed add Profile.",
        autoDismiss: 1
      })
    );
    if (axios.isCancel(e)) {
      return true;
    } else if (e.response) {
      return dispatch({
        type: wuserConstants.FETCH_WUSERS_ERROR,
        payload: e.response.data.error
      });
    }
  }
};

export const fetchProfile = async id => {
  try {
    const result = await axios.get(`${SERVER_URL}/wusers/${id}`, {
      requestId: "fetchProfile"
    });

    if (result.data) {
      const profile = result.data.profile;
      const orgName = result.data.orgName;

      profile.gallery.map(pic => {
        pic.url = `${SERVER_URL}/images/${orgName}/wusers/${profile._id}/${pic.name}`;
        return profile;
      });
      return profile;
    } else {
      return false;
    }
  } catch (e) {
    store.dispatch(removeRequest("fetchProfile"));
    if (axios.isCancel(e)) {
      return false;
    }
    console.log(e);
    store.dispatch(
      errorNotification({
        title: "Failed to fetch profile!",
        autoDismiss: 1
      })
    );
    return false;
  }
};

export const queryUser = name => dispatch => {
  dispatch({
    type: wuserConstants.SET_WUSERS_LOADING
  });
  axios
    .get(`${SERVER_URL}/wusers/search?searchterm=${name}`)
    .then(res => {
      const profiles = res.data.doc;
      const orgName = res.data.orgName;
      profiles.map(profile => {
        if (profile.gallery && profile.gallery[0]) {
          profile.profilePic = `${SERVER_URL}/images/${orgName}/wusers/${profile._id}/${profile.gallery[0].name}`;
          profile.gallery.map(pic => {
            pic.url = `${SERVER_URL}/images/${orgName}/wusers/${profile._id}/${pic.name}`;
            return profile;
          });
        }
        return profiles;
      });
      dispatch({
        type: wuserConstants.QUERY_USER,
        payload: {
          users: profiles,
          query: name
        }
      });
    })
    .catch(err => console.log(err));
};
