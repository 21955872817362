import React, { useState, useEffect } from "react";
import axios from "axios";

import keys from "../../config/keys";
import HomeOverview from "./HomeOverview1";
import { FiUsers } from "react-icons/fi";
import { FiSearch } from "react-icons/fi";
import { FiUser } from "react-icons/fi";
// import status_up from "../../assets/img/status_up.png";
// import status_down from "../../assets/img/status_down.png";

const HomeOverviewComp = (props) => {
  const [casesCount, setCasesCount] = useState(0);
  const Cards = {
    icon1: <FiUsers />,
    heading1: props.usersCount?.toString() || "0",
    subheading1: "Total Users",
    // status1: <img src={status_up} alt="status1" />,
    icon2: <FiSearch />,
    heading2: casesCount?.toString() || "0",
    subheading2: "Cases",
    // status2: <img src={status_down} alt="status2" />,
    icon3: <FiUser />,
    heading3: "0",
    subheading3: "Suspect Found",
    // status3: <img src={status_up} alt="status3" />
  };

  useEffect(() => {
    fetchCasesCount();
  }, []);

  async function fetchCasesCount() {
    const count = await axios
      .get(`${keys.SERVER_URL}/cases/count`)
      .then((res) => res.data.count)
      .catch(() => {});
    if (count) {
      setCasesCount(count);
    }
  }

  return <HomeOverview {...Cards} margin={props.margin} />;
};

export default HomeOverviewComp;
