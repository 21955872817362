import React, { useState, useEffect } from "react";
import loadImage from "blueimp-load-image/js";

import ProfilePlaceholder from "../../assets/img/profile_placeholder.jpg";
import DefaultAvatar from "../../assets/img/default_profile_img.png";
import CasePlaceholder from "../../assets/img/broken-image.png";

const Image = props => {
  const { src, type, alt, ...restOfProps } = props;
  const [imgUrl, setImgUrl] = useState(src ? src : "");

  useEffect(() => {
    // Fixing the orientaion of the image
    loadImage(
      src,
      function(canvas) {
        if (canvas && canvas.toDataURL) {
          const newURL = canvas.toDataURL("image/jpeg");
          setImgUrl(newURL);
        }
      },
      {
        canvas: true,
        orientation: true
      }
    );
  }, [src]);

  return (
    <img
      src={imgUrl}
      alt={alt}
      {...restOfProps}
      onError={e => {
        if (type === "case") {
          e.target.src = CasePlaceholder;
        } else if (type === "profile") {
          e.target.src = DefaultAvatar;
        } else {
          e.target.src = ProfilePlaceholder;
        }
      }}
    />
  );
};

export default Image;
