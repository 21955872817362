import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FaPlus } from "react-icons/fa";

import CaseTable from "./CaseTable";
import Button from "../../components/common/Button";
import { ReactComponent as EmptySVG } from "../../assets/img/empty.svg";
import makeRequest from "../../config/helpers";
import { colors } from "../../components/themes/base";

const CaseWrapper = styled.div`
  font-family: ${(props) => props.fontFamily};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .headerWrapper {
    display: flex;
    justify-content: space-between;
    padding: 2.5rem 0;
    width: 94%;
    color: #142945;
    font-family: ${(props) => props.fontFamily};
    font-size: 2.2rem;
    font-weight: 700;
    letter-spacing: 0.18px;
    .buttons {
      display: flex;
    }
  }

  .case-table {
    margin: 0 5rem;
  }
`;

const StyledNotFound = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    max-width: 30rem;
    transform: translateY(-20rem);
  }

  .message {
    transform: translateY(-45rem);
    text-align: center;

    h4 {
      font-size: 3rem;
      font-weight: bold;
      margin: 0;
    }

    p {
      font-size: 1.8rem;
      color: grey;
    }

    button {
      margin: 0 auto;
      background: ${colors.brand};
      font-size: 1.6rem;
      box-shadow: 0 1rem 2rem 0 rgba(124, 160, 73, 0.15);
      color: #ffffff;
      padding: 1rem 2rem;
      text-transform: uppercase;
    }
  }
`;

const NoCasesFound = (props) => (
  <StyledNotFound>
    <EmptySVG />
    <div className="message">
      <h4>No Cases</h4>
      <p>When you upload cases. They will appear here.</p>

      <Button
        height={"34px"}
        onClick={() => props.history.push("/investigator/create")}
      >
        Add
      </Button>
    </div>
  </StyledNotFound>
);

class Cases extends Component {
  state = {
    loading: true,
    caseList: [],
    users: [],
  };

  componentDidMount() {
    this.fetchCases();
  }

  fetchCases = async () => {
    this.setState({ loading: true });
    const res = await makeRequest("GET", "/investigator/cases");
    this.setState({ loading: false });

    if (res.status === 200 && res.caseList) {
      this.setState({ caseList: res.caseList });
    }

    if (res.usersList) {
      this.setState({ users: res.usersList });
    }
  };

  render() {
    if (!this.state.loading && !this.state.caseList.length) {
      return <NoCasesFound {...this.props} />;
    }

    return (
      <CaseWrapper {...this.props}>
        <div className="headerWrapper">
          <div className="heading">Cases</div>
          <div className="buttons">
            <Button
              boxShadow="0 2px 10px 0 rgba(44,137,255,0.06)"
              height="36px"
              width="118px"
              color="#fff"
              background={colors.brand}
              fontSize="1.4rem"
              margin="0rem 1rem"
              borderRadius="3rem"
              style={{ textTransform: "none" }}
              onClick={() => this.props.history.push(`/investigator/create`)}
            >
              <FaPlus /> &nbsp;{" "}
              <div style={{ marginLeft: "0.5rem" }}>Add Case</div>
            </Button>
          </div>
        </div>

        <div className="case-table">
          <CaseTable
            loading={this.state.loading}
            caseList={this.state.caseList}
            users={this.state.users}
          />
        </div>
      </CaseWrapper>
    );
  }
}

export default Cases;

Cases.propTypes = {
  fontFamily: PropTypes.string,
  background: PropTypes.string,
};

Cases.defaultProps = {
  fontFamily: '"Muli", sans-serif',
  background: "#fff",
};
