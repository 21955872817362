import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Button from "../../components/common/Button";
import { FaPlus } from "react-icons/fa";
import DefaultTable from "../../components/Table/Table";
import { colors } from "../../components/themes/base";

const ManageCaseWrapper = styled.div`
  font-family: ${(props) => props.fontFamily};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .headerWrapper {
    display: flex;
    justify-content: space-between;
    padding: 2.5rem 0;
    width: 90%;
    color: #142945;
    font-family: ${(props) => props.fontFamily};
    font-size: 2.2rem;
    font-weight: 700;
    letter-spacing: 0.18px;
    .buttons {
      display: flex;
    }
  }

  .case-table {
    margin: 0 5rem;
  }
`;

class ManageCase extends Component {
  render() {
    return (
      <ManageCaseWrapper {...this.props}>
        <div className="headerWrapper">
          <div className="heading">Cases</div>
          <div className="buttons">
            {/* <Button
              boxShadow="0 2px 10px 0 rgba(44,137,255,0.06)"
              width="103"
              height="36"
              background="#fff"
              color="#223539"
              fontSize="1.4rem"
              borderRadius="3rem"
              style={{
                textTransform: "none",
                marginRight: "1rem"
              }}
            >
              <div>Filter</div> &nbsp;{" "}
              <MdFilterList
                style={{
                  color: colors.brand,
                  fontSize: "2rem",
                  marginLeft: "0.5rem"
                }}
              />
            </Button> */}
            <Button
              boxShadow="0 2px 10px 0 rgba(44,137,255,0.06)"
              height="36"
              width="103"
              color="#fff"
              background={colors.brand}
              fontSize="1.4rem"
              margin="0rem 1rem"
              borderRadius="3rem"
              style={{ textTransform: "none" }}
              onClick={() => this.props.history.push(`/facesearch/cases/add`)}
            >
              <FaPlus /> &nbsp;{" "}
              <div style={{ marginLeft: "0.5rem" }}>Add Case</div>
            </Button>
          </div>
        </div>

        <div className="case-table">
          <DefaultTable showID hideAdd />
        </div>
      </ManageCaseWrapper>
    );
  }
}

export default ManageCase;

ManageCase.propTypes = {
  fontFamily: PropTypes.string,
  background: PropTypes.string,
};

ManageCase.defaultProps = {
  fontFamily: '"Muli", sans-serif',
  background: "#fff",
};
