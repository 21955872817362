import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import MainWavesurfer from "./MainWavesurfer";
import keys from "../../config/keys";
import { errorNotification } from "../../components/Notifcations";

const AudioRedaction = (props) => {
  const [audiodata, setData] = useState({});

  const [regions, setRegions] = useState([]);
  const regionsRef = useRef(regions);
  const setRegionsRef = (data) => {
    regionsRef.current = data;
    setRegions(data);
  };

  useEffect(() => {
    fetchURL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchURL = async () => {
    try {
      const url = `${keys.SERVER_URL}/audio/${props.match.params.id}`;
      const res = await axios.get(url, { requestId: "getOneAudio" });

      if (res.status === 200 && res.data.audioObj) {
        const data = { ...res.data.audioObj };
        data.peaks = res.data.peaks;

        if (
          res.data.audioObj.audio_redaction &&
          res.data.audioObj.audio_redaction.metadata &&
          res.data.audioObj.audio_redaction.metadata.muteRegions
        ) {
          setRegionsRef(res.data.audioObj.audio_redaction.metadata.muteRegions);
        }

        setData(data);
      } else {
        errorNotification({
          title: "Unable to load the audio",
          autoDismiss: 1,
        });
      }
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }

      console.log(e);
      errorNotification("Unable to load the audio");
    }
  };

  return (
    <MainWavesurfer
      showredactbuttons
      regions={regionsRef.current}
      setRegions={setRegionsRef}
      {...audiodata}
    />
  );
};

export default AudioRedaction;
