import axios from "axios";

import keys from "../config/keys";

import { removeRequest } from "./request.actions";

import {
  SET_VIDEO_FILES,
  SET_OFFICER_NAME,
  SET_VIDEO_REDACTION_PURPOSE,
  APPEND_VIDEO_FILES,
  SET_RAW_VIDEOS,
  SET_REDACTED_VIDEOS,
  RESET_FORM,
  SET_UPLOADING,
  SET_FILES_UPLOADING_PROGRESS,
  SET_AUDIO_FILES,
  APPEND_AUDIO_FILES,
} from "./types";

const { SERVER_URL } = keys;

export const setOfficerName = (name) => ({
  type: SET_OFFICER_NAME,
  payload: name,
});

export const setVideoRedactionPurpose = (purpose) => ({
  type: SET_VIDEO_REDACTION_PURPOSE,
  payload: purpose,
});

export const setVideoFiles = (files) => ({
  type: SET_VIDEO_FILES,
  payload: files,
});

export const appendVideoFiles = (files) => ({
  type: APPEND_VIDEO_FILES,
  payload: files,
});

export const setAudioFiles = (files) => ({
  type: SET_AUDIO_FILES,
  payload: files,
});

export const appendAudioFiles = (files) => ({
  type: APPEND_AUDIO_FILES,
  payload: files,
});

export const setRawVideos = (videos) => ({
  type: SET_RAW_VIDEOS,
  payload: videos,
});

export const setRedactedVideos = (videos) => ({
  type: SET_REDACTED_VIDEOS,
  payload: videos,
});

export const resetForm = () => ({
  type: RESET_FORM,
});

export const setUploading = (status) => ({
  type: SET_UPLOADING,
  payload: status,
});

export const setUploadingProgress = (progress) => ({
  type: SET_FILES_UPLOADING_PROGRESS,
  payload: progress,
});

export const uploadVideos = (formData) => async (dispatch) => {
  try {
    const config = {
      requestId: "uploadVideos",
      onUploadProgress: (ProgressEvent) => {
        let uploadedProgress =
          (ProgressEvent.loaded / ProgressEvent.total) * 100;
        uploadedProgress = (uploadedProgress * 0.5).toFixed(0); // Taking only 50% for upload progress

        return dispatch({
          type: SET_FILES_UPLOADING_PROGRESS,
          payload: parseInt(uploadedProgress, 10),
        });
      },
    };
    const res = await axios.post(
      `${SERVER_URL}/redact/videos`,
      formData,
      config
    );
    return res.data;
  } catch (e) {
    dispatch(removeRequest("uploadVideos"));
    if (axios.isCancel(e)) {
      return true;
    } else {
      console.log(e);
      return false;
    }
  }
};

export const getUploadProgress = (redactionID) => async () => {
  try {
    const res = await axios.get(
      `${SERVER_URL}/redact/${redactionID}/upload/progress?buster=` +
        new Date().getTime(),
      { requestId: "getUploadProgress" }
    );
    if (res.data.success) {
      const { status, progress } = res.data;
      if (status === "Failed") {
        return false;
      }
      return { status, realProgress: progress };
    } else {
      return false;
    }
  } catch (e) {
    if (axios.isCancel(e)) {
      return { success: true };
    } else {
      console.log(e);
      return false;
    }
  }
};

export const fetchVideos = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${SERVER_URL}/redact/videos?buster=` + new Date().getTime(),
      {
        requestId: "fetchVideos",
      }
    );
    if (res.data.success) {
      if (res.data.rawVideos.length === 0) {
        dispatch({
          type: SET_RAW_VIDEOS,
          payload: null,
        });
      } else {
        dispatch({
          type: SET_RAW_VIDEOS,
          payload: res.data.rawVideos,
        });
      }
      return true;
    } else {
      dispatch({
        type: SET_RAW_VIDEOS,
        payload: null,
      });
      return false;
    }
  } catch (e) {
    dispatch({
      type: SET_RAW_VIDEOS,
      payload: null,
    });
    if (axios.isCancel(e)) {
      return true;
    } else {
      console.log(e);
      return false;
    }
  }
};

export const fetchRedactedVideos = () => async (dispatch) => {
  try {
    const res = await axios.get(`${SERVER_URL}/redact/videos/redacted`, {
      requestId: "fetchRedactedVideos",
    });
    if (res.data.success) {
      if (res.data.redactedVideos.length === 0) {
        dispatch({
          type: SET_REDACTED_VIDEOS,
          payload: null,
        });
      } else {
        dispatch({
          type: SET_REDACTED_VIDEOS,
          payload: res.data.redactedVideos,
        });
      }
      return true;
    } else {
      dispatch({
        type: SET_REDACTED_VIDEOS,
        payload: null,
      });
      return false;
    }
  } catch (e) {
    if (axios.isCancel(e)) {
      return true;
    } else {
      console.log(e);
      dispatch({
        type: SET_REDACTED_VIDEOS,
        payload: null,
      });
      return false;
    }
  }
};

export const deleteVideo = (videoID) => async () => {
  try {
    const res = await axios.delete(`${SERVER_URL}/redact/videos/${videoID}`, {
      requestId: "deleteVideo",
    });
    return res.data;
  } catch (e) {
    if (axios.isCancel(e)) {
      return true;
    } else {
      console.log(e);
      return false;
    }
  }
};

export const getIframeURL = (videoID) => async () => {
  try {
    const res = await axios.get(
      `${SERVER_URL}/redact/videos/${videoID}/redact`
    );
    return {
      iframeSrc: decodeURIComponent(res.data.iframeSrc),
      notes: res.data.notes,
      name: res.data.name,
    };
  } catch (e) {
    if (axios.isCancel(e)) {
      return true;
    } else {
      console.log(e);
      return false;
    }
  }
};

export const saveMetadata = (videoID, metadata, notes) => async () => {
  try {
    const res = await axios.post(
      `${SERVER_URL}/redact/videos/${videoID}/save`,
      { metadata, notes },
      { requestId: "saveMetadata" }
    );
    return res.data;
  } catch (e) {
    if (axios.isCancel(e)) {
      return true;
    } else {
      console.log(e);
      return false;
    }
  }
};

export const saveRedactedVideo = (videoID) => async () => {
  try {
    const res = await axios.get(
      `${SERVER_URL}/redact/videos/${videoID}/redacted/save`,
      { requestId: "saveRedactedVideo" }
    );
    return res.data;
  } catch (e) {
    if (axios.isCancel(e)) {
      return true;
    } else {
      console.log(e);
      return false;
    }
  }
};

export const deleteRedactedVideo = (videoID) => async () => {
  try {
    const res = await axios.delete(
      `${SERVER_URL}/redact/videos/${videoID}/redacted?buster=` +
        new Date().getTime(),
      { requestId: "deleteRedactedVideo" }
    );
    return res.data;
  } catch (e) {
    if (axios.isCancel(e)) {
      return true;
    } else {
      console.log(e);
      return false;
    }
  }
};
