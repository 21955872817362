import axios from "axios";
import jwt_decode from "jwt-decode";

import { errorNotification, successNotification } from "../actions";
import { removeRequest } from "./request.actions";
import { userConstants } from "../constants";
import setAuthToken from "../utils/setAuthToken";
import keys from "../config/keys";

const { SERVER_URL } = keys;

export const loginUser = (userData, history) => (dispatch) => {
  dispatch({ type: userConstants.REQUEST });
  dispatch({ type: userConstants.CLEAR_ERRORS });

  axios
    .post(`${SERVER_URL}/signin`, userData, { requestId: "loginUser" })
    .then((res) => {
      // Save to localStorage
      const { token } = res.data;
      // Set toke to ls
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));

      if (window.drift && window.drift.api && window.drift.api.widget) {
        window.drift.identify(decoded._id.toString(), {
          _id: decoded._id.toString(),
          name: decoded.fullName,
          email: decoded.email,
        });
        window.drift.api.widget.show();
      }
    })
    .catch((err) => {
      dispatch(removeRequest("loginUser"));
      dispatch({ type: userConstants.SET_LOADING_FALSE });
      if (err.response) {
        const error = err.response.data;
        // Send validation errors to the reducer
        if (error.type === "validation") {
          return dispatch({
            type: userConstants.ERORRS,
            payload: error.errors,
          });
        }
      } else if (err.request) {
        dispatch(
          errorNotification({
            title: "Failed to submit login details.",
            autoDismiss: 1,
          })
        );
        if (axios.isCancel(err)) {
          return true;
        } else {
          console.log(err);
          return false;
        }
      }
    });
};

export const registerUser = (userData, history) => (dispatch) => {
  dispatch({ type: userConstants.REQUEST });

  axios
    .post(`${SERVER_URL}/signup`, userData, { requestId: "registerUser" })
    .then((res) => {
      dispatch({ type: userConstants.CLEAR_ERRORS });
      setTimeout(() => history.push("/signin"), 2000);
      dispatch({ type: userConstants.SET_LOADING_FALSE });
      dispatch(
        successNotification({
          title: "Registration Successful! Please sign in",
          autoDismiss: 1,
        })
      );
      dispatch({ type: userConstants.SET_LOADING_FALSE });
    })
    .catch((err) => {
      dispatch(removeRequest("registerUser"));
      dispatch({ type: userConstants.SET_LOADING_FALSE });
      if (err.response) {
        const error = err.response.data;
        // Send validation errors to the reducer
        if (error.type === "validation") {
          return dispatch({
            type: userConstants.ERORRS,
            payload: error.errors,
          });
        }
      } else {
        if (axios.isCancel(err)) {
          return true;
        } else {
          dispatch(
            errorNotification({
              title: "Failed to submit registration details.",
              autoDismiss: 1,
            })
          );
        }
      }
    });
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  dispatch({ type: userConstants.LOGOUT });
  if (window.drift && window.drift.api && window.drift.api.widget) {
    window.drift.reset();
  }
  window.location.reload();
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: userConstants.SET_CURRENT_USER,
    payload: decoded,
  };
};

// Forgot Password
export const forgotPassword = (data) => (dispatch) => {
  dispatch({ type: userConstants.CLEAR_ERRORS });
  dispatch({ type: userConstants.REQUEST });
  axios
    .post(`${SERVER_URL}/forgot`, data, { requestId: "forgotPassword" })
    .then((res) => {
      dispatch({ type: userConstants.SET_LOADING_FALSE });
      dispatch(
        successNotification({
          title: "A password reset email has been sent your email address!",
          autoDismiss: 1,
        })
      );
    })
    .catch((err) => {
      console.log("forgot catch");
      dispatch(removeRequest("forgotPassword"));
      dispatch({ type: userConstants.SET_LOADING_FALSE });

      if (err.response) {
        const error = err.response.data;
        console.log(error);
        if (!error.success) {
          return dispatch({
            type: userConstants.ERORRS,
            payload: error,
          });
        }
      } else {
        dispatch(
          errorNotification({
            title: "failed to reach server.",
            autoDismiss: 1,
          })
        );
        if (axios.isCancel(err)) {
          return true;
        }
      }
    });
};

// Reset password
export const resetPassword = (data, history) => (dispatch) => {
  dispatch({ type: userConstants.CLEAR_ERRORS });
  dispatch({ type: userConstants.REQUEST });
  axios
    .post(
      `${SERVER_URL}/reset/${data.token}`,
      { password: data.password },
      { requestId: "resetPassword" }
    )
    .then((res) => {
      setTimeout(() => history.push("/signin"), 1000);
      dispatch({ type: userConstants.SET_LOADING_FALSE });
      dispatch(
        successNotification({
          title: "Your password has been successfully reset. Please log in.",
          autoDismiss: 1,
        })
      );
    })
    .catch((err) => {
      dispatch(removeRequest("resetPassword"));
      dispatch({ type: userConstants.SET_LOADING_FALSE });

      if (err.response) {
        const error = err.response.data;
        // Send validation errors to the reducer
        if (error.type === "validation") {
          return dispatch({
            type: userConstants.ERORRS,
            payload: error.errors,
          });
        }
      } else {
        dispatch(
          errorNotification({
            title: "failed to reach server.",
            autoDismiss: 1,
          })
        );
        if (axios.isCancel(err)) {
          return true;
        }
      }
    });
};

export const clearValidationErrors = () => (dispatch) => {
  dispatch({ type: userConstants.CLEAR_ERRORS });
};

export const updateUserProfile = (userData, history) => async (dispatch) => {
  dispatch({ type: userConstants.UPDATE_PROFILE_REQUEST });

  try {
    const res = await axios.post(`${SERVER_URL}/profile`, userData, {
      requestId: "updateUserProfile",
    });
    // make loading false
    dispatch({ type: userConstants.UPDATE_PROFILE_SUCCESS });

    if (res.data.success) {
      dispatch(
        successNotification({
          title: "Profile Updated Successfully!",
          autoDismiss: 1,
        })
      );

      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      const decoded = jwt_decode(token);
      // Set current user
      await dispatch(setCurrentUser(decoded));
      history.push("/profile");
    } else {
      dispatch(
        errorNotification({
          title: "Failed to update profile!",
          autoDismiss: 1,
        })
      );
    }
  } catch (err) {
    dispatch(removeRequest("updateUserProfile"));
    if (axios.isCancel(err)) {
      return true;
    }
    dispatch({ type: userConstants.UPDATE_PROFILE_SUCCESS });
    dispatch(
      errorNotification({
        title: "Failed to update profile!",
        autoDismiss: 1,
      })
    );
  }
};
