import React from "react";
// import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import Modal from "react-modal";
import styled from "styled-components";

// import { PDF } from "../EditorTools/PDFConverter";
import Button from "../../components/common/Button";
import { colors } from "../themes/base";

const buttonProps = {
  width: "110px",
  height: "48px",
  fontSize: "1.6rem",
  background: colors.brand,
  boxShadow: "0 10px 20px 0 rgba(124, 160, 73, 0.15)",
  color: "#FFFFFF",
};

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 99999999,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: 0,
    border: 0,
    outline: 0,
    transform: "translate(-50%, -50%)",
  },
};

const ModalContent = styled.div`
  box-sizing: border-box;
  width: 100.5rem;
  color: #142945;

  iframe {
    border: 0;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin: 2rem;
    margin-top: 20px;
  }

  .pdf-viewer {
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-decoration: none;
    background: ${colors.brand};
    border-radius: 4px;
    color: white;
    font-size: 1.6rem;
    font-family: "Muli", sans-serif;
    text-transform: unset;
    font-weight: 500;
    line-height: 3;
    height: 48px;
    width: 110px;
    text-align: center;
  }
`;

const PDFView = (props) => {
  const {
    isVisible,
    handleCancel,
    // comments,
    // caseID,
    // videoName
  } = props;

  // const getPDFName = (name) => {
  //   if (!name) {
  //     return "Sample.pdf";
  //   }

  //   const splitted = name.split(".");
  //   const fileName = splitted.splice(0, splitted.length - 1).join(".");
  //   return `${fileName}.pdf`;
  // };

  return (
    <Modal
      isOpen={isVisible}
      style={customStyles}
      contentLabel="Warning Modal"
      ariaHideApp={false}
      onRequestClose={handleCancel}
    >
      <ModalContent>
        {/* <PDFViewer style={{ height: "700px", width: "100%" }}>
          <PDF data={comments} caseID={caseID} />
        </PDFViewer> */}
        <div className="buttons">
          <Button
            {...buttonProps}
            color={colors.brand}
            border={`1px solid ${colors.brand}`}
            background="transparent"
            boxShadow="none"
            onClick={handleCancel}
            margin="auto 10px"
          >
            Cancel
          </Button>
          {/* <PDFDownloadLink
            document={<PDF data={comments} caseID={caseID} />}
            fileName={getPDFName(videoName)}
            className="pdf-viewer"
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Download"
            }
          </PDFDownloadLink> */}
        </div>
      </ModalContent>
    </Modal>
  );
};

export default PDFView;
