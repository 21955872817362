import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import ToolsContext from "./ToolsContext";
import {
  toHHMMSS,
  toSec,
  mergeRegions,
  checkStartAndEnd,
} from "../../containers/Tools/utils";
import { colors } from "../../components/themes/base";

const ToolsProvider = (props) => {
  const [videodata, setVideodata] = useState({
    src: "",
    top: 0,
    left: 0,
    width: 800,
    height: 500,
    duration: 100,
    loaded: false,
    videoWidth: 800,
    videoHeight: 500,
  });

  // Report
  const [selector, setSelector] = useState({
    x: 100,
    y: 100,
    height: 100,
    width: 100,
    scaleX: 1,
    scaleY: 1,
  });
  const [slctCmtID, setCmtID] = useState(false);
  const [comments, setComments] = useState([]);
  const addComment = (sec) => {
    setComments((exisComments) => {
      return [
        ...exisComments,
        {
          id: uuidv4(),
          time: toHHMMSS(sec),
          screenshots: [],
          notes: "Sample Text",
        },
      ];
    });
  };

  const deleteComment = (cmntID) => {
    setComments((comments) => comments.filter((cmnt) => cmnt.id !== cmntID));
    if (slctCmtID === cmntID) {
      setCmtID(null);
      setSelector({
        x: 100,
        y: 100,
        height: 100,
        width: 100,
        scaleX: 1,
        scaleY: 1,
      });
    }
  };
  const handleSelectorChange = (newValues) => {
    setSelector((exis) => {
      return { ...exis, ...newValues };
    });
  };

  const handleCommentChange = (id, newValues) => {
    const newComments = comments.map((comment) => {
      if (comment.id !== id) {
        return comment;
      }

      return { ...comment, ...newValues };
    });

    setComments(newComments);
  };

  // Audio
  const [audioName, setAudioName] = useState("");
  const [aRegions, setARegions] = useState([]);
  const [aCropType, setACropType] = useState("split");
  const [aDuration, setADuration] = useState("00:04:33.00");
  const handleAudioRegionAdd = (start, end) => {
    const startInSec = toSec(start);
    const endInSec = toSec(end);

    const final = checkStartAndEnd(startInSec, endInSec, videodata.duration);

    const newRegions = mergeRegions(aRegions, final.start, final.end);
    setARegions(newRegions);
  };

  const handleAudioRegionDelete = (regionID) => {
    setARegions((rgns) => rgns.filter((region) => region.id !== regionID));
  };

  const handleAudioDataChange = (id, newValues = {}) => {
    const newRegions = aRegions.map((region) => {
      if (region.id !== id) {
        return region;
      }

      return { ...region, ...newValues };
    });

    setARegions(newRegions);
  };

  // VideoContext
  const [bgColor, setBgColor] = useState(colors.black);
  const [videoFit, setVideoFit] = useState("Fill Frame");
  const [resolution, setResolution] = useState("1280x720");
  const [duration, setDuration] = useState("04:33:0");
  const [bgOpacity, setBgOpacity] = useState(1);
  const [regions, setRegions] = useState([]);
  const [cropType, setCropType] = useState("merge");

  const handleRegionsAdd = (start, end) => {
    const startInSec = toSec(start);
    const endInSec = toSec(end);

    const final = checkStartAndEnd(startInSec, endInSec, videodata.duration);
    const newRegions = mergeRegions(regions, final.start, final.end);
    setRegions(newRegions);
  };

  const handleRegionDelete = (regionID) => {
    setRegions((rgns) => rgns.filter((region) => region.id !== regionID));
  };

  const handleVideoDataChange = (id, newValues = {}) => {
    const newRegions = regions.map((region) => {
      if (region.id !== id) {
        return region;
      }

      // const modifiedData = { ...region, ...newValues };
      // const final = checkStartAndEnd(
      //   modifiedData.start,
      //   modifiedData.end,
      //   videodata.duration
      // );

      // return { ...modifiedData, ...final };

      return { ...region, ...newValues };
    });

    setRegions(newRegions);
  };

  // Contrast States
  const [brightness, setBrightness] = useState(1);
  const [contrast, setContrast] = useState(1);
  const [saturation, setSaturation] = useState(1);
  const [exposure, setExposure] = useState(0);
  const handleReset = () => {
    setBrightness(1);
    setContrast(1);
    setSaturation(1);
    setExposure(0);
  };

  // Effects States
  const [effect, setEffect] = useState("none");

  // Text States
  const textInitialState = {
    id: uuidv4(),
    name: "Layer1",
    notes: "Sample Text",
    start: toHHMMSS(0),
    end: toHHMMSS(videodata.duration),
    x: 0,
    y: 0,
    scaleX: 1,
    scaleY: 1,
    rotation: 0,
    fontFamily: "sans-seriff",
    fontSize: 48,
    align: "start",
    fontStyle: "normal",
    letterSpace: "0.00",
    lineHeight: "1.00",
    fill: colors.palette[7],
    opacity: 1,
  };

  const [layers, setLayers] = useState([]);
  const handleTextAdd = () => {
    const layer = {
      ...textInitialState,
      id: uuidv4(),
      name: `Layer ${layers.length + 1}`,
      start: toHHMMSS(0),
      end: toHHMMSS(videodata.duration),
    };

    setLayers((lrs) => [...lrs, layer]);
    return layer;
  };
  const handleTextDelete = (layerID) => {
    setLayers((lrs) => lrs.filter((layer) => layer.id !== layerID));
  };
  const handleLayerDataChange = (id, newValues = {}) => {
    const newLayers = layers.map((layer) => {
      if (layer.id !== id) {
        return layer;
      }

      return { ...layer, ...newValues };
    });

    setLayers(newLayers);
  };

  // Shape states
  const shapeInitialState = {
    id: uuidv4(),
    name: "Shape1",
    type: "square",
    outline: 2,
    outlineOpacity: 0.3,
    outlineColor: colors.palette[0],
    fillOpacity: 0.3,
    fillColor: "#6dbbff",
    start: toHHMMSS(0),
    end: toHHMMSS(videodata.duration),
    x: 100,
    y: 100,
    scaleX: 1,
    scaleY: 1,
    height: 100,
    width: 100,
    rotation: 0,
  };
  const [selectedShape, setSelectedShape] = useState("square");
  const [shapes, setShapes] = useState([]);

  const getFillColor = (shape) => {
    switch (shape) {
      case "square":
        return "#6dbbff";
      case "star":
        return "#f1b721";
      case "roundedsquare":
        return "#dc3545";
      case "circle":
        return "#007bff";
      case "triangle":
        return "#a1df20";
      case "pentagon":
        return "#6ed1ec";

      default:
        return "#6dbbff";
    }
  };

  const handleShapeAdd = (shp = null) => {
    const shapeToAdd = shp || selectedShape;
    const newShape = {
      ...shapeInitialState,
      id: uuidv4(),
      name: `Shape ${shapes.length + 1}`,
      type: shapeToAdd,
      fillColor: getFillColor(shapeToAdd),
      outlineColor: getFillColor(shapeToAdd),
      start: toHHMMSS(0),
      end: toHHMMSS(videodata.duration),
    };

    setShapes((shps) => [...shps, newShape]);
    return newShape;
  };

  const handleShapeDelete = (shapeID) => {
    setShapes((shps) => shps.filter((shape) => shape.id !== shapeID));
  };

  const handleShapeDataChange = (id, newValues = {}) => {
    const newShapes = shapes.map((shape) => {
      if (shape.id !== id) {
        return shape;
      }

      return { ...shape, ...newValues };
    });

    setShapes(newShapes);
  };

  // Speed State
  const [rate, setRate] = useState(1);
  const handleSpeedReset = () => {
    setRate(1);
  };

  const contextData = {
    report: {
      selector,
      slctCmtID,
      comments,
    },

    audio: {
      aRegions,
    },

    videoContext: {
      bgColor,
      videoFit,
      resolution,
      duration,
      cropType,
      bgOpacity,
      regions,
    },

    contrast: {
      brightness,
      contrast,
      saturation,
      exposure,
    },

    effects: {
      effect,
    },

    text: {
      layers,
    },

    shape: {
      selectedShape,
      shapes,
    },

    speed: {
      rate,
      setRate,
      handleSpeedReset,
    },
  };

  const getContextData = () => contextData;

  const setContextData = (data = {}) => {
    if (data.report) {
      if (data.report.selector) {
        setSelector(data.report.selector);
      }

      if (data.report.slctCmtID) {
        setCmtID(data.report.slctCmtID);
      }

      if (data.report.comments) {
        setComments(data.report.comments);
      }
    } else {
      setSelector({
        x: 100,
        y: 100,
        height: 100,
        width: 100,
        scaleX: 1,
        scaleY: 1,
      });
      setCmtID(false);
      setComments([]);
    }

    if (data.audio && data.audio.aRegions) {
      setARegions(data.audio.aRegions);
    } else {
      setARegions([]);
    }

    if (data.videoContext) {
      if (data.videoContext.bgColor) {
        setBgColor(data.videoContext.bgColor);
      }
      if (data.videoContext.videoFit) {
        setVideoFit(data.videoContext.videoFit);
      }
      if (data.videoContext.resolution) {
        setResolution(data.videoContext.resolution);
      }
      if (data.videoContext.duration) {
        setDuration(data.videoContext.duration);
      }
      if (data.videoContext.cropType) {
        setCropType(data.videoContext.cropType);
      }
      if (data.videoContext.bgOpacity) {
        setBgOpacity(data.videoContext.bgOpacity);
      }
      if (data.videoContext.regions) {
        setRegions(data.videoContext.regions);
      }
    } else {
      setBgColor(colors.black);
      setVideoFit("Fill Frame");
      setResolution("1280x720");
      setDuration("04:33:0");
      setCropType("merge");
      setBgOpacity(1);
      setRegions([]);
    }

    if (data.contrast) {
      if (data.contrast.brightness) {
        setBrightness(data.contrast.brightness);
      }
      if (data.contrast.contrast) {
        setContrast(data.contrast.contrast);
      }
      if (data.contrast.saturation) {
        setSaturation(data.contrast.saturation);
      }
      if (data.contrast.exposure) {
        setExposure(data.contrast.exposure);
      }
    } else {
      setBrightness(1);
      setContrast(1);
      setSaturation(1);
      setExposure(0);
    }

    if (data.effects && data.effects.effect) {
      setEffect(data.effects.effect);
    } else {
      setEffect("none");
    }

    if (data.text && data.text.layers) {
      setLayers(data.text.layers);
    } else {
      setLayers([]);
    }

    if (data.shape) {
      if (data.shape.selectedShape) {
        setSelectedShape(data.shape.selectedShape);
      }

      if (data.shape.shapes) {
        setShapes(data.shape.shapes);
      }
    } else {
      setSelectedShape("square");
      setShapes([]);
    }

    if (data.speed && data.speed.rate) {
      setRate(data.speed.rate);
    } else {
      setRate(1);
    }
  };

  return (
    <ToolsContext.Provider
      value={{
        videodata,
        setVideodata,

        report: {
          selector,
          slctCmtID,
          setCmtID,
          comments,
          setComments,
          addComment,
          deleteComment,
          handleCommentChange,
          handleSelectorChange,
        },

        audio: {
          audioName,
          setAudioName,
          regions: aRegions,
          setRegions: setARegions,
          cropType: aCropType,
          setCropType: setACropType,
          duration: aDuration,
          setDuration: setADuration,
          handleChange: handleAudioDataChange,
          handleAdd: handleAudioRegionAdd,
          handleDelete: handleAudioRegionDelete,
        },

        videoContext: {
          bgColor,
          setBgColor,
          videoFit,
          setVideoFit,
          resolution,
          setResolution,
          duration,
          setDuration,
          cropType,
          setCropType,
          bgOpacity,
          setBgOpacity,
          regions,
          setRegions,
          handleAdd: handleRegionsAdd,
          handleDelete: handleRegionDelete,
          handleVideoDataChange,
        },

        contrast: {
          brightness,
          setBrightness,
          contrast,
          setContrast,
          saturation,
          setSaturation,
          exposure,
          setExposure,
          handleReset,
        },

        effects: {
          effect,
          setEffect,
        },

        text: {
          layers,
          setLayers,
          handleAdd: handleTextAdd,
          handleDelete: handleTextDelete,
          handleLayerDataChange,
          textInitialState,
          duration: toHHMMSS(videodata.duration),
        },

        shape: {
          selectedShape,
          setSelectedShape,
          shapes,
          setShapes,
          handleAdd: handleShapeAdd,
          handleDelete: handleShapeDelete,
          handleShapeDataChange,
          shapeInitialState,
          duration: toHHMMSS(videodata.duration),
        },

        speed: {
          rate,
          setRate,
          handleSpeedReset,
        },

        getContextData,
        setContextData,
      }}
    >
      {props.children}
    </ToolsContext.Provider>
  );
};

export default ToolsProvider;
