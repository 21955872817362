import React from "react";
import "./placeholder.css";

export default function PlaceholderRawCard() {
  return (
    <div
      className="ph-item"
      style={{
        padding: "1rem",
        background: "white",
        boxShadow: "0 2px 10px 0 rgba(44, 137, 255, 0.06)",
      }}
    >
      <div className="ph-col-12" style={{ padding: 0 }}>
        <div
          className="ph-picture"
          style={{
            width: "100%",
            height: "21rem",
            borderRadius: "3px",
            marginBottom: "4px",
          }}
        />
        <div style={{ padding: "1.6rem" }}>
          <div className="ph-row" style={{ marginTop: 0, marginBottom: 0 }}>
            <div className="ph-col-10" />
          </div>
          <div className="ph-row" style={{ marginTop: "6px", marginBottom: 0 }}>
            <div className="ph-col-6" />
          </div>
        </div>
        <div className="ph-row" style={{ padding: "0 1.5rem 1.6rem 1.5rem" }}>
          <div className="ph-col-2" style={{ marginRight: "3rem" }} />
          <div className="ph-col-2" style={{ marginRight: "3rem" }} />
          <div className="ph-col-2" style={{ marginRight: "3rem" }} />
          <div className="ph-col-2" style={{ marginRight: "3rem" }} />
        </div>
      </div>
    </div>
  );
}
