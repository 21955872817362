import React, { useState } from "react";
import styled from "styled-components";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "../../components/common/Table/Table";
import { icons } from "../../components/themes/base";
import CellButton from "../../components/common/Button";

import EditRegionModal from "../Modal/EditRegionModal";

const TableWrapper = styled.div`
  background: #f7f8fb;
  margin: 0rem 10rem 4rem 10rem;

  table thead {
    outline: thin solid #f7f8fb;
    outline-width: 3px;
  }

  table tr {
    height: 7rem;
  }

  table .id {
    width: 4rem;
  }

  table .time {
    width: 10rem;
  }

  table .notes {
    @media only screen and (max-width: 1400px) {
      width: 50%;
      max-width: 50%;
    }

    @media only screen and (max-width: 1000px) {
      width: 40%;
      max-width: 40%;
    }

    width: 70%;
    max-width: 70%;
  }

  table .icons {
    width: 3rem;
  }
`;

const StyledCell = styled(TableCell)`
  text-align: center;
  padding: 0px;
  overflow: visible;
  text-overflow: unset;
  white-space: initial;
`;

function AudioTable(props) {
  const [editID, setID] = useState(null);

  const handleEdit = (id, start, end, notes) => {
    setID(null);
    props.handleEdit(id, start, end, notes);
  };

  const handleCancel = () => {
    setID(null);
  };

  return (
    <TableWrapper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell th className="time">
              Start
            </TableCell>
            <TableCell th className="time">
              End
            </TableCell>
            <TableCell th className="notes">
              Notes
            </TableCell>
            <TableCell th className="icons" />
            <TableCell th className="icons" />
          </TableRow>
        </TableHead>
        <TableBody>
          {props.regions.map((region) => (
            <TableRow key={region.id}>
              <TableCell>{region.start}</TableCell>
              <TableCell>{region.end}</TableCell>
              <TableCell>{region.notes}</TableCell>

              <StyledCell style={{ textAlign: "right" }}>
                <CellButton
                  height="30px"
                  width="30px"
                  title={`Delete`}
                  border="1px solid white"
                  background={`url(${icons.IC_Edit}) center center / 20px 20px no-repeat`}
                  onClick={() => setID(region.id)}
                />
              </StyledCell>

              <StyledCell>
                <CellButton
                  height="30px"
                  width="30px"
                  title={`Delete`}
                  border="1px solid white"
                  background={`url(${icons.IC_Delete}) center center / 20px 20px no-repeat`}
                  onClick={() => props.handleDelete(region.id)}
                />
              </StyledCell>

              {editID === region.id && (
                <EditRegionModal
                  isVisible={editID === region.id}
                  id={region.id}
                  start={region.start}
                  end={region.end}
                  notes={region.notes}
                  handleEdit={handleEdit}
                  duration={props.duration}
                  handleCancel={handleCancel}
                />
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
}

export default AudioTable;
