import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { IoIosArrowDown } from "react-icons/io";
import defaultAvatar from "../../assets/img/default_profile_img.png";

import Image from "../../containers/common/Image";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  div,
  img {
    margin: 0.3rem 1rem 0 0;
    cursor: pointer;
  }
  .name {
    text-align: left;
    margin-left: 0.5rem;
    font-size: 1.4rem;
    width: 7.7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  svg {
    cursor: pointer;
  }
`;

const StyledAvatar = styled.div`
  display: block;
  width: 3rem;
  padding: 7px;
  cursor: pointer;
  img {
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    object-fit: cover;
  }
`;

const ProfileInfo = (props) => {
  const avatar = props.avatar || defaultAvatar;

  return (
    <Wrapper {...props}>
      <StyledAvatar onClick={props.onAvatarClick}>
        <Image type="profile" width="100%" src={avatar} alt="avatar" />
      </StyledAvatar>
      <div className="name" onClick={props.onAvatarClick}>
        {props.username.split(" ")[0]}
      </div>
      <IoIosArrowDown onClick={props.onDownArrowClick} />
    </Wrapper>
  );
};

export default ProfileInfo;

ProfileInfo.propTypes = {
  avatar: PropTypes.string,
  username: PropTypes.string,
  onAvatarClick: PropTypes.func,
  onDownArrowClick: PropTypes.func,
};

ProfileInfo.defaultProps = {
  unreadNotifications: false,
};
