import styled from "styled-components";

const StyledNotification = styled.div`
  background: ${props => props.background};
  color: ${props => props.color};
  min-width: 31.4rem;
  height: 8rem;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 10px 0 #0002;
  border-radius: 0.3rem;

  .icon {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    background-color: ${props => props.iconBackground};
    height: 100%;
    width: 2.4rem;
    font-size: 3rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    height: 65%;
    letter-spacing: 0.6px;
    font-family: "Muli", sans-serif;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.1rem;
    .title {
      text-transform: uppercase;
      font-weight: 600;
      color: ${props => props.iconBackground};
      margin-bottom: 2px;
    }
  }

  button {
    background: white;
    margin: 0 1rem;
    padding: 0.5rem;
    border: none;
    border-radius: 50%;
  }
`;

export default StyledNotification;
