import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import jwt_decode from "jwt-decode";
import { QueryClient, QueryClientProvider } from "react-query";
import axios from "axios";

import "./index.css";
import GlobalState from "./context";
import setAuthToken from "./utils/setAuthToken";
import setAxiosConfig from "./utils/setAxiosConfig";
import { setCurrentUser, logoutUser } from "./actions/";
import Notifications from "./components/Notifications";
import Routes from "./Routes";
import store from "./store";
import keys from "./config/keys";
import "./polyfill.js";

//Setting the axios config
setAxiosConfig();

const defaultQueryFn = async ({ queryKey }) => {
  const { data } = await axios.get(`${keys.SERVER_URL}/${queryKey.join("/")}`);
  return data;
};

const queryClient = new QueryClient({
  defaultOptions: { queries: { queryFn: defaultQueryFn } },
});

const hideDrift = () => {
  if (window.drift && window.drift.api && window.drift.api.widget) {
    window.drift.api.widget.hide();
  }
};

// Check for token
if (localStorage.jwtToken) {
  // Set auth token auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currenTime = Date.now() / 1000;
  if (decoded.exp < currenTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "/signin";
    hideDrift();
  } else if (window.drift && window.drift.api && window.drift.api.widget) {
    window.drift.identify(decoded._id.toString(), {
      _id: decoded._id.toString(),
      name: decoded.fullName,
      email: decoded.email,
    });
    window.drift.api.widget.show();
  }
} else {
  hideDrift();
}

const root = createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <GlobalState>
        <Routes />
        <Notifications />
      </GlobalState>
    </Provider>
  </QueryClientProvider>
);
