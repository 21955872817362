import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from 'react-router-dom';

const StyledAnchor = styled.div`
 a { 
  text-decoration: none;
  color: ${props => props.fontColor};
  opacity: ${props => (props.isActive ? 1 : 0.5)};
 }
`;

const LinkItem = ({ src, label, fontColor, isActive }) => (
  <StyledAnchor fontColor={fontColor} isActive={isActive}>
    <Link to={src}>{label}</Link>
  </StyledAnchor>
);

export default LinkItem;

LinkItem.propTypes = {
  src: PropTypes.string,
  label: PropTypes.string,
  fontColor: PropTypes.string,
  isActive: PropTypes.bool
};

LinkItem.defaultProps = {
  fontColor: "#142945",
  isActive: false
};
