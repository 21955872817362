import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { withRouter, Redirect } from "react-router-dom";
import { useQuery } from "react-query";
import { connect } from "react-redux";

import { colors } from "../../components/themes/base";
import {
  successNotification,
  warningNotification,
  errorNotification,
} from "../../components/Notifcations";
import PlaceholderUserCard from "../../components/Placeholder/PlaceholderUserCard";
import Button from "../common/Button";
import SidebarButton from "../../components/common/Button";
import { ReactComponent as EmptySVG } from "../../assets/img/empty.svg";
import Loader from "../../components/Loader/ModalLoaderIcon";
import makeRequest from "../../config/helpers";
import audioThumb from "../../assets/images/audio-poster.png";
import HrefButton from "../../components/common/HrefButton";
import { fetchVideos } from "../../actions/redaction.actions";

const buttonProps = {
  fontSize: "1rem",
  boxShadow: "none",
  width: "7rem",
  borderRadius: "3rem",
  padding: "0.7rem 1.5rem",
};

const SideBar = styled.div`
  height: 93vh;
  width: 25%;
  box-shadow: 6px 9px 13px 5px #525151;
  position: fixed;
  right: 0;
  background: white;

  .heading {
    color: #142945;
    font-family: Muli;
    font-size: 2.2rem;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.18px;
    margin: 2.5rem 5rem 2.5rem 5rem;
  }

  .selected-names {
    display: flex;
    flex-direction: column;
    margin: 1rem 2rem;
    font-size: 16px;
    text-transform: capitalize;
    height: 70vh;
    overflow-y: scroll;

    span:nth-child(odd) {
      background: rgba(241, 243, 242, 0.7);
    }

    span {
      margin: 0.5rem 0;
      text-align: center;
      border-radius: 4px;
      padding: 10px;
      overflow-wrap: break-word;
    }
  }

  .bottom-buttons {
    display: flex;
    justify-content: space-around;
    margin: 2rem 2rem 2rem 3rem;
    position: fixed;
    bottom: 0;
    width: 20vw;
  }
`;

const StyledWrapper = styled.div`
  width: ${(props) => (props.sidebarVisible ? "75%" : "100%")};
  padding-bottom: 3rem;

  .heading {
    color: #142945;
    font-family: Muli;
    font-size: 2.2rem;
    font-weight: 700;
    letter-spacing: 0.18px;
    margin: 2.5rem 5rem 1rem 5rem;
  }

  .flex-container {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 2rem;
    margin-left: 5rem;
    margin-right: 3rem;

    @media (min-width: 750px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (min-width: 1050px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (min-width: 1340px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @media (min-width: 1600px) {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .tile {
      border-radius: 4px;
      border: 1px solid rgba(20, 41, 69, 0.14);
      border-radius: 4px;
      background: white;
      height: 255px;

      .video-poster {
        width: 100%;
        height: 150px;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        background: url(https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQ3n4v4BJ82tIA6EIyywvYvDXkx_HO5w0W-RA&usqp=CAU);
        background-size: cover;
        background-repeat: no-repeat;
      }

      .img-poster {
        object-fit: cover;
        min-height: 150px;
        height: 150px;
        width: 100%;
      }

      .audio-poster {
        object-fit: contain;
        min-height: 150px;
        height: 150px;
        width: 100%;
        background-color: black;
      }

      .video-details {
        margin: 0 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .video-name {
          margin: 0.7rem 0;
          font-family: Muli;
          font-size: 14px;
          text-transform: capitalize;
        }

        .vd-modified {
          margin: 0.7rem 0;
          opacity: 0.5;
          font-size: 10px;
        }

        .buttons-container {
          display: flex;
          justify-content: space-between;
          margin: 1.5rem 0;

          .action-buttons {
            display: flex;
          }
        }
      }
    }
  }
`;

const StyledNotFound = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    max-width: 30rem;
    transform: translateY(-20rem);
  }

  .message {
    transform: translateY(-45rem);
    text-align: center;

    h4 {
      font-size: 3rem;
      font-weight: bold;
      margin: 0;
    }

    p {
      font-size: 1.8rem;
      color: grey;
    }

    button {
      margin: 0 auto;
      background: ${colors.brand};
      font-size: 1.6rem;
      box-shadow: 0 1rem 2rem 0 rgba(124, 160, 73, 0.15);
      color: #ffffff;
      padding: 1rem 2rem;
      text-transform: uppercase;
    }
  }
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 97%;
  margin: 5rem;
  div {
    margin-right: 1.4rem;
    &:last-child {
      margin-right: 0rem;
    }
  }
`;

const SelectableTile = styled.div`
  flex-basis: 30%;
  border-radius: 4px;
  box-sizing: border-box;
  background: white;
  height: 255px;
  border: ${(props) =>
    props.selected
      ? `3px solid ${colors.brand} !important`
      : "1px solid rgba(20, 41, 69, 0.14)"};
`;

const NoCasesFound = (props) => (
  <StyledNotFound>
    <EmptySVG />
    <div className="message">
      <h4>No Videos or Images found</h4>
      <p>When you upload. They will appear here.</p>

      <Button
        onClick={() =>
          props.history.push(`/investigator/cases/${props.caseID}/edit`)
        }
      >
        Add
      </Button>
    </div>
  </StyledNotFound>
);

const PlaceHolderCards = () => (
  <CardWrapper>
    {Array.from(Array(10).keys()).map((_, i) => (
      <PlaceholderUserCard key={i} />
    ))}
  </CardWrapper>
);

function ReviewCases(props) {
  const caseID = props.match.params.caseid;
  const [deletingIDs, setDeleteIDs] = useState([]);
  const [movingId, setMovingID] = useState(null);
  const [loading, setLoading] = useState(false);
  const [caseObj, setCase] = useState({
    rawVideos: [],
    convertedVideos: [],
    clippedVideo: [],
    rawImages: [],
    rawAudios: [],
    clippedAudios: [],
  });
  const [allowDeletion, setAllowDeletion] = useState(false);
  const [selectedAudios, setSelected] = useState([]);
  const [currentJobID, setCurrentJobID] = useState(null);
  const [jobProgress, setJobProgress] = useState(0);

  useQuery(["investigator", "job", currentJobID], {
    enabled: !!currentJobID,
    refetchInterval: 1000,
    onSuccess(jobData) {
      if (!jobData) return;

      const { progress } = jobData;

      if (progress === -1) {
        errorNotification("Unable to merge audios.Please try again");
        setCurrentJobID(null);
        return;
      }

      setJobProgress(progress);

      if (progress >= 100) {
        setSelected([]);
        setCurrentJobID(null);
        successNotification("Merged successfully");
        fetchCase();
      }
    },
    onError() {
      errorNotification("Unable to merge audios.Please try again");
      setCurrentJobID(null);
    },
  });

  useEffect(() => {
    if (!caseID) {
      props.history.push("/investogator/cases");
      return;
    }

    fetchCase(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseID]);

  const fetchCase = async (loading = false) => {
    if (loading) {
      setLoading(true);
    }

    const res = await makeRequest("GET", `/investigator/cases/${caseID}`);
    setLoading(false);

    if (res.status !== 200 || res.error) {
      props.history.push("/investogator/cases");
      return;
    }

    setCase(res.caseObj);

    if (res.allowDeletion) {
      setAllowDeletion(true);
    }
  };

  const handleMerge = async () => {
    if (!caseID) {
      errorNotification("Something went wrong.Please try again");
      return;
    }

    if (selectedAudios.length <= 1) {
      warningNotification("Atleast two audio files must be selected to merge");
      return;
    }

    const audioURLList = selectedAudios.map((audio) => ({
      serverUrl: audio.serverUrl,
      blobName: audio.blobName,
      name: audio.originalName,
    }));

    const { jobID } = await makeRequest(
      "POST",
      `/investigator/cases/${caseID}/merge`,
      null,
      { audioURLList }
    );
    if (jobID) {
      setCurrentJobID(jobID);
    } else {
      errorNotification("Unable to merge audios.Please try again");
    }
  };

  const isSelected = (id) => {
    const idExist = selectedAudios.find((audio) => audio._id === id);
    if (idExist) {
      return true;
    } else {
      return false;
    }
  };

  const toggleSelect = (audioObj) => {
    if (!!currentJobID) return;

    const idExist = selectedAudios.find((audio) => audio._id === audioObj._id);
    if (idExist) {
      setSelected((audios) =>
        audios.filter((audio) => audio._id !== audioObj._id)
      );
    } else {
      setSelected((audios) => [...audios, audioObj]);
    }
  };

  const moveVideoToRedaction = async (videoID) => {
    if (!videoID) return;

    setMovingID(videoID);
    const { status } = await makeRequest(
      "POST",
      `/investigator/videos/${videoID}/move`
    );
    if (status !== 200) {
      errorNotification("Something went wrong.Please try again");
    } else {
      successNotification("Moved successfully");
    }
    setMovingID(null);
    props.fetchVideos();
  };

  const handleDeleteVideo = async (videoID = null) => {
    if (!videoID) {
      return;
    }

    setDeleteIDs((ids) => [...ids, videoID]);
    const res = await makeRequest(
      "DELETE",
      `/investigator/cases/${caseID}/videos/${videoID}`
    );
    setDeleteIDs((ids) => ids.filter((id) => id !== videoID));

    if (res.status === 200) {
      fetchCase();
      successNotification("Deleted Successfully");
    } else {
      errorNotification("Unable to delete.Please try again.");
    }
  };

  const handleDeleteAudio = async (audioID = null) => {
    if (!audioID) {
      return;
    }

    setSelected((audios) => audios.filter((audio) => audio._id !== audioID));
    setDeleteIDs((ids) => [...ids, audioID]);

    const res = await makeRequest(
      "DELETE",
      `/investigator/cases/${caseID}/audios/${audioID}`
    );
    setDeleteIDs((ids) => ids.filter((id) => id !== audioID));
    if (res.status === 200) {
      fetchCase();
      successNotification("Deleted Successfully");
    } else {
      errorNotification("Unable to delete.Please try again.");
    }
  };

  const handleDeleteImage = async (imageID = null) => {
    if (!imageID) {
      return;
    }

    setDeleteIDs((ids) => [...ids, imageID]);
    const res = await makeRequest(
      "DELETE",
      `/investigator/cases/${caseID}/images/${imageID}`
    );
    setDeleteIDs((ids) => ids.filter((id) => id !== imageID));

    if (res.status === 200) {
      fetchCase();
      successNotification("Deleted Successfully");
    } else {
      errorNotification("Unable to delete.Please try again.");
    }
  };

  if (loading) {
    return <PlaceHolderCards />;
  }

  if (!loading && !caseObj) {
    return <Redirect to="/investigator/cases" />;
  }

  const totalCounts =
    (caseObj.rawImages && caseObj.rawImages.length) +
    (caseObj.rawVideos && caseObj.rawVideos.length) +
    (caseObj.convertedVideos && caseObj.convertedVideos.length) +
    (caseObj.clippedVideos && caseObj.clippedVideos.length) +
    (caseObj.rawAudios && caseObj.rawAudios.length) +
    (caseObj.redactedAudios && caseObj.redactedAudios.length) +
    (caseObj.mergedAudios && caseObj.mergedAudios.length) +
    (caseObj.clippedAudios && caseObj.clippedAudios.length);

  if (totalCounts === 0) {
    return <NoCasesFound {...props} caseID={caseID} />;
  }

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <StyledWrapper sidebarVisible={selectedAudios.length > 0}>
        {caseObj.rawImages && caseObj.rawImages.length > 0 && (
          <div>
            <div className="heading">Raw Images</div>
            <div className="flex-container">
              {caseObj.rawImages.map((image) => (
                <div className="tile" key={image._id}>
                  <img className="img-poster" src={image.url} alt="" />
                  <div className="video-details">
                    <p className="video-name">{image.originalName}</p>
                    <p className="vd-modified">
                      {new Date(image.createdAt.toString())
                        .toGMTString()
                        .slice(0, 16)}
                    </p>
                    <div className="buttons-container">
                      <Button
                        border="1px solid #e4e7ee"
                        background="#fff"
                        color={colors.button}
                        onClick={props.handleRedact}
                        disabled
                        {...buttonProps}
                      >
                        Review
                      </Button>
                      {allowDeletion && (
                        <Button
                          className="deleteBtn"
                          color="red"
                          border="none"
                          background="#F9FAFD"
                          disabled={!allowDeletion}
                          onClick={() => handleDeleteImage(image._id)}
                          {...buttonProps}
                        >
                          {deletingIDs.includes(image._id) ? (
                            <Loader />
                          ) : (
                            "Delete"
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {caseObj.rawVideos && caseObj.rawVideos.length > 0 && (
          <div>
            <div className="heading">Original Videos</div>
            <div className="flex-container">
              {caseObj.rawVideos.map((video) => (
                <div className="tile" key={video._id}>
                  <img className="img-poster" src={video.thumbUrl} alt="" />
                  <div className="video-details">
                    <p className="video-name">{video.originalName}</p>
                    <p className="vd-modified">
                      {new Date(video.createdAt.toString())
                        .toGMTString()
                        .slice(0, 16)}
                    </p>
                    <div className="buttons-container">
                      <div className="action-buttons">
                        <Button
                          border="1px solid #e4e7ee"
                          background="#fff"
                          color={colors.button}
                          onClick={() =>
                            props.history.push(
                              `/investigator/cases/${caseID}/clips/${video._id}`
                            )
                          }
                          {...buttonProps}
                        >
                          Review
                        </Button>
                        <Button
                          margin="0 0 0 5px"
                          border="1px solid #e4e7ee"
                          background="#fff"
                          color={colors.button}
                          {...buttonProps}
                          disabled={!!movingId}
                          onClick={() => moveVideoToRedaction(video._id)}
                        >
                          Move to Redaction
                        </Button>
                      </div>

                      {allowDeletion && (
                        <Button
                          className="deleteBtn"
                          color="red"
                          border="none"
                          background="#F9FAFD"
                          disabled={!allowDeletion}
                          onClick={() => handleDeleteVideo(video._id)}
                          {...buttonProps}
                        >
                          {deletingIDs.includes(video._id) ? (
                            <Loader />
                          ) : (
                            "Delete"
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {caseObj.clippedVideos && caseObj.clippedVideos.length > 0 && (
          <div>
            <div className="heading">Clips</div>
            <div className="flex-container">
              {caseObj.clippedVideos.map((video) => (
                <div className="tile" key={video._id}>
                  <img className="img-poster" src={video.thumbUrl} alt="" />

                  <div className="video-details">
                    <p className="video-name">{video.originalName}</p>
                    <p className="vd-modified">
                      {new Date(video.createdAt.toString())
                        .toGMTString()
                        .slice(0, 16)}
                    </p>
                    <div className="buttons-container">
                      <div className="action-buttons">
                        <Button
                          border="1px solid #e4e7ee"
                          background="#fff"
                          color={colors.button}
                          onClick={() =>
                            props.history.push(
                              `/investigator/cases/${caseID}/clips/${video._id}`
                            )
                          }
                          {...buttonProps}
                        >
                          Review
                        </Button>
                        <Button
                          margin="0 0 0 5px"
                          border="1px solid #e4e7ee"
                          background="#fff"
                          color={colors.button}
                          {...buttonProps}
                          disabled={!!movingId}
                          onClick={() => moveVideoToRedaction(video._id)}
                        >
                          Move to Redaction
                        </Button>
                      </div>

                      {allowDeletion && (
                        <Button
                          className="deleteBtn"
                          color="red"
                          border="none"
                          background="#F9FAFD"
                          disabled={!allowDeletion}
                          onClick={() => handleDeleteVideo(video._id)}
                          {...buttonProps}
                        >
                          {deletingIDs.includes(video._id) ? (
                            <Loader />
                          ) : (
                            "Delete"
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {caseObj.rawAudios && caseObj.rawAudios.length > 0 && (
          <div>
            <div className="heading">Original Audios</div>
            <div className="flex-container">
              {caseObj.rawAudios.map((audio) => (
                <SelectableTile
                  key={audio._id}
                  className="tile"
                  selected={isSelected(audio._id)}
                  onClick={() => {
                    toggleSelect(audio);
                  }}
                >
                  <img className="audio-poster" src={audioThumb} alt="" />

                  <div className="video-details">
                    <p className="video-name">{audio.originalName}</p>
                    <p className="vd-modified">
                      {new Date(audio.createdAt.toString())
                        .toGMTString()
                        .slice(0, 16)}
                    </p>
                    <div className="buttons-container">
                      <Button
                        border="1px solid #e4e7ee"
                        background="#fff"
                        color={colors.button}
                        onClick={(e) => {
                          e.stopPropagation();
                          props.history.push(
                            `/investigator/cases/${caseID}/sound/${audio._id}`
                          );
                        }}
                        {...buttonProps}
                      >
                        Review
                      </Button>
                      {allowDeletion && (
                        <Button
                          className="deleteBtn"
                          color="red"
                          border="none"
                          background="#F9FAFD"
                          disabled={!allowDeletion}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteAudio(audio._id);
                          }}
                          {...buttonProps}
                        >
                          {deletingIDs.includes(audio._id) ? (
                            <Loader />
                          ) : (
                            "Delete"
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                </SelectableTile>
              ))}
            </div>
          </div>
        )}

        {caseObj.redactedAudios && caseObj.redactedAudios.length > 0 && (
          <div>
            <div className="heading">Redacted Audios</div>
            <div className="flex-container">
              {caseObj.redactedAudios.map((audio) => (
                <SelectableTile
                  key={audio._id}
                  className="tile"
                  selected={isSelected(audio._id)}
                  onClick={() => {
                    toggleSelect(audio);
                  }}
                >
                  <img className="audio-poster" src={audioThumb} alt="" />

                  <div className="video-details">
                    <p className="video-name">{audio.originalName}</p>
                    <p className="vd-modified">
                      {new Date(audio.createdAt.toString())
                        .toGMTString()
                        .slice(0, 16)}
                    </p>
                    <div className="buttons-container">
                      <Button
                        border="1px solid #e4e7ee"
                        background="#fff"
                        color={colors.button}
                        onClick={(e) => {
                          e.stopPropagation();
                          props.history.push(
                            `/investigator/cases/${caseID}/sound/${audio._id}`
                          );
                        }}
                        {...buttonProps}
                      >
                        Review
                      </Button>
                      {allowDeletion && (
                        <Button
                          className="deleteBtn"
                          color="red"
                          border="none"
                          background="#F9FAFD"
                          disabled={!allowDeletion}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteAudio(audio._id);
                          }}
                          {...buttonProps}
                        >
                          {deletingIDs.includes(audio._id) ? (
                            <Loader />
                          ) : (
                            "Delete"
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                </SelectableTile>
              ))}
            </div>
          </div>
        )}

        {caseObj.mergedAudios && caseObj.mergedAudios.length > 0 && (
          <div>
            <div className="heading">Merged Audios</div>
            <div className="flex-container">
              {caseObj.mergedAudios.map((audio) => (
                <div key={audio._id} className="tile">
                  <img className="audio-poster" src={audioThumb} alt="" />

                  <div className="video-details">
                    <p className="video-name">{audio.originalName}</p>
                    <p className="vd-modified">
                      {new Date(audio.createdAt.toString())
                        .toGMTString()
                        .slice(0, 16)}
                    </p>
                    <div className="buttons-container">
                      <HrefButton
                        href={audio.url}
                        border="1px solid #e4e7ee"
                        color={colors.button}
                        background="#fff"
                        {...buttonProps}
                      >
                        Download
                      </HrefButton>
                      {allowDeletion && (
                        <Button
                          className="deleteBtn"
                          color="red"
                          border="none"
                          background="#F9FAFD"
                          disabled={!allowDeletion}
                          onClick={() => handleDeleteAudio(audio._id)}
                          {...buttonProps}
                        >
                          {deletingIDs.includes(audio._id) ? (
                            <Loader />
                          ) : (
                            "Delete"
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {caseObj.clippedAudios && caseObj.clippedAudios.length > 0 && (
          <div>
            <div className="heading">Clipped Audios</div>
            <div className="flex-container">
              {caseObj.clippedAudios.map((audio) => (
                <SelectableTile
                  key={audio._id}
                  className="tile"
                  selected={isSelected(audio._id)}
                  onClick={() => {
                    toggleSelect(audio);
                  }}
                >
                  <img className="audio-poster" src={audioThumb} alt="" />
                  <div className="video-details">
                    <p className="video-name">{audio.originalName}</p>
                    <p className="vd-modified">
                      {new Date(audio.createdAt.toString())
                        .toGMTString()
                        .slice(0, 16)}
                    </p>
                    <div className="buttons-container">
                      <HrefButton
                        href={audio.url}
                        border="1px solid #e4e7ee"
                        color={colors.button}
                        background="#fff"
                        {...buttonProps}
                      >
                        Download
                      </HrefButton>
                      {allowDeletion && (
                        <Button
                          className="deleteBtn"
                          color="red"
                          border="none"
                          background="#F9FAFD"
                          disabled={!allowDeletion}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteAudio(audio._id);
                          }}
                          {...buttonProps}
                        >
                          {deletingIDs.includes(audio._id) ? (
                            <Loader />
                          ) : (
                            "Delete"
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                </SelectableTile>
              ))}
            </div>
          </div>
        )}
      </StyledWrapper>

      {selectedAudios.length > 0 && (
        <SideBar>
          <div className="heading">Merge Files</div>
          <div className="selected-names">
            {selectedAudios.map((audio) => (
              <span key={audio._id}>{audio.originalName}</span>
            ))}
          </div>

          <div className="bottom-buttons">
            <SidebarButton
              height="54px"
              fontSize="16px"
              fontWeight="600"
              color={colors.tertiary}
              background="transparent"
              padding="0"
              onClick={() => setSelected([])}
              disabled={!!currentJobID}
            >
              Cancel
            </SidebarButton>

            {!!currentJobID && (
              <SidebarButton
                width="140px"
                fontSize="16px"
                fontWeight="600"
                textTransform="uppercase"
              >
                {jobProgress}%
              </SidebarButton>
            )}

            {!currentJobID && (
              <SidebarButton
                width="140px"
                fontSize="16px"
                fontWeight="600"
                textTransform="uppercase"
                onClick={handleMerge}
              >
                Apply
              </SidebarButton>
            )}
          </div>
        </SideBar>
      )}
    </div>
  );
}

const ReviewCasesWithRouter = withRouter(ReviewCases);
export default connect(null, { fetchVideos })(ReviewCasesWithRouter);
