import React from "react";

import AudioProvider from "./Audio/AudioProvider";
import ToolsPovider from "./Tools/ToolsProvider";
import { ImageSearchProvider } from "./ImageSearch/ImageSearchContext";
import { VideoSearchProvider } from "./VideoSearch/VideoSearchContext";

const GlobalState = (props) => (
  <ToolsPovider>
    <AudioProvider>
      <ImageSearchProvider>
        <VideoSearchProvider>{props.children}</VideoSearchProvider>
      </ImageSearchProvider>
    </AudioProvider>
  </ToolsPovider>
);

export default GlobalState;
