import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import InputField from "../../components/common/Form/InputField";
import TextAreaField from "../../components/common/Form/TextAreaField";
import Dropzone from "../common/Form/Dropzone";
import Button from "../../components/common/Button";
import { addProfile } from "../../actions";
import TextSelect from "../../components/common/Form/TextSelect";
import LoadingOverlay from "../../components/Loader/LoadingOverlay";
import DateInputField from "../../components/common/Form/DateInputField";
import { colors } from "../../components/themes/base";

const buttonProps = {
  width: "140px",
  height: "48px",
  fontSize: "1.6rem",
  background: colors.brand,
  boxShadow: "0 10px 20px 0 rgba(124, 160, 73, 0.15)",
  color: "#FFFFFF",
  textTransform: "uppercase",
};

const FormWrapper = styled.div`
  box-sizing: border-box;
  background: ${(props) => props.background};
  width: calc(100% - 10rem); /* minus sidebar width */
  border-radius: 3px;
  margin: 2rem 5rem;
  padding-top: 3rem;
  margin-bottom: 0;
  display: flex;
  justify-content: center;

  form {
    width: 100%;
    margin: 0 auto;
    padding: 0 10rem;
    min-height: calc(100vh - 175px);
    font-family: ${(props) => props.fontFamily};
    color: ${(props) => props.fontColor};
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .buttons {
      padding-bottom: 3rem;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      button {
        font-size: 1.6rem;
      }
    }

    .formFields {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      div {
        flex: 1 1 45%;
        &:nth-child(1),
        &:nth-child(3) {
          margin-right: 2rem;
        }
      }
    }
  }
`;

const validateInput = (validationInput, setError) => {
  const items = Object.entries(validationInput);
  const error = {};

  items.forEach(([label, item]) => {
    if (!item || !item.length) {
      error[label] = "Please fill this field";
    }
  });

  if (Object.keys(error).length > 0) {
    setError(error);
    return false;
  }

  return true;
};

const genderList = ["male", "female", "other"];

const AddProfile = (props) => {
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [dob, setDob] = useState(new Date().toDateString());
  const [gender, setGender] = useState("male");
  const [location, setLocation] = useState("");
  const [notes, setNotes] = useState("");
  const [files, setFiles] = useState([]);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const setSelectedFiles = (additionalFiles) => {
    setFiles([...files, ...additionalFiles]);
  };

  return (
    <LoadingOverlay isActive={loading} loaderMessage={"Submitting Profile..."}>
      <div className="">
        <div
          style={{
            color: "#142945",
            fontFamily: "Muli",
            fontSize: "2.2rem",
            fontWeight: "700",
            letterSpacing: "0.18px",
            margin: "2.5rem 5rem",
          }}
        >
          <span style={{ opacity: "0.5" }}>Manage Watchlist {">"}</span> Add
          Profile
        </div>
        <FormWrapper {...props}>
          <form>
            <Dropzone
              files={files}
              handleDrop={setSelectedFiles}
              setFiles={setFiles}
              accept="image/*"
              thumbWidth={15}
              thumbHeight={15}
              error={error.files}
            />
            <div className="formFields">
              <InputField
                width={510}
                label="First Name*"
                id="first-name"
                name="first-name"
                value={firstname}
                handleChange={(e) => {
                  setfirstname(e.target.value);
                  setError({});
                }}
                error={error.firstname}
              />
              <InputField
                width={510}
                label="Last Name*"
                id="last-name"
                name="last-name"
                value={lastname}
                handleChange={(e) => {
                  setlastname(e.target.value);
                  setError({});
                }}
                error={error.lastname}
              />
              <DateInputField
                width={510}
                type="date"
                label="Date of Birth"
                id="dob"
                name="dob"
                value={dob}
                handleChange={(date) => {
                  setDob(date);
                  setError({});
                }}
                error={error.dob}
              />
              <TextSelect
                options={genderList}
                handleSelect={setGender}
                selectedOption={gender}
                label="Gender*"
              />
            </div>
            <InputField
              label="Location"
              id="location"
              name="location"
              value={location}
              handleChange={(e) => {
                setLocation(e.target.value);
                setError({});
              }}
              error={error.location}
            />
            <TextAreaField
              label="Notes"
              id="notes"
              name="notes"
              value={notes}
              handleChange={(e) => {
                setNotes(e.target.value);
                setError({});
              }}
              error={error.notes}
            />
          </form>
        </FormWrapper>
        <div
          className="buttons"
          style={{
            display: "flex",
            backgroundColor: "#fff",
            justifyContent: "flex-end",
            padding: "2rem 5rem",
            position: "sticky",
            bottom: "0",
          }}
        >
          <Button
            {...buttonProps}
            background="transparent"
            color="#777"
            boxShadow="none"
            border="none"
            onClick={() => props.history.push("/facesearch/watchlist/")}
          >
            Cancel
          </Button>
          <Button
            {...buttonProps}
            onClick={async (e) => {
              e.preventDefault();
              const validationInput = {
                firstname,
                lastname,
                gender,
                files,
              };
              if (!validateInput(validationInput, setError)) {
                return;
              }
              setLoading(true);

              const imageFormObj = new FormData();
              for (var x = 0; x < files.length; x++) {
                imageFormObj.append("files", files[x]);
              }
              imageFormObj.append("firstname", firstname);
              imageFormObj.append("lastname", lastname);
              imageFormObj.append("dob", dob);
              imageFormObj.append("gender", gender);
              imageFormObj.append("location", location);
              imageFormObj.append("notes", notes);
              await props.addProfile(imageFormObj);
              setLoading(false);
              props.history.push("/facesearch/watchlist/");
            }}
          >
            Register
          </Button>
        </div>
      </div>
    </LoadingOverlay>
  );
};

AddProfile.propTypes = {
  fontFamily: PropTypes.string,
  fontColor: PropTypes.string,
  background: PropTypes.string,
  width: PropTypes.string,
  handleRegister: PropTypes.func,
  handleCancel: PropTypes.func,
};

AddProfile.defaultProps = {
  fontFamily: '"Muli", sans-serif',
  fontColor: "#142945",
  background: "#FFFFFF",
  width: "100%",
};

export default connect(null, { addProfile })(AddProfile);
