import React, { useEffect, useRef } from "react";
import { Rect, Transformer } from "react-konva";

function KonvaSelector(props) {
  const rRef = useRef();
  const { tRef } = props;

  useEffect(() => {
    tRef.current.nodes([rRef.current]);
    tRef.current.getLayer().batchDraw();
  }, [tRef]);

  const handleDoubleClick = () => {
    if (props.cmtID) {
      props.takeScreenshot(props.cmtID);
    }
  };

  return (
    <React.Fragment>
      <Rect
        draggable
        ref={rRef}
        {...props}
        strokeWidth={0}
        fill="transparent"
        onDragEnd={() => {
          const node = rRef.current;
          const x = node.x() / props.sX;
          const y = node.y() / props.sY;
          const scaleX = node.scaleX() / props.sX;
          const scaleY = node.scaleY() / props.sY;

          props.handleChange({
            x,
            y,
            scaleX,
            scaleY,
          });
        }}
        onDblClick={handleDoubleClick}
      />

      <Transformer
        ref={tRef}
        anchorSize={7}
        borderDash={[3]}
        rotateEnabled={false}
        anchorStrokeWidth={1}
        anchorStroke="white"
        anchorFill="#F40050"
        borderStrokeWidth={1.5}
        anchorCornerRadius={50}
        borderStroke="#F40050"
        onTransformEnd={() => {
          const node = rRef.current;
          const x = node.x() / props.sX;
          const y = node.y() / props.sY;
          const scaleX = node.scaleX() / props.sX;
          const scaleY = node.scaleY() / props.sY;

          props.handleChange({
            x,
            y,
            scaleX,
            scaleY,
          });
        }}
      />
    </React.Fragment>
  );
}

export default KonvaSelector;
