import React, { Component } from "react";
import styled from "styled-components";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import { colors as themecolors } from "../../components/themes/base";
import { icons } from "../../components/themes/base";
import Button from "../../components/common/Button";
import NameAvatar from "./NameAvatar";
import {
  successNotification,
  warningNotification,
  errorNotification,
} from "../../actions";
import ShareModal from "../../components/Modal/ShareModal";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "../../components/common/Table/Table";
import PlaceholderCaseCard from "../../components/Placeholder/PlaceholderCaseCard";
import makeRequest from "../../config/helpers";

const colors = [
  "#E4584B",
  "#2086dc",
  "#38b14d",
  "#F1A527",
  "#E71581",
  "#47BAFF",
  "#F0C80E",
];

const TableWrapper = styled.div`
  background: #f7f8fb;

  table thead {
    outline: thin solid #f7f8fb;
    outline-width: 3px;
  }

  table tr {
    height: 7rem;
  }

  table tr:hover .edit-btn {
    display: inline-block;
  }

  table .image {
    width: 6rem;
  }

  table .case-id {
    width: 30rem;
  }

  table .case-desc {
    width: 38rem;
  }
`;

const StyledCell = styled(TableCell)`
  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .edit-btn {
      margin-left: 0.5rem;
      opacity: 0.5;
      display: none;

      svg {
        width: 1.6rem;
        height: 1.8rem;
      }
    }
  }
`;

const ViewCell = styled(Link)`
  text-decoration: none;
  border: 1px solid #edeff2;
  color: ${(props) => (props.disabled ? "#c2c3c5" : themecolors.button)};
  border-radius: 3rem;
  padding: 8px 18px;
  font-size: 12px;
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

const PlaceHolderCards = () => (
  <>
    {Array.from(Array(5).keys()).map((_, i) => (
      <PlaceholderCaseCard key={i} />
    ))}
  </>
);

const CaseDate = (props) => {
  if (!props.date) {
    return "NIL";
  }

  return new Date(props.date.toString()).toGMTString().slice(0, 16);
};

class ICaseTable extends Component {
  state = {
    showModal: false,
    filteredUsers: [],
    selectedUsers: [],
    sharingCaseID: null,
    sharingCaseName: "",
  };

  componentDidMount() {
    this.setState({ filteredUsers: this.props.users.slice(0, 6) });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.users !== this.props.users) {
      this.setState({ filteredUsers: this.props.users.slice(0, 6) });
    }
  }

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  performSearch = (keyword) => {
    const fltrUsers = [];

    for (let user of this.props.users) {
      if (
        (user.name && user.name.toLowerCase().match(keyword.toLowerCase())) ||
        (user.email && user.email.toLowerCase().match(keyword.toLowerCase()))
      ) {
        fltrUsers.push(user);
      }
    }

    this.setState({ filteredUsers: fltrUsers });
  };

  handleShare = async () => {
    if (!this.state.sharingCaseID) {
      this.props.warningNotification({ title: "Email cannot be empty" });
      return;
    }

    this.setState({ showModal: false });

    const res = await makeRequest(
      "POST",
      `/investigator/cases/${this.state.sharingCaseID}/share`,
      null,
      { shareIDs: this.state.selectedUsers }
    );

    if (res.status === 200) {
      this.setState({
        selectedUsers: [],
        filteredUsers: this.props.users.slice(0, 6),
        sharingCaseID: null,
        sharingCaseName: "",
      });
      this.props.successNotification({ title: "Shared Successfully" });
    } else {
      this.props.errorNotification({
        title: "Failed to enable sharing.Please try again",
      });
    }
  };

  onUserSelect = (id) => {
    const exits = this.state.selectedUsers.includes(id);

    if (exits) {
      this.setState({
        selectedUsers: this.state.selectedUsers.filter(
          (userID) => userID !== id
        ),
      });
    } else {
      this.setState({ selectedUsers: [...this.state.selectedUsers, id] });
    }
  };

  handleClick = (caseID, caseName) => {
    this.setState({
      showModal: true,
      sharingCaseID: caseID,
      sharingCaseName: caseName,
    });
  };

  render() {
    return (
      <React.Fragment>
        <ShareModal
          isVisible={this.state.showModal}
          users={this.props.users}
          handleShare={this.handleShare}
          handleCancel={this.handleCancel}
          filteredUsers={this.state.filteredUsers}
          selectedUsers={this.state.selectedUsers}
          onSelect={this.onUserSelect}
          onSearch={this.performSearch}
          sharingCaseName={this.state.sharingCaseName}
        />
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell th first className="image">
                  #
                </TableCell>
                <TableCell th>Name</TableCell>
                <TableCell th className="case-desc">
                  Case Description
                </TableCell>
                <TableCell th>Created</TableCell>
                <TableCell th />
                <TableCell th />
                <TableCell th />
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.loading ? (
                <PlaceHolderCards />
              ) : (
                this.props.caseList.map((caseObj, i) => (
                  <TableRow key={caseObj._id}>
                    <TableCell first>
                      <NameAvatar bg={colors[i % colors.length]}>
                        {caseObj && caseObj.title
                          ? caseObj.title.slice(0, 2)
                          : "CE"}
                      </NameAvatar>
                    </TableCell>
                    <TableCell>{caseObj.title}</TableCell>
                    <TableCell>{caseObj.summary}</TableCell>

                    <TableCell>
                      <CaseDate date={caseObj.createdAt} />
                    </TableCell>
                    <StyledCell>
                      <ViewCell to={`/investigator/cases/${caseObj._id}`}>
                        View
                      </ViewCell>
                    </StyledCell>

                    <StyledCell>
                      <Button
                        height="34px"
                        width="34px"
                        title="Edit Case"
                        border="1px solid white"
                        background={`url(${icons.IC_Edit}) center center / 22px 22px no-repeat`}
                        onClick={() =>
                          this.props.history.push(
                            `/investigator/cases/${caseObj._id}/edit`
                          )
                        }
                      />
                    </StyledCell>

                    {caseObj.allowShare ? (
                      <StyledCell>
                        <Button
                          height="34px"
                          width="34px"
                          title="Share Case"
                          border="1px solid white"
                          background={`url(${icons.IC_Share}) center center / 22px 22px no-repeat`}
                          onClick={() =>
                            this.handleClick(caseObj._id, caseObj.title)
                          }
                        />
                      </StyledCell>
                    ) : (
                      <StyledCell />
                    )}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableWrapper>
      </React.Fragment>
    );
  }
}

const TableWithRouter = withRouter(ICaseTable);
export default connect(null, {
  warningNotification,
  errorNotification,
  successNotification,
})(TableWithRouter);
