import axios from "axios";
import keys from "./keys";

async function makeRequest(method, url, headers = {}, data = {}, config = {}) {
  try {
    const response = await axios({
      baseURL: keys.SERVER_URL,
      method,
      url: url,
      data,
      ...config,
    });

    return { status: response.status, ...response.data, error: null };
  } catch ({ response }) {
    if (response) {
      return { status: response.status, ...response.data, error: null };
    }
    return { error: "Network failure" };
  }
}

export default makeRequest;
