import React from "react";
import "./placeholder.css";
import { TableRow, TableCell } from "../../components/common/Table/Table";

export default function PlaceholderCaseCard() {
  return (
    <TableRow>
      <TableCell first>
        <div
          className="ph-picture"
          style={{
            height: "3rem",
            width: "3rem",
            borderRadius: "50%",
            marginBottom: "4px",
          }}
        />
      </TableCell>
      <TableCell>
        <div
          className="ph-row"
          style={{ marginTop: "0.5rem", marginBottom: 0 }}
        >
          <div className="ph-col-6" />
        </div>
      </TableCell>
      <TableCell>
        <div
          className="ph-row"
          style={{ marginTop: "0.5rem", marginBottom: 0 }}
        >
          <div className="ph-col-6" />
        </div>
      </TableCell>
      <TableCell>
        <div
          className="ph-row"
          style={{ marginTop: "0.5rem", marginBottom: 0 }}
        >
          <div className="ph-col-6" />
        </div>
      </TableCell>
      <TableCell>
        <div
          className="ph-row"
          style={{ marginTop: "0.5rem", marginBottom: 0 }}
        >
          <div className="ph-col-6" />
        </div>
      </TableCell>
      <TableCell>
        <div
          className="ph-row"
          style={{ marginTop: "0.5rem", marginBottom: 0 }}
        >
          <div className="ph-col-6" />
        </div>
      </TableCell>
      <TableCell>
        <div
          className="ph-row"
          style={{ marginTop: "0.5rem", marginBottom: 0 }}
        >
          <div className="ph-col-6" />
        </div>
      </TableCell>
    </TableRow>
  );
}
