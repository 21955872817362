import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const InputWrapper = styled.div`
  width: 100%;
  label {
    width: 100%;
    color: rgba(20, 41, 69, 0.5);
    line-height: 3rem;
    font-size: 1.4rem;
    display: block;
    margin-bottom: 1.8rem;
    display: block;
    .react-datepicker-wrapper {
      width: 100%;
    }

    .react-datepicker {
      font-size: 1.1rem;
      height: 20rem;

      &__month-container {
        min-width: 20rem;
        height: 100%;
      }

      div {
        margin-right: 0 !important;
      }

      &__month {
        height: 73%;
        display: flex;
        flex-direction: column;
      }

      &__current-month {
        font-size: 1.2rem;
      }

      &__header {
        padding-top: 4px;
      }

      &__day-names,
      &__week {
        display: flex;
      }
    }

    input {
      font-family: ${props => props.fontFamily};
      box-sizing: border-box;
      height: 4.8rem;
      width: 100%;
      padding-left: 1.6rem;
      line-height: 2rem;
      font-size: 1.6rem;
      border-radius: 3px;
      border: ${props => props.border};
      &:focus {
        outline: 0;
      }
    }
  }
  div.error {
    color: #f00;
    font-size: 1.2rem !important;
  }
`;

const InputField = props => {
  let selectedDate = new Date(props.value);

  return (
    <InputWrapper {...props}>
      <label htmlFor={props.name}>
        <div className="label">{props.label}</div>
        <DatePicker
          selected={selectedDate}
          onChange={e => props.handleChange(e.toDateString())}
        />
        <div className="error">{props.error}</div>
      </label>
    </InputWrapper>
  );
};

InputField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  handleChange: PropTypes.func,
  width: PropTypes.number,
  border: PropTypes.string,
  error: PropTypes.string,
  fontFamily: PropTypes.string
};

InputField.defaultProps = {
  type: "text",
  required: true,
  width: 104,
  border: "1px solid #edeff2",
  error: "",
  fontFamily: '"Muli", sans-serif'
};

export default InputField;
