import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";
import { CircularProgressbar } from "react-circular-progressbar";

import Image from "../Image";
import UploadIcon from "../../../assets/img/upload-icon.png";
import CancelIcon from "../../../assets/img/cancel.svg";
import AudioFilePreview from "../../../assets/img/audio-file-preview.jpg";
import "react-circular-progressbar/dist/styles.css";

import "react-circular-progressbar/dist/styles.css";

const DropzoneWrapper = styled.section`
  font-family: ${(props) => props.fontFamily};
  margin-bottom: 2.2rem;
  .dropzone {
    height: 14rem;
    width: ${(props) => props.width};
    box-shadow: 0 0 1rem 0 rgba(6, 37, 31, 0.04);
    border-radius: 3px;
    border: 1px dashed rgba(20, 41, 69, 0.15);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    outline: 1px solid transparent;

    input {
      box-sizing: border-box;
    }

    .upload-icon {
      width: 100%;
      text-align: center;

      img {
        width: 4.2rem;
      }

      p {
        font-size: 1.4rem;
        opacity: 0.5;
        margin: 1rem 0;
        max-width: 100%;
      }
    }
  }
`;

const Thumbnails = styled.aside`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 1.7rem;

  div:not(:first-of-type) {
    background-color: #000;
  }

  div {
    width: ${(props) => `${props.thumbWidth}rem`};
    height: ${(props) =>
      props.thumbHeight ? `${props.thumbHeight}rem` : "auto"};
    margin: 0.9rem 0.9rem;
    position: relative;
    border-radius: 3px;

    &:first-child {
      width: ${(props) => `${props.thumbWidth}rem`};
      height: ${(props) =>
        props.thumbHeight ? `${props.thumbHeight}rem` : "auto"};
      .upload-icon {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
          height: 2.5rem;
          width: 3rem;
        }

        p {
          font-size: 1.4rem;
          opacity: 0.5;
          margin: 1rem 0;
        }
      }
    }

    video,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 3px;
    }

    .img-preview {
      ${(props) => (props.uploading ? "filter: brightness(40%);" : "")}
    }

    video {
      ${(props) => (props.uploading ? "filter: brightness(40%);" : "")}
    }

    svg {
      position: absolute;
      top: 17%;
      left: 26%;
      width: 10rem;
      height: 10rem;
    }

    .delete-btn {
      ${(props) => (props.uploading ? "display: none;" : "")}
      position: absolute;
      right: -6px;
      top: -6px;
      width: 1.8rem;
      height: 1.8rem;
      border-radius: 50%;
      color: white;
      text-align: center;
      border: none;
      cursor: pointer;

      .cancel-icon {
        left: 0px;
        top: 0px;
        position: absolute;
      }
    }

    .delete-btn:focus {
      outline: 0;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  div.audio-thumbs {
    background: #fff;
    border: 1px solid lightgray;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .audio-preview {
      width: 7rem;
      height: 8rem;
    }

    span {
      display: block;
      text-align: center;
      font-size: 1.4rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 90%;
    }

    .progress-container {
      position: absolute;
      background: rgba(0, 0, 0, 0.6);
      top: 0px;
      left: 0px;
      margin: 0;
    }
  }
`;

const Dropzone = (props) => {
  const { files, handleDrop, setFiles } = props;
  const { getRootProps, getInputProps } = useDropzone({
    accept: props.accept,
    disabled: props.uploading,
    onDrop: (acceptedFiles) => {
      handleDrop(
        acceptedFiles.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
            id: uuidv4(),
          });
        })
      );
    },
  });

  const handleDelete = (e, fileId) => {
    e.preventDefault();
    const remainingFiles = files.filter((f) => f.id !== fileId);
    setFiles(remainingFiles);
  };

  const RenderProgressbar = ({ uploadProgress }) => {
    const failed = uploadProgress === -1;
    const text = failed ? "Failed" : `${Math.floor(uploadProgress)}%`;
    const color = failed ? "red" : "white";

    return (
      <CircularProgressbar
        value={uploadProgress}
        text={text}
        styles={{
          root: { width: "5.5rem", height: "5.5rem", strokeWidth: 6 },
          path: {
            strokeWidth: 6,
            stroke: `${color}, ${uploadProgress / 100})`,
          },
          trail: { stroke: "#d6d6d6", strokeWidth: 6 },
          text: {
            fontSize: "2.5rem",
            fontWeight: "bold",
            transform: "translateY(2px)",
            fill: color,
          },
        }}
      />
    );
  };

  const imageThumbs = (file) => {
    const isUploadProgressInt =
      typeof props.uploadProgress === "number" ||
      typeof props.uploadProgress === "string";

    let progress = props.uploadProgress;
    if (!isUploadProgressInt) {
      progress = props.uploadProgress[file.id];
    }

    return (
      <div key={file.id}>
        <Image
          src={file.preview}
          alt="Upload Preview"
          className={"img-preview"}
        />
        <button
          className="delete-btn"
          onClick={(e) => handleDelete(e, file.id)}
        >
          <img className="cancel-icon" alt="cancel" src={CancelIcon} />
        </button>
        {props.uploading && <RenderProgressbar uploadProgress={progress} />}
      </div>
    );
  };

  const videoThumbs = (file) => {
    const isUploadProgressInt =
      typeof props.uploadProgress === "number" ||
      typeof props.uploadProgress === "string";

    let progress = props.uploadProgress;
    if (!isUploadProgressInt) {
      progress = props.uploadProgress[file.id];
    }
    return (
      <div key={file.id}>
        <video src={file.preview} />
        <button
          className="delete-btn"
          onClick={(e) => handleDelete(e, file.id)}
        >
          <img className="cancel-icon" alt="cancel" src={CancelIcon} />
        </button>
        {props.uploading && <RenderProgressbar uploadProgress={progress} />}
      </div>
    );
  };

  const audioThumbs = (file) => {
    const isUploadProgressInt =
      typeof props.uploadProgress === "number" ||
      typeof props.uploadProgress === "string";

    let progress = props.uploadProgress;
    if (!isUploadProgressInt) {
      progress = props.uploadProgress[file.id];
    }
    return (
      <div className="audio-thumbs" key={file.id}>
        <img
          className="audio-preview"
          src={AudioFilePreview}
          alt="audio-icon"
        />
        <span>{file.name}</span>
        <button
          className="delete-btn"
          onClick={(e) => handleDelete(e, file.id)}
        >
          <img className="cancel-icon" alt="cancel" src={CancelIcon} />
        </button>

        {props.uploading && (
          <div className="progress-container">
            <RenderProgressbar uploadProgress={progress} />
          </div>
        )}
      </div>
    );
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <DropzoneWrapper {...props}>
      {files.length === 0 ? (
        <>
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} multiple={props.multiple} />
            <div className="upload-icon">
              <img src={UploadIcon} alt="Upload Icon" />
              <p>Drag and drop here to upload</p>
            </div>
          </div>
          <div
            style={{
              color: "#f00",
              fontSize: "1.2rem",
              marginTop: "0.5rem",
            }}
          >
            {props.error}
          </div>
        </>
      ) : null}
      <Thumbnails {...props}>
        {files.length > 0 ? (
          <div {...getRootProps({ className: "dropzone" })}>
            <input
              {...getInputProps()}
              multiple={props.multiple}
              disabled={props.disabled}
            />
            <div className="upload-icon">
              <img src={UploadIcon} alt="Upload Icon" />
              <p>Drag and drop here to upload</p>
            </div>
          </div>
        ) : null}

        {files.map((file) => {
          if (file.type.startsWith("image")) {
            return imageThumbs(file);
          } else if (file.type.startsWith("audio")) {
            return audioThumbs(file);
          } else {
            return videoThumbs(file);
          }
        })}
      </Thumbnails>
    </DropzoneWrapper>
  );
};

Dropzone.propTypes = {
  files: PropTypes.array,
  handleDrop: PropTypes.func.isRequired,
  setFiles: PropTypes.func.isRequired,
  fontFamily: PropTypes.string,
  accept: PropTypes.string,
  thumbWidth: PropTypes.number,
  thumbHeight: PropTypes.number,
  width: PropTypes.string,
  uploadProgress: PropTypes.oneOfType(PropTypes.object, PropTypes.number),
  multiple: PropTypes.bool,
};

Dropzone.defaultProps = {
  fontFamily: '"Muli", sans-serif',
  accept: "video/*",
  thumbWidth: 16.7,
  width: "100%",
  uploadProgress: {},
  multiple: true,
};

export default Dropzone;
