import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors } from "../themes/base";

import AnchorText from "../common/AnchorText";
import SubProperty from "../common/SubProperty";
import ToolsHeaderText from "../common/ToolsHeaderText";

const StyledWrapper = styled.div`
  padding: 0 10px;
  height: 680px;
  overflow: scroll;

  .header-container {
    display: flex;
    justify-content: space-between;
  }
`;

const SpeedButton = styled.button`
  width: 54px;
  height: 54px;
  border-radius: 2px;
  background: transparent;
  border: ${(props) =>
    props.selected
      ? `2px solid ${colors.speed_tools.border}`
      : "1px solid rgba(20, 41, 69,0.1)"};

  color: #142945;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  margin-right: 20px;
  margin-top: 10px;
  cursor: pointer;

  :focus {
    outline: 0;
  }
`;

function SpeedProperty(props) {
  return (
    <StyledWrapper>
      <div className="header-container">
        <ToolsHeaderText>Video Speed</ToolsHeaderText>
        <AnchorText style={{ margin: "10px" }} onClick={props.handleSpeedReset}>
          Reset
        </AnchorText>
      </div>
      <SubProperty>
        <div>
          <SpeedButton
            selected={props.rate === 0.5}
            onClick={() => props.setRate(0.5)}
          >
            0.5x
          </SpeedButton>
          <SpeedButton
            selected={props.rate === 1}
            onClick={() => props.setRate(1)}
          >
            1x
          </SpeedButton>
          <SpeedButton
            selected={props.rate === 1.5}
            onClick={() => props.setRate(1.5)}
          >
            1.5x
          </SpeedButton>
          <SpeedButton
            selected={props.rate === 2}
            onClick={() => props.setRate(2)}
          >
            2x
          </SpeedButton>
        </div>
      </SubProperty>
    </StyledWrapper>
  );
}

export default SpeedProperty;

SpeedProperty.propTypes = {
  rate: PropTypes.number,
  setRate: PropTypes.func,
  handleSpeedReset: PropTypes.func,
};

SpeedProperty.defaultProps = {
  rate: 1,
  setRate: () => {},
  handleSpeedReset: () => {},
};
