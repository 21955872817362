import {
  SET_ENCODING,
  SET_FILES_ENCODING_PROGRESS,
  SET_ENCODE_MODAL,
  SET_ENCODE_VIDEO_ID,
} from "../actions/types";

const initialState = {
  isEncoding: false,
  encodingProgress: 0,
  isEncodeModalVisible: false,
  encodeVideoId: null,
};

const encodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ENCODING:
      return { ...state, isEncoding: action.payload };
    case SET_FILES_ENCODING_PROGRESS:
      return { ...state, encodingProgress: action.payload };
    case SET_ENCODE_MODAL:
      return { ...state, isEncodeModalVisible: action.payload };
    case SET_ENCODE_VIDEO_ID:
      return { ...state, encodeVideoId: action.payload };
    default:
      return state;
  }
};
export default encodeReducer;
