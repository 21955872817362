import React from "react";

import styled from "styled-components";
import { DefaultTable, HomeOverviewComp } from "../../components";

const Wrapper = styled.div`
  margin: 0 5rem;

  .overview {
    color: #142945;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0.18px;
    font-family: "Muli", sans-serif;
    margin: 2rem 0;
  }
`;

function HistoricFaceSearch() {
  return (
    <Wrapper>
      <h2 className="overview">Overview</h2>
      <HomeOverviewComp />
      <DefaultTable showID={false} hideAdd={false} />
    </Wrapper>
  );
}

export default HistoricFaceSearch;
