import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Login from "./containers/Signin/Login";
import Signup from "./containers/Signup/Signup";
import ForgotPassword from "./containers/ForgotPassword/ForgotPassword";
import ResetPassword from "./containers/ForgotPassword/ResetPassword";
import requireAuth from "./components/hocs/requireAuth";
import CreateCase from "./containers/CreateCase";
import EditCase from "./containers/ManageCase/EditCase";
import ManageCase from "./containers/ManageCase";
import SearchCase from "./containers/SearchCase";
import SearchLoader from "./containers/SearchCase/SearchLoader";
import TopSearchResultsPage from "./containers/SearchResult/TopSearchResultsPage";
import GroupFaces from "./containers/GroupFaces";
import GroupProgress from "./containers/GroupFaces/GroupProgress";
import GroupResult from "./containers/GroupFaces/GroupResult";
import Tools from "./containers/Tools";

import CreateICase from "./containers/Investigator/CreateICase";
import Cases from "./containers/Investigator/Cases";
import ReviewCases from "./containers/Investigator/ReviewCases";
import EditICase from "./containers/Investigator/EditCase";
import Demograph from "./containers/Demographs";
import DemographResult from "./containers/Demographs/DemographResult";
// import CreateImageSearchCase from "./containers/ImageSearch/CreateCase/CreateCase";
// import ImageSearchCases from "./containers/ImageSearch/CaseList/CaseList";
// import ImageSearchViewCase from "./containers/ImageSearch/ViewCase/ViewCase";
// import CreateVideoSearchCase from "./containers/VideoSearch/CreateCase/CreateCase";
// import VideoSearchCases from "./containers/VideoSearch/CaseList/CaseList";
// import VideoSearchViewCase from "./containers/VideoSearch/ViewCase/ViewCase";
import {
  HistoricFaceSearch,
  VideoRedaction,
  ManageUsersComp,
  AddProfile,
  UpdateProfile,
  ViewProfile,
  AddVideo,
  RawVideos,
  RedactedVideos,
  ShowIframe,
  AudioRedaction,
} from "./containers";

import Home from "./Home";
import ViewUserProfile from "./containers/Profile/ViewProfile";
import EditUserProfile from "./containers/Profile/EditProfile";
import RawAudio from "./containers/RawAudio";
import AddAudio from "./containers/AddAudio";
import RedactedAudio from "./containers/RedactedAudio";
import NotFound from "./containers/common/NotFound";
import Users from "./containers/Users/index";
import Invite from "./containers/Users/Invite";
import SetPassword from "./containers/Users/Password";
// import VideoDetails from "./containers/VideoSearch/VideoDetails/VideoDetails";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/forgotPassword" component={ForgotPassword} />
        <Route path="/reset/:token" component={ResetPassword} />
        <Route path="/users" exact component={requireAuth(Users)} />
        <Route path="/users/invite" exact component={Invite} />
        <Route path="/set/:token" exact component={SetPassword} />
        <Route path="/profile" exact component={requireAuth(ViewUserProfile)} />
        <Route path="/profile/edit" component={requireAuth(EditUserProfile)} />
        <Route exact path="/video" component={requireAuth(VideoRedaction)} />
        <Route
          exact
          path="/video/raw/videos"
          component={requireAuth(RawVideos)}
        />
        <Route
          exact
          path="/video/raw/audio"
          component={requireAuth(RawAudio)}
        />
        <Route
          exact
          path="/video/iframe/:id"
          component={requireAuth(ShowIframe)}
        />
        <Route
          exact
          path="/video/redacted/videos"
          component={requireAuth(RedactedVideos)}
        />
        <Route
          exact
          path="/video/redacted/audio"
          component={requireAuth(RedactedAudio)}
        />
        <Route
          exact
          path="/video/add/video"
          component={requireAuth(AddVideo)}
        />
        <Route
          exact
          path="/video/add/audio"
          component={requireAuth(AddAudio)}
        />
        <Route
          exact
          path="/audio/redact/:id"
          component={requireAuth(AudioRedaction)}
        />
        <Route
          exact
          path="/facesearch"
          component={requireAuth(HistoricFaceSearch)}
        />
        <Route
          exact
          path="/facesearch/watchlist"
          component={requireAuth(ManageUsersComp)}
        />
        <Route
          exact
          path="/facesearch/watchlist/add"
          component={requireAuth(AddProfile)}
        />
        <Route
          exact
          path="/facesearch/watchlist/view/:id"
          component={requireAuth(ViewProfile)}
        />
        <Route
          exact
          path="/facesearch/watchlist/edit/:id"
          component={requireAuth(UpdateProfile)}
        />
        <Route
          exact
          path="/facesearch/cases"
          component={requireAuth(ManageCase)}
        />
        <Route
          exact
          path="/facesearch/cases/add"
          component={requireAuth(CreateCase)}
        />
        <Route
          exact
          path="/facesearch/cases/edit/:id"
          component={requireAuth(EditCase)}
        />
        <Route
          exact
          path="/facesearch/searchcase"
          component={requireAuth(SearchCase)}
        />
        <Route
          exact
          path="/facesearch/searchcase/:searchId/:jobId/progress"
          component={requireAuth(SearchLoader)}
        />
        <Route
          exact
          path="/facesearch/searchcase/:searchId/:jobId/result"
          component={requireAuth(TopSearchResultsPage)}
        />
        <Route
          exact
          path="/facesearch/group"
          component={requireAuth(GroupFaces)}
        />
        <Route
          exact
          path="/facesearch/group/:groupid/progress"
          component={requireAuth(GroupProgress)}
        />
        <Route
          exact
          path="/facesearch/group/:groupid/result"
          component={requireAuth(GroupResult)}
        />
        <Route
          exact
          path="/facesearch/demograph"
          component={requireAuth(Demograph)}
        />
        <Route
          exact
          path="/facesearch/demograph/result"
          component={requireAuth(DemographResult)}
        />
        <Route
          exact
          path="/investigator/create"
          component={requireAuth(CreateICase)}
        />
        <Route
          exact
          path="/investigator/cases"
          component={requireAuth(Cases)}
        />
        <Route
          exact
          path="/investigator/cases/:caseid"
          component={requireAuth(ReviewCases)}
        />
        <Route
          exact
          path="/investigator/cases/:caseid/edit"
          component={requireAuth(EditICase)}
        />
        <Route
          exact
          path="/investigator/cases/:caseid/:type/:id"
          component={requireAuth(Tools)}
        />
        {/* <Route
          exact
          path="/imagesearch/create"
          component={requireAuth(CreateImageSearchCase)}
        />

        <Route
          exact
          path="/imagesearch/cases"
          component={requireAuth(ImageSearchCases)}
        />

        <Route
          exact
          path="/imagesearch/cases/:id"
          component={requireAuth(ImageSearchViewCase)}
        />

        <Route
          exact
          path="/videosearch/create"
          component={requireAuth(CreateVideoSearchCase)}
        />

        <Route
          exact
          path="/videosearch/cases"
          component={requireAuth(VideoSearchCases)}
        />

        <Route
          exact
          path="/videosearch/cases/:id"
          component={requireAuth(VideoSearchViewCase)}
        />

        <Route
          exact
          path="/videosearch/cases/:id/videos/:videoid"
          component={requireAuth(VideoDetails)} 
        />*/}
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
