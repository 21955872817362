import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const TextAreaWrapper = styled.div`
  width: ${(props) => props.width};
  font-family: ${(props) => props.fontFamily};
  label {
    width: 100%;
    color: rgba(20, 41, 69, 0.5);
    line-height: 3rem;
    font-size: 1.4rem;
    display: block;
    margin-bottom: 1.5rem;

    textarea {
      box-sizing: border-box;
      padding: 1.6rem;
      width: 100%;
      line-height: 2rem;
      font-size: 1.6rem;
      border-radius: 3px;
      border: ${(props) => props.border};
      font-family: ${(props) => props.fontFamily};
      resize: none;
      &:focus {
        outline: 0;
      }
    }
    div {
      color: #f00;
      font-size: 1.2rem !important;
    }
  }
`;

const TextAreaField = (props) => {
  return (
    <TextAreaWrapper {...props}>
      <label htmlFor={props.name}>
        {props.label}
        <textarea
          id={props.id}
          name={props.name}
          placeholder={props.placeholder}
          required={props.required}
          value={props.value}
          onChange={props.handleChange}
          rows={props.rows}
        />
        <div>{props.error}</div>
      </label>
    </TextAreaWrapper>
  );
};

TextAreaField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  handleChange: PropTypes.func,
  rows: PropTypes.number,
  width: PropTypes.string,
  fontFamily: PropTypes.string,
  border: PropTypes.string,
};

TextAreaField.defaultProps = {
  required: true,
  width: "100%",
  fontFamily: '"Muli", sans-serif',
  rows: 5,
  border: "1px solid #edeff2",
};

export default TextAreaField;
