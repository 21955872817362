import { ADD_REQUEST, REMOVE_REQUEST } from "../actions/types";

const initialState = {
  requestPending: []
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case ADD_REQUEST:
      return {
        ...state,
        requestPending: [...state.requestPending, action.payload]
      };

    case REMOVE_REQUEST:
      const newRequestPending = [...state.requestPending];
      newRequestPending.splice(state.requestPending.indexOf(action.payload), 1);
      return {
        ...state,
        requestPending: newRequestPending
      };
    default:
      return state;
  }
}
