import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import Image from "../../containers/common/Image";

const SearchResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${props => props.fontFamily};
  padding: ${props => props.padding};
`;

const UserPicturesWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  img {
    margin: ${props => props.imgMargin};
  }
  margin-bottom: 20px;
`;

const Carousel = props => {
  return (
    <SearchResultWrapper {...props}>
      <UserPicturesWrapper {...props}>
        {props.userPictures.map((pic, i) => {
          return (
            <Image
              key={i}
              src={pic.url}
              width="212px"
              height="140px"
              alt="userpicture"
              style={{ borderRadius: "3px" }}
            />
          );
        })}
      </UserPicturesWrapper>
    </SearchResultWrapper>
  );
};

export default Carousel;

Carousel.propTypes = {
  padding: PropTypes.string,
  imgMargin: PropTypes.string,
  fontFamily: PropTypes.string,
  userPictures: PropTypes.array.isRequired
};

Carousel.defaultProps = {
  padding: "5px 5px",
  imgMargin: "10px",
  fontFamily: '"Muli", sans-serif'
};
