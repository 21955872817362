import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Redirect, Link } from "react-router-dom";

import Background from "../../components/Form/Background";
import Button from "../../components/Form/Button";
import Input from "../../components/common/Form/MaterialInput";
import { forgotPassword } from "../../actions";
import CloseIcon from "../../assets/img/ic_close.svg";
import loader from "../../assets/img/loader.svg";
import { colors } from "../../components/themes/base";

const Wrapper = styled.div`
  display: flex;
  .bg {
    @media only screen and (min-width: ${(props) =>
        `${props.mdBreakpoint}px`}) {
      width: 68vw;
    }
    @media only screen and (max-width: ${(props) =>
        `${props.mdBreakpoint}px`}) {
      display: none;
    }
  }

  .close-icon {
    position: absolute;
    top: 3.5rem;
    right: 3.5rem;
  }

  .forgotPassword {
    background: ${(props) => props.background};
    width: 20vw;
    height: 100vh;
    font-family: ${(props) => props.fontFamily};
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: ${(props) => props.margin};
    div:first-child {
      @media only screen and (max-width: ${(props) =>
          `${props.mdBreakpoint}px`}) {
        width: 70vw;
      }
    }
    @media only screen and (max-width: ${(props) =>
        `${props.mdBreakpoint}px`}) {
      width: 70vw;
    }
  }
  .header {
    max-width: 100%;
    display: block !important;
    color: #142945;
    font-weight: 600;
    font-size: 3rem;
    margin-bottom: 5rem;
  }
`;

class ForgotPassword extends Component {
  state = {
    email: "",
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  resetPassword = (e) => {
    e.preventDefault();
    this.props.forgotPassword({ email: this.state.email });
  };

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    }
    return (
      <Wrapper {...this.props.styles}>
        <Link to="/signin">
          <img className="close-icon" src={CloseIcon} alt="close icon" />
        </Link>
        <div className="bg">
          <Background />
        </div>
        <div className="forgotPassword">
          <div className="header">Forgot Password</div>
          <Input
            label="Email Address"
            placeholder="Email Address"
            name="email"
            value={this.state.email}
            handleChange={this.onChange}
            error={this.props.errors.message}
          />
          <Button
            background={colors.primary}
            color="#fff"
            onClick={this.resetPassword}
            textTransform="none"
            fontSize="1.8rem"
            fontWeight="normal"
          >
            Reset&nbsp;&nbsp;
            {this.props.loading ? <img src={loader} alt="loader" /> : null}
          </Button>
        </div>
      </Wrapper>
    );
  }
}

ForgotPassword.defaultProps = {
  styles: {
    background: "#FFFFFF",
    fontFamily: '"Muli", sans-serif',
    margin: "auto",
    mdBreakpoint: 900,
  },
};

const mapStateToProps = (state) => ({
  errors: state.user.errors,
  isAuthenticated: state.user.isAuthenticated,
  loading: state.user.loading,
});

export default connect(mapStateToProps, { forgotPassword })(ForgotPassword);
