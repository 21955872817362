import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors } from "../themes/base";

import Toolbar from "./Toolbar";
import Button from "../common/Button";

const StyledWrapper = styled.div`
  display: flex;
  padding: 0rem 0rem 0 2rem;
  justify-content: center;
  min-width: 250px;
  width: ${(props) => (props.fullScreenMode ? "20vw" : "20vw")};
  height: ${(props) => (props.fullScreenMode ? "100vh" : "100%")};

  .properties-container {
    width: 100%;

    border-radius: 3px;
    position: relative;
    background-color: ${colors.white};
    box-shadow: 0 2px 10px 0 ${colors.shadow};

    .main-content {
      height: ${(props) => (props.fullScreenMode ? "89vh" : "72vh")};
    }

    .button-container {
      display: flex;
      margin: 20px 10px;
    }

    .bottom-buttons {
      display: flex;
      margin: 2rem 2rem 2rem 3rem;
      justify-content: space-between;
    }
  }
`;

function EditorTools(props) {
  return (
    <div style={{ display: "flex" }}>
      <StyledWrapper
        openTools={props.openTools}
        fullScreenMode={props.fullScreenMode}
      >
        <div className="properties-container">
          <div className="main-content">
            {(!props.audiodata || !props.audiodata.url) && (
              <div className="button-container">
                <Button height="34px" borderRadius="19px" margin="7px">
                  Editing Tools
                </Button>
                <Button
                  margin="7px"
                  height="34px"
                  color={colors.secondary}
                  borderRadius="19px"
                  background="rgba(20, 41, 69,0.03)"
                  onClick={() => props.onSelect("report")}
                >
                  Report
                </Button>
              </div>
            )}

            {props.children}
          </div>

          <div className="bottom-buttons">
            <Button
              height="54px"
              fontSize="16px"
              fontWeight="600"
              color={colors.tertiary}
              background="transparent"
              padding="0"
            >
              Cancel
            </Button>

            {!props.applying && (
              <Button
                width="140px"
                fontSize="16px"
                fontWeight="600"
                textTransform="uppercase"
                onClick={props.handleApply}
              >
                Apply
              </Button>
            )}

            {props.applying && (
              <Button
                width="140px"
                fontSize="16px"
                fontWeight="600"
                textTransform="uppercase"
              >
                {props.jobProgress}%
              </Button>
            )}
          </div>
        </div>
      </StyledWrapper>
      <Toolbar
        selected={props.selected}
        onSelect={props.onSelect}
        openTools={props.openTools}
        toggle={props.toggle}
        fullScreenMode={props.fullScreenMode}
        audiodata={props.audiodata}
      />
    </div>
  );
}

export default EditorTools;

EditorTools.propTypes = {
  selected: PropTypes.string,
  onSelect: PropTypes.func,
  handleApply: PropTypes.func,
};

EditorTools.defaultProps = {
  selected: "video",
  onSelect: () => {},
  handleApply: () => {},
};
