import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { connect } from "react-redux";
import axios from "axios";

import keys from "../../config/keys";
import AudioContext from "../../context/Audio/AudioContext";
import {
  successNotification,
  warningNotification,
  errorNotification,
} from "../../actions";
import InputField from "../../components/common/Form/InputField";
import Dropzone from "../common/Form/Dropzone";
import Button from "../../components/common/Button";
import { colors } from "../../components/themes/base";

const MAX_FILE_SIZE = 2 * 1024 * 1024 * 1024; // 2GB

const buttonProps = {
  width: "140px",
  height: "48px",
  fontSize: "1.6rem",
  background: colors.brand,
  boxShadow: "0 1rem 2rem 0 rgba(124, 160, 73, 0.15)",
  color: "#FFFFFF",
  textTransform: "uppercase",
};

const FormWrapper = styled.div`
  box-sizing: border-box;
  background: ${(props) => props.background};
  width: calc(100% - 10rem); /* minus sidebar width */
  border-radius: 3px;
  margin: 2rem 5rem;
  padding-top: 3rem;
  margin-bottom: 0;
  display: flex;
  justify-content: center;

  form {
    box-sizing: border-box;
    width: 100%;
    min-height: calc(100vh - 175px);
    margin: 0 auto;
    padding: 0 10rem;
    font-family: ${(props) => props.fontFamily};
    color: ${(props) => props.fontColor};
    display: flex;
    flex-direction: column;

    .buttons {
      box-sizing: border-box;
      padding-bottom: 3rem;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }

    @media (max-width: ${(props) => `${props.mdBreakpoint}px`}) {
      width: 100%;
    }
  }
`;

const AddAudio = (props) => {
  const [error, setError] = useState("");
  const [officerName, setOfficerName] = useState("");
  const [purpose, setPurpose] = useState("");
  const [selectedAudio, setSelectedAudio] = useState([]);

  const { uploading, setUploading, upPrgs, setUpPrgs } =
    useContext(AudioContext);

  const validateInput = (validationInput, state) => {
    const items = Object.entries(validationInput);
    const error = {};

    items.forEach(([label, item]) => {
      if (!item || !item.length) {
        error[label] = "Please fill this field";
      }
    });

    if (Object.keys(error).length > 0) {
      const newState = { ...state, error };
      setError(newState.error);
      return true;
    }

    return false;
  };

  const audioUploadProgress = (redactionID) => {
    const url = `${keys.SERVER_URL}/audio/${redactionID}/progress`;
    const upInterval = setInterval(async () => {
      try {
        const res = await axios.get(url, { requestId: "audioUpPrgs" });
        if (res.status === 200) {
          setUpPrgs(res.data.progress);

          // On Successfully uploaded
          if (res.data.status === 1) {
            clearInterval(upInterval);
            setUploading(false);
            setUpPrgs(0);
            props.history.push("/video/raw/audio");
            props.successNotification({
              title: "Successfully uploaded",
              autoDismiss: 1,
            });
          }

          // On Failure
          if (res.data.status === -1) {
            clearInterval(upInterval);
            setUpPrgs(0);
            setUploading(false);
            props.errorNotification({
              title: "Uploaded Failed",
              autoDismiss: 1,
            });
          }
        }
      } catch (e) {
        console.log(e);
        clearInterval(upInterval);
        setUploading(false);
        props.errorNotification({
          title: "Unable to reach server",
          autoDismiss: 1,
        });
      }
    }, 3000);
  };

  const handleSubmit = async () => {
    if (validateInput({ officerName, purpose, selectedAudio }, error)) {
      return;
    }

    if (
      officerName.replace(/\s/g, "").length === 0 ||
      purpose.replace(/\s/g, "").length === 0
    ) {
      props.warningNotification({
        title: "Please fill both Officer name and Redaction purpose",
        autoDismiss: 1,
      });
      return;
    }

    // Check file size
    if (selectedAudio[0].size > MAX_FILE_SIZE) {
      props.warningNotification({
        title: `File size must be less than 2GB`,
        autoDismiss: 1,
      });
      return;
    }

    const formData = new FormData();
    formData.append("officerName", officerName);
    formData.append("purpose", purpose);
    selectedAudio.map((audio) => formData.append("audio_file", audio));

    const config = {
      maxContentLength: 2 * 1024 * 1024 * 1024, // 2GB
      requestId: "uploadAudios",

      onUploadProgress: (ProgressEvent) => {
        let uploadedProgress =
          (ProgressEvent.loaded / ProgressEvent.total) * 100;
        uploadedProgress = (uploadedProgress * 0.5).toFixed(0); // Taking only 50% for upload progress
        return setUpPrgs(parseInt(uploadedProgress, 10));
      },
    };

    const url = `${keys.SERVER_URL}/audio/`;
    try {
      setUploading(true);
      const res = await axios.post(url, formData, config);
      if (res.status === 200) {
        const { redactionID } = res.data;
        audioUploadProgress(redactionID);
      } else {
        setUpPrgs(0);
        setUploading(false);
        props.errorNotification({
          title: "Uploaded Failed",
          autoDismiss: 1,
        });
      }
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }

      console.log(e);
      setUpPrgs(0);
      setUploading(false);
      props.errorNotification({
        title: "Uploaded Failed",
        autoDismiss: 1,
      });
    }
  };

  return (
    <div>
      <div
        style={{
          color: "#142945",
          fontFamily: "Muli",
          fontSize: "2.2rem",
          fontWeight: "700",
          letterSpacing: "0.18px",
          margin: "2.5rem 5rem",
        }}
      >
        Add Audio
      </div>
      <FormWrapper {...props}>
        <form onSubmit={(e) => e.preventDefault()}>
          <InputField
            required={false}
            label="Officer Name*"
            id="officer-name"
            name="officer-name"
            value={officerName}
            handleChange={(e) => {
              setOfficerName(e.target.value);
              setError({});
            }}
            error={error.officerName}
            disabled={uploading}
          />
          <InputField
            required={false}
            label="Redaction Purpose*"
            id="redaction-purpose"
            name="redaction-purpose"
            value={purpose}
            handleChange={(e) => {
              setPurpose(e.target.value);
              setError({});
            }}
            error={error.purpose}
            disabled={uploading}
          />
          <Dropzone
            thumbWidth={21.2}
            thumbHeight={15}
            files={selectedAudio}
            handleDrop={setSelectedAudio}
            setFiles={setSelectedAudio}
            multiple={false}
            uploading={uploading}
            accept=".mp4,.avi,.wmv,.webm,.3gpp,.mp3,.ogg,.wav,.aif,.m4a,.wma"
            uploadProgress={upPrgs}
            error={error.selectedAudio}
            disabled={uploading}
          />
        </form>
      </FormWrapper>
      <div
        className="buttons"
        style={{
          display: "flex",
          backgroundColor: "#fff",
          justifyContent: "flex-end",
          padding: "2rem 5rem",
          position: "sticky",
          bottom: "0",
        }}
      >
        {uploading ? (
          <Button
            {...buttonProps}
            disabled
            background="transparent"
            color="#777"
            boxShadow="none"
            border="none"
          >
            Cancel
          </Button>
        ) : (
          <Button
            {...buttonProps}
            background="transparent"
            color="#777"
            boxShadow="none"
            border="none"
            onClick={() => props.history.push("/video/raw/audio")}
          >
            Cancel
          </Button>
        )}
        {uploading ? (
          <Button style={{ pointerEvents: "none" }} {...buttonProps}>
            Uploading...
          </Button>
        ) : (
          <Button {...buttonProps} onClick={handleSubmit}>
            Upload
          </Button>
        )}
      </div>
    </div>
  );
};

AddAudio.propTypes = {
  fontFamily: PropTypes.string,
  fontColor: PropTypes.string,
  background: PropTypes.string,
  width: PropTypes.string,
  handleUpload: PropTypes.func,
  handleCancel: PropTypes.func,
  mdBreakpoint: PropTypes.number,
};

AddAudio.defaultProps = {
  fontFamily: '"Muli", sans-serif',
  fontColor: "#142945",
  background: "#FFFFFF",
  width: "90%",
  mdBreakpoint: 900,
};

export default connect(null, {
  successNotification,
  warningNotification,
  errorNotification,
})(AddAudio);
