import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../components/themes/base";

const Wrapper = styled.div`
  height: 90vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Muli", sans-serif;
  font-size: 1.6rem;
  .notfound {
    max-width: 55rem;
    width: 100%;
    text-align: center;
    line-height: 1.4;
    .notfound-404 {
      height: 15.5rem;
      h1 {
        font-size: 12rem;
        font-weight: 700;
        margin: 0;
        color: #232323;
      }
    }
    h2 {
      font-size: 2.2rem;
      font-weight: 700;
      margin: 0;
      text-transform: uppercase;
      color: #232323;
    }
    p {
      color: #787878;
      font-weight: 300;
    }
    a {
      display: inline-block;
      padding: 12px 30px;
      font-weight: 700;
      background-color: ${colors.brand};
      color: #fff;
      border-radius: 40px;
      text-decoration: none;
      -webkit-transition: 0.2s all;
      transition: 0.2s all;
      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

const NotFound = (props) => {
  return (
    <Wrapper>
      <div className="notfound">
        <div className="notfound-404">
          <h1>404</h1>
        </div>
        <h2>Oops! Page Cannot Be Found</h2>
        <p>
          Sorry but the page you are looking for does not exist, have been
          removed. name changed or is temporarily unavailable.
        </p>
        <Link to="/">Back to homepage</Link>
      </div>
    </Wrapper>
  );
};

export default NotFound;
