import styled from "styled-components";

const NameAvatar = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: ${(props) => props.bg || "#2086dc"};
  color: white;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-family: Muli;
  font-weight: 700;
`;

export default NameAvatar;
