import React, { useContext, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Stage, Layer, Image as KonvaImage } from "react-konva";
import Konva from "konva";
import { v4 as uuidv4 } from "uuid";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Sidebar from "react-sidebar";
import $ from "jquery";

import { colors, icons } from "../../components/themes/base";
import ShapeRenderer from "./ShapeRenderer";
import CropSection from "../../components/CropSection";
import ToolsContext from "../../context/Tools/ToolsContext";
import VideoControls from "./VideoControls";
import { torgba, deepMerge, scrollZoom } from "./utils";
import KonvaSelector from "./ReportTool/KonvaSelector";
import "../../utils/wheelzoom";
import {
  successNotification,
  errorNotification,
  warningNotification,
} from "../../components/Notifcations";
import ToolsRenderer from "./ToolsRenderer";
import AudioConcat from "./Audio";

import keys from "../../config/keys";
import { useQuery } from "react-query";

const { SERVER_URL } = keys;

const ToolsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${(props) => (props.fullScreenMode ? "auto" : "2rem 0rem 0rem 5rem")};
`;

const VideoWrapper = styled.div`
  @media only screen and (max-width: 1600px) {
    width: ${(props) => (props.fullScreenMode ? "96.5vw" : "65vw")};
  }

  @media only screen and (max-width: 1450px) {
    width: ${(props) => (props.fullScreenMode ? "96.5vw" : "62.5vw")};
  }

  @media only screen and (max-width: 1360px) {
    width: ${(props) => (props.fullScreenMode ? "95.5vw" : "60.5vw")};
  }

  width: ${(props) => (props.fullScreenMode ? "96.5vw" : "66vw")};
  height: ${(props) => (props.fullScreenMode ? "100vh" : "85.5vh")};
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(44, 137, 255, 0.06);
  display: flex;
  align-items: center;
  flex-direction: column;

  .video-container-class {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    overflow: hidden;
    width: 100%;
    height: ${(props) => (props.fullScreenMode ? "100vh" : "auto")};
    background: ${(props) => torgba(props.bgColor, props.bgOpacity)};

    .shape-renderer {
      max-height: 100%;
      position: relative;
      overflow: hidden;
      transition: transform 0.3s;

      .edit-video {
        max-width: 100%;
        max-height: 100%;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        filter: brightness(${(props) => props.brightness})
          contrast(${(props) => props.contrast})
          saturate(${(props) => props.saturation})
          sepia(${(props) => props.exposure});

        :focus {
          outline: 0;
        }
      }
    }

    .selector-add {
      position: absolute;
      top: ${(props) =>
        props.selector.y * props.scaleY +
        (props.selector.height * props.scaleY * props.selector.scaleY - 10)}px;
      left: ${(props) =>
        props.selector.x * props.scaleX +
        (props.selector.width * props.scaleX * props.selector.scaleX + 7)}px;
    }
  }

  .magnifier-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;

  .buttons-container {
    margin: 2.5rem auto 0rem auto;

    button {
      border: 0;
      height: 30px;
      width: 50px;
      cursor: pointer;
    }

    .undo {
      background: url(${icons.IC_Undo});
      background-color: transparent;
      background-size: 20px 20px;
      background-position: center;
      background-repeat: no-repeat;
    }

    .redo {
      background: url(${icons.IC_Redo});
      background-color: transparent;
      background-size: 20px 20px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
`;

function VideoTools(props) {
  const caseID = props.match.params.caseid;
  const changes = useRef([{ text: [], shapes: [] }]);
  const changeIdx = useRef(0);

  const stageRef = useRef();
  const layerRef = useRef();
  const imageRef = useRef();
  const tRef = useRef();
  const initialHeight = useRef(600);
  const initialWidth = useRef(800);
  const cData = useRef({});

  const {
    report,
    audio,
    videoContext,
    contrast,
    effects,
    text,
    shape,
    videodata,
    setVideodata,
    speed,
    getContextData,
    setContextData,
  } = useContext(ToolsContext);
  const [selectedtool, setSelectedTool] = useState("video");
  const [selectedShapeID, setSelected] = useState(null);
  const [videoURL, setVideoURL] = useState(null);

  const [loading, setLoading] = useState(false);
  const [fullScreenMode, setFullScreen] = useState(false);
  const [applying, setApplying] = useState(false);

  const [scaleX, setScaleX] = useState(1);
  const scaleXRef = React.useRef(scaleX);
  const setScaleXRef = (data) => {
    scaleXRef.current = data;
    setScaleX(data);
  };

  const [scaleY, setScaleY] = useState(1);
  const scaleYRef = React.useRef(scaleY);
  const setScaleYRef = (data) => {
    scaleYRef.current = data;
    setScaleY(data);
  };

  const videodataRef = useRef(videodata);
  const setVideodataRef = (data) => {
    videodataRef.current = data;
    setVideodata(data);
  };

  const [videoName, setVideoName] = useState("");

  const [audiodata, setData] = useState({});
  const [openTools, setOpen] = React.useState(true);
  const intervalRef = useRef();

  const [currentJobID, setCurrentJobID] = useState(null);
  const [jobProgress, setJobProgress] = useState(0);
  useQuery(["investigator", "job", currentJobID], {
    enabled: !!currentJobID,
    refetchInterval: 1000,
    onSuccess(jobData) {
      if (!jobData) return;

      const { progress } = jobData;
      if (progress === -1) {
        errorNotification("Failed to generate the clipped video");
        setCurrentJobID(null);
        setApplying(false);
        setJobProgress(0);
        return;
      }

      setJobProgress(progress);
      if (progress >= 100) {
        successNotification("Successfully Generated");
        props.history.push(`/investigator/cases/${caseID}`);
        setApplying(false);
      }
    },
    onError() {
      errorNotification("Failed to generate the clipped video");
      setCurrentJobID(null);
      setApplying(false);
    },
  });

  useEffect(() => {
    if (props.match.params.type !== "clips") {
      return;
    }

    const videoID = props.match.params.id;
    if (!videoID) {
      props.history.push("/investigator/cases");
      return;
    }

    window.addEventListener("resize", changeScale);

    fetchVideoURL(videoID);

    return () => {
      window.removeEventListener("resize", changeScale);
      setContextData({});
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id, props.match.params.type]);

  useEffect(() => {
    if (props.match.params.type !== "clips") {
      return;
    }

    cData.current = getContextData();
    const video = document.getElementById("ff-video");
    if (video && cData.current.speed && cData.current.speed.rate) {
      video.playbackRate = cData.current.speed.rate;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getContextData]);

  useEffect(() => {
    if (props.match.params.type !== "sound") {
      return;
    }

    const audioID = props.match.params.id;
    if (!audioID) {
      props.history.push("/investigator/cases");
      return;
    }

    fetchAudioURL(audioID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.type, props.match.params.id]);

  const toggle = () => {
    setOpen((status) => !status);
  };

  const setTool = (value) => {
    setSelectedTool(value);

    if (fullScreenMode && !openTools) {
      setOpen(true);
    }
  };

  const handleAudioApply = async () => {
    const audioID = props.match.params.id;

    if (audio.regions.length === 0) {
      warningNotification(`No regions ${videoContext.cropType}`);
      return;
    }

    try {
      setApplying(true);
      const { data } = await axios.post(
        `${SERVER_URL}/investigator/audios/${audioID}/clip`,
        {
          caseID,
          audioID,
          op: audio.cropType,
          timeRegions: audio.regions,
        }
      );

      if (data.jobID) {
        setCurrentJobID(data.jobID);
      } else {
        setApplying(false);
        errorNotification("Failed to clip audio");
      }
    } catch (err) {
      setApplying(false);

      errorNotification("Failed to clip audio");
    }
  };

  const handleApply = async () => {
    if (props.match.params.type === "sound") {
      handleAudioApply();
      return;
    }

    if (videoContext.regions.length === 0) {
      warningNotification(`No regions ${videoContext.cropType}`);
      return;
    }

    try {
      setApplying(true);
      const videoID = props.match.params.id;
      const { data } = await axios.post(
        `${SERVER_URL}/investigator/videos/${videoID}/clip`,
        {
          caseID,
          videoID,
          op: videoContext.cropType,
          timeRegions: videoContext.regions,
        }
      );

      if (data.jobID) {
        setCurrentJobID(data.jobID);
      } else {
        errorNotification("Failed to generate the clipped video");
        setApplying(false);
      }
    } catch (err) {
      errorNotification("Failed to generate the clipped video");
      setApplying(false);
    }
  };

  const fetchAudioURL = async (audioID) => {
    setLoading(true);
    const res = await axios.get(`${SERVER_URL}/investigator/audios/${audioID}`);
    setLoading(false);

    if (res.status !== 200) {
      errorNotification("Something went wrong.Please try again");
      return;
    }

    if (!res.data.audioObj) {
      return;
    }

    const data = { ...res.data.audioObj };
    data.peaks = res.data.peaks || [];
    audio.setRegions(res.data.audioObj.metadata || []);
    audio.setAudioName(res.data.audioObj.originalName || "");
    setData(data);
  };

  const fetchVideoURL = async (videoID) => {
    setLoading(true);
    const res = await axios.get(`${SERVER_URL}/investigator/videos/${videoID}`);
    setLoading(false);

    if (res.status !== 200) {
      errorNotification("Something went wrong.Please try again");
      return;
    }

    if (res.data.videoObj && res.data.videoObj.url) {
      setVideoURL(res.data.videoObj.url);
    }

    if (
      res.data.videoObj &&
      res.data.videoObj._doc &&
      res.data.videoObj._doc.originalName
    ) {
      setVideoName(res.data.videoObj._doc.originalName);
    }
  };

  const fetchMetadata = async (videoID = null) => {
    if (!videoID) {
      return;
    }

    const res = await axios.get(
      `${SERVER_URL}/investigator/videos/${videoID}/edit`
    );

    if (res.status === 200) {
      setContextData(res.data.metadata);
      restoreChanges(res.data.metadata);
      intervalRef.current = setInterval(() => {
        updateMetadata(videoID, cData.current);
      }, 10 * 1000);
    }
  };

  const changeUI = () => {
    const header = document.getElementById("main-header");
    if (header) {
      header.style.display = !fullScreenMode ? "none" : "block";
    }

    const sidebar = document.getElementById("main-sidebar");
    if (sidebar) {
      sidebar.style.display = !fullScreenMode ? "none" : "block";
    }

    const mainContent = document.getElementById("main-content");
    if (mainContent) {
      mainContent.style.marginTop = !fullScreenMode ? "0" : "6.8rem";
      mainContent.style.marginLeft = !fullScreenMode ? "0" : "10rem";
    }
  };

  const toggleFullScreen = () => {
    changeUI();
    if (!fullScreenMode) {
      setSelectedTool("video");
      setOpen(false);
    }

    setFullScreen((status) => !status);

    const video = document.getElementById("ff-video");
    if (!video) {
      return;
    }

    setTimeout(() => {
      const data = {
        top: video.offsetTop,
        left: video.offsetLeft,
        width: video.offsetWidth,
        height: video.offsetHeight,
      };
      setVideodataRef({ ...videodata, ...data });
      setScaleXRef(video.offsetWidth / initialWidth.current);
      setScaleYRef(video.offsetHeight / initialHeight.current);
    }, 100);
  };

  const updateMetadata = async (videoID, metadata) => {
    if (!videoID || currentJobID) {
      return;
    }

    const res = await axios.post(
      `${SERVER_URL}/investigator/videos/${videoID}/edit`,
      { metadata }
    );
    if (res.status !== 200) {
      console.log("Unable to save local changes");
    }
  };

  const restoreChanges = (data) => {
    const datatoStore = {
      text: [],
      shapes: [],
    };
    if (data.text && data.text.layers) {
      datatoStore.text = [...data.text.layers];
    }

    if (data.shape && data.shape.shapes) {
      datatoStore.shapes = [...data.shape.shapes];
    }

    changes.current = [datatoStore];
  };

  const storeCurrentState = (datatoStore) => {
    if (changes.current[1]) {
      changes.current[0] = changes.current[1];
      changes.current[1] = datatoStore;
    } else {
      changes.current.push(datatoStore);
    }

    if (changes.current.length >= 1) {
      changeIdx.current = changes.current.length - 1;
    }
  };

  const createdTextOrShape = (textAdded = true, newData) => {
    const datatoStore = {
      text: text.layers,
      shapes: shape.shapes,
    };

    if (textAdded) {
      datatoStore.text = [...text.layers, newData];
    } else {
      datatoStore.shapes = [...shape.shapes, newData];
    }

    storeCurrentState(datatoStore);
  };

  const deletedTextOrShape = (textDeleted, id) => {
    const datatoStore = {};
    if (textDeleted) {
      datatoStore.text = text.layers.filter((txt) => txt.id !== id);
      datatoStore.shapes = shape.shapes;
    } else {
      datatoStore.text = text.layers;
      datatoStore.shapes = shape.shapes.filter((shp) => shp.id !== id);
    }

    storeCurrentState(datatoStore);
  };

  const storeChanges = (obj) => {
    const datatoStore = {};

    if (obj.text) {
      const filteredText = text.layers.map((txt) => {
        if (txt.id !== obj.id) {
          return txt;
        } else {
          return obj;
        }
      });

      const shapesData = shape.shapes.map((shp) => {
        return shp;
      });

      datatoStore.text = filteredText;
      datatoStore.shapes = shapesData;
    } else {
      const filteredShapes = shape.shapes.map((shp) => {
        if (shp.id !== obj.id) {
          return shp;
        } else {
          return obj;
        }
      });

      const texts = text.layers.map((txt) => {
        return txt;
      });

      datatoStore.text = texts;
      datatoStore.shapes = filteredShapes;
    }

    storeCurrentState(datatoStore);
  };

  const undo = () => {
    if (changeIdx.current !== 0) {
      changeIdx.current = changeIdx.current - 1;
    }

    if (!changes.current[changeIdx.current]) {
      return;
    }

    const data = changes.current[changeIdx.current];
    if (data.text) {
      // Delete extra texts which are not previously found
      text.layers.map((txt) => {
        const txtIDExist = data.text.find((exTxt) => exTxt.id === txt.id);
        if (!txtIDExist) {
          text.handleDelete(txt.id);
        }
        return txt;
      });

      // Revert changes back
      data.text.map((txt) => {
        const txtIDExist = text.layers.find((exTxt) => exTxt.id === txt.id);
        if (txtIDExist) {
          text.handleLayerDataChange(txt.id, {
            x: txt.x,
            y: txt.y,
            scaleX: txt.scaleX,
            scaleY: txt.scaleY,
            rotation: txt.rotation,
          });
        } else {
          text.setLayers((lrs) => [...lrs, txt]);
        }

        return txt;
      });
    }

    if (data.shapes) {
      // Delete extra shapes added newly
      shape.shapes.map((shp) => {
        const shpExist = data.shapes.find((exShp) => exShp.id === shp.id);
        if (!shpExist) {
          shape.handleDelete(shp.id);
        }

        return shp;
      });

      data.shapes.map((shp) => {
        const shpExist = shape.shapes.find((exShp) => exShp.id === shp.id);
        if (shpExist) {
          shape.handleShapeDataChange(shp.id, {
            x: shp.x,
            y: shp.y,
            scaleX: shp.scaleX,
            scaleY: shp.scaleY,
            rotation: shp.rotation,
          });
        } else {
          shape.setShapes((exShps) => [...exShps, shp]);
        }

        return shp;
      });
    }
  };

  const redo = () => {
    if (changeIdx.current === changes.current.length - 1) {
      return;
    }

    changeIdx.current = changeIdx.current + 1;

    if (!changes.current[changeIdx.current]) {
      return;
    }

    const data = changes.current[changeIdx.current];
    if (data.text) {
      text.layers.map((txt) => {
        const txtIDExist = data.text.find((exTxt) => exTxt.id === txt.id);
        if (!txtIDExist) {
          text.handleDelete(txt.id);
        }
        return txt;
      });
      data.text.map((txt) => {
        const txtIDExist = text.layers.find((exTxt) => exTxt.id === txt.id);
        if (txtIDExist) {
          text.handleLayerDataChange(txt.id, {
            x: txt.x,
            y: txt.y,
            scaleX: txt.scaleX,
            scaleY: txt.scaleY,
            rotation: txt.rotation,
          });
        } else {
          text.setLayers((lrs) => [...lrs, txt]);
        }

        return txt;
      });
    }

    if (data.shapes) {
      shape.shapes.map((shp) => {
        const shpExist = data.shapes.find((exShp) => exShp.id === shp.id);

        if (!shpExist) {
          shape.handleDelete(shp.id);
        }

        return shp;
      });

      data.shapes.map((shp) => {
        const shpExist = shape.shapes.find((exShp) => exShp.id === shp.id);
        if (shpExist) {
          shape.handleShapeDataChange(shp.id, {
            x: shp.x,
            y: shp.y,
            scaleX: shp.scaleX,
            scaleY: shp.scaleY,
            rotation: shp.rotation,
          });
        } else {
          shape.setShapes((exShps) => [...exShps, shp]);
        }

        return shp;
      });
    }
  };

  function changeScale() {
    const video = document.getElementById("ff-video");
    if (!video) {
      return;
    }

    setVideodataRef({
      ...videodataRef.current,
      top: video.offsetTop,
      left: video.offsetLeft,
      width: video.offsetWidth,
      height: video.offsetHeight,
    });
    setScaleXRef(video.offsetWidth / initialWidth.current);
    setScaleYRef(video.offsetHeight / initialHeight.current);
  }

  function resetSelector() {
    report.setCmtID(null);
    report.handleSelectorChange({
      x: 100,
      y: 100,
      scaleX: 1,
      scaleY: 1,
      width: 100,
      height: 100,
    });
  }

  async function takeScreenshot(cmtID) {
    for (let cmnt of report.comments) {
      if (cmnt.id !== cmtID) {
        continue;
      }

      const video = document.getElementById("ff-video");
      if (!video) {
        return;
      }

      try {
        const canvas = document.createElement("canvas");
        canvas.height = video.offsetHeight;
        canvas.width = video.offsetWidth;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(video, 0, 0, video.offsetWidth, video.offsetHeight);

        const image = new Image();
        image.src = canvas.toDataURL();
        image.addEventListener("load", async () => {
          tRef.current.setAttrs({ visible: false });
          imageRef.current.setAttrs({
            image: image,
            visible: true,
            draggable: false,
            x: 0,
            y: 0,
            height: video.offsetHeight,
            width: video.offsetWidth,
          });

          // imageRef.current.cache();
          // imageRef.current.brightness(contrast.brightness - 1.3);
          // imageRef.current.contrast(contrast.contrast * 100 - 100);
          // imageRef.current.saturation(contrast.saturation * 1.5 - 2);

          layerRef.current.batchDraw();

          const url = stageRef.current.toDataURL({
            x: report.selector.x * scaleXRef.current,
            y: report.selector.y * scaleYRef.current,
            width:
              report.selector.width *
              report.selector.scaleX *
              scaleXRef.current,
            height:
              report.selector.height *
              report.selector.scaleY *
              scaleYRef.current,
          });
          imageRef.current.setAttrs({ visible: false });
          tRef.current.setAttrs({ visible: true });

          const blob = await fetch(url).then((res) => res.blob());
          const formdata = new FormData();
          formdata.append("file", blob);

          const storeRes = await axios.post(
            `${SERVER_URL}/investigator/videos/${props.match.params.id}/comments/${cmnt.id}`,
            formdata
          );

          const screenshots = [
            ...cmnt.screenshots,
            {
              id: uuidv4(),
              scrnShotb64: `${SERVER_URL}/static/${storeRes.data.url}`,
              ...report.selector,
            },
          ];

          report.handleCommentChange(cmnt.id, { screenshots });
          resetSelector();
        });

        image.remove();
        canvas.remove();
      } catch (e) {
        console.log(e);
        resetSelector();
      }
    }
  }

  function handleStageClick(e) {
    if (e.target === e.target.getStage()) {
      setSelected(null);
    } else if (e.target.id()) {
      setSelected(e.target.id());
      if (e.target instanceof Konva.Text) {
        setTool("text");
        const textArea = document.getElementById(`textarea_${e.target.id()}`);
        if (textArea) {
          textArea.focus();
        }
      } else {
        setTool("shape");
      }
    } else {
      setSelected(null);
    }
  }

  if (loading) {
    return <div></div>;
  }

  let textProps = {
    fontFamily: "helvetica",
    fontSize: 48,
    align: "start",
    fontStyle: "normal",
    letterSpace: "0.00",
    lineHeight: "1.00",
    fill: colors.palette[7],
  };

  let shapeProps = {
    fillColor: colors.palette[7],
    fillOpacity: 1,
    outline: 2,
    outlineOpacity: 1,
    outlineColor: colors.palette[0],
  };

  if (selectedShapeID) {
    let selectedShape = text.layers.find((layer) => {
      if (layer.id === selectedShapeID) {
        return layer;
      }

      return null;
    });

    if (selectedShape) {
      textProps = { ...textProps, ...selectedShape };
    } else {
      selectedShape = shape.shapes.find((shp) => {
        if (shp.id === selectedShapeID) {
          return shp;
        }
        return null;
      });
      shapeProps = { ...shapeProps, ...selectedShape };
    }
  }

  const toolsProps = {
    jobProgress,
    videoName,
    selectedtool,
    applying,
    openTools,
    toggle,
    setTool,
    fullScreenMode,
    handleApply,
    videoContext,
    videodata,
    contrast,
    effects,
    text,
    textProps,
    createdTextOrShape,
    deletedTextOrShape,
    setSelected,
    selectedShapeID,
    shape,
    shapeProps,
    audio,
    speed,
    report,
    caseID,
  };

  return (
    <div>
      {!fullScreenMode && props.match.params.type === "clips" && (
        <HeaderContainer>
          <div className="buttons-container">
            <button className="undo" title="undo" onClick={undo}></button>
            <button className="redo" title="redo" onClick={redo}></button>
          </div>
        </HeaderContainer>
      )}

      <ToolsWrapper fullScreenMode={fullScreenMode}>
        {fullScreenMode && (
          <Sidebar
            open={true}
            styles={{
              root: {
                backgroundColor: "none",
                left: "auto",
                width: openTools && fullScreenMode ? "400px" : "60px",
                zIndex: 9999,
              },
              overlay: { backgroundColor: "none", display: "none" },
            }}
            sidebar={
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  overflow: "hidden",
                }}
              >
                <ToolsRenderer {...toolsProps} audiodata={audiodata} />
              </div>
            }
            pullRight
          >
            <div />
          </Sidebar>
        )}
        <VideoWrapper
          scaleX={scaleXRef.current}
          scaleY={scaleYRef.current}
          brightness={contrast.brightness}
          contrast={contrast.contrast}
          saturation={contrast.saturation}
          exposure={contrast.exposure}
          videodata={videodata}
          bgColor={videoContext.bgColor}
          bgOpacity={videoContext.bgOpacity}
          selector={report.selector}
          fullScreenMode={fullScreenMode}
        >
          {props.match.params.type === "sound" && (
            <AudioConcat {...audio} {...audiodata} />
          )}

          {props.match.params.type === "clips" && (
            <>
              <div className="video-container-class" id="video-container">
                <div className="shape-renderer" id="shapes-renderer">
                  <video
                    id="ff-video"
                    className="edit-video"
                    src={videoURL}
                    onLoadedMetadata={(e) => {
                      const data = {
                        loaded: true,
                        src: e.target.src,
                        top: e.target.offsetTop,
                        left: e.target.offsetLeft,
                        width: e.target.offsetWidth,
                        height: e.target.offsetHeight,
                        duration: e.target.duration,
                        videoWidth: e.target.videoWidth,
                        videoHeight: e.target.videoHeight,
                      };

                      new scrollZoom($("#video-container"), 5, 0.3);
                      initialHeight.current = e.target.offsetHeight;
                      initialWidth.current = e.target.offsetWidth;

                      setVideodataRef(data);
                      fetchMetadata(props.match.params.id);
                    }}
                  />

                  <canvas
                    id="opencv-output"
                    style={{
                      top: 0,
                      left: 0,
                      position: "absolute",
                      width: `${videodata.width}px`,
                      height: `${videodata.height}px`,
                    }}
                    width={videodata.height}
                    height={videodata.height}
                  />

                  <VideoControls
                    videodata={videodata}
                    setRate={speed.setRate}
                    fullScreenMode={fullScreenMode}
                    toggleFullScreen={toggleFullScreen}
                  />

                  {videodata.loaded && (
                    <Stage
                      ref={stageRef}
                      style={{
                        position: "absolute",
                        top: `${videodata.top}px`,
                        left: `${videodata.left}px`,
                      }}
                      height={videodata.height}
                      width={videodata.width}
                      onClick={handleStageClick}
                    >
                      <Layer ref={layerRef}>
                        <KonvaImage
                          ref={imageRef}
                          filters={[
                            Konva.Filters.Brighten,
                            Konva.Filters.Contrast,
                            Konva.Filters.HSL,
                          ]}
                        />
                        <ShapeRenderer
                          videodata={videodata}
                          storeChanges={storeChanges}
                          selectedShapeID={selectedShapeID}
                          textList={text.layers}
                          shapeList={shape.shapes}
                          scaleXRef={scaleXRef.current}
                          scaleYRef={scaleYRef.current}
                          handleTextDataChange={text.handleLayerDataChange}
                          handleShapeDataChange={shape.handleShapeDataChange}
                        />
                        {report.slctCmtID && (
                          <KonvaSelector
                            {...report.selector}
                            tRef={tRef}
                            x={report.selector.x * scaleXRef.current}
                            y={report.selector.y * scaleYRef.current}
                            scaleX={report.selector.scaleX * scaleXRef.current}
                            scaleY={report.selector.scaleY * scaleYRef.current}
                            sX={scaleXRef.current}
                            sY={scaleYRef.current}
                            handleChange={report.handleSelectorChange}
                            cmtID={report.slctCmtID}
                            takeScreenshot={takeScreenshot}
                          />
                        )}
                      </Layer>
                    </Stage>
                  )}
                </div>
              </div>
              <CropSection
                duration={videodata.duration}
                regions={videoContext.regions}
                regionsArr={deepMerge(videoContext.regions)}
                setRegions={videoContext.setRegions}
                videoSrc={videoURL}
                fullScreenMode={fullScreenMode}
              />
            </>
          )}
        </VideoWrapper>
        {!fullScreenMode && (
          <ToolsRenderer {...toolsProps} audiodata={audiodata} />
        )}
      </ToolsWrapper>
    </div>
  );
}

export default withRouter(VideoTools);
