import moment from "moment";
import ColorConverter from "color";

export const torgba = (hex, opacity = 1) => {
  if (hex && (hex.length === 4 || hex.length === 7)) {
    try {
      const colorObj = ColorConverter(hex).rgb().object();
      return `rgba(${colorObj.r},${colorObj.g},${colorObj.b},${opacity})`;
    } catch (err) {
      return hex;
    }
  }

  return hex;
};

export const toSec = (timeFormat) => {
  return moment.duration(timeFormat).asSeconds();
};

export const toHHMMSS = (sec = 100) => {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = Math.floor(sec - hours * 3600 - minutes * 60);
  const milliseconds = sec.toString().split(".")[1] || "0";

  const formatted =
    hours.toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0") +
    ":" +
    seconds.toString().padStart(2, "0") +
    "." +
    milliseconds;

  return formatted.slice(0, 11);
};

export const deepMerge = (regions = []) => {
  const mergedArray = [];

  for (let region of regions) {
    mergedArray.push(toSec(region.start));
    mergedArray.push(toSec(region.end));
  }

  return mergedArray;
};

export const getBase64Image = (
  video,
  videodata,
  cropRegion = { x: 0, y: 0, height: 100, width: 100 },
  magnifier = false
) => {
  const canvas = document.createElement("canvas");

  if (magnifier) {
    canvas.width = videodata.width * 3;
    canvas.height = videodata.height * 3;
  } else {
    canvas.width = videodata.width;
    canvas.height = videodata.height;
  }

  let displayWidth = canvas.width;
  let displayHeight = canvas.height;

  if (magnifier) {
    displayWidth = canvas.width * 1.3;
    displayHeight = canvas.height * 1.3;
  }

  const ctx = canvas.getContext("2d");
  ctx.drawImage(
    video,
    cropRegion.x,
    cropRegion.y,
    cropRegion.width,
    cropRegion.height,
    0,
    0,
    displayWidth,
    displayHeight
  );
  const base64ImageData = canvas.toDataURL();
  return base64ImageData;
};
