import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "react-query";

import {
  successNotification,
  warningNotification,
  errorNotification,
} from "../../components/Notifcations";
import InputField from "../../components/common/Form/InputField";
import TextAreaField from "../../components/common/Form/TextAreaField";
import Dropzone from "../common/Form/Dropzone";
import Button from "../../components/common/Button";
import {
  createInvestigation,
  uploadFilesToInvestigation,
} from "../../config/api";
import { colors } from "../../components/themes/base";

const VIDEO_FORMATS =
  ".mp4,.avi,.wmv,.webm,.3gp,.3gpp,.dma,.vob,.mpg,.mov,.flv,.ogg,.ogv,.drc,.gif,.rmvb,.gifv,.mng,.yuv,.rm,.viv,.amv,.asf,.mpeg,.svi,.3g2,.mxf,.roq,.nsv,.m4p,.m4v,.ts";
const AUDIO_FORMATS = ".mp3,.wma,.aac,.m4a,.wav,.aif";
const MAX_FILE_SIZE = 8 * 1024 * 1024 * 1024; // 8GB

const buttonProps = {
  width: "140px",
  height: "48px",
  fontSize: "1.6rem",
  background: colors.brand,
  boxShadow: "0 10px 20px 0 rgba(124, 160, 73, 0.15)",
  color: "#FFFFFF",
  textTransform: "uppercase",
};

const FormWrapper = styled.div`
  background: ${(props) => props.background};
  width: calc(100% - 10rem); /* minus sidebar width */
  border-radius: 3px;
  margin: 2rem 5rem;
  padding-top: 3rem;
  margin-bottom: 0;
  display: flex;
  justify-content: center;

  form {
    width: 100%;
    min-height: calc(100vh - 175px);
    margin: 0 auto;
    padding: 0 10rem;
    font-family: ${(props) => props.fontFamily};
    color: ${(props) => props.fontColor};
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
`;

const CreateICase = (props) => {
  const [name, setName] = useState("");
  const [notes, setNotes] = useState("");
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploading, setUploading] = useState(false);
  const [currentJobID, setCurrentJobID] = useState(null);

  const caseIDRef = useRef(null);
  const uploadingFileID = useRef(null);
  const filesCopy = useRef([]);
  const failedFileIDs = useRef([]);

  const { mutate: createMutate } = useMutation(createInvestigation);
  const { mutate: uploadMutate } = useMutation(uploadFilesToInvestigation);

  useQuery(["investigator", "job", currentJobID], {
    enabled: !!currentJobID,
    refetchInterval: 1000,
    onSuccess(jobData) {
      if (!jobData || !uploadingFileID.current) return;

      const { progress } = jobData;
      setUploadProgress((prgs) => ({
        ...prgs,
        [uploadingFileID.current]: progress,
      }));
    },
    onError() {
      if (!uploadingFileID.current) return;
      setUploadProgress((prgs) => ({
        ...prgs,
        [uploadingFileID.current]: -1,
      }));
    },
  });

  const uploadFile = useCallback(
    async (file, caseID, endpoint = "videos") => {
      const formData = new FormData();
      formData.append("file", file);

      uploadMutate(
        {
          caseID,
          endpoint,
          formData,
          onProgress: (percent) => {
            setUploadProgress((prgs) => ({
              ...prgs,
              [uploadingFileID.current]: percent * 0.5,
            }));
          },
        },
        {
          onSuccess({ jobID }) {
            setCurrentJobID(jobID);
          },
          onError() {
            setUploadProgress((prgs) => ({
              ...prgs,
              [uploadingFileID.current]: -1,
            }));
          },
        }
      );
    },
    [uploadMutate]
  );

  const uploadNextFile = useCallback(() => {
    if (!caseIDRef.current) return;

    setCurrentJobID(null);
    const [nextFile, ...restFiles] = filesCopy.current;

    if (!nextFile) {
      setUploading(false);

      const failedFiles = failedFileIDs.current;
      if (failedFiles.length === 0) {
        successNotification("Successfully uploaded");
        props.history.push("/investigator/cases");
        return;
      }

      setFiles((files) =>
        files.filter((file) => failedFiles.includes(file.id))
      );
      errorNotification(`Failed to upload ${failedFiles.length} file(s)`);
      return;
    }

    filesCopy.current = restFiles;
    uploadingFileID.current = nextFile.id;

    const ext = nextFile.path.split(".").pop().toLowerCase();
    if (VIDEO_FORMATS.includes(ext)) {
      uploadFile(nextFile, caseIDRef.current, "videos");
    }

    if (AUDIO_FORMATS.includes(ext)) {
      uploadFile(nextFile, caseIDRef.current, "audios");
    }
  }, [props.history, uploadFile]);

  useEffect(() => {
    if (!uploadProgress) return;

    const currentProgress = uploadProgress[uploadingFileID.current];
    if (currentProgress === -1) {
      failedFileIDs.current = [
        ...failedFileIDs.current,
        uploadingFileID.current,
      ];
    }

    if (currentProgress === -1 || currentProgress === 100) {
      uploadNextFile();
    }
  }, [uploadProgress, uploadNextFile]);

  const createCase = async () => {
    const progressObj = {};
    for (const file of files) {
      progressObj[file.id] = 0;
    }
    setUploadProgress(progressObj);
    setUploading(true);
    failedFileIDs.current = [];
    filesCopy.current = [...files];

    if (caseIDRef.current) {
      uploadNextFile();
      return;
    }

    createMutate(
      { name, notes },
      {
        onSuccess({ caseID }) {
          caseIDRef.current = caseID;
          uploadNextFile();
        },
        onError() {
          setUploading(false);
          errorNotification("Failed to create case");
        },
      }
    );
  };

  const checkFileSize = () => {
    for (let file of files) {
      if (file.size > MAX_FILE_SIZE) {
        return true;
      }
    }

    return false;
  };

  const handleRegister = async () => {
    if (!name.replace(/\s/g, "").length) {
      warningNotification("Please fill the Case Name");
      return;
    }

    if (files.length === 0) {
      warningNotification("Please select some images");
      return;
    }

    if (checkFileSize()) {
      warningNotification("Each file size must be less than 8GB");
      return;
    }

    createCase();
  };

  return (
    <div>
      <div
        style={{
          color: "#142945",
          fontFamily: "Muli",
          fontSize: "2.2rem",
          fontWeight: "700",
          letterSpacing: "0.18px",
          margin: "2.5rem 5rem",
        }}
      >
        Create Case
      </div>
      <FormWrapper {...props}>
        <form onSubmit={(e) => e.preventDefault()}>
          <InputField
            label="Case Name*"
            id="case-name"
            name="case-name"
            value={name}
            handleChange={(e) => setName(e.target.value)}
          />
          <TextAreaField
            label="Notes"
            id="note"
            name="note"
            value={notes}
            handleChange={(e) => setNotes(e.target.value)}
          />
          <Dropzone
            files={files}
            thumbHeight={15} // 14 for 5 in a row
            thumbWidth={21.2} // 19.7 for 5 in a row
            setFiles={setFiles}
            handleDrop={(fls) => {
              setFiles((exFiles) => [...exFiles, ...fls]);
            }}
            uploading={uploading}
            uploadProgress={uploadProgress}
            accept={VIDEO_FORMATS + "," + AUDIO_FORMATS}
          />
        </form>
      </FormWrapper>
      <div
        className="buttons"
        style={{
          display: "flex",
          backgroundColor: "#fff",
          justifyContent: "flex-end",
          padding: "2rem 5rem",
          position: "sticky",
          bottom: "0",
          fontSize: "1.6rem",
        }}
      >
        <Button
          {...buttonProps}
          background="transparent"
          color="#777"
          boxShadow="none"
          border="none"
          onClick={() => props.history.push("/investigator/cases")}
        >
          Cancel
        </Button>
        <Button {...buttonProps} onClick={handleRegister} disabled={uploading}>
          Register
        </Button>
      </div>
    </div>
  );
};

CreateICase.propTypes = {
  fontFamily: PropTypes.string,
  fontColor: PropTypes.string,
  background: PropTypes.string,
  width: PropTypes.string,
  handleRegister: PropTypes.func,
  handleCancel: PropTypes.func,
};

CreateICase.defaultProps = {
  fontFamily: '"Muli", sans-serif',
  fontColor: "#142945",
  background: "#FFFFFF",
  width: "100%",
};

export default withRouter(CreateICase);
