import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";

import { colors } from "../themes/base";
import Button from "../../components/common/Button";
import AudioNotesPDF from "../../containers/AudioReaction/AudioNotesPDF";

const buttonProps = {
  width: "140px",
  height: "48px",
  fontSize: "1.6rem",
  background: colors.brand,
  boxShadow: "0 10px 20px 0 rgba(124, 160, 73, 0.15)",
  color: "#FFFFFF",
};

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 99999999,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: 0,
    border: 0,
    outline: 0,
    transform: "translate(-50%, -50%)",
  },
};

const ModalContent = styled.div`
  box-sizing: border-box;
  color: #142945;

  iframe {
    border: 0;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    background-color: rgb(65, 68, 71);
    padding: 2rem;
    margin-top: -2px;
  }
`;

const PDFView = (props) => {
  const { isVisible, handleCancel, name, duration, regions } = props;
  const [iframeSrc, setIframeSrc] = useState(null);
  const docRef = useRef(null);

  const getNotesPdfSrc = useCallback(async ({ name, duration, regions }) => {
    const { jsPDF } = await import("jspdf");
    const { applyPlugin } = await import("jspdf-autotable");
    applyPlugin(jsPDF);
    const pdf = new jsPDF();

    pdf.setFontSize(20);
    pdf.text("Audio Report", 85, 15);

    pdf.setFontSize(10);
    pdf.text("Audio Name", 10, 30);
    pdf.text("Duration", 10, 45);
    pdf.text("Date", 10, 60);

    pdf.setTextColor(colors.brand);
    pdf.setFontSize(12);
    pdf.text(name, 40, 30);
    pdf.text(duration, 40, 44);
    pdf.text(new Date().toLocaleDateString(), 40, 59);

    pdf.autoTable({
      head: [["Start", "End", "Notes"]],
      body: regions.map(({ start, end, notes }) => [start, end, notes]),
      headStyles: {
        fillColor: colors.brand,
        minCellHeight: 12,
        valign: "middle",
      },
      bodyStyles: { minCellHeight: 12, valign: "middle" },
      margin: { top: 75, bottom: 10, right: 10, left: 10 },
    });

    docRef.current = pdf;
    const src = pdf.output("bloburl");
    setIframeSrc(src);
  }, []);

  useEffect(() => {
    getNotesPdfSrc({ name, duration, regions });
  }, [name, duration, regions, getNotesPdfSrc]);

  const getPDFName = (audioName) => {
    if (!audioName) {
      return "Sample.pdf";
    }

    const splitted = audioName.split(".");
    const fileName = splitted.splice(0, splitted.length - 1).join(".");
    return `${fileName}.pdf`;
  };

  const handleDownload = () => {
    if (!docRef.current) return;

    const pdfName = getPDFName(name);
    docRef.current.save(pdfName);
  };

  return (
    <Modal
      isOpen={isVisible}
      style={customStyles}
      contentLabel="Audio Pdf Modal"
      ariaHideApp={false}
      onRequestClose={handleCancel}
    >
      <ModalContent>
        <AudioNotesPDF iframeSrc={iframeSrc} />
        <div className="buttons">
          <Button
            {...buttonProps}
            color={colors.brand1}
            border={`1px solid ${colors.brand1}`}
            background="transparent"
            boxShadow="none"
            onClick={handleCancel}
            margin="auto 10px"
          >
            Cancel
          </Button>
          <Button
            {...buttonProps}
            boxShadow="none"
            onClick={handleDownload}
            margin="auto 10px"
          >
            Download
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default PDFView;
