import axios from "axios";

import keys from "../config/keys";
import BrokenImage from "../assets/img/broken-image.png";
import ProfilePlaceholder from "../assets/img/profile_placeholder.jpg";

import { removeRequest } from "./request.actions";

import {
  SET_CASE_LIST,
  SET_USER_LIST,
  FILTER_USER_LIST,
  SET_SELECTED_CASE,
  SET_SELECTED_USERS,
  ADD_SELECTED_USER,
  REMOVE_SELECTED_USER,
  SELECT_ALL,
  SET_PROGRESS,
  SET_RESULT,
  SET_LOADER,
  REMOVE_LOADER,
  LOAD_MORE_SELECTED_USERS_TO_SHOW
} from "./types";
import { warningNotification, errorNotification } from "./index.js";

let currentColorIndex = 0;

const { SERVER_URL, resultDimensions } = keys;

export const addCaseList = cases => ({
  type: SET_CASE_LIST,
  payload: cases
});

export const addUserList = users => ({
  type: SET_USER_LIST,
  payload: users
});

export const setSelectedCase = data => ({
  type: SET_SELECTED_CASE,
  payload: data
});

export const setSelectedUsers = users => ({
  type: SET_SELECTED_USERS,
  payload: users
});

export const addSelectedUsers = user => ({
  type: ADD_SELECTED_USER,
  payload: user
});

export const removeSelectedUser = user => ({
  type: REMOVE_SELECTED_USER,
  payload: user
});

export const selectAll = users => ({
  type: SELECT_ALL,
  payload: users
});

export const shapeUsersList = (profiles, orgName) => {
  let modifiedList = [];
  profiles.map(user => {
    const name = `${user.firstname} ${user.lastname}`;
    const gender = user.gender;
    const id = user._id;
    let imgUrl = ProfilePlaceholder;

    if (user.gallery) {
      if (user.gallery[0]) {
        imgUrl = `${SERVER_URL}/images/${orgName}/wusers/${id}/${user.gallery[0].name}`;
      }
    }
    const userObj = {
      id: user._id,
      name,
      gender,
      imgUrl,
      gallery: user.gallery ? user.gallery : null
    };
    return modifiedList.push(userObj);
  });
  return modifiedList;
};

export const fetchUsers = () => async dispatch => {
  try {
    dispatch({
      type: SET_LOADER,
      payload: "Fetching Users..."
    });
    const res = await axios.get(`${SERVER_URL}/wusers`, {
      requestId: "fetchUsers"
    });

    if (res) {
      if (res.data.profiles.length === 0) {
        dispatch(
          warningNotification({
            title: "No users found!",
            autoDismiss: 1
          })
        );
      }
      const userList = shapeUsersList(res.data.profiles, res.data.orgName);
      dispatch({
        type: SET_USER_LIST,
        payload: userList
      });
    } else {
      dispatch(
        errorNotification({
          title: "Failed to fetch users!",
          autoDismiss: 1
        })
      );
      dispatch({
        type: SET_USER_LIST,
        payload: null
      });
      return false;
    }
  } catch (e) {
    dispatch(removeRequest("fetchUsers"));
    if (axios.isCancel(e)) {
      return true;
    } else {
      console.log(e);
      dispatch(
        errorNotification({
          title: "Failed to fetch users!",
          autoDismiss: 1
        })
      );
      dispatch({
        type: SET_USER_LIST,
        payload: null
      });
      return false;
    }
  }
};

/* Filter user list for select user select field in Search Case */
export const filterUsers = userName => dispatch => {
  axios.get(`${SERVER_URL}/wusers/search?searchterm=${userName}`).then(res => {
    if (res.data.doc.length === 0) {
      dispatch(
        warningNotification({
          title: "No users found!",
          autoDismiss: 1
        })
      );
    }
    const userList = shapeUsersList(res.data.doc, res.data.orgName);
    dispatch({
      type: FILTER_USER_LIST,
      payload: userList
    });
  });
};

export const fetchCases = args => async dispatch => {
  const loader = args ? args.loader : true;
  try {
    if (loader) {
      dispatch({
        type: SET_LOADER,
        payload: "Fetching Cases..."
      });
    }
    const res = await axios.get(`${SERVER_URL}/cases`, {
      requestId: "fetchCases"
    });

    const caseList = [];
    if (res.data.success) {
      const orgName = res.data.orgName;
      if (res.data.cases.length === 0) {
        dispatch(
          warningNotification({
            title: "No cases found!",
            autoDismiss: 1
          })
        );
      }
      res.data.cases.map(caseObj => {
        let imgUrl = BrokenImage;
        if (caseObj.media) {
          if (caseObj.media[0]) {
            imgUrl = `${SERVER_URL}/images/${orgName}/cases/${caseObj._id}/${caseObj.media[0].name}`;
          }
        }

        if (caseObj.search) {
          dispatch({
            type: SET_PROGRESS,
            payload: { caseId: caseObj._id, progress: caseObj.search.progress }
          });
        }

        return caseList.push({
          id: caseObj._id,
          name: caseObj.name,
          notes: caseObj.notes,
          imageUrl: imgUrl,
          search: caseObj.search,
          status: "completed",
          completion: "89.5%",
          createdAt: caseObj.createdAt
            ? new Date(caseObj.createdAt.toString()).toGMTString().slice(0, 16)
            : "NIL"
        });
      });

      dispatch({
        type: SET_CASE_LIST,
        payload: caseList
      });

      return caseList;
    } else {
      dispatch(
        errorNotification({
          title: "Failed to fetch cases!",
          autoDismiss: 1
        })
      );
      dispatch({
        type: SET_CASE_LIST,
        payload: null
      });
      return false;
    }
  } catch (e) {
    dispatch(removeRequest("fetchCases"));
    if (axios.isCancel(e)) {
      return true;
    } else {
      console.log(e);
      dispatch(
        errorNotification({
          title: "Failed to fetch cases!",
          autoDismiss: 1
        })
      );
      dispatch({
        type: SET_CASE_LIST,
        payload: null
      });

      return false;
    }
  }
};

export const submitSearch = (caseObj, users, selectedAll) => async dispatch => {
  try {
    dispatch({
      type: SET_LOADER,
      payload: "Searching..."
    });
    const caseId = caseObj.id;
    const galleryIds = [];

    users.map(user => {
      if (user.gallery && user.gallery[0]) {
        return galleryIds.push(user.gallery[0]._id);
      }
      return null;
    });

    const res = await axios.post(
      `${SERVER_URL}/searches`,
      {
        case: caseId,
        gallery: galleryIds,
        selectedAll: selectedAll
      },
      { requestId: "submitSearch" }
    );
    dispatch({
      type: REMOVE_LOADER
    });
    if (res.data) {
      return {
        jobID: res.data.jobID,
        searchID: res.data.searchID,
        initialCall: true
      };
    } else {
      return false;
    }
  } catch (e) {
    dispatch({ type: REMOVE_LOADER });
    dispatch(removeRequest("submitSearch"));
    if (axios.isCancel(e)) {
      return { initialCall: false };
    } else {
      console.log(e);
      return false;
    }
  }
};

export const getSearchProgress = searchID => async () => {
  try {
    const res = await axios.get(`${SERVER_URL}/searches/${searchID}`);
    return res.data;
  } catch (e) {
    return null;
  }
};

export const setProgress = (caseId, progress) => ({
  type: SET_PROGRESS,
  payload: { caseId, progress }
});

function getRandomColor() {
  const colors = ["#E4584B", "#2086dc", "#38b14d", "#F1A527"];

  const color = colors[currentColorIndex % colors.length];
  currentColorIndex++;
  return color;
}

const getScaleRatio = (displayHeight, probeWidth, probeHeight) => {
  //Calculating scale ratio
  let scaleRatio = displayHeight / probeHeight;

  let resultHeight = displayHeight;
  let resultWidth = (probeWidth / probeHeight) * displayHeight;

  if (resultWidth > resultDimensions.width) {
    return getScaleRatio(displayHeight - 50, probeWidth, probeHeight);
  } else {
    return { scaleRatio, resultHeight, resultWidth };
  }
};

const handleImageData = async (orgName, ResultJson) => {
  let metadata = { ...ResultJson.media };
  const cases = Object.keys(metadata);
  const newMetadata = {};
  for (let caseName of cases) {
    const caseObj = metadata[caseName];
    const { probeHeight, probeWidth } = caseObj;

    const { scaleRatio, resultHeight, resultWidth } = await getScaleRatio(
      resultDimensions.height,
      probeWidth,
      probeHeight
    );

    caseObj.resultHeight = resultHeight;
    caseObj.resultWidth = resultWidth;

    for (let face of caseObj.faces) {
      face.name = "Suspect";

      const x1 = face.bbox[0] * scaleRatio;
      const y1 = face.bbox[1] * scaleRatio;
      const x2 = face.bbox[2] * scaleRatio;
      const y2 = face.bbox[3] * scaleRatio;
      face.bbox = [y1, x1, x2 - x1, y2 - y1];

      face.thumbnail = `${SERVER_URL}/images/${orgName}/${face.thumbnail}`;

      face.color = getRandomColor();
      for (let match of face.matches) {
        const wuserId = match.id.split("/")[2];
        match.wuserId = wuserId;
        match.id = `${SERVER_URL}/images/${orgName}/${match.id}`;
      }
    }
    const caseNameWithExt = `${SERVER_URL}/images/${orgName}/cases/${caseObj.probeName}`;
    newMetadata[caseNameWithExt] = caseObj;
  }
  return newMetadata;
};

const handleVideoData = (orgName, ResultJson) => {
  const metadata = { ...ResultJson };
  const newMetadata = {
    duration: ResultJson.duration,
    fps: ResultJson.fps,
    height: ResultJson.height,
    width: ResultJson.width,
    timeline: {},
    detections: ResultJson.detections
  };

  const timelineKeys = Object.keys(metadata.timeline);

  for (let timelineKey of timelineKeys) {
    for (let match of metadata.timeline[timelineKey].matches) {
      const wuserId = match.galleryImage.split("/")[2];
      match.wuserId = wuserId;
      match.galleryImage = `${SERVER_URL}/images/${orgName}/${match.galleryImage}`;
    }

    const timelineKeyWithExt = `${SERVER_URL}/images/${orgName}/cases/${timelineKey}`;
    newMetadata.timeline[timelineKeyWithExt] = metadata.timeline[timelineKey];
  }

  return newMetadata;
};

export const getSearchResult = searchId => async dispatch => {
  try {
    const res = await axios.get(`${SERVER_URL}/searches/${searchId}/results`, {
      requestId: "getSearchResult"
    });
    const orgName = res.data.orgName;
    const ResultJson = res.data.resultJSONObj;

    if (ResultJson.media) {
      const resultData = await handleImageData(orgName, ResultJson);
      dispatch({
        type: SET_RESULT,
        payload: resultData
      });
    } else if (ResultJson.timeline) {
      const resultData = await handleVideoData(orgName, ResultJson);
      await dispatch({
        type: SET_RESULT,
        payload: resultData
      });
    }

    return { success: true };
  } catch (e) {
    dispatch(removeRequest("getSearchResult"));
    if (axios.isCancel(e)) {
      return true;
    } else {
      console.log(e);
      return false;
    }
  }
};

export const getSelectedUsers = searchId => async dispatch => {
  try {
    const res = await axios.get(`${SERVER_URL}/searches/${searchId}/gallery`, {
      requestId: "getSelectedUsers"
    });

    let selectedUsers = [];
    if (res.data.wusers) {
      for (let user of res.data.wusers) {
        const id = user._id;
        const name = `${user.firstname} ${user.lastname}`;
        const gender = user.gender;

        let imgUrl = "#";
        if (user.gallery[0].name) {
          imgUrl = `${SERVER_URL}/images/${res.data.orgName}/wusers/${id}/${user.gallery[0].name}`;
        }

        selectedUsers.push({
          id,
          name,
          gender,
          imgUrl,
          gallery: user.gallery ? user.gallery : null
        });
      }
    }

    let caseName = "";
    if (res.data.caseObj && res.data.caseObj.name) {
      caseName = res.data.caseObj.name;
    }

    return { users: selectedUsers, caseName };
  } catch (e) {
    dispatch(removeRequest("getSelectedUsers"));
    return {};
  }
};

export const setResult = result => ({
  type: SET_RESULT,
  payload: result
});

export const loadMoreSelectedUsersToShow = page => dispatch => {
  dispatch({ type: LOAD_MORE_SELECTED_USERS_TO_SHOW, payload: page });
};
