import React, { useState } from "react";

import AudioContext from "./AudioContext";

const AudioProvider = props => {
  const [audioList, setAudioList] = useState([]);
  const [redactedaudioList, setRedactedAudioList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [upPrgs, setUpPrgs] = useState(0);

  return (
    <AudioContext.Provider
      value={{
        audioList,
        redactedaudioList,
        setAudioList,
        setRedactedAudioList,
        uploading,
        setUploading,
        upPrgs,
        setUpPrgs
      }}
    >
      {props.children}
    </AudioContext.Provider>
  );
};

export default AudioProvider;
