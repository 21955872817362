import axios from "axios";

import keys from "../config/keys";

const axiosWithBaseUrl = axios.create({ baseURL: keys.SERVER_URL });
axiosWithBaseUrl.defaults.headers.common = axios.defaults.headers.common;

export const createInvestigation = async (reqData) => {
  const { data } = await axiosWithBaseUrl.post("/investigator/cases", reqData);
  return data;
};

export const updateInvestigation = async ({ caseID, name, notes }) => {
  const { data } = await axiosWithBaseUrl.patch(
    `/investigator/cases/${caseID}`,
    { name, notes }
  );
  return data;
};

export const uploadFilesToInvestigation = async ({
  caseID,
  endpoint,
  formData,
  onProgress,
}) => {
  const { data } = await axiosWithBaseUrl.post(
    `/investigator/cases/${caseID}/${endpoint}`,
    formData,
    {
      onUploadProgress: function ({ loaded, total }) {
        const percentCompleted = Math.round((loaded * 100) / total);
        if (onProgress) {
          onProgress(percentCompleted);
        }
      },
    }
  );
  return data;
};

export const deleteInvestigation = async (caseID) => {
  const { data } = await axiosWithBaseUrl.delete(
    `/investigator/cases/${caseID}`
  );
  return data;
};
