import $ from "jquery";

const getCloseButton = (buttonID) => {
  const closeButton = document.createElement("div");
  closeButton.setAttribute("id", buttonID);
  closeButton.setAttribute("class", "closeButton");
  closeButton.style.float = "right";
  closeButton.style.top = "0";
  closeButton.style.cursor = "pointer";
  closeButton.style.background =
    "linear-gradient(-45deg, transparent 0%, transparent 46%, white 46%,  white 56%,transparent 56%, transparent 100%),linear-gradient(45deg, transparent 0%, transparent 46%, white 46%,  white 56%,transparent 56%, transparent 100%)";
  closeButton.style.width = "20px";
  closeButton.style.height = "20px";
  closeButton.style.borderWidth = "2px";
  closeButton.style.borderStyle = "solid";
  closeButton.style.borderRadius = "100%";
  closeButton.style.backgroundColor = "red";
  closeButton.style.boxShadow = "0px 0px 5px 2px rgba(0,0,0,0.5)";
  closeButton.style.transition = "all 0.3s ease";
  closeButton.style.display = "none";
  closeButton.style.boxSizing = "border-box";
  closeButton.style.borderColor = "red";
  closeButton.style.zIndex = "4";

  return closeButton;
};

export const regionCreatedListener = (wavesurfer) => {
  wavesurfer.on("region-created", (region) => {
    $(".wavesurfer-handle").css({ "background-color": "transparent" });
    $(".wavesurfer-handle-end").css({ height: "80%", top: "20%" });
    $(".wavesurfer-region").css({ zIndex: 3 });

    region.on("click", (e) => {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
    });

    const regionElement = region.element;
    regionElement.appendChild(getCloseButton(region.id));
  });
};

export const regionMouseEnterListener = (wavesurfer, handleDelete) => {
  wavesurfer.on("region-mouseenter", (e) => {
    $(`[id=${e.id}]`)
      .css("display", "block")
      .click(() => {
        handleDelete(e.id);
      });
  });
};

export const regionMouseLeaveListener = (wavesurfer) => {
  wavesurfer.on("region-mouseleave", (e) => {
    $(`[id=${e.id}]`).css("display", "none");
  });
};

export const regionUpdateEndListener = (wavesurfer, mergeRegions) => {
  wavesurfer.on("region-update-end", mergeRegions);
};

export const keyBoardListeners = (e, wavesurfer, setSpeed) => {
  e.preventDefault();

  const c = String.fromCharCode(e.which);
  switch (c) {
    // Skip backward for 5sec
    case "a": {
      wavesurfer.skipBackward(5);

      break;
    }

    // Skip backward for 1sec
    case "s": {
      wavesurfer.skipBackward(1);
      break;
    }

    // Skip backward for 0.1sec
    case "d": {
      wavesurfer.skipBackward(0.1);
      break;
    }

    // Skip forward for 0.1sec
    case "f": {
      wavesurfer.skipForward(0.1);
      break;
    }

    // Skip forward for 1sec
    case "g": {
      wavesurfer.skipForward(1);
      break;
    }

    // Skip forward for 5sec
    case "h": {
      wavesurfer.skipForward(5);
      break;
    }

    // Set the playbackrate to 0.25
    case "1": {
      wavesurfer.setPlaybackRate(0.25);
      setSpeed("0.25");
      break;
    }

    // Set the playbackrate to 0.5
    case "2": {
      wavesurfer.setPlaybackRate(0.5);
      setSpeed("0.5");
      break;
    }

    // Set the playbackrate to 1.0
    case "3": {
      wavesurfer.setPlaybackRate(1.0);
      setSpeed("1.0");
      break;
    }

    // Set the playbackrate to 2.0
    case "4": {
      wavesurfer.setPlaybackRate(2.0);
      setSpeed("2.0");
      break;
    }

    // Set the playbackrate to 4.0
    case "5": {
      wavesurfer.setPlaybackRate(4.0);
      setSpeed("4.0");
      break;
    }

    default: {
      break;
    }
  }
};
