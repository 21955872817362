import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors } from "../themes/base";

const SidebarWrapper = styled.div`
  height: 100vh;
  width: ${(props) => `${props.width}rem`};
  background: ${(props) => props.background};
  box-shadow: ${(props) => props.sbBoxShadow};

  ul {
    margin: 0;
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
`;

const Sidebar = (props) => {
  return (
    <SidebarWrapper {...props}>
      <ul>{props.children}</ul>
    </SidebarWrapper>
  );
};

export default Sidebar;

Sidebar.propTypes = {
  width: PropTypes.number,
  background: PropTypes.string,
  children: PropTypes.node.isRequired,
  sbBoxShadow: PropTypes.string,
};

Sidebar.defaultProps = {
  width: 10,
  background: colors.brand1,
  sbBoxShadow: "0 2px 10px 0 rgba(44, 137, 255, 0.06)",
};
