import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";

import { colors, icons } from "../themes/base";

import Button from "../common/Button";
import SubProperty from "../common/SubProperty";
import ToolbarInput from "../common/ToolbarInput";
import ToolsHeaderText from "../common/ToolsHeaderText";
import ToolbarDropdown from "../common/ToolbarDropdown";
import ToolbarTimeInput from "../common/ToolbarTimeInput";
import ToolbarPropDivider from "../common/ToolbarPropDivider";

const StyledWrapper = styled.div`
  padding: ${(props) => props.padding || "0 10px"};
  margin: 1rem 0;
  border-left: ${(props) => props.borderLeft};

  .light-text {
    opacity: 0.5;
    color: ${colors.secondary};
    font-family: Muli;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    text-transform: capitalize;
  }

  .text-container {
    height: 37px;
    display: flex;
    font-size: 13px;
    color: ${colors.secondary};
    font-family: Muli;
    letter-spacing: 0;
    line-height: 15px;
    text-transform: capitalize;
    justify-content: space-between;
  }

  .header-container {
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;

    input {
      background: ${colors.white};
      border: 1px solid transparent;
      color: ${colors.s_light};
      font-family: Muli;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 15px;

      :focus {
        outline: 0;
        border: 1px solid ${colors.s_extralight};
      }
    }
  }

  .buttons-tick-cancel {
    display: flex;
  }

  .error-msg {
    color: red;
    margin: 1rem;
    font-weight: 600;
  }
`;

const RegionsContainer = styled.div`
  max-height: ${(props) => (props.fullScreenMode ? "63%" : "54%")};
  overflow-y: scroll;
`;

const toSec = (timeFormat) => {
  return moment.duration(timeFormat).asSeconds();
};

const getBorderColor = (start, end, duration) => {
  const s = toSec(start);
  const e = toSec(end);
  const d = toSec(duration);

  if (s > d || e > d || s > e) {
    return "6px solid red";
  }

  return `6px solid ${colors.text_tools.border}`;
};

const RenderErrorMessage = ({ start, end, duration }) => {
  const s = toSec(start);
  const e = toSec(end);
  const d = toSec(duration);

  if (s > d) {
    return <p className="error-msg">Start time greater than video duration</p>;
  }

  if (e > d) {
    return <p className="error-msg">End time greater than video duration</p>;
  }

  if (s > e) {
    return <p className="error-msg">Start time greater than end time</p>;
  }

  return <p></p>;
};

function VideoProperty(props) {
  const [showAddModel, setShowAdd] = useState(false);
  const [start, setStart] = useState("00:00:00.0");
  const [end, setEnd] = useState(props.duration);

  useEffect(() => {
    setEnd(props.duration);
  }, [props.duration, props.resolution]);

  const handleDataChange = (id, property, value) => {
    if (!id) {
      return;
    }

    props.handleVideoDataChange(id, {
      [property]: value,
    });
  };

  const initiateState = () => {
    setShowAdd(false);
    setStart("00:00:00.0");
    setEnd(props.duration);
  };

  const handleTick = () => {
    props.handleAdd(start, end);
    initiateState();
  };

  return (
    <React.Fragment>
      <StyledWrapper>
        <ToolsHeaderText>Project</ToolsHeaderText>
        <ToolbarPropDivider />

        <SubProperty>
          <p className="light-text">Resolution</p>
          <ToolbarInput
            disabled
            value={props.resolution}
            onChange={props.setResolution}
          />
        </SubProperty>
        <ToolbarPropDivider />
        <SubProperty>
          <p className="light-text">Duration</p>
          <ToolbarInput
            disabled
            value={props.duration}
            onChange={props.setDuration}
          />
        </SubProperty>
        <ToolbarPropDivider />
        <SubProperty>
          <ToolbarDropdown
            width="100%"
            fontSize="13px"
            fontWeight={600}
            value={props.cropType}
            options={[
              { label: "Merge", value: "merge" },
              { label: "Delete", value: "delete" },
              { label: "Split", value: "split" },
            ]}
            handleChange={props.setCropType}
          />
        </SubProperty>
        <ToolbarPropDivider />
      </StyledWrapper>

      <RegionsContainer fullScreenMode={props.fullScreenMode}>
        {props.regions.map((rgn) => (
          <StyledWrapper
            key={rgn.id}
            padding="0"
            borderLeft={getBorderColor(rgn.start, rgn.end, props.duration)}
          >
            <SubProperty>
              <div className="header-container">
                <input disabled value={rgn.name} />
                <div>
                  <Button
                    padding="7px"
                    background={`url(${icons.IC_Delete}) center center / 16px 16px no-repeat`}
                    onClick={() => props.handleDelete(rgn.id)}
                  />
                </div>
              </div>
            </SubProperty>
            <SubProperty>
              <ToolbarTimeInput
                duration={props.duration}
                label="Start"
                value={rgn.start}
                onChange={(value) => handleDataChange(rgn.id, "start", value)}
              />
            </SubProperty>
            <SubProperty>
              <ToolbarTimeInput
                duration={props.duration}
                label="End"
                value={rgn.end}
                onChange={(value) => handleDataChange(rgn.id, "end", value)}
              />
            </SubProperty>
            <RenderErrorMessage
              start={rgn.start}
              end={rgn.end}
              duration={props.duration}
            />
            <SubProperty justifyContent="flex-start" alignItems="center">
              <input
                type="checkbox"
                checked={rgn.mute}
                onChange={() => handleDataChange(rgn.id, "mute", !rgn.mute)}
              />
              <label>Mute</label>
            </SubProperty>
          </StyledWrapper>
        ))}

        {showAddModel && (
          <StyledWrapper
            borderLeft={getBorderColor(start, end, props.duration)}
          >
            <SubProperty>
              <div className="header-container">
                <input disabled value={`Region ${props.regions.length + 1}`} />
                <div className="buttons-tick-cancel">
                  <Button
                    padding="7px"
                    margin="0 10px"
                    background={`url(${icons.IC_Cancel}) center center / 20px 20px no-repeat`}
                    onClick={initiateState}
                  />
                  <Button
                    padding="7px"
                    background={`url(${icons.IC_Tick}) center center / 20px 20px no-repeat`}
                    onClick={handleTick}
                  />
                </div>
              </div>
            </SubProperty>
            <SubProperty>
              <ToolbarTimeInput
                duration={props.duration}
                label="Start"
                value={start}
                onChange={setStart}
              />
            </SubProperty>
            <SubProperty>
              <ToolbarTimeInput
                duration={props.duration}
                label="End"
                value={end}
                onChange={setEnd}
              />
            </SubProperty>
            <RenderErrorMessage
              start={start}
              end={end}
              duration={props.duration}
            />
          </StyledWrapper>
        )}

        <StyledWrapper>
          <SubProperty>
            <Button
              fontSize="10px"
              fontWeight="600"
              color="#142945"
              padding="14px 5px 10px 30px"
              background={`url(${icons.IC_Add_Layer}) left center / 23px 23px no-repeat`}
              onClick={() => setShowAdd(true)}
            >
              Add New Region
            </Button>
          </SubProperty>
        </StyledWrapper>
      </RegionsContainer>
    </React.Fragment>
  );
}

export default VideoProperty;

VideoProperty.propTypes = {
  bgColor: PropTypes.string,
  videoFit: PropTypes.string,
  resolution: PropTypes.string,
  duration: PropTypes.string,
  bgOpacity: PropTypes.number,
  cropType: PropTypes.string,
  setCropType: PropTypes.func,
  setBgColor: PropTypes.func,
  setVideoFit: PropTypes.func,
  setResolution: PropTypes.func,
  setDuration: PropTypes.func,
  setBgOpacity: PropTypes.func,
};

VideoProperty.defaultProps = {
  bgColor: colors.black,
  videoFit: "Fill Frame",
  resolution: "120x720",
  duration: "04:33:0",
  bgOpacity: 1,
  cropType: "merge",
  setCropType: () => {},
  setBgColor: () => {},
  setVideoFit: () => {},
  setResolution: () => {},
  setDuration: () => {},
  setBgOpacity: () => {},
};
