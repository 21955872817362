import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Button from "../common/Button";
import { colors, icons } from "../themes/base";

import ToolbarPropDivider from "../common/ToolbarPropDivider";
import PDFViewModal from "../Modal/PDFView";

const circleColors = ["#24c875", "#EB5858", "#20A6FC"];

const Timeline = styled.div`
  position: relative;
  margin: 0 auto;
  min-height: 666px;
  height: ${(props) => (props.fullScreenMode ? "86%" : "666px")};
  overflow-y: scroll;
  overflow-x: hidden;

  &::after {
    content: "";
    position: absolute;
    width: 4px;
    opacity: 0.54;
    background-color: #e0e0e0;
    top: 16px;
    bottom: 0;
    left: 10px;
    margin-left: -3px;
  }

  .contentitems {
    padding: 0px 10px;
    background-color: white;
    position: relative;
    border-radius: 6px;

    .comment-heading {
      display: flex;
      justify-content: space-between;
    }

    .screenshots-container {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
    }

    h4 {
      height: 15px;
      width: 50px;
      margin: 13px 0 8px 0;
      color: rgba(20, 41, 69, 0.4);
      font-family: Muli;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 15px;
    }

    textarea {
      height: 70px;
      width: 100%;
      margin: 0;
      color: ${colors.secondary};
      font-family: Muli;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 16px;
      border: 0;
      resize: none;
      border-radius: 3px;
      background: rgba(20, 41, 69, 0.03);
      padding: 1rem;

      &:focus {
        outline: 0;
      }
    }
  }
`;

const TimelineCircle = styled.div`
  padding: 2px 1rem;
  position: relative;
  background-color: inherit;
  left: 12px;

  ::after {
    content: "";
    position: absolute;
    width: 13px;
    height: 13px;
    background-color: white;
    border: 2px solid ${(props) => props.circleColor || "#24c875"};
    top: 15px;
    border-radius: 50%;
    z-index: 1;
    left: -11px;
  }
`;

const StyledWrapper = styled.div`
  padding: 2rem 2rem;
  justify-content: center;
  min-width: 350px;
  height: ${(props) => (props.fullScreenMode ? "96vh" : "auto")};
  width: 23%;
  background-color: ${colors.white};
  box-shadow: 0 2px 10px 0 ${colors.shadow};
  border-radius: 3px;

  .button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 10px 0;
  }

  .options-container {
    display: flex;
    justify-content: flex-end;
    padding: 15px 0;

    .printer-section {
      display: flex;
    }
  }

  .bottom-buttons {
    display: flex;
    margin: 2rem 2rem 2rem 7rem;
    justify-content: space-between;
  }
`;

const ScreenShot = styled.div`
  height: 34px;
  width: 34px;
  border: 1px solid white;
  border-radius: 3px;
  background: url("${(props) => props.url}");
  background-size: 34px 34px;
  background-repeat: no-repeat;
`;

function RenderScreenshots(props) {
  return props.screenshots.map((scrnshot) => {
    return <ScreenShot key={scrnshot.id} url={scrnshot.scrnShotb64} />;
  });
}

function Report(props) {
  const [showModal, setModal] = React.useState(false);

  const handleCancel = () => {
    setModal(false);
  };

  const handleAddComment = () => {
    const video = document.getElementById("ff-video");
    props.addComment(video.currentTime);
  };

  const handleDelete = (id) => {
    props.deleteComment(id);
  };
  return (
    <StyledWrapper fullScreenMode={props.fullScreenMode}>
      <PDFViewModal
        videoName={props.videoName}
        isVisible={showModal}
        comments={props.comments}
        handleCancel={handleCancel}
        caseID={props.caseID}
      />
      <div className="button-container">
        <div style={{ display: "flex" }}>
          <Button
            height="34px"
            borderRadius="19px"
            margin="0"
            color={colors.secondary}
            background="rgba(20, 41, 69,0.03)"
            onClick={() => props.setTool("video")}
          >
            Editing Tool
          </Button>
          <Button margin="0 15px" height="34px" borderRadius="19px">
            Report
          </Button>
        </div>
        <div style={{ display: "flex" }}>
          <Button
            title="Add Comment"
            onClick={handleAddComment}
            background={`url(${icons.IC_Add_Comment}) center center / 16px 16px no-repeat`}
          />
          {/* <Button
            title="Generate PDF"
            onClick={() => setModal(true)}
            background={`url(${icons.IC_Printer}) center center / 16px 14px no-repeat`}
          /> */}
        </div>
      </div>

      <ToolbarPropDivider
        margin="auto"
        border={`0.1px solid rgba(151, 151, 151, 0.2)`}
      />

      <Timeline id="report-timeline" fullScreenMode={props.fullScreenMode}>
        {props.comments.map((comment, i) => (
          <TimelineCircle key={comment.id} circleColor={circleColors[i % 3]}>
            <div className="contentitems">
              <div className="comment-heading">
                <h4>{comment.time}</h4>
                <Button
                  title="Delete"
                  opacity={0.54}
                  onClick={() => handleDelete(comment.id)}
                  background={`url(${icons.IC_Bin}) center center / 12px 12px no-repeat`}
                />
              </div>
              <div className="screenshots-container">
                <RenderScreenshots screenshots={comment.screenshots} />
                <Button
                  height="34px"
                  width="34px"
                  title="Take Screenshot"
                  border="1px solid white"
                  backgroundColor="#F8F8F9 !important"
                  background={`url(${icons.IC_Plus}) center center / 22px 22px no-repeat`}
                  onClick={() => props.setCmtID(comment.id)}
                />
              </div>
              <textarea
                value={comment.notes}
                onChange={(e) =>
                  props.handleCommentChange(comment.id, {
                    notes: e.target.value,
                  })
                }
              />
            </div>
          </TimelineCircle>
        ))}
      </Timeline>

      <div className="bottom-buttons">
        <Button
          height="54px"
          fontSize="16px"
          fontWeight="600"
          color={colors.tertiary}
          background="transparent"
          padding="0"
        >
          Cancel
        </Button>
        <Button
          width="140px"
          fontSize="16px"
          fontWeight="600"
          textTransform="uppercase"
        >
          Apply
        </Button>
      </div>
    </StyledWrapper>
  );
}

export default Report;

PropTypes.propTypes = {};

PropTypes.defaultProps = {};
