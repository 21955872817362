import { wuserConstants } from "../constants";

const INITIAL_STATE = {
  loading: false,
  pageCount: 0,
  usersOnPage: [],
  query: "",
  filteredUsers: [],
  filteredUsersOnPage: [],
  filteredUsersPageCount: 0,
};

const USERS_PER_PAGE = 40;

export default function wusersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case wuserConstants.FETCH_WUSERS:
      //Construct the image urls
      const wusers = JSON.parse(JSON.stringify(action.payload.profiles));

      const pageCount = Math.ceil(
        action.payload.profilesCount / USERS_PER_PAGE
      );

      return {
        ...state,
        loading: false,
        pageCount,
        usersOnPage: wusers,
      };
    case wuserConstants.SET_USERS_ON_PAGE:
      return { ...state, usersOnPage: action.payload, loading: false };
    case wuserConstants.QUERY_USER:
      const users = action.payload.users;
      const filteredUsersPageCount = Math.ceil(users.length / USERS_PER_PAGE);
      const paginatedUsers = users.slice(0, USERS_PER_PAGE);
      return {
        ...state,
        filteredUsers: action.payload.users,
        filteredUsersPageCount,
        filteredUsersOnPage: paginatedUsers,
        query: action.payload.query,
        loading: false,
      };
    case wuserConstants.SET_FILTERED_USERS_ON_PAGE:
      const start = action.payload * USERS_PER_PAGE;
      const end = action.payload * USERS_PER_PAGE + USERS_PER_PAGE;
      const filteredUsers = state.filteredUsers.slice(start, end);
      return { ...state, filteredUsersOnPage: filteredUsers };
    case wuserConstants.FETCH_WUSERS_ERROR:
      return {
        ...state,
        users: null,
        loading: false,
      };
    case wuserConstants.SET_WUSERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
