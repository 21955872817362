import React, { useState } from "react";
import Dropdown, { MenuItem } from "../SelectDropdown/Dropdown";
import PropTypes from "prop-types";

import styled from "styled-components";
import selected_ic from "../../../assets/img/selected_ic.png";

const CustomDropdown = styled("div")`
  position: relative;
  border-radius: 3px;
  button {
    border-color: #edeff2 !important;
  }
`;

CustomDropdown.propTypes = Dropdown.propTypes;
CustomDropdown.defaultProps = Dropdown.defaultProps;

const CustomDropdownToggle = styled("div")`
  height: 4.8rem;
  width: 100%;
  border: 1px solid #edeff2;
  border-radius: 3px;
  color: #142945;
  font-family: "Muli", sans-serif;
  font-size: 1.6rem;
  line-height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

CustomDropdownToggle.propTypes = Dropdown.Toggle.propTypes;
CustomDropdownToggle.defaultProps = Dropdown.Toggle.defaultProps;

const CustomDropdownMenu = styled("div")`
  border-radius: 3px !important;
  width: 100%;
  max-height: 30rem;
  overflow-y: auto;
  box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 30%);
  padding: 0 !important;
  margin: 0;
  position: absolute;
  z-index: 5;
  background-color: white;
  border: none;
`;

CustomDropdownMenu.propTypes = Dropdown.Menu.propTypes;
CustomDropdownMenu.defaultProps = Dropdown.Menu.defaultProps;

const Customhr = styled.hr`
  padding: 0;
  border: none;
  margin: 0px;
  height: 1px;
  background-color: #edeff2;
  display: block;
  width: calc(100% - 20px);
  margin: 0 10px;
`;

const CustomMenuItem = styled("div")`
  height: 4.8rem;
  width: 100%;
  color: #223539;
  font-family: "Muli", sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.8rem;
  padding: 0 10px;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    background-color: #edeff2;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
  }
`;

CustomMenuItem.propTypes = MenuItem.propTypes;
CustomMenuItem.defaultProps = MenuItem.defaultProps;

const CustomSelectField = (props) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen((status) => !status);
  };

  const onItemSelect = (option) => {
    props.onCaseSelect(option);
    toggleOpen();
  };

  return (
    <CustomDropdown disabled={props.disabled}>
      <CustomDropdownToggle onClick={toggleOpen}>
        {props.selectedCase.id ? props.selectedCase.name : "-Select-"}
        <span style={{ float: "right", marginLeft: "5px" }}>&#9662;</span>
      </CustomDropdownToggle>
      {open && (
        <CustomDropdownMenu>
          {props.options.map((option) => (
            <React.Fragment key={"fragment" + option.id}>
              <CustomMenuItem
                key={option.id}
                onClick={() => onItemSelect(option)}
              >
                <div key={"parent-id" + option.id}>
                  {option.name}
                  {props.selectedCase.id === option.id ? (
                    <img
                      key={"img" + option.id}
                      src={selected_ic}
                      alt="selected"
                    />
                  ) : null}
                </div>
              </CustomMenuItem>
              <Customhr />
            </React.Fragment>
          ))}
        </CustomDropdownMenu>
      )}
    </CustomDropdown>
  );
};

export default CustomSelectField;

CustomSelectField.propTypes = {
  oncaseSelect: PropTypes.func,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  selectedCase: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};
