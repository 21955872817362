import React from "react";
import styled from "styled-components";
import { Range, getTrackBackground } from "react-range";

import {
  IoIosPlay,
  IoIosPause,
  IoIosFastforward,
  IoMdVolumeHigh,
  IoMdVolumeOff,
  IoMdExpand
} from "react-icons/io";

const StyledControls = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 21.5rem;

  .play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);
    cursor: pointer;

    svg {
      fill: white;
      transform: translateX(2px);
      width: 3rem !important;
      height: 3rem !important;
      cursor: pointer;
    }
  }

  .duration {
    position: absolute;
    bottom: 2rem;
    right: 1rem;
    padding: 2px 5px;
    border: 1px solid white;
    border-radius: 10px;
    color: white;
  }

  .player-controls {
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    flex-direction: column;
    justify-content: flex-end;
    display: flex;
    padding: 0.8rem;

    .buttons {
      display: flex;
      align-items: center;
      font-size: 2rem;
      color: white;

      svg {
        padding: 0 0.5rem;
        cursor: pointer;
      }

      .time {
        font-size: 1.1rem;
        opacity: 0.6;
        flex: 1;
      }

      .volume-slider {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.5rem;

        svg ~ div {
          display: none !important;
        }

        &:hover svg ~ div {
          display: flex !important;
        }
      }
    }
  }
`;

function formattedTime(time) {
  if (!time) return "0:00";

  let hrs = ~~(time / 3600);
  let mins = ~~((time % 3600) / 60);
  let secs = ~~time % 60;

  let ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
}

const PlaybackSlider = props => {
  let sliderValues = [];
  let duration = "";
  if (props.hasLoaded) {
    sliderValues = props.values;
    duration = props.duration;
  } else {
    // set some initial values while the video has not loaded
    sliderValues = [1, 0.1];
    duration = 10000;
  }

  return (
    <Range
      step={0.1}
      min={0}
      max={duration}
      values={[sliderValues[0]]}
      onChange={props.handleSeekBarChange}
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          style={{
            ...props.style,
            height: "24px",
            display: "flex",
            width: "100%"
          }}
        >
          <div
            ref={props.ref}
            style={{
              height: "3px",
              width: "100%",
              borderRadius: "4px",
              background: getTrackBackground({
                values: sliderValues,
                colors: [
                  "#ff0000",
                  "rgba(255,255,255,0.5)",
                  "rgba(255,255,255,0.2)"
                ],
                min: 0,
                max: duration
              }),
              alignSelf: "center"
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ props }) => <div {...props} />}
    />
  );
};

const VolumeSlider = props => {
  const sliderValues = props.values;
  return (
    <div className="volume-slider">
      {props.isMuted || props.values === 0 ? (
        <IoMdVolumeOff onClick={props.mute} />
      ) : (
        <IoMdVolumeHigh onClick={props.mute} />
      )}
      <Range
        className="slider"
        step={0.1}
        min={0}
        max={1}
        values={sliderValues}
        onChange={props.handleVolumeChange}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "5px",
              display: "flex",
              width: "40px"
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "3px",
                width: "40px",
                background: getTrackBackground({
                  values: sliderValues,
                  colors: ["#ff0000", "#ccc"],
                  min: 0,
                  max: 1
                }),
                alignSelf: "center"
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => <div {...props} />}
      />
    </div>
  );
};

class Controls extends React.Component {
  handleSeekBarChange = values => {
    this.props.setCurrentTime(values[0]);
  };

  handleVolumeChange = values => {
    this.props.setVolume(values[0]);
  };

  render() {
    const { props } = this;
    return (
      <StyledControls>
        {!props.isPlaying &&
        (props.currentTime === 0 || props.currentTime === props.duration) ? (
          <>
            <span className="play-btn" onClick={props.play}>
              <IoIosPlay />
            </span>

            {props.duration ? (
              <span className="duration">{formattedTime(props.duration)}</span>
            ) : null}
          </>
        ) : (
          <div className="player-controls">
            <PlaybackSlider
              duration={props.duration}
              handleSeekBarChange={this.handleSeekBarChange}
              values={[props.currentTime, props.buffered]}
              hasLoaded={props.hasLoaded}
            />

            <div className="buttons">
              {props.isPlaying ? (
                <IoIosPause onClick={props.play} />
              ) : (
                <IoIosPlay onClick={props.play} />
              )}
              <IoIosFastforward />
              <VolumeSlider
                mute={props.mute}
                isMuted={props.isMuted}
                handleVolumeChange={this.handleVolumeChange}
                values={[props.currentVolume]}
              />
              <span className="time">
                {formattedTime(props.currentTime)} {" / "}
                {formattedTime(props.duration)}
              </span>

              <IoMdExpand onClick={props.fullScreen} />
            </div>
          </div>
        )}
      </StyledControls>
    );
  }
}

export default Controls;
