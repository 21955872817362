import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import axios from "axios";
import { withRouter } from "react-router-dom";

import searchIcon from "../../assets/img/search-icon.png";
import CustomSelectField from "../common/Form/CustomSelectField";
import Button from "../common/Button";
import {
  successNotification,
  warningNotification,
  errorNotification,
} from "../../actions";
import { fetchCases } from "../../actions/search.case.actions";

import keys from "../../config/keys";
import { colors } from "../../components/themes/base";

const buttonProps = {
  width: "140px",
  height: "48px",
  fontSize: "1.6rem",
  background: colors.brand,
  boxShadow: "0 1rem 2rem 0 rgba(124, 160, 73, 0.15)",
  color: "#FFFFFF",
};

const Wrapper = styled.div`
  min-height: 73vh;
  max-width: 90%;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(44, 137, 255, 0.06);
  padding: 2rem 10%;
  margin: 0 5rem;
  display: flex;
  flex-direction: column;

  .info {
    opacity: 0.6;
    color: #142945;
    font-family: ${(props) => props.fontFamily};
    font-size: 1.4rem;
    line-height: 2.2rem;
    text-align: center;
    margin-bottom: 3.5rem;
  }

  .selectWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    max-width: 100%;

    .selectedPics {
      margin-top: 0.5rem;
      display: flex;
      flex-wrap: wrap;
      .userPic {
        position: relative;
        width: 6.5rem;
        height: 5rem;
        margin-bottom: 1rem;
        .user {
          margin: 0.8rem;
          top: 0;
          left: 0;
          height: 5rem;
          width: 5rem;
          position: absolute;
          object-fit: cover;
          border-radius: 50%;
        }
        .cancel {
          position: absolute;
          top: 5px;
          right: 5px;
          height: 1.6rem;
          width: auto;
          z-index: 2;
          cursor: pointer;
        }
      }
    }
  }

  .label {
    opacity: 0.5;
    color: #142945;
    font-family: ${(props) => props.fontFamily};
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-bottom: 0.8rem;
  }

  .slider_value {
    opacity: 0.5;
    color: #142945;
    font-family: ${(props) => props.fontFamily};
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  .buttons {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 3px;

    .search-icon {
      display: block;
      width: 5rem;
      height: 5rem;
      opacity: 0.7;
    }
  }
`;

const ProgressWrapper = styled.div`
  text-align: center;

  .loading-text {
    margin-top: 2rem;
    font-size: 1.4rem;
    font-weight: 600;
    color: #142945;
  }

  .search-icon {
    display: block;
    width: 5rem;
    height: 5rem;
    opacity: 0.7;
    transform: translateX(-0.5rem);
  }

  .search-progress {
    margin: 1rem auto;
    position: relative;
    width: 30rem;
    height: 0.7rem;
    background: #eee;
    border-radius: 100px;

    .completed {
      height: 100%;
      border-radius: 100px;
      position: absolute;
      top: 0;
      left: 0;
      background: ${colors.brand};
    }
  }
`;

class Demograph extends React.Component {
  state = {
    selectedCase: {},
    loading: false,
    progress: 30,
    error: null,
  };

  componentDidMount = () => {
    this.props.fetchCases();
  };

  setSelectedCase = (selectedCase) => {
    this.setState({ selectedCase });
  };

  handleStart = async () => {
    if (!this.state.selectedCase || !this.state.selectedCase.id) {
      this.props.warningNotification({
        title: "Please select a case",
      });
      return;
    }

    this.setState({ loading: true });
    try {
      const { data } = await axios.post(`${keys.SERVER_URL}/demograph`, {
        caseID: this.state.selectedCase.id,
      });

      if (!data.id) {
        this.props.errorNotification({
          title: "Failed to get details",
        });
        return;
      }
    } catch (err) {
      this.setState({ loading: false });
      this.props.errorNotification({
        title: "Failed to get details",
      });
    }
  };

  render() {
    const { props, state } = this;
    const caseList = props.caseList === null ? [] : props.caseList;

    return (
      <div>
        <div
          style={{
            color: "#142945",
            fontFamily: "Muli",
            fontSize: "2.2rem",
            fontWeight: "700",
            letterSpacing: "0.18px",
            margin: "2.5rem 5rem",
          }}
        >
          <span style={{ opacity: "0.5" }}>Demographics</span>
          {state.selectedCase &&
            state.selectedCase.name &&
            " > " + state.selectedCase.name}
        </div>
        <Wrapper {...props}>
          <div className="info" />
          <div className="selectWrapper">
            <div className="label">{props.searchCaseLabel}</div>
            <CustomSelectField
              options={caseList}
              disabled={state.loading}
              onCaseSelect={this.setSelectedCase}
              selectedCase={state.selectedCase}
            />
          </div>

          {this.state.loading && (
            <div className="component">
              <img className="search-icon" src={searchIcon} alt="searchIcon" />
              <ProgressWrapper>
                <div className="loading-text">
                  {this.state.progress}% Completed
                </div>
                <div className="search-progress">
                  <div
                    className="completed"
                    style={{
                      width: `${this.state.progress * 0.3}rem`,
                      background: this.state.error ? "red" : colors.brand,
                    }}
                  />
                </div>
                {this.state.error && (
                  <div className="loading-text" style={{ color: "red" }}>
                    Failed to fetch demographics.
                  </div>
                )}
              </ProgressWrapper>
            </div>
          )}
        </Wrapper>
        <div
          className="buttons"
          style={{
            display: "flex",
            backgroundColor: "#fff",
            justifyContent: "flex-end",
            padding: "2rem 5rem",
            position: "sticky",
            bottom: "0",
            zIndex: 10,
          }}
        >
          <Button
            {...buttonProps}
            background="transparent"
            color="#777"
            boxShadow="none"
            border="none"
            onClick={() =>
              props.history.push({
                pathname: `/facesearch/cases`,
              })
            }
            disabled={state.loading}
          >
            Cancel
          </Button>
          <Button
            {...buttonProps}
            disabled={state.loading}
            onClick={this.handleStart}
          >
            Start
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  caseList: state.search.caseList,
});
const DemographWithRouter = withRouter(Demograph);
export default connect(mapStateToProps, {
  fetchCases,
  warningNotification,
  errorNotification,
  successNotification,
})(DemographWithRouter);

DemographWithRouter.propTypes = {
  fontFamily: PropTypes.string,
  onCaseSelect: PropTypes.func,
  caseOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  searchCaseLabel: PropTypes.string.isRequired,
  selectedCase: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

DemographWithRouter.defaultProps = {
  fontFamily: '"Muli", sans-serif',
  searchCaseLabel: "Select Case*",
};
