import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-decoration: none;
    color: ${props => props.fontColor};
    pointer-events: ${props => (props.disabled ? "none" : "auto")};
    div {
      max-width: 100%;
    }
  }
`;

const IconWrapper = styled.div`
  width: 2.8rem;
  padding-bottom: 5px;

  img {
    width: 80%;
  }
`;

const LinkItem = ({ src, label, fontColor, icon, isActive, disabled }) => (
  <Wrapper fontColor={fontColor} isActive={isActive} disabled={disabled}>
    <Link to={src}>
      <IconWrapper>
        <img src={icon} alt={label} />
      </IconWrapper>
      <div>{label}</div>
    </Link>
  </Wrapper>
);

export default LinkItem;

LinkItem.propTypes = {
  src: PropTypes.string,
  label: PropTypes.string,
  fontColor: PropTypes.string
};

LinkItem.defaultProps = {
  fontColor: "#FFFFFF",
  isActive: false
};
