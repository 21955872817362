import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Modal from "react-modal";

import TimeInput from "../AudioComponents/TimeInput";
import Button from "../../components/common/Button";
import { toSec, toHHMMSS } from "../common/utils";
import { warningNotification } from "../Notifcations";
import { colors } from "../themes/base";

const buttonProps = {
  width: "140px",
  height: "48px",
  fontSize: "1.6rem",
  background: colors.brand,
  boxShadow: "0 10px 20px 0 rgba(124, 160, 73, 0.15)",
  color: "#FFFFFF",
};

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 9,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const ModalContent = styled.div`
  box-sizing: border-box;
  width: 50.5rem;
  padding: 2rem;
  color: #142945;
  font-family: Muli;

  .heading {
    text-align: center;
    font-size: 2.4rem;
    font-weight: 600;
    color: ${colors.brand};
  }

  .content {
    .start-end-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 3rem 0 2rem 0;

      .time-container {
        width: 200px;
        font-weight: 700;
        font-size: 15px;
        display: flex;
        justify-content: space-between;
        background: whitesmoke;
        padding: 1rem;
        border-radius: 4px;
      }
    }

    textarea {
      border-radius: 4px;
      width: 96%;
      height: 100px;
      margin: 0 0 2rem 0;
      resize: none;
      font-size: 14px;
      padding: 1rem;
      font-family: Muli;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    padding: 0 6.6rem;
  }
`;

const EditRegionModal = (props) => {
  const { isVisible, handleCancel, handleEdit, duration, id } = props;

  const [start, setStart] = useState(props.start);
  const [end, setEnd] = useState(props.end);
  const [notes, setNotes] = useState(props.notes);

  const avoidPropagation = (e) => {
    e.stopPropagation();
  };

  const handleClick = () => {
    if (toSec(start) >= toSec(end)) {
      warningNotification("Start cannot be greater than end");
      return;
    }

    if (toSec(end) > duration) {
      warningNotification(`End must be less than ${toHHMMSS(duration)}`);
      return;
    }

    handleEdit(id, start, end, notes);
  };

  return (
    <Modal
      isOpen={isVisible}
      onAfterOpen={() => {
        const textArea = document.getElementById("txtarea");
        if (textArea) {
          textArea.addEventListener("keypress", avoidPropagation);
        }
      }}
      onBeforeClose={() => {
        const textArea = document.getElementById("txtarea");
        if (textArea) {
          textArea.removeEventListener("keypress", avoidPropagation);
        }
      }}
      style={customStyles}
      contentLabel="Edit Modal"
      ariaHideApp={false}
      onRequestClose={handleCancel}
    >
      <ModalContent>
        <div className="heading">Edit Region</div>
        <div className="content">
          <div className="start-end-container">
            <div className="time-container">
              <label>Start</label>
              <TimeInput
                id="start_time"
                value={start}
                onChange={setStart}
                duration={toHHMMSS(duration)}
              />
            </div>

            <div className="time-container">
              <label>End</label>
              <TimeInput
                id="end_time"
                value={end}
                duration={toHHMMSS(duration)}
                onChange={setEnd}
              />
            </div>
          </div>

          <textarea
            id="txtarea"
            placeholder="Add notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>
        <div className="buttons">
          <Button
            {...buttonProps}
            boxShadow="none"
            color={colors.brand}
            background="transparent"
            border={`1px solid ${colors.brand}`}
            onClick={handleCancel}
          >
            Cancel
          </Button>

          <Button {...buttonProps} onClick={handleClick}>
            Save
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default EditRegionModal;

EditRegionModal.propTypes = {
  duration: PropTypes.number,
  isVisible: PropTypes.bool,
  handleEdit: PropTypes.func,
  handleCancel: PropTypes.func,
};

EditRegionModal.defaultProps = {
  duration: "00:10:00.00",
  isVisible: false,
  handleEdit: () => {},
  handleCancel: () => {},
};
