import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";

import Image from "../../../containers/common/Image";
import UploadIcon from "../../../assets/img/upload-icon.png";
import CancelIcon from "../../../assets/img/cancel.svg";

const DropzoneWrapper = styled.section`
  font-family: ${(props) => props.fontFamily};
  margin-bottom: 2.2rem;
  .dropzone {
    height: 14rem;
    width: ${(props) => props.width};
    box-shadow: 0 0 1rem 0 rgba(6, 37, 31, 0.04);
    border-radius: 3px;
    border: 1px dashed rgba(20, 41, 69, 0.15);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    outline: 1px solid transparent;

    &:focus {
      outline: 0;
    }

    input {
      box-sizing: border-box;
    }

    .upload-icon {
      width: 100%;
      text-align: center;

      img {
        width: 4.2rem;
      }

      p {
        font-size: 1.4rem;
        opacity: 0.5;
        margin: 1rem 0;
        max-width: 100%;
      }
    }
  }
`;

const Thumbnails = styled.aside`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 1.7rem;

  div {
    width: ${(props) => `${props.thumbWidth}rem`};
    height: ${(props) =>
      props.thumbHeight ? `${props.thumbHeight}rem` : "auto"};
    margin: 0.9rem 0.9rem;
    position: relative;

    &:first-child {
      width: ${(props) => `${props.thumbWidth}rem`};
      height: ${(props) =>
        props.thumbHeight ? `${props.thumbHeight}rem` : "auto"};
      .upload-icon {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
          height: 2.5rem;
          width: 3rem;
        }

        p {
          font-size: 1.4rem;
          opacity: 0.5;
          margin: 1rem 0;
        }
      }
    }

    video,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 3px;
    }

    .delete-btn {
      position: absolute;
      right: -6px;
      top: -6px;
      width: 1.8rem;
      height: 1.8rem;
      border-radius: 50%;
      color: white;
      text-align: center;
      border: none;
      cursor: pointer;

      .cancel-icon {
        height: 2rem;
        width: 2rem;
        left: 0px;
        top: 0px;
        position: absolute;
      }
    }

    .delete-btn:focus {
      outline: 0;
    }
  }
`;

const Dropzone = (props) => {
  const { files, handleDrop, setFiles } = props;
  const { getRootProps, getInputProps } = useDropzone({
    accept: props.accept,
    onDrop: (acceptedFiles) => {
      handleDrop(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            id: uuidv4(),
          })
        )
      );
    },
  });

  const handleDelete = (e, fileId) => {
    e.preventDefault();
    const remainingFiles = files.filter((f) => f.id !== fileId);
    setFiles(remainingFiles);
  };

  const imageThumbs = files.map((file) => (
    <div key={file.id}>
      <Image src={file.preview} alt="Upload Preview" />
      <button className="delete-btn" onClick={(e) => handleDelete(e, file.id)}>
        <img className="cancel-icon" alt="cancel" src={CancelIcon} />
      </button>
    </div>
  ));

  const videoThumbs = files.map((file) => (
    <div key={file.id}>
      <video src={file.preview} />
      <button className="delete-btn" onClick={(e) => handleDelete(e, file.id)}>
        <img className="cancel-icon" alt="cancel" src={CancelIcon} />
      </button>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <DropzoneWrapper {...props}>
      {imageThumbs.length === 0 && props.selectedFiles.length === 0 ? (
        <>
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <div className="upload-icon">
              <img src={UploadIcon} alt="Upload Icon" />
              <p>Drag and drop here to upload</p>
            </div>
          </div>
          <div
            style={{
              color: "#f00",
              fontSize: "1.2rem",
              marginTop: "0.5rem",
            }}
          >
            {props.error}
          </div>
        </>
      ) : null}
      <Thumbnails {...props}>
        {imageThumbs.length > 0 || props.selectedFiles.length > 0 ? (
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <div className="upload-icon">
              <img src={UploadIcon} alt="Upload Icon" />
              <p>Drag and drop here to upload</p>
            </div>
          </div>
        ) : null}
        {props.accept.startsWith("image") ? imageThumbs : videoThumbs}
        {props.selectedFiles.map((file) => (
          <div key={file._id}>
            <Image className="preview" src={file.url} alt="Upload Preview" />
            <img
              className="delete-btn"
              src={CancelIcon}
              onClick={(e) => props.handleDelete(file._id)}
              alt="delete"
            />
          </div>
        ))}
      </Thumbnails>
    </DropzoneWrapper>
  );
};

Dropzone.propTypes = {
  files: PropTypes.array,
  handleDrop: PropTypes.func.isRequired,
  setFiles: PropTypes.func.isRequired,
  fontFamily: PropTypes.string,
  accept: PropTypes.string,
  thumbWidth: PropTypes.number,
  thumbHeight: PropTypes.number,
  width: PropTypes.string,
  selectedFiles: PropTypes.array,
  handleDelete: PropTypes.func,
};

Dropzone.defaultProps = {
  fontFamily: '"Muli", sans-serif',
  accept: "video/*",
  thumbWidth: 16.7,
  width: "100%",
};

export default Dropzone;
