import { loaderConstants } from "../constants";

const initialState = {
  isActive: false,
  message: "Loading...",
};

const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case loaderConstants.SHOW_LOADER:
      return {
        ...state,
        isActive: true,
        message: action.payload,
      };
    case loaderConstants.HIDE_LOADER:
      return {
        ...state,
        isActive: false,
        message: "Loading...",
      };
    default:
      return state;
  }
};

export default loaderReducer;
