import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const InputWrapper = styled.div`
  margin-bottom: 2.7rem;

  fieldset {
    position: relative;
    padding: 0;
    margin: 5px;
    border: none;
    overflow: visible;
  }

  input {
    box-sizing: border-box;
    width: 100%;
    padding: 12px 10px 8px;
    border: none;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #ddd;
    font-size: 1.6rem;
    outline: none;
    cursor: text;
  }
  input::-webkit-input-placeholder {
    -webkit-transition: color 300ms ease;
    transition: color 300ms ease;
  }
  input:not(:focus)::-webkit-input-placeholder {
    color: transparent;
  }
  label {
    position: absolute;
    top: 10px;
    left: 0px;
    font-size: 1.6rem;
    opacity: 0.5;
    color: #142945;
    -webkit-transform-origin: 0 -150%;
    transform-origin: 0 -150%;
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
    pointer-events: none;
  }
  input:focus ~ label,
  input:valid ~ label {
    -webkit-transform: translateY(-2.9rem);
    transform: translateY(-2.9rem);
  }

  div {
    color: #f00;
    font-size: 1.2rem !important;
    padding-left: 1.2rem;
  }
`;

const MaterialInput = props => {
  return (
    <InputWrapper className="material">
      <fieldset>
        <input
          type={props.type}
          id={props.id}
          name={props.name}
          required={props.required}
          value={props.value}
          onChange={props.handleChange}
        />
        <label>{props.label}</label>
      </fieldset>
      <div>{props.error}</div>
    </InputWrapper>
  );
};

MaterialInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  handleChange: PropTypes.func,
  width: PropTypes.number,
  border: PropTypes.string,
  error: PropTypes.string,
  fontFamily: PropTypes.string
};

MaterialInput.defaultProps = {
  type: "text",
  required: true,
  width: 104,
  border: "1px solid #ccc",
  error: "",
  fontFamily: '"Muli", sans-serif'
};

export default MaterialInput;
