import {
  ADD_CASE_FILES,
  SET_CASE_FILES,
  SET_CASE_NAME,
  SET_CASE_NOTES,
  SET_CASE_UPLOAD_STATUS,
  RESET_CASE_FORM,
} from "../actions/types";

const INITIAL_STATE = {
  caseName: "",
  caseNotes: "",
  caseFiles: [],
  caseUploadStatus: false,
};

const caseReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CASE_NAME:
      return { ...state, caseName: action.payload };
    case SET_CASE_NOTES:
      return { ...state, caseNotes: action.payload };
    case SET_CASE_FILES:
      return { ...state, caseFiles: action.payload };
    case ADD_CASE_FILES:
      return { ...state, caseFiles: [...state.caseFiles, ...action.payload] };
    case SET_CASE_UPLOAD_STATUS:
      return { ...state, caseUploadStatus: action.payload };
    case RESET_CASE_FORM:
      return {
        ...state,
        caseName: "",
        caseNotes: "",
        caseFiles: [],
        caseUploadStatus: false,
      };
    default:
      return state;
  }
};

export default caseReducer;
