import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledNav = styled.nav`
  width: 100%;
  height: ${props => `${props.height}rem`};
  background: ${props => props.background};
  font-family: ${props => props.fontFamily};
  box-shadow: ${props => props.headerBoxShadow};

  ul {
    margin: 0;
    height: 100%;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 4rem;
  }
`;

const Header = props => {
  return (
    <StyledNav {...props}>
      <ul>{props.children}</ul>
    </StyledNav>
  );
};

export default Header;

Header.propTypes = {
  height: PropTypes.number,
  background: PropTypes.string,
  children: PropTypes.node.isRequired,
  fontFamily: PropTypes.string,
  headerBoxShadow: PropTypes.string
};

Header.defaultProps = {
  height: 4.8,
  background: "#FFFFFF",
  fontFamily: '"Muli", sans-serif',
  headerBoxShadow: "0 2px 10px 0 rgba(44, 137, 255, 0.06)"
};
