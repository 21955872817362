import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import queryString from "query-string";
import { warningNotification, errorNotification } from "../../actions";
import { Redirect } from "react-router-dom";
import loader from "../../assets/img/loader.svg";

import { registerUser, clearValidationErrors } from "../../actions";
import Background from "../../components/Form/Background";
import Button from "../../components/Form/Button";
import Input from "../../components/common/Form/MaterialInput";
import { colors } from "../../components/themes/base";

const BackgroundSignUpWrapper = styled.div`
  display: flex;
  .bg {
    @media only screen and (min-width: ${(props) =>
        `${props.mdBreakpoint}px`}) {
      width: 68vw;
    }
    @media only screen and (max-width: ${(props) =>
        `${props.mdBreakpoint}px`}) {
      display: none;
    }
  }
  .signup {
    background: ${(props) => props.background};
    width: 20vw;
    height: 100vh;
    font-family: ${(props) => props.fontFamily};
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: ${(props) => props.margin};
    div:first-child {
      @media only screen and (max-width: ${(props) =>
          `${props.mdBreakpoint}px`}) {
        width: 70vw;
      }
    }
    @media only screen and (max-width: ${(props) =>
        `${props.mdBreakpoint}px`}) {
      width: 70vw;
    }
  }
  .header {
    max-width: 100%;
    display: block !important;
    color: #142945;
    font-weight: 600;
    font-size: 3rem;
    margin-bottom: 4rem;
  }
  .login {
    color: #00b2e6;
    text-align: left;
    cursor: pointer;
  }
  .error {
    color: #f00;
    font-size: 1rem;
    padding: 5px 0;
  }
`;

const Wrapper = styled.div`
  margin-bottom: ${(props) => `${props.marginBottom}rem`};
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
  opacity: ${(props) => props.opacity};

  .login-text {
    opacity: 0.5;
  }
`;

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      password: "",
      retypePassword: "",
      org: "",
    };
  }

  componentDidMount = () => {
    const { id } = queryString.parse(this.props.location.search);
    if (!id) {
      this.props.warningNotification({
        title: "An organization ID is required for signup.",
        autoDismiss: 1,
      });
    } else {
      this.setState({ org: id });
    }
  };

  onChange = (e) => {
    // clear validation errors
    this.props.clearValidationErrors();

    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { name, email, password, retypePassword, org } = this.state;
    if (password !== retypePassword) {
      this.props.errorNotification({
        title: "Passwords do not match",
        autoDismiss: 1,
      });
    } else {
      const userData = {
        fullName: name,
        email,
        password,
        org,
      };
      this.props.registerUser(userData, this.props.history);
    }
  };

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    }

    if (this.props.errors.org) {
      this.props.errorNotification({
        title: "No Organization ID found!",
        autoDismiss: 1,
      });
      this.props.clearValidationErrors();
    }

    return (
      <BackgroundSignUpWrapper {...this.props.styles}>
        <div className="bg">
          <Background />
        </div>
        <div className="signup">
          <div className="header">Create Your Account</div>
          <Input
            label="Full Name"
            value={this.state.name}
            handleChange={this.onChange}
            placeholder="Full Name"
            name="name"
            error={this.props.errors.fullName}
          />
          <Input
            label="Email Address"
            value={this.state.email}
            handleChange={this.onChange}
            placeholder="Email Address"
            type="text"
            name="email"
            error={this.props.errors.email}
          />
          <Input
            label="Enter Password"
            value={this.state.password}
            handleChange={this.onChange}
            placeholder="Enter Password"
            type="password"
            name="password"
            error={this.props.errors.password}
          />
          <Input
            label="Retype Password"
            value={this.state.retypePassword}
            handleChange={this.onChange}
            placeholder="Retype Password"
            type="password"
            name="retypePassword"
          />
          <Button
            background={colors.primary}
            color="#fff"
            onClick={this.onSubmit}
            textTransform="none"
            fontSize="1.8rem"
            fontWeight="normal"
            marginBottom={5}
          >
            Register&nbsp; &nbsp;
            {this.props.loading ? <img src={loader} alt="loader" /> : null}
          </Button>
          {/* <Button
            handleChange={this.onChange}
            background="#F66457"
            color="#fff"
            onClick={this.onSubmit}
            marginBottom={5}
            textTransform="none"
            fontSize="1.8rem"
            fontWeight="normal"
          >
            Log in with Google&nbsp; &nbsp;
          </Button> */}
          {/* <Wrapper
            marginBottom={6.3}
            color="black"
            fontSize="1.4rem"
            opacity="1"
          >
            <span className="login-text">
              Already have a Video Redactor account?&nbsp;
            </span>
            <Link className="login" to={{ pathname: "/signin" }} justify="left">
              Log in
            </Link>
          </Wrapper> */}
          <Wrapper color="#142945" fontSize="1.2rem" opacity="0.3">
            &copy;2023 All Rights Reserved. Suspect Technologies&reg; Ltd.
            <br />
          </Wrapper>
        </div>
      </BackgroundSignUpWrapper>
    );
  }
}

Signup.defaultProps = {
  styles: {
    background: "#FFFFFF",
    fontFamily: '"Muli", sans-serif',
    margin: "auto",
    mdBreakpoint: 900,
  },
};

const mapStateToProps = (state) => ({
  errors: state.user.errors,
  isAuthenticated: state.user.isAuthenticated,
  loading: state.user.loading,
});

export default connect(mapStateToProps, {
  registerUser,
  clearValidationErrors,
  warningNotification,
  errorNotification,
})(Signup);
