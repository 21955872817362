import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { colors } from "../themes/base";

const StyledItem = styled.li`
  font-family: ${(props) => props.fontFamily};
  font-size: 1.2rem;
  font-weight: bold;
  color: ${(props) => props.fontColor};
  width: 100%;
  letter-spacing: 0.2px;
  text-align: center;
  padding: 1rem 1.2rem;
  box-sizing: border-box;
  border-left: ${(props) =>
    props.isActive ? `4px solid ${props.lineColor}` : "none"};
  opacity: ${(props) => (props.isActive ? 1 : 0.5)};
`;

const SidebarItem = (props) => {
  return <StyledItem {...props}>{props.children}</StyledItem>;
};

export default SidebarItem;

SidebarItem.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  fontColor: PropTypes.string,
  fontFamily: PropTypes.string,
  lineColor: PropTypes.string,
  lineBoxShadow: PropTypes.string,
};

SidebarItem.defaultProps = {
  isActive: false,
  fontColor: "#FFFFFF",
  fontFamily: '"Muli", sans-serif',
  lineColor: colors.brand,
  lineBoxShadow: "0 2px 10px 0 rgba(20, 141, 255, 0.5)",
};
