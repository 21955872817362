import React, { useState } from "react";
import { debounce } from "lodash";

import "./styles.scss";

const AudioComponent = (props) => {
  const [zoomLevel, setZoomLevel] = useState(0);

  const zoomPlus = () => {
    if (zoomLevel >= 70) {
      return;
    }

    setZoomLevel((zm) => {
      props.wavesurfer.zoom(zm + 1);
      return zm + 1;
    });
  };

  const zoomMinus = () => {
    if (zoomLevel <= 0) {
      return;
    }

    setZoomLevel((zm) => {
      props.wavesurfer.zoom(zm - 1);
      return zm - 1;
    });
  };

  const debouncezmPlus = debounce(zoomPlus, 100);
  const debouncezmMinus = debounce(zoomMinus, 100);

  return (
    <div className="audiocomponent">
      <div id="wavesurfer-zoom-buttons">
        <div id="audio_zoom_plus_box" onClick={debouncezmPlus}>
          <svg
            id="audio_zoom_plus"
            className="icon icon-plus"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="svg-path"
              d="M8 4 h3 v5 h5 v3 h-5 v5 h-3 v-5 h-5 v-3 h5 z"
            />
          </svg>
        </div>

        <div className="audio__buttons-container">
          <svg
            className="zoom-icon"
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.5 14h-.79l-.28-.27c.98-1.14 1.57-2.62 1.57-4.23 0-3.59-2.91-6.5-6.5-6.5s-6.5 2.91-6.5 6.5 2.91 6.5 6.5 6.5c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99 1.49-1.49-4.99-5zm-6 0c-2.49 0-4.5-2.01-4.5-4.5s2.01-4.5 4.5-4.5 4.5 2.01 4.5 4.5-2.01 4.5-4.5 4.5z"
              className="svg-path"
            />
          </svg>
        </div>
        <div id="audio_zoom_minus_box" onClick={debouncezmMinus}>
          <svg
            id="audio_zoom_minus"
            className="icon icon-minus"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path className="svg-path" d="M4 8 h12 v3 h-12 v-3 z" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default AudioComponent;
