import React, { Component } from "react";
import { connect } from "react-redux";
import ViewProfile from "../../components/ViewProfile";
import { editProfile } from "../../actions";

class ViewProfileContainer extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.editProfile(id);
  }

  render() {
    return <ViewProfile {...this.props} />;
  }
}

function mapStateToProps({ wuser }) {
  return wuser;
}
export default connect(
  mapStateToProps,
  { editProfile }
)(ViewProfileContainer);
