import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import OverviewCard from "./OverviewCard";

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  justify-content: space-between;
  padding-top: 2rem;
  margin: ${(props) => props.margin || "auto"};
`;

const HomeOverview = (props) => {
  return (
    <CardWrapper {...props}>
      <OverviewCard
        icon={props.icon1}
        heading={props.heading1}
        subheading={props.subheading1}
        status={props.status1}
      />
      <OverviewCard
        icon={props.icon2}
        heading={props.heading2}
        subheading={props.subheading2}
        status={props.status2}
      />
      <OverviewCard
        icon={props.icon3}
        heading={props.heading3}
        subheading={props.subheading3}
        status={props.status3}
      />
    </CardWrapper>
  );
};

export default HomeOverview;

HomeOverview.propTypes = {
  icon1: PropTypes.object,
  heading1: PropTypes.string,
  subheading1: PropTypes.string,
  status1: PropTypes.object,
  icon2: PropTypes.object,
  heading2: PropTypes.string,
  subheading2: PropTypes.string,
  status2: PropTypes.object,
  icon3: PropTypes.object,
  heading3: PropTypes.string,
  subheading3: PropTypes.string,
  status3: PropTypes.object,
};
