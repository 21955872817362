import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";

import {
  successNotification,
  warningNotification,
  errorNotification,
} from "../../actions";
import Image from "../common/Image";
import TextAreaField from "../../components/common/Form/TextAreaField";
import TopMatchesCard from "./TopMatchesCard";
import { setResult, getSearchResult } from "../../actions/search.case.actions";
import Loader from "../../components/Loader/Loader";
// import profileImg from "../../assets/img/avatar.png"; // MOCK DATA
import { ReactComponent as ErrorImage } from "../../assets/img/server_down.svg";
import { colors } from "../../components/themes/base";

const INITIAL_IMAGE_WIDTH = 800;
const INITIAL_IMAGE_HEIGHT = 500;

const StyledPage = styled.div`
  display: flex;
  width: 90%;
  border-radius: 3px;
  margin: 2rem auto;
  background: ${(props) => props.background};

  .image-container {
    flex: 1 1 70%;

    .image {
      overflow: hidden;
      width: 100%;
      height: 50rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      align-items: start;
      position: relative;
      img {
        width: auto;
        height: ${(props) => `${props.imageHeight}px`};
        object-fit: cover;
      }

      .boxes {
        position: absolute;
        top: 0;
        width: ${(props) => `${props.imageWidth}px`};
        height: ${(props) => `${props.imageHeight}px`};

        .box {
          position: absolute;
          border-width: 3px;
          border-style: solid;
          border-radius: 3px;
        }

        .profile-box {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          background: white;
          border-radius: 3px;
          width: 11.7rem;
          height: 3.2rem;
          padding: 4px 5px;
          margin-top: 0.6rem;
          font-family: "Muli", sans-serif;

          .img {
            width: 2.4rem;
            height: 2.4rem;
            padding: 0.5rem;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          .details {
            flex: 1;

            .name {
              font-weight: 600;
              font-size: 1.1rem;
            }

            .info {
              font-size: 1rem;
              color: rgba(20, 41, 69, 0.53);
            }
          }
        }
      }
    }

    .pagination {
      display: flex;
      justify-content: flex-start;
      width: 65rem;
      overflow: hidden;
      align-items: center;
      padding: 0 3rem;
      color: #43546a;

      .info {
        font-size: 1.4rem;
        opacity: 0.5;
      }

      .react-pagination {
        list-style: none;
        display: flex;
        padding-left: 0;

        li {
          padding: 0.3rem 0.5rem;
          font-size: 1.4rem;
          opacity: 0.5;
          margin: 0 1rem;
          cursor: pointer;

          a {
            outline: none;
          }
        }

        li.previous,
        li.next {
          font-size: 1.8rem;
        }

        li.active {
          border: 1px solid ${colors.brand};
          border-radius: 3px;
          opacity: 1;
        }
      }
    }

    .notes-container {
      padding: 2.4rem 3rem;
    }
  }

  .cards-container {
    flex: 1 1 30%;
    font-family: ${(props) => props.fontFamily};
    height: 54rem;
    overflow-y: auto;
    .heading {
      text-align: center;
      color: #142945;
      font-size: 1.2rem;
      font-weight: 600;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const StyledErrorMessage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    max-width: 30rem;
    transform: translateY(-20rem);
  }

  .message {
    transform: translateY(-45rem);
    text-align: center;

    h4 {
      font-size: 3rem;
      font-weight: bold;
      margin: 0;
    }

    p {
      font-size: 1.8rem;
      color: grey;
    }
  }
`;

const TopMatchesList = (props) => {
  return props.matchedData.faces.map((data, i) => (
    <div key={i}>
      <TopMatchesCard
        bgColor={data.color}
        identifiedPerson={data.thumbnail}
        matchedUsers={data.matches}
      />
    </div>
  ));
};

const ErrorMessage = (props) => (
  <StyledErrorMessage>
    <ErrorImage />
    <div className="message">
      <h4>Failed to fetch search result.</h4>
      <p>Please try again after some time.</p>
    </div>
  </StyledErrorMessage>
);

const BoundingBox = ({ box: [top, left, width, height], color }) => (
  <div
    className="box"
    style={{ top, left, width, height, borderColor: color }}
  />
);

// const ProfileInfoBox = ({ box: [top, left, width, height], color }) => (
//   <div
//     className="profile-box"
//     style={{ top: top + height, left, borderColor: color }}
//   >
//     <div className="img">
//       <Image src={profileImg} alt="suspect" />
//     </div>
//     <div className="details">
//       <div className="name">Patricia Ruiz</div>
//       <div className="info">Suspect Math</div>
//     </div>
//   </div>
// );

const Boxes = (props) => (
  <div
    className="boxes"
    style={{ height: props.imageHeight, width: props.imageWidth }}
  >
    {props.faces.map((face, i) => (
      <React.Fragment key={i}>
        <BoundingBox box={face.bbox} color={face.color} />
        {/* <ProfileInfoBox box={face.bbox} color={face.color} /> */}
      </React.Fragment>
    ))}
  </div>
);

const Pagination = (props) => (
  <div className="pagination">
    <ReactPaginate
      previousLabel={<FaLongArrowAltLeft />}
      nextLabel={<FaLongArrowAltRight />}
      breakLabel={"..."}
      breakClassName={"break-me"}
      pageCount={props.pageCount}
      marginPagesDisplayed={1}
      pageRangeDisplayed={3}
      onPageChange={props.handlePageClick}
      containerClassName={"react-pagination"}
      activeClassName={"active"}
    />
  </div>
);

class TopSearchResultsPage extends Component {
  state = {
    pageCount: 0,
    currentPage: 0,
    matchedData: {},
    notes: "",
    resultImage: "",
    faces: [],
    imageHeight: INITIAL_IMAGE_HEIGHT,
    imageWidth: INITIAL_IMAGE_WIDTH,
    error: false,
  };

  async componentDidMount() {
    const data = await this.props.getSearchResult(
      this.props.match.params.searchId
    );
    if (data) {
      if (data.success) this.getMetadata(0);
    } else {
      this.setState({ error: true });
      this.props.errorNotification({
        title: "Something went wrong..Try agin later",
        autoDismiss: 1,
      });
    }
  }

  getMetadata = async (pageNumber) => {
    const metadata = this.props.result;
    const cases = Object.keys(metadata);
    const pageCount = cases.length;
    const matchedData = metadata[cases[pageNumber]];
    const resultImage = cases[pageNumber];

    if (!matchedData) {
      return;
    }

    const { faces, resultHeight, resultWidth } = matchedData;

    if (!faces) {
      return;
    }

    this.setState({
      pageCount,
      faces,
      matchedData,
      resultImage,
      imageHeight: resultHeight,
      imageWidth: resultWidth,
    });
  };

  handlePageClick = (data) => {
    this.setState({ currentPage: data.selected });
    this.getMetadata(data.selected);
  };

  render() {
    if (this.state.error) {
      return <ErrorMessage />;
    }

    if (Object.keys(this.state.matchedData).length === 0) {
      return (
        <div
          style={{
            width: "100%",
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      );
    } else {
      return (
        <div>
          <div
            style={{
              color: "#142945",
              fontFamily: "Muli",
              fontSize: "2.2rem",
              fontWeight: "700",
              letterSpacing: "0.18px",
              margin: "2.5rem 5rem",
              padding: "0 2rem",
            }}
          >
            <span style={{ opacity: "0.5", marginRight: "0.3rem" }}>
              Search Case >
            </span>{" "}
            Search Result
          </div>
          <StyledPage {...this.props}>
            <div className="image-container">
              <div className="image">
                <Image
                  src={this.state.resultImage}
                  style={{
                    height: this.state.imageHeight,
                    width: this.state.imageWidth,
                  }}
                  alt="search result"
                />
                <Boxes
                  faces={this.state.faces}
                  imageHeight={this.state.imageHeight}
                  imageWidth={this.state.imageWidth}
                />
              </div>
              <Pagination
                currentPage={this.state.currentPage}
                pageCount={this.state.pageCount}
                handlePageClick={this.handlePageClick}
              />
              <div className="notes-container">
                <TextAreaField
                  label="Notes"
                  id="notes"
                  name="notes"
                  value={this.state.notes}
                  handleChange={(e) => this.setState({ notes: e.target.value })}
                />
              </div>
            </div>
            <div className="cards-container">
              <div className="heading">Top Matches </div>
              <TopMatchesList matchedData={this.state.matchedData} />
            </div>
          </StyledPage>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  result: state.search.result,
});

export default connect(mapStateToProps, {
  setResult,
  getSearchResult,
  successNotification,
  warningNotification,
  errorNotification,
})(TopSearchResultsPage);

TopSearchResultsPage.propTypes = {
  background: PropTypes.string,
  notes: PropTypes.string,
  setNotes: PropTypes.func,
  fontFamily: PropTypes.string,
  matchedData: PropTypes.object,
  resultImage: PropTypes.string,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  faces: PropTypes.array,
  pageCount: PropTypes.number,
  currentPage: PropTypes.number,
  handlePageClick: PropTypes.func,
};

TopSearchResultsPage.defaultProps = {
  background: "#ffffff",
  notes: "",
  fontFamily: '"Muli", sans-serif',
  imageWidth: 800,
  imageHeight: 500,
};
