import React, { useRef, useEffect } from "react";
import { Circle, Transformer } from "react-konva";

import { torgba } from "../utils";

function KonvaCircle(props) {
  const cRef = useRef();
  const tRef = useRef();

  useEffect(() => {
    if (props.selected && props.visible) {
      tRef.current.nodes([cRef.current]);
      tRef.current.getLayer().batchDraw();
    }
  }, [props.selected, props.visible]);

  return (
    <React.Fragment>
      <Circle
        {...props}
        draggable
        ref={cRef}
        strokeWidth={Number(props.outline)}
        fill={torgba(props.fillColor, props.fillOpacity)}
        stroke={torgba(props.outlineColor, props.outlineOpacity)}
        onDragMove={(e) => {
          const node = cRef.current;

          const halfWidth = (node.width() * node.scaleX()) / 2;
          const halfHeight = (node.height() * node.scaleY()) / 2;

          if (node.x() - halfWidth < 0) {
            e.target.position({
              x: halfWidth,
              y: node.y(),
            });
          }

          if (node.y() - halfHeight < 0) {
            e.target.position({
              x: node.x(),
              y: halfHeight,
            });
          }

          if (node.x() + halfWidth > props.videodata.width) {
            e.target.position({
              x: props.videodata.width - halfWidth,
              y: node.y(),
            });
          }

          if (node.y() + halfHeight > props.videodata.height) {
            e.target.position({
              x: node.x(),
              y: props.videodata.height - halfHeight,
            });
          }
        }}
        onDragEnd={() => {
          const node = cRef.current;
          const x = node.x() / props.sX;
          const y = node.y() / props.sY;
          const scaleX = node.scaleX() / props.sX;
          const scaleY = node.scaleY() / props.sY;
          const rotation = node.rotation();
          props.storeChanges({
            ...props,
            id: props.id,
            text: false,
            x,
            y,
            scaleX,
            scaleY,
            rotation,
          });
          props.handleShapeDataChange(props.id, { x, y });
        }}
      />

      {props.selected && props.visible && (
        <Transformer
          ref={tRef}
          borderStrokeWidth={1.5}
          borderStroke="#F40050"
          borderDash={[3]}
          anchorFill="#F40050"
          anchorStroke="white"
          anchorStrokeWidth={1}
          anchorCornerRadius={50}
          anchorSize={7}
          rotateAnchorOffset={25}
          // onTransform={(e) => {
          //   const node = cRef.current;

          //   const halfWidth = (node.width() * node.scaleX()) / 2;
          //   const halfHeight = (node.height() * node.scaleY()) / 2;

          //   console.log(halfWidth, node.width(), node.scaleX());

          //   if (node.x() - halfWidth < 0) {
          //     node.scaleX(1);
          //     node.setAttrs({
          //       x: halfWidth,
          //       y: node.y(),
          //       width: props.x + (props.width * props.scaleX) / 2,
          //     });
          //   }

          //   if (node.y() - halfHeight < 0) {
          //     node.scaleY(1);
          //     node.setAttrs({
          //       x: node.x(),
          //       y: halfHeight,
          //       height: props.y + (props.height * props.scaleY) / 2,
          //     });
          //   }

          //   if (node.x() + halfWidth > props.videodata.width) {
          //     node.scaleX(1);
          //     node.setAttrs({
          //       x: props.x,
          //       width:
          //         props.videodata.width +
          //         (props.width * props.scaleX) / 2 -
          //         props.x,
          //     });
          //   }

          //   if (node.y() + halfHeight > props.videodata.height) {
          //     node.scaleY(1);
          //     node.setAttrs({
          //       y: props.y,
          //       height:
          //         props.videodata.height +
          //         (props.height * props.scaleY) / 2 -
          //         props.y,
          //     });
          //   }
          // }}
          onTransformEnd={() => {
            const node = cRef.current;
            const x = node.x() / props.sX;
            const y = node.y() / props.sY;
            const scaleX = node.scaleX() / props.sX;
            const scaleY = node.scaleY() / props.sY;
            const rotation = node.rotation();
            props.storeChanges({
              ...props,
              id: props.id,
              text: false,
              x,
              y,
              scaleX,
              scaleY,
              rotation,
            });
            props.handleShapeDataChange(props.id, {
              scaleX,
              scaleY,
              rotation,
            });
          }}
        />
      )}
    </React.Fragment>
  );
}

export default KonvaCircle;
