import React from "react";
import PropTypes from "prop-types";
import { FaSearch } from "react-icons/fa";
import styled from "styled-components";

import Dropdown, { MenuItem } from "../common/SelectDropdown/Dropdown";
import CustomLabel from "../common/SelectDropdown/CustomLabel";
import "../common/SelectDropdown/SearchFilter.css";

const CustomDropdown = styled("div")`
  position: relative;
  border-radius: 3px;
  button {
    border-color: #edeff2 !important;
  }
`;

CustomDropdown.propTypes = Dropdown.propTypes;
CustomDropdown.defaultProps = Dropdown.defaultProps;

const CustomDropdownToggle = styled("div")`
  height: 4.8rem;
  width: 100%;
  border: 1px solid #edeff2;
  border-radius: 3px;
  color: #142945;
  font-family: "Muli", sans-serif;
  font-size: 1.6rem;
  line-height: 2rem;
  display: flex;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  cursor: pointer;
`;

CustomDropdownToggle.propTypes = Dropdown.Toggle.propTypes;
CustomDropdownToggle.defaultProps = Dropdown.Toggle.defaultProps;

const CustomDropdownMenu = styled("div")`
  border-radius: 3px !important;
  width: 100%;
  max-height: 30rem;
  overflow-y: auto;
  box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 30%);
  padding: 0 !important;
  margin: 0;
  position: absolute;
  z-index: 4;
  background-color: white;
  border: none;
`;

CustomDropdownMenu.propTypes = Dropdown.Menu.propTypes;
CustomDropdownMenu.defaultProps = Dropdown.Menu.defaultProps;

const Customhr = styled.hr`
  padding: 0;
  border: 1px solid #edeff2;
  margin: 0px;
  height: 1px;
  background-color: #edeff2;
  display: block;
  width: calc(100% - 20px);
  margin: 0 10px;
`;

const CustomMenuItem = styled("div")`
  height: 4.8rem;
  width: 100%;
  color: #223539;
  font-family: "Muli", sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.8rem;
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    background-color: #edeff2;
  }

  div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }
`;

CustomMenuItem.propTypes = MenuItem.propTypes;
CustomMenuItem.defaultProps = MenuItem.defaultProps;

const CustomSelect = (props) => {
  const [query, setQuery] = React.useState("");
  const [open, setOpen] = React.useState(false);

  let selectedNames = "";
  props.selectedUsers
    .slice(0, 6)
    .forEach((user) => (selectedNames += user.name + ", "));
  const submitHandler = (e) => {
    e.preventDefault();
    props.filterUsers(query);
  };

  const toggleOpen = () => {
    setOpen((status) => !status);
  };

  const onItemSelect = (option) => {
    props.onUserSelect(option);
  };

  const onSelectAll = () => {
    props.onSelectAll();
    toggleOpen();
  };

  return (
    <CustomDropdown>
      <CustomDropdownToggle onClick={toggleOpen}>
        {props.selectedUsers.length > 0
          ? selectedNames.slice(0, selectedNames.length - 2)
          : "-Select-"}
        <span style={{ float: "right", marginLeft: "5px" }}>&#9662;</span>
      </CustomDropdownToggle>

      {open && (
        <CustomDropdownMenu>
          <form
            onSubmit={(e) => submitHandler(e)}
            className="input-icon-group dropdown-menu-filter"
          >
            <input
              id="filter"
              type="text"
              className="form-control"
              style={{ width: "100%" }}
              placeholder="Search..."
              onChange={(e) => setQuery(e.target.value)}
              autoFocus
            />
            <label className="input-icon-label" htmlFor="filter">
              <FaSearch />
            </label>
          </form>
          <CustomMenuItem onClick={onSelectAll}>
            <div>Select All</div>
          </CustomMenuItem>
          {props.options.map((option) => (
            <React.Fragment key={"fragment" + option.id}>
              <Customhr />
              <CustomMenuItem onClick={() => onItemSelect(option)}>
                <CustomLabel {...option} selectedUsers={props.selectedUsers} />
              </CustomMenuItem>
            </React.Fragment>
          ))}
        </CustomDropdownMenu>
      )}
    </CustomDropdown>
  );
};

export default CustomSelect;

CustomSelect.propTypes = {
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      gender: PropTypes.string,
      imgUrl: PropTypes.string,
    })
  ),
};
