import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Modal from "react-modal";

import Button from "../../components/common/Button";
import { colors } from "../themes/base";

const buttonProps = {
  width: "140px",
  height: "48px",
  fontSize: "1.6rem",
  background: colors.brand,
  boxShadow: "0 10px 20px 0 rgba(124, 160, 73, 0.15)",
  color: "#FFFFFF",
};

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const ModalContent = styled.div`
  box-sizing: border-box;
  width: 50.5rem;
  padding: 2rem;
  color: #142945;

  .heading {
    text-align: center;
    font-size: 2.4rem;
    font-weight: 600;
  }

  .message {
    padding: 2.4rem 0;
    font-size: 1.6rem;
    text-align: center;

    .name {
      font-weight: 600;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    padding: 0 6.6rem;
  }
`;

class DeleteVideoModal extends Component {
  render() {
    const { isVisible, handleCancel, handleDelete, videoName } = this.props;
    return (
      <Modal
        isOpen={isVisible}
        style={customStyles}
        contentLabel="Warning Modal"
        ariaHideApp={false}
        onRequestClose={handleCancel}
      >
        <ModalContent>
          <div className="heading">Delete Video</div>
          <div className="message">
            Are you sure about deleting{" "}
            <span className="name">"{videoName}"</span> video?
            <div>You can’t undo this.</div>
          </div>
          <div className="buttons">
            <Button
              {...buttonProps}
              color="#FF3B30"
              border="1px solid #FF3B30"
              background="transparent"
              boxShadow="none"
              onClick={handleDelete}
            >
              Delete
            </Button>
            <Button {...buttonProps} onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </ModalContent>
      </Modal>
    );
  }
}

export default DeleteVideoModal;

DeleteVideoModal.propTypes = {
  isVisible: PropTypes.bool,
  closeModal: PropTypes.func,
  handleDelete: PropTypes.func,
  handleCancel: PropTypes.func,
  videoName: PropTypes.string,
};

DeleteVideoModal.defaultProps = {};
