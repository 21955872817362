import React from "react";
import "./placeholder.css";

export default function PlaceholderUserCard() {
  return (
    <div
      className="ph-item"
      style={{
        width: "18.7%",
        maxWidth: "calc(16.6666% + 27px)",
        minWidth: "21.2rem",
        height: "27rem",
        padding: "1rem",
        background: "white",
        boxShadow: "0 2px 10px 0 rgba(44, 137, 255, 0.06)",
      }}
    >
      <div className="ph-col-12" style={{ padding: 0 }}>
        <div
          className="ph-picture"
          style={{
            width: "100%",
            height: "16rem",
            borderRadius: "3px",
            marginBottom: "4px",
          }}
        />
        <div
          className="ph-row"
          style={{ marginTop: "0.5rem", marginBottom: 0 }}
        >
          <div className="ph-col-6" />
        </div>
        <div className="ph-row" style={{ marginTop: "4px", marginBottom: 0 }}>
          <div className="ph-col-8" />
        </div>
        <div className="ph-row" style={{ marginTop: "4px", marginBottom: 0 }}>
          <div className="ph-col-2" />
        </div>
        <div className="ph-row" style={{ marginTop: "4px", marginBottom: 0 }}>
          <div className="ph-col-12" />
        </div>
      </div>
    </div>
  );
}
