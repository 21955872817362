import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";

import {
  fetchCases,
  fetchUsers,
  filterUsers,
  setSelectedCase,
  setSelectedUsers,
  addSelectedUsers,
  removeSelectedUser,
  loadMoreSelectedUsersToShow,
  submitSearch,
  selectAll,
  setProgress,
} from "../../actions/search.case.actions";
import styled from "styled-components";
import CustomSelect from "./CustomSelect";
import CustomSelectField from "../common/Form/CustomSelectField";
import Button from "../common/Button";
import cancel from "../../assets/img/cancel.svg";
import {
  successNotification,
  warningNotification,
  errorNotification,
} from "../../actions";
import Image from "../common/Image";
import LoaderOverlay from "../../components/Loader/LoadingOverlay";
import { colors } from "../../components/themes/base";

const buttonProps = {
  width: "140px",
  height: "48px",
  fontSize: "1.6rem",
  background: colors.brand,
  boxShadow: "0 1rem 2rem 0 rgba(124, 160, 73, 0.15)",
  color: "#FFFFFF",
};

const SearchCaseWrapper = styled.div`
  min-height: 73vh;
  max-width: 90%;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(44, 137, 255, 0.06);
  padding: 2rem 10%;
  margin: 0 5rem;
  display: flex;
  flex-direction: column;
  .info {
    opacity: 0.6;
    color: #142945;
    font-family: ${(props) => props.fontFamily};
    font-size: 1.4rem;
    line-height: 2.2rem;
    text-align: center;
    margin-bottom: 3.5rem;
  }
  .selectWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    max-width: 100%;
    .selectedPics {
      margin-top: 0.5rem;
      display: flex;
      flex-wrap: wrap;
      .userPic {
        position: relative;
        width: 6.5rem;
        height: 5rem;
        margin-bottom: 1rem;
        .user {
          margin: 0.8rem;
          top: 0;
          left: 0;
          height: 5rem;
          width: 5rem;
          position: absolute;
          object-fit: cover;
          border-radius: 50%;
        }
        .cancel {
          position: absolute;
          top: 5px;
          right: 5px;
          height: 1.6rem;
          width: auto;
          z-index: 2;
          cursor: pointer;
        }
      }
    }
  }
  .label {
    opacity: 0.5;
    color: #142945;
    font-family: ${(props) => props.fontFamily};
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-bottom: 0.8rem;
  }
  .buttons {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

class SearchCase extends React.Component {
  componentDidMount = () => {
    this.selectedAll = false;

    this.props.fetchCases();
    this.props.fetchUsers();
  };

  render() {
    const { props } = this;
    const caseList = props.caseList === null ? [] : props.caseList;
    const userList = props.userList === null ? [] : props.userList;
    const selectedUsersLength = props.selectedUsers.length;
    const hasMoreSelectedUsersToShow =
      selectedUsersLength !== 0 &&
      props.selectedUsersToShow.length !== props.selectedUsers.length;

    return (
      <LoaderOverlay
        isActive={!!props.loading}
        loaderMessage={props.loaderMessage}
      >
        <div
          style={{
            color: "#142945",
            fontFamily: "Muli",
            fontSize: "2.2rem",
            fontWeight: "700",
            letterSpacing: "0.18px",
            margin: "2.5rem 5rem",
          }}
        >
          Search Case
        </div>
        <SearchCaseWrapper {...props}>
          <div className="info" />
          <div className="selectWrapper">
            <div className="label">{props.searchCaseLabel}</div>
            <CustomSelectField
              onCaseSelect={props.setSelectedCase}
              options={caseList}
              selectedCase={props.selectedCase}
            />
          </div>
          <div className="selectWrapper">
            <div className="label">{props.searchUserLabel}</div>
            <CustomSelect
              onSelectAll={() => {
                props.selectAll(props.userList);
                this.selectedAll = true;
              }}
              onUserSelect={props.addSelectedUsers}
              options={userList}
              selectedUsers={props.selectedUsers}
              filterUsers={props.filterUsers}
            />

            <InfiniteScroll
              initialLoad={false}
              pageStart={5}
              loadMore={props.loadMoreSelectedUsersToShow}
              hasMore={hasMoreSelectedUsersToShow}
              useWindow
            >
              <div className="selectedPics">
                {props.selectedUsersToShow.map((user) => (
                  <div key={`userPicDiv${user.id}`} className="userPic">
                    <Image
                      className="user"
                      src={user.imgUrl}
                      alt="selectedUser"
                    />
                    <img
                      className="cancel"
                      src={cancel}
                      alt="cancel"
                      onClick={() => {
                        props.removeSelectedUser(user);
                        this.selectedAll = false;
                      }}
                    />
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          </div>
        </SearchCaseWrapper>
        <div
          className="buttons"
          style={{
            display: "flex",
            backgroundColor: "#fff",
            justifyContent: "flex-end",
            padding: "2rem 5rem",
            position: "sticky",
            bottom: "0",
            zIndex: 10,
          }}
        >
          <Button
            {...buttonProps}
            background="transparent"
            color="#777"
            boxShadow="none"
            border="none"
            onClick={() =>
              props.history.push({
                pathname: `/facesearch/cases`,
              })
            }
          >
            Cancel
          </Button>
          <Button
            {...buttonProps}
            onClick={async () => {
              if (
                Object.keys(props.selectedCase).length === 0 ||
                props.selectedUsers.length === 0
              ) {
                props.warningNotification({
                  title: "Please select all the fields",
                  autoDismiss: 1,
                });
              } else {
                const data = await props.submitSearch(
                  props.selectedCase,
                  props.selectedUsers,
                  this.selectedAll
                );
                if (data.initialCall) {
                  const jobId = data.jobID;
                  const searchId = data.searchID;
                  if (!jobId || !searchId) {
                    props.errorNotification({
                      title: "Failed to start search",
                      autoDismiss: 1,
                    });
                  } else {
                    props.setProgress(props.selectedCase.id, 0);
                    props.setSelectedCase({});
                    props.setSelectedUsers([]);
                    props.fetchCases();
                    props.history.push({
                      pathname: `/facesearch/searchcase/${searchId}/${jobId}/progress`,
                    });
                  }
                } else {
                  props.errorNotification({
                    title: "Failed to start search",
                    autoDismiss: 1,
                  });
                }
              }
            }}
          >
            Search
          </Button>
        </div>
      </LoaderOverlay>
    );
  }
}

const mapStateToProps = (state) => ({
  caseList: state.search.caseList,
  userList: state.search.filteredUsers,
  selectedCase: state.search.selectedCase,
  selectedUsers: state.search.selectedUsers,
  selectedUsersToShow: state.search.selectedUsersToShow,
  selectedUsersPage: state.search.selectedUsersPage,
  loading: state.search.loading,
  loaderMessage: state.search.loaderMessage,
});

export default connect(mapStateToProps, {
  setProgress,
  fetchCases,
  fetchUsers,
  filterUsers,
  setSelectedCase,
  setSelectedUsers,
  addSelectedUsers,
  removeSelectedUser,
  loadMoreSelectedUsersToShow,
  submitSearch,
  selectAll,
  warningNotification,
  errorNotification,
  successNotification,
})(SearchCase);

SearchCase.propTypes = {
  fontFamily: PropTypes.string,
  onUserSelect: PropTypes.func,
  onCaseSelect: PropTypes.func,
  caseOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  userOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      gender: PropTypes.string,
      imgUrl: PropTypes.string,
    })
  ),
  searchCaseLabel: PropTypes.string.isRequired,
  searchUserLabel: PropTypes.string.isRequired,
  selectedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      gender: PropTypes.string,
      imgUrl: PropTypes.string,
    })
  ),
  selectedCase: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

SearchCase.defaultProps = {
  fontFamily: '"Muli", sans-serif',
  searchCaseLabel: "Select Case*",
  searchUserLabel: "Select User*",
};
