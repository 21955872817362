import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

// TableHead
const StyledTableHead = styled.thead`
  width: 100%;
  background: ${props => props.background};
  border-radius: 3px 3px 0 0;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${props => props.fontColor};
`;

const TableHead = props => (
  <StyledTableHead {...props}>{props.children}</StyledTableHead>
);

TableHead.propTypes = {
  background: PropTypes.string,
  fontColor: PropTypes.string
};

TableHead.defaultProps = {
  background: "#FFFFFF",
  fontColor: "rgba(39, 55, 74, 0.5)"
};

// TableBody
const StyledTableBody = styled.tbody`
  width: 100%;
  background: ${props => props.background};
  border-radius: 3px 3px 0 0;
  padding-left: 3rem;
  color: ${props => props.fontColor};
  font-size: 1.4rem;
  font-weight: 600;
`;

const TableBody = props => (
  <StyledTableBody {...props}>{props.children}</StyledTableBody>
);

TableBody.propTypes = {
  background: PropTypes.string,
  fontColor: PropTypes.string
};

TableBody.defaultProps = {
  background: "#FFFFFF",
  fontColor: "#223539"
};

// TableRow
const TableRow = props => <tr>{props.children}</tr>;

// TableCell
const StyledTh = styled.th`
  text-align: ${props => (props.center ? "center" : "left")};
  padding-left: ${props => (props.first ? "3.0rem" : "1.2rem")};
  padding-right: ${props => (props.last ? "3.0rem" : "1.2rem")};
  white-space: nowrap;
  height: ${props => `${props.height}rem`};
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 32rem;
`;
const StyledTd = styled.td`
  text-align: ${props => (props.center ? "center" : "left")};
  padding-left: ${props => (props.first ? "3.0rem" : "1.2rem")};
  padding-right: ${props => (props.last ? "3.0rem" : "1.2rem")};
  white-space: nowrap;
  height: ${props => `${props.height}rem`};
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 32rem;
`;

const TableCell = props => {
  if (props.th) return <StyledTh {...props}>{props.children}</StyledTh>;
  return <StyledTd {...props}>{props.children}</StyledTd>;
};

TableCell.propTypes = {
  center: PropTypes.bool,
  first: PropTypes.bool,
  last: PropTypes.bool,
  height: PropTypes.number
};

TableCell.defaultProps = {
  height: 4.8
};

// Table
const StyledTable = styled.div`
  width: ${props => props.width};
  overflow-x: auto;
  table {
    table-layout: fixed;
    width: 100%;
    font-family: ${props => props.fontFamily};
    border-collapse: separate;
    border-spacing: 0 3px;
  }
`;

const Table = props => {
  return (
    <StyledTable {...props}>
      <table>{props.children}</table>
    </StyledTable>
  );
};

Table.propTypes = {
  width: PropTypes.string,
  fontFamily: PropTypes.string
};

Table.defaultProps = {
  width: "100%",
  fontFamily: '"Muli", sans-serif'
};

export { Table, TableHead, TableBody, TableRow, TableCell };
