import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import axios from "axios";

import keys from "../../config/keys";
import {
  successNotification,
  warningNotification,
  errorNotification,
} from "../../actions";

import AudioContext from "../../context/Audio/AudioContext";

import { setEncodeModal, setEncodeVideoId } from "../../actions/encode.actions";
import DeleteVideoModal from "../../components/Modal/DeleteVideoModal";
import EncodeVideoModal from "../../components/Modal/EncodeVideoModal";
import PlaceholderRawCard from "../../components/Placeholder/PlaceholderRawCard";
import AudioPlayerCard from "../../components/VideoCard/AudioPlayerCard";
import audioThumb from "../../assets/images/audio-poster.png";
import NotFound from "../../components/Errors/NotFound";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 2rem;
  margin-left: 5rem;
  margin-right: 3rem;
  padding-bottom: 3rem;

  @media only screen and (min-width: 680px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media only screen and (min-width: 1020px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media only screen and (min-width: 1200px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;

const NoAudioFound = (props) => (
  <NotFound
    title="No Audio"
    message="When you upload audio. They will appear here."
    button={{
      text: "Upload",
      handleButtonClick: () => props.history.push("/video/add/audio"),
    }}
  />
);

const PlaceHolderCards = () => (
  <Grid>
    {Array.from(Array(12).keys()).map((_, i) => (
      <PlaceholderRawCard key={i} />
    ))}
  </Grid>
);

const RawAudio = (props) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [audioId, setaudioId] = useState(null);
  const [audioName, setaudioName] = useState("");
  const [deleteStatus, setDeleteStatus] = useState(false);

  const { audioList, setAudioList } = useContext(AudioContext);

  useEffect(() => {
    fetchAudio();
    // eslint-disable-next-line
  }, []);

  const fetchAudio = () => {
    const url = `${keys.SERVER_URL}/audio`;
    axios
      .get(url, { requestId: "fetchAudio" })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.audioList.length === 0) {
            setAudioList(null);
          } else {
            setAudioList(res.data.audioList);
          }
        } else {
          props.errorNotification({
            title: "Unable to fetch data",
            autoDismiss: 1,
          });
        }
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          props.errorNotification({
            title: "Unable to fetch data",
            autoDismiss: 1,
          });
        }
      });
  };

  const deleteAudio = (id) => {
    const url = `${keys.SERVER_URL}/audio/${id}`;

    axios
      .delete(url, { requestId: "deleteAudio" })
      .then((res) => {
        setDeleteStatus(false);
        if (res.status === 200) {
          fetchAudio();
          props.successNotification({
            title: "Deleted Successfully",
            autoDismiss: 1,
          });
        } else {
          props.errorNotification({
            title: "Unable to delete audio",
            autoDismiss: 1,
          });
        }
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          props.errorNotification({
            title: "Unable to delete audio",
            autoDismiss: 1,
          });
        }
      });
  };

  if (audioList === null) {
    return <NoAudioFound history={props.history} />;
  } else if (audioList.length === 0) {
    return (
      <div>
        <div
          style={{
            color: "#142945",
            fontFamily: "Muli",
            fontSize: "2.2rem",
            fontWeight: "700",
            letterSpacing: "0.18px",
            margin: "2.5rem 5rem",
          }}
        >
          Raw Audios
        </div>
        <PlaceHolderCards />;
      </div>
    );
  } else {
    return (
      <div>
        <DeleteVideoModal
          isVisible={deleteModal}
          videoName={audioName}
          handleCancel={() => setDeleteModal(false)}
          handleDelete={() => {
            setDeleteStatus(true);
            setDeleteModal(false);
            deleteAudio(audioId);
          }}
        />
        <EncodeVideoModal
          {...props}
          isVisible={props.isEncodeModalVisible}
          handleCancel={() => props.setEncodeModal(false)}
          handleEncode={() => {
            props.encodeVideo(props.encodeVideoId);
          }}
        />
        <div
          style={{
            color: "#142945",
            fontFamily: "Muli",
            fontSize: "2.2rem",
            fontWeight: "700",
            letterSpacing: "0.18px",
            margin: "2.5rem 5rem",
          }}
        >
          Raw Audio
        </div>
        <Grid>
          {audioList.map((audio) => {
            return (
              <AudioPlayerCard
                key={audio._id}
                deleteStatus={audio._id === audioId ? deleteStatus : false}
                videoURL={`${audio.url}?t=${new Date().toISOString()}`}
                videoTitle={audio.name}
                poster={audioThumb}
                timestamp={new Date(audio.last_modified).toGMTString()}
                handleRedact={() => {
                  props.history.push(`/audio/redact/${audio._id.toString()}`);
                }}
                handleDelete={() => {
                  setaudioName(audio.name);
                  setaudioId(audio._id.toString());
                  setDeleteModal(true);
                }}
                href={audio.url}
              />
            );
          })}
        </Grid>
      </div>
    );
  }
};

RawAudio.propTypes = {
  rawVideos: PropTypes.arrayOf(
    PropTypes.shape({
      videoURL: PropTypes.string,
      poster: PropTypes.string,
      videoTitle: PropTypes.string,
      timestamp: PropTypes.string,
      handleRedact: PropTypes.func,
      handleSave: PropTypes.func,
      handleEncode: PropTypes.func,
      handleDelete: PropTypes.func,
    })
  ),
};

RawAudio.defaultProps = {};

const mapStateToProps = (state) => ({
  encoding: state.encode.isEncoding,
  encodingProgress: state.encode.encodingProgress,
  isEncodeModalVisible: state.encode.isEncodeModalVisible,
});

export default connect(mapStateToProps, {
  successNotification,
  warningNotification,
  errorNotification,
  setEncodeModal,
  setEncodeVideoId,
})(RawAudio);
