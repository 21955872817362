import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactPaginate from "react-paginate";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";

import { colors } from "../themes/base";
import PlaceholderUserCard from "../../components/Placeholder/PlaceholderUserCard";
import ProfileCard from "./ProfileCard";
import { ReactComponent as EmptySVG } from "../../assets/img/empty.svg";

const Wrapper = styled.div`
  .paginate {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 3rem;
    color: #43546a;

    .info {
      font-size: 1.4rem;
      opacity: 0.5;
    }

    .react-pagination {
      list-style: none;
      display: flex;

      li {
        padding: 0.3rem 1rem;
        font-size: 1.4rem;
        opacity: 0.5;
        margin: 0 1rem;
        cursor: pointer;

        a {
          outline: none;
        }
      }

      li.previous,
      li.next {
        font-size: 1.8rem;
      }

      li.active {
        border: 1px solid ${colors.brand};
        border-radius: 3px;
        opacity: 1;
      }
    }
  }
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  div {
    margin-right: 1.4rem;
    &:last-child {
      margin-right: 0rem;
    }
  }
`;

const StyledNotFound = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    max-width: 30rem;
    transform: translateY(-20rem);
  }

  .message {
    transform: translateY(-45rem);
    text-align: center;

    h4 {
      font-size: 3rem;
      font-weight: bold;
      margin: 0;
    }

    p {
      font-size: 1.8rem;
      color: grey;
    }

    button {
      margin: 0 auto;
      background: ${colors.brand};
      font-size: 1.6rem;
      box-shadow: 0 1rem 2rem 0 rgba(124, 160, 73, 0.15);
      color: #ffffff;
      padding: 1rem 2rem;
      text-transform: uppercase;
    }
  }
`;

const NoUsersFound = (props) => (
  <StyledNotFound>
    <EmptySVG />
    <div className="message">
      <h4>No Users</h4>
      <p>It looks like we do not have a User with that name.</p>
    </div>
  </StyledNotFound>
);

const PlaceHolderCards = () => (
  <CardWrapper>
    {Array.from(Array(10).keys()).map((_, i) => (
      <PlaceholderUserCard key={i} />
    ))}
  </CardWrapper>
);

const SearchUser = (props) => {
  const [currentPage, setCurrentPage] = React.useState(0);
  return props.loading ? (
    <PlaceHolderCards />
  ) : (
    <Wrapper>
      <CardWrapper>
        {props.filteredUsers.length !== 0 ? (
          props.filteredUsers.map((user) => (
            <ProfileCard
              key={user._id}
              profilePic={user.profilePic}
              name={`${user.firstname} ${user.lastname}`}
              details={props.getDetails(user)}
              place={user.loc}
              lastUpdated={"18hrs ago"}
              handleEditProfile={() => props.handleEditProfile(user._id)}
            />
          ))
        ) : (
          <NoUsersFound />
        )}
      </CardWrapper>
      <div className="paginate">
        <ReactPaginate
          previousLabel={<FaLongArrowAltLeft />}
          nextLabel={<FaLongArrowAltRight />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={props.filteredUsersPageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(data) => {
            setCurrentPage(data.selected);
            props.handlePageClick(data);
            return;
          }}
          containerClassName={"react-pagination"}
          activeClassName={"active"}
          forcePage={currentPage}
        />
      </div>
    </Wrapper>
  );
};

SearchUser.propTypes = {
  filteredUsersPageCount: PropTypes.number.isRequired,
  handlePageClick: PropTypes.func.isRequired,
  handleEditProfile: PropTypes.func.isRequired,
  getDetails: PropTypes.func.isRequired,
  filteredUsers: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SearchUser;
