import React from "react";
import ReactPaginate from "react-paginate";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import styled from "styled-components";
import { colors } from "../themes/base";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 60%;
  overflow: hidden;
  align-items: center;
  padding: 0 3rem;
  color: #43546a;

  .info {
    font-size: 1.4rem;
    opacity: 0.5;
  }

  .react-pagination {
    list-style: none;
    display: flex;
    padding-left: 0;

    li {
      padding: 0.3rem 0.5rem;
      font-size: 1.4rem;
      opacity: 0.5;
      margin: 0 1rem;
      cursor: pointer;
      width: 16px;
      text-align: center;

      a {
        outline: none;
      }
    }

    li.previous,
    li.next {
      font-size: 1.8rem;
    }

    li.active {
      border: 1px solid ${colors.brand};
      border-radius: 3px;
      opacity: 1;
    }
  }
`;

const Pagination = (props) => (
  <Wrapper>
    <ReactPaginate
      breakLabel={"..."}
      pageRangeDisplayed={3}
      marginPagesDisplayed={1}
      activeClassName={"active"}
      breakClassName={"break-me"}
      pageCount={props.pageCount}
      nextLabel={<FaLongArrowAltRight />}
      onPageChange={props.handlePageClick}
      previousLabel={<FaLongArrowAltLeft />}
      containerClassName={"react-pagination"}
    />
  </Wrapper>
);

export default Pagination;
