import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";
import Modal from "react-modal";

import Button from "../../components/common/Button";
import Loader from "../../assets/img/loader.svg";
import { colors } from "../themes/base";

const buttonProps = {
  width: "140px",
  height: "48px",
  fontSize: "1.6rem",
  background: colors.brand,
  boxShadow: "0 1rem 2rem 0 rgba(124, 160, 73, 0.15)",
  color: "#FFFFFF",
};

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const enlargeWidth = keyframes`
  from {
    width: 14rem;
  }
  to {
    width: 100%;
  }
`;

const ModalContent = styled.div`
  box-sizing: border-box;
  width: 50.5rem;
  padding: 2rem;
  color: #142945;

  .heading {
    text-align: center;
    font-size: 2.4rem;
    font-weight: 600;
  }

  .message {
    padding: 2.4rem 0;
    font-size: 1.6rem;
    text-align: center;

    .name {
      font-weight: 600;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    padding: 0 6.6rem;

    .encoding {
      pointer-events: none;
      animation: ${enlargeWidth} 0.3s linear normal;
    }
  }
`;

class EncodeVideoModal extends Component {
  render() {
    const {
      isVisible,
      handleCancel,
      handleEncode,
      encoding,
      encodingProgress,
    } = this.props;
    return (
      <Modal
        isOpen={isVisible}
        style={customStyles}
        contentLabel="Warning Modal"
        ariaHideApp={false}
      >
        <ModalContent>
          <div className="heading">Encode Video</div>
          <div className="message">Are you sure you want to encode?</div>
          <div className="buttons">
            {encoding ? (
              <Button
                style={{ pointerEvents: "none", width: "100%" }}
                className="encoding"
                {...buttonProps}
                onClick={handleEncode}
              >
                <>
                  <img src={Loader} alt="loader" /> &nbsp;{encodingProgress}%
                </>
              </Button>
            ) : (
              <>
                <Button
                  className="encode"
                  {...buttonProps}
                  onClick={handleEncode}
                >
                  Encode
                </Button>
                <Button
                  {...buttonProps}
                  color={colors.brand}
                  border={`1px solid ${colors.brand}`}
                  background="transparent"
                  boxShadow="none"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        </ModalContent>
      </Modal>
    );
  }
}

export default EncodeVideoModal;

EncodeVideoModal.propTypes = {
  isVisible: PropTypes.bool,
  closeModal: PropTypes.func,
  handleEncode: PropTypes.func,
  handleCancel: PropTypes.func,
};

EncodeVideoModal.defaultProps = {};
