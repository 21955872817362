import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactSlider from "react-slider";

import { colors } from "../themes/base";

const StyledSlider = styled(ReactSlider)`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  border-radius: 4px;
`;

const StyledThumb = styled.div`
  cursor: grab;
  transform: translateY(-35%);
  appearance: none;
  border-radius: 50%;
  cursor: pointer;
  height: 22px;
  width: 22px;
  background-color: ${colors.white};
  box-shadow: 0 2px 4px 0 ${colors.half_black};

  &:focus {
    outline: 0;
  }
`;

const Thumb = (props) => <StyledThumb {...props}></StyledThumb>;

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${(props) =>
    props.index === 1 ? "rgba(20, 41, 69, 0.05)" : colors.primary};
  border-radius: 999px;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

function ToolbarRange(props) {
  return (
    <StyledSlider
      {...props}
      value={[props.value]}
      renderTrack={Track}
      renderThumb={Thumb}
    />
  );
}

export default ToolbarRange;

ToolbarRange.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
};

ToolbarRange.defaultProps = {
  min: 0,
  max: 100,
  step: 1,
  value: 60,
  width: "300px",
  height: "8px",
  onChange: () => {},
};
