import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import NavigationWrapper from "../NavigationWrapper/NavigationWrapper";

const RequireAuth = (ComposedComponent, licenseType) => {
  class RequireAuth extends Component {
    render() {
      const { isAuthenticated } = this.props;
      if (isAuthenticated) {
        if (
          !licenseType ||
          (this.props.user.licenses &&
            this.props.user.licenses.indexOf(licenseType) !== -1)
        ) {
          return (
            <NavigationWrapper {...this.props}>
              <ComposedComponent {...this.props} />
            </NavigationWrapper>
          );
        }
        return <Redirect to="/notfound" />;
      }
      return <Redirect to="/signin" />;
    }
  }

  const mapStateToProps = ({ user }) => ({
    isAuthenticated: user.isAuthenticated,
    user: user.user,
  });

  return connect(mapStateToProps)(RequireAuth);
};

export default RequireAuth;
