import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Modal from "react-modal";

import { colors, icons } from "../themes/base";
import keys from "../../config/keys";
import Button from "../../components/common/Button";

const { SERVER_URL } = keys;

const colorsList = [
  "#E4584B",
  "#2086dc",
  "#38b14d",
  "#F1A527",
  "#E71581",
  "#47BAFF",
  "#F0C80E",
];

const Divider = styled.div`
  height: 0.5px;
  margin-left: 30px;
  background-color: #e7ecf9;
`;

const NameAvatar = styled.div`
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  border-radius: 50%;
  background: ${(props) => props.bg || "#2086dc"};
  color: white;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: Muli;
  font-weight: 700;
`;

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: 0,
    transform: "translate(-50%, -50%)",
  },
};

const ModalContent = styled.div`
  box-sizing: border-box;
  height: 425px;
  width: 325px;
  border-radius: 3px;
  background-color: #ffffff;
  color: #142945;

  .heading {
    height: 18px;
    width: 81px;
    color: #142945;
    font-family: Muli;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.4px;
    line-height: 18px;
    text-align: center;
  }

  .selected-users {
    height: 300px;
    padding: 0 1.4rem;
    overflow-y: scroll;
    margin: 1rem 0;

    font-size: 12px;
    font-family: Muli;
    text-align: center;

    letter-spacing: 0;
    line-height: 16px;
  }

  .slct-users-container {
    display: flex;
    align-items: center;
    height: 44px;
    justify-content: space-between;

    .remove-btn {
      border: 0;
      background: transparent;
      cursor: pointer;
      padding: 0;
      opacity: 0.5;
      color: #142945;
      font-family: Muli;
      font-size: 11px;
      letter-spacing: 0;
      line-height: 14px;
    }
  }

  .avatar-name {
    display: flex;
    justify-content: space-between;
    align-items: center;

    max-width: 251px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .avatar {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      margin-right: 1rem;
    }

    p {
      font-size: 13px;
      max-width: 200px;
    }
  }

  .default-text {
    opacity: 0.5;
    color: #142945;
    font-family: Muli;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    padding: 1rem 1.4rem;
    margin-top: 100px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    height: 44px;
    text-align: center;
    align-items: center;
    padding: 0 15px;
    border-bottom: 1px solid #e7ecf9;
  }
`;

const UserSelect = styled.div`
  position: relative;
  padding: 1rem 1.4rem;
  color: rgba(20, 41, 69, 1);

  .share-input {
    height: 38px;
    border-radius: 4px;
    background-color: #f7f8fb;
    font-family: Muli;
    box-sizing: border-box;
    height: 3.8rem;
    width: 100%;
    padding: 0 1rem;
    line-height: 2rem;
    font-size: 14px;
    border: 0px;
    caret-color: #4482ff;
    :focus {
      outline: 0;
    }

    ::placeholder {
      opacity: 0.5;
      color: #142945;
      font-family: Muli;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 18px;
    }
  }

  #share-with-input:focus + #share-persons {
    display: block;
  }

  .options {
    display: none;
    z-index: 999;
    max-height: 318px;
    width: 284px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 -2px 10px 0 rgba(35, 31, 32, 0.1),
      0 2px 10px 0 rgba(35, 31, 32, 0.1);
    position: absolute;
    top: 48px;
    background: white;
    overflow-y: scroll;
    padding: 5px 6px;
    font-size: 15px;
    font-family: Muli;
    text-align: center;

    .option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 44px;
      margin: 0px 5px;
      font-family: Muli;
      cursor: pointer;

      .slct-btn {
        background: transparent;
        border: 0;

        :focus {
          outline: 0;
        }
      }
    }
  }
`;

const Tick = styled.div`
  background: url(${icons.IC_BLue_Tick});
  background-size: cover;
  height: 15px;
  width: 15px;
  margin-right: 5px;
`;

const SelectUser = ({ onSearch, options, onSelect, selectedUsers }) => {
  return (
    <UserSelect>
      <input
        id="share-with-input"
        className="share-input"
        name="case-name"
        placeholder="Find People"
        onChange={(e) => onSearch(e.target.value)}
        autoComplete="off"
      />
      <div className="options" id="share-persons">
        {options &&
          options.length > 0 &&
          options.map((opt, i) => (
            <React.Fragment key={opt._id}>
              <div
                className="option"
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  onSelect(opt._id);
                }}
              >
                <div className="avatar-name">
                  {opt.profileImage ? (
                    <div
                      className="avatar"
                      style={{
                        background: `url("${SERVER_URL}${opt.profileImage}")`,
                        backgroundSize: "20px 20px",
                      }}
                    ></div>
                  ) : (
                    <NameAvatar bg={colorsList[i % colorsList.length]}>
                      {opt && opt.fullName ? opt.fullName.slice(0, 2) : "CE"}
                    </NameAvatar>
                  )}
                  <p>{opt.fullName}</p>
                </div>

                {selectedUsers.includes(opt._id) && <Tick />}
              </div>
              <Divider />
            </React.Fragment>
          ))}

        {(!options || options.length === 0) && <p>No results found</p>}
      </div>
    </UserSelect>
  );
};

const RenderSelectedUsers = ({ users, selectedUserIDs, onSelect }) => {
  const slctUsers = [];
  for (let user of users) {
    if (selectedUserIDs.includes(user._id)) {
      slctUsers.push(user);
    }
  }

  return slctUsers.map((user, i) => (
    <React.Fragment key={user._id}>
      <div className="slct-users-container">
        <div className="avatar-name">
          {user.profileImage ? (
            <div
              className="avatar"
              style={{
                background: `url("${SERVER_URL}${user.profileImage}")`,
                backgroundSize: "20px 20px",
              }}
            ></div>
          ) : (
            <NameAvatar bg={colorsList[i % colorsList.length]}>
              {user && user.fullName ? user.fullName.slice(0, 2) : "CE"}
            </NameAvatar>
          )}

          <p>{user.fullName}</p>
        </div>
        <button className="remove-btn" onClick={() => onSelect(user._id)}>
          Remove
        </button>
      </div>
      <Divider />
    </React.Fragment>
  ));
};

class ShareCaseModal extends Component {
  render() {
    const {
      isVisible,
      handleCancel,
      onSelect,
      handleShare,
      selectedUsers,
      filteredUsers,
      onSearch,
      users,
    } = this.props;

    return (
      <Modal
        isOpen={isVisible}
        style={customStyles}
        contentLabel="Warning Modal"
        ariaHideApp={false}
        onRequestClose={handleCancel}
      >
        <ModalContent>
          <div className="buttons">
            <Button
              width="auto"
              color="#142945"
              fontSize="12px"
              background="transparent"
              border="0"
              boxShadow="none"
              onClick={handleCancel}
            >
              Cancel
            </Button>

            <div className="heading">Share With</div>
            <Button
              width="auto"
              color={colors.primary}
              fontSize="12px"
              fontWeight="700"
              background="transparent"
              border="0"
              boxShadow="none"
              onClick={handleShare}
            >
              SHARE
            </Button>
          </div>

          <SelectUser
            options={filteredUsers}
            selectedUsers={selectedUsers}
            onSearch={onSearch}
            onSelect={onSelect}
          />
          {selectedUsers && selectedUsers.length > 0 && (
            <div className="selected-users">
              <RenderSelectedUsers
                users={users}
                selectedUserIDs={selectedUsers}
                onSelect={onSelect}
              />
            </div>
          )}

          {(!selectedUsers || selectedUsers.length === 0) && (
            <div className="default-text">
              <p>
                Enter the name or email of the people you want to share the case
                with.
              </p>
            </div>
          )}
        </ModalContent>
      </Modal>
    );
  }
}

export default ShareCaseModal;

ShareCaseModal.propTypes = {
  isVisible: PropTypes.bool,
  handleShare: PropTypes.func,
  handleCancel: PropTypes.func,
  onSelect: PropTypes.func,
};

ShareCaseModal.defaultProps = {};
