import React from "react";
import styled from "styled-components";

import { colors } from "../themes/base";

const TimeContainer = styled.div`
  position: relative;

  .time {
    display: flex;
    justify-content: space-between;
    margin: auto;

    p {
      height: 13px;
      width: 27px;
      color: ${colors.secondary};
      font-family: Muli;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 13px;
      text-align: center;
    }
  }
`;

const toHHMMSS = (secs) => {
  var sec_num = parseInt(secs, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor(sec_num / 60) % 60;
  var seconds = sec_num % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");
};

const RenderTime = ({ increament, duration }) => {
  const list = ["00:00"];

  let sumUp = 0;
  for (let i = 1; i <= 6; i++) {
    sumUp = sumUp + increament;
    list.push(toHHMMSS(sumUp));
  }

  return (
    <TimeContainer>
      <div className="time">
        {list.map((time) => (
          <p key={time}>{time}</p>
        ))}
      </div>
    </TimeContainer>
  );
};

function Timeline(props) {
  const { duration } = props;
  const increament = Number((duration / 6).toFixed(2));

  return <RenderTime increament={increament} duration={duration} />;
}

export default Timeline;
