import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

const Wrapper = styled.div`
  display: flex;

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    flex: 0 0 10rem;
    height: 100vh;
    width: 10rem;
    z-index: 2000;
  }

  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    overflow: hidden;

    .header {
      z-index: 1000;
      margin-left: 10rem;
      height: 6.8rem;
      position: fixed;
      top: 0;
      width: calc(100vw - 10rem);
    }

    .content {
      max-width: 100%;
      margin-left: 10rem;
      margin-top: 6.8rem;
      background: #f7f8fb;
      min-height: calc(100vh - 6.8rem);
      overflow: scroll;
    }
  }
`;

class NavigationWrapper extends Component {
  render() {
    return (
      <Wrapper>
        <div className="sidebar" id="main-sidebar">
          <Sidebar {...this.props} />
        </div>
        <div className="container">
          <div id="main-header" className="header">
            <Header {...this.props} />
          </div>

          <div id="main-content" className="content">
            {this.props.children}
          </div>
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = ({ loader }) => ({
  isActive: loader.isActive,
  loaderMessage: loader.message,
});

export default connect(mapStateToProps)(NavigationWrapper);
