import React, { useEffect, useState, useRef } from "react";
import WaveSurfer from "wavesurfer.js";
import TimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline.min";
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.min";
import CursorPlugin from "wavesurfer.js/dist/plugin/wavesurfer.cursor.min";
import axios from "axios";
import styled from "styled-components";
import { FaPlus } from "react-icons/fa";
import { withRouter } from "react-router-dom";

import {
  successNotification,
  errorNotification,
} from "../../components/Notifcations";
import keys from "../../config/keys";
import Button from "../../components/common/Button";
import LoadingOverlay from "../../components/Loader/LoadingOverlay";
import Loader from "../../components/Loader/Loader";
import ZoomComponent from "./ZoomComponent";
import PlayAndPause from "./PlayAndPause";
import VolumeBar from "./VolumeBar";
import DropDown from "../../components/common/ToolbarDropdown";
import {
  regionCreatedListener,
  regionMouseEnterListener,
  regionMouseLeaveListener,
  regionUpdateEndListener,
  keyBoardListeners,
} from "./listeners";
import AudioTable from "../../components/AudioComponents/AudioTable";
import AddRegionModal from "../../components/Modal/AddRegionModal";
import { toHHMMSS, toSec } from "../../components/common/utils";
import AudioPDFModal from "../../components/Modal/AudioPDFModal";
import { colors, icons } from "../../components/themes/base";
import IconButton from "../../components/common/Button";
import Pagination from "../../components/common/Pagination";

function formatTimeCallback(cursorTime) {
  return toHHMMSS(cursorTime);
}

const buttonProps = {
  height: "48px",
  fontSize: "1.6rem",
  background: colors.brand,
  boxShadow: "0 1rem 2rem 0 rgba(124, 160, 73, 0.15)",
  color: "#FFFFFF",
  textTransform: "uppercase",
  padding: "5px",
};

const styles = {
  waveContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "12rem 4rem 1rem",

    margin: "auto",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "2rem 12rem",
  },
};

const TABLE_ROWS = 10;

const LoaderWrapper = styled.div`
  height: calc(100vh - 13rem);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainContainer = styled.div`
  min-height: 70vh;

  .audio-heading {
    color: #142945;
    font-family: Muli;
    font-size: 2.2rem;
    font-weight: 700;
    letter-spacing: 0.18px;
    margin: 2.5rem 5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    justify-content: space-between;

    .save-status {
      font-size: 1.5rem;
      opacity: 0.8;
      color: red;
    }
  }

  .audio-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5rem 10rem 0rem 10rem;
    color: #142945;
    font-family: Muli;
    font-size: 2.2rem;
    font-weight: 700;
    letter-spacing: 0.18px;
    .buttons {
      display: flex;
    }
  }

  .waveform-class {
    @media only screen and (max-width: 1600px) {
      width: ${(props) => (props.showredactbuttons ? "80vw" : "58vw")};
    }

    @media only screen and (max-width: 1450px) {
      width: ${(props) => (props.showredactbuttons ? "80vw" : "55.5vw")};
    }

    @media only screen and (max-width: 1360px) {
      width: ${(props) => (props.showredactbuttons ? "80vw" : "53.5vw")};
    }

    width: ${(props) => (props.showredactbuttons ? "80vw" : "59vw")};
    margin-left: 40px;
    position: relative;
  }

  .buttons-container {
    display: flex;
  }
`;

const NOT_SAVED = "Recent changes not saved";
const SAVE_FAILED = "Unable to save work";

const SaveStatus = styled.div`
  font-size: 1.3rem;
  opacity: 0.5;
  color: ${(props) => props.color};
`;

const AudioRedaction = (props) => {
  const [loading, setLoading] = useState(true);
  const [isRedacting, setIsRedacting] = useState(false);
  const [speed, setSpeed] = useState("1.0");
  const [showAddModal, setAddModal] = useState(false);
  const [pdfModal, setPDFModal] = useState(false);
  const [saveStatus, setsaveStatus] = useState("");

  const [currentPage, setCurrentPage] = useState(0);
  const currentPageRef = useRef(currentPage);
  const setCurrentPageRef = (data) => {
    currentPageRef.current = data;
    setCurrentPage(data);
  };

  const wavesurfer = useRef();
  const interval = useRef();

  useEffect(() => {
    if (!props.url) {
      return;
    }

    loadWavesurfer(props.url, props.peaks);
    return () => {
      removeAllEventListeners();
    };
    // eslint-disable-next-line
  }, [props.url]);

  useEffect(() => {
    if (isRedacting) {
      clearInterval(interval.current);
      return;
    }

    if (interval.current) {
      clearInterval(interval.current);
    }

    interval.current = setInterval(() => {
      handleSave(props.regions, false);
    }, 60 * 1000);

    return () => {
      clearInterval(interval.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.regions, props._id, isRedacting]);

  useEffect(() => {
    if (!wavesurfer.current) {
      return;
    }

    // Remove previous event listener to take effect of new cropType
    wavesurfer.current.un("audioprocess");

    wavesurfer.current.on("audioprocess", (e) =>
      playListener(e, props.cropType)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cropType]);

  const removeAllEventListeners = () => {
    try {
      document.removeEventListener("click", clickListener);

      if (wavesurfer.current) {
        wavesurfer.current.empty();
        wavesurfer.current.unAll();
        document.removeEventListener("keypress", addKeyboardListener);
      }
    } catch {}
  };

  const handlePageClick = (data) => {
    setCurrentPageRef(data.selected);
  };

  const playListener = (e, cropType) => {
    const keys = Object.keys(wavesurfer.current.regions.list);

    let regionIn = false;
    for (let wkey of keys) {
      const start = wavesurfer.current.regions.list[wkey].start;
      const end = wavesurfer.current.regions.list[wkey].end;

      if (e >= start && e <= end) {
        regionIn = true;
        break;
      }
    }

    if (regionIn && (props.showredactbuttons || cropType === "redact")) {
      wavesurfer.current.setVolume(0);
    } else {
      const volumeBar = document.getElementById("wavesurfer-volume");
      wavesurfer.current.setVolume(volumeBar.value);
    }
  };

  const addKeyboardListener = (e) => {
    keyBoardListeners(e, wavesurfer.current, setSpeed);
  };

  const setTitle = (regionsArr) => {
    for (let region of regionsArr) {
      const regionsUI = document.getElementsByTagName("region");

      for (let rUI of regionsUI) {
        const dataID = rUI.getAttribute("data-id");
        if (dataID === region.id) {
          if (props.showredactbuttons) {
            rUI.title = `${toHHMMSS(region.start)} - ${toHHMMSS(region.end)}`;
          } else {
            rUI.title = `${region.start} - ${region.end}`;
          }
        }
      }
    }
  };

  const mergeRegions = () => {
    let keys = Object.keys(wavesurfer.current.regions.list)
      .slice()
      .sort(
        (a, b) =>
          wavesurfer.current.regions.list[a].start -
          wavesurfer.current.regions.list[b].start
      );

    let i = 0;
    while (i < keys.length - 1) {
      const current = wavesurfer.current.regions.list[keys[i]];
      const next = wavesurfer.current.regions.list[keys[i + 1]];

      // If no overlapping continue to next region
      if (current.end < next.start) {
        i++;
        continue;
      }

      if (next.end > current.end) {
        current.onResize(next.end - current.end);
      }

      // Copy notes of the next to current
      let notes = current.data.notes || "";
      if (next.data.notes) {
        notes = notes ? `${notes} ${next.data.notes}` : next.data.notes;
      }

      current.data.notes = notes;
      next.remove();
      keys.splice(i + 1, 1);
    }

    const regionsArr = keys.map((wkey) => {
      let start = wavesurfer.current.regions.list[wkey].start;
      let end = wavesurfer.current.regions.list[wkey].end;

      if (!props.showredactbuttons) {
        start = toHHMMSS(start);
        end = toHHMMSS(end);
      }

      return {
        id: wavesurfer.current.regions.list[wkey].id,
        start,
        end,
        notes: wavesurfer.current.regions.list[wkey].data.notes || "",
      };
    });

    setTitle(regionsArr);
    setsaveStatus(NOT_SAVED);

    if (regionsArr.length < currentPageRef.current * TABLE_ROWS) {
      setCurrentPageRef(0);
    }

    props.setRegions(regionsArr);
  };

  const handleEdit = (id, start, end, notes) => {
    const keys = Object.keys(wavesurfer.current.regions.list);
    for (let keyID of keys) {
      if (wavesurfer.current.regions.list[keyID].id !== id) {
        continue;
      }

      wavesurfer.current.regions.list[keyID].data.notes = notes;

      const startDiff =
        toSec(start) - wavesurfer.current.regions.list[keyID].start;
      wavesurfer.current.regions.list[keyID].onResize(startDiff, "start");

      const endDiff = toSec(end) - wavesurfer.current.regions.list[keyID].end;
      wavesurfer.current.regions.list[keyID].onResize(endDiff, "end");
    }

    mergeRegions();
  };

  const handleAdd = (start, end, notes, id, checkOverlap = true) => {
    const newdata = {
      start: toSec(start),
      end: toSec(end),
      color: "rgba(254,10,50,0.2)",
      data: { notes: notes || "" },
    };

    if (id) {
      newdata.id = id;
    }

    wavesurfer.current.addRegion(newdata);

    if (checkOverlap) {
      mergeRegions();
    }
    setAddModal(false);
  };

  const handleDelete = (id) => {
    const keys = Object.keys(wavesurfer.current.regions.list);
    for (let wkey of keys) {
      if (wavesurfer.current.regions.list[wkey].id !== id) {
        continue;
      }

      // Unmute if region is deleted and current time is in region
      if (
        wavesurfer.current.getCurrentTime() >
          wavesurfer.current.regions.list[wkey].start &&
        wavesurfer.current.getCurrentTime() <
          wavesurfer.current.regions.list[wkey].end
      ) {
        const volumeBar = document.getElementById("wavesurfer-volume");
        wavesurfer.current.setVolume(volumeBar.value);
      }

      // Delete the region
      wavesurfer.current.regions.list[wkey].remove();
    }

    const newkeys = Object.keys(wavesurfer.current.regions.list);
    const regionsArr = newkeys.map((wkey) => {
      return {
        id: wavesurfer.current.regions.list[wkey].id,
        start: props.showredactbuttons
          ? wavesurfer.current.regions.list[wkey].start
          : toHHMMSS(wavesurfer.current.regions.list[wkey].start),
        end: props.showredactbuttons
          ? wavesurfer.current.regions.list[wkey].end
          : toHHMMSS(wavesurfer.current.regions.list[wkey].end),
        notes: wavesurfer.current.regions.list[wkey].data.notes || "",
      };
    });

    setsaveStatus(NOT_SAVED);
    props.setRegions(regionsArr);
  };

  const handleSave = async (rgns, showNotification = true) => {
    try {
      const url = `${keys.SERVER_URL}/audio/${props._id}/save`;
      const res = await axios.post(url, { metadata: { muteRegions: rgns } });

      if (res.status === 200) {
        setsaveStatus("");
        if (showNotification) {
          successNotification("Successfully Saved");
        }
      } else {
        setsaveStatus(SAVE_FAILED);
        if (showNotification) {
          errorNotification("Unable to save the work");
        }
      }
    } catch (e) {
      setsaveStatus(SAVE_FAILED);
    }
  };

  const handleRedact = async () => {
    setIsRedacting(true);
    wavesurfer.current.stop();

    // Save the current work
    try {
      const saveUrl = `${keys.SERVER_URL}/audio/${props._id}/save`;
      const saveRes = await axios.post(
        saveUrl,
        { metadata: { muteRegions: props.regions } },
        { requestId: "saveAudioMetadata" }
      );

      if (saveRes.status !== 200) {
        setIsRedacting(false);
        errorNotification("Unable to generate the audio");
        return;
      }
    } catch (e) {
      setIsRedacting(false);
      errorNotification("Unable to generate the audio");
    }

    // Start redacting
    try {
      const url = `${keys.SERVER_URL}/audio/${props._id}/redact`;
      const res = await axios.get(url);
      setIsRedacting(false);
      if (res.status === 200) {
        successNotification("Successfully generated the audio");
        props.history.push("/video/redacted/audio");
      } else {
        errorNotification("Unable to generate the audio");
      }
    } catch (e) {
      console.log(e);
      setIsRedacting(false);
      errorNotification("Unable to generate the audio");
    }
  };

  const clickListener = () => {
    if (document.activeElement.tagName === "BODY") {
      document.addEventListener("keypress", addKeyboardListener);
    } else {
      document.removeEventListener("keypress", addKeyboardListener);
    }
  };

  const readyListener = () => {
    wavesurfer.current.on("ready", () => {
      wavesurfer.current.on("audioprocess", (e) =>
        playListener(e, props.cropType)
      );
      regionCreatedListener(wavesurfer.current);
      regionMouseEnterListener(wavesurfer.current, handleDelete);
      regionMouseLeaveListener(wavesurfer.current);
      regionUpdateEndListener(wavesurfer.current, mergeRegions);
      setLoading(false);

      if (!props.showredactbuttons) {
        props.setDuration(toHHMMSS(wavesurfer.current.getDuration()));
      }

      wavesurfer.current.on("zoom", () =>
        setTimeout(() => setTitle(props.regions), 100)
      );

      wavesurfer.current.clearRegions();
      for (let rgn of props.regions) {
        if (props.showredactbuttons) {
          handleAdd(
            toHHMMSS(rgn.start),
            toHHMMSS(rgn.end),
            rgn.notes || "",
            rgn.id,
            false
          );
        } else {
          handleAdd(rgn.start, rgn.end, rgn.notes || "", rgn.id, false);
        }
      }

      document.addEventListener("click", clickListener);
    });
  };

  const loadWavesurfer = (audioURL, peaks = []) => {
    wavesurfer.current = WaveSurfer.create({
      container: "#waveform",
      progressColor: colors.brand,
      backend: "MediaElement",
      mediaType: "audio",
      normalize: true,
      plugins: [
        RegionsPlugin.create({
          regions: [],
        }),
        TimelinePlugin.create({
          container: "#wave-timeline",
        }),
        CursorPlugin.create({
          opacity: 1,
          showTime: true,
          formatTimeCallback: formatTimeCallback,
          customShowTimeStyle: {
            "background-color": "#000",
            color: "#fff",
            padding: "2px",
            "font-size": "10px",
          },
        }),
      ],
    });

    if (audioURL.startsWith("http")) {
      wavesurfer.current.load(audioURL, peaks);
    } else {
      wavesurfer.current.load(`${keys.SERVER_URL}/static/${audioURL}`, peaks);
    }
    wavesurfer.current.enableDragSelection({ color: "rgba(254,10,50,0.2)" });

    wavesurfer.current.on("error", () => {
      errorNotification("Unable to load the audio");
    });

    readyListener();
  };

  const toHHMMSSRegions = (inRegios) =>
    inRegios.map((rgn) => {
      return { ...rgn, start: toHHMMSS(rgn.start), end: toHHMMSS(rgn.end) };
    });

  const handlePrintClick = async () => {
    setPDFModal(true);
  };

  return (
    <LoadingOverlay
      isActive={isRedacting}
      loaderMessage={`Redacting`}
      background="rgba(0, 0, 0, 0.5)"
      height="100vh"
    >
      {showAddModal && (
        <AddRegionModal
          isVisible={showAddModal}
          handleAdd={handleAdd}
          duration={wavesurfer.current.getDuration()}
          handleCancel={() => setAddModal(false)}
        />
      )}

      {pdfModal && (
        <AudioPDFModal
          isVisible={pdfModal}
          regions={
            props.showredactbuttons
              ? toHHMMSSRegions(props.regions)
              : props.regions
          }
          name={props.name || props.originalName || "Unknown"}
          duration={toHHMMSS(wavesurfer.current.getDuration())}
          handleCancel={() => setPDFModal(false)}
        />
      )}

      <MainContainer showredactbuttons={props.showredactbuttons}>
        <div className="audio-heading">
          <div>
            <span style={{ opacity: "0.5", marginRight: "0rem" }}>
              {"Audio > "}
            </span>
            {props.name || props.originalName || ""}
          </div>

          <SaveStatus color={saveStatus === NOT_SAVED ? "black" : "red"}>
            {saveStatus}
          </SaveStatus>
        </div>

        {loading && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}

        <div style={styles.waveContainer}>
          {!loading && <ZoomComponent wavesurfer={wavesurfer.current} />}

          <div>
            <div className="waveform-class" id="waveform" />
            <div className="waveform-class" id="wave-timeline" />
          </div>
        </div>

        {!loading && (
          <React.Fragment>
            <div style={styles.buttonContainer}>
              <div className="play-volume-container">
                <PlayAndPause wavesurfer={wavesurfer.current} />
                <VolumeBar wavesurfer={wavesurfer.current} />
                <DropDown
                  options={[
                    { label: "0.25x", value: "0.25" },
                    { label: "0.5x", value: "0.5" },
                    { label: "1x", value: "1.0" },
                    { label: "2x", value: "2.0" },
                    { label: "4x", value: "4.0" },
                  ]}
                  width="70px"
                  value={speed}
                  handleChange={(value) => {
                    if (wavesurfer.current) {
                      wavesurfer.current.setPlaybackRate(value);
                      setSpeed(value);
                    }
                  }}
                />
              </div>

              <div className="buttons-container">
                <IconButton
                  backgroundColor="white"
                  margin="0 3rem"
                  title="Generate PDF"
                  onClick={handlePrintClick}
                  background={`url(${icons.IC_Printer}) center center / 20px 18px no-repeat`}
                />

                {props.showredactbuttons && (
                  <React.Fragment>
                    <Button
                      {...buttonProps}
                      style={{ display: "inline-block", marginRight: "2rem" }}
                      onClick={() => handleSave(props.regions)}
                    >
                      Save Work
                    </Button>
                    <Button
                      {...buttonProps}
                      style={{ display: "inline-block" }}
                      onClick={handleRedact}
                    >
                      Generate Redacted Audio
                    </Button>
                  </React.Fragment>
                )}
              </div>
            </div>

            {props.showredactbuttons && (
              <React.Fragment>
                <div className="audio-header-wrapper">
                  <p>Audio List</p>
                  <Pagination
                    currentPage={currentPageRef.current}
                    pageCount={
                      props.regions.length > TABLE_ROWS
                        ? props.regions.length / TABLE_ROWS
                        : 1
                    }
                    handlePageClick={handlePageClick}
                  />
                  <Button
                    boxShadow="0 2px 10px 0 rgba(44,137,255,0.06)"
                    height="36"
                    width="143"
                    color="#fff"
                    fontSize="1.4rem"
                    margin="0rem 1rem"
                    borderRadius="3rem"
                    background={colors.brand}
                    style={{ textTransform: "none" }}
                    onClick={() => setAddModal(true)}
                  >
                    <FaPlus /> &nbsp;
                    <div style={{ marginLeft: "0.5rem" }}>Add Region</div>
                  </Button>
                </div>

                <AudioTable
                  regions={props.regions
                    .slice(
                      currentPageRef.current * TABLE_ROWS,
                      currentPageRef.current * TABLE_ROWS + TABLE_ROWS
                    )
                    .map((rgn) => ({
                      ...rgn,
                      start: toHHMMSS(rgn.start),
                      end: toHHMMSS(rgn.end),
                    }))}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  duration={wavesurfer.current.getDuration()}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </MainContainer>
    </LoadingOverlay>
  );
};

export default withRouter(AudioRedaction);
