import React, { Component } from "react";

class KeyboardShortcuts extends Component {
  componentDidMount() {
    this.fps = 24;
    this.video = document.getElementById("ff-video");
    this.videodata = this.props.videodata;
  }

  componentDidUpdate(nextProps) {
    if (
      nextProps.videodata &&
      nextProps.videodata.loaded &&
      nextProps.videodata.loaded !== this.videodata.loaded
    ) {
      this.videodata = nextProps.videodata;

      document.addEventListener("click", () => {
        if (document.activeElement.tagName === "BODY") {
          document.addEventListener("keypress", this.keyPress);
          document.addEventListener("keydown", this.keyDown);
        } else {
          document.removeEventListener("keypress", this.keyPress);
          document.removeEventListener("keydown", this.keyDown);
        }
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keypress", this.keyPress);
    document.removeEventListener("keydown", this.keyDown);
  }

  keyPress = (e) => {
    e.preventDefault();
    if (!this.videodata.loaded) {
      return;
    }

    let pauseTime;
    let intervalId;
    let rewindBy;
    let rewind = false;

    if (e.keyCode === 86 || e.keyCode === 118) {
      // v
      this.video.pause();
      rewind = true;
      rewindBy = 0.5;
    }
    if (e.keyCode === 66 || e.keyCode === 98) {
      // b
      this.video.pause();
      rewind = true;
      rewindBy = 1.0;
    }
    if (e.keyCode === 78 || e.keyCode === 110) {
      // n
      this.video.pause();
      rewind = true;
      rewindBy = 3.0;
    }
    if (e.keyCode === 77 || e.keyCode === 109) {
      // m
      this.video.pause();
      rewind = true;
      rewindBy = 5.0;
    }

    if (rewind) {
      pauseTime = this.video.currentTime;
      this.video.currentTime = this.video.currentTime - rewindBy;
      intervalId = setInterval(() => {
        if (this.video.paused) {
          clearInterval(intervalId);
        } else if (this.video.currentTime >= pauseTime) this.video.pause();
      }, 25);
      this.props.play();
    }

    switch (e.keyCode) {
      case 32: {
        this.props.play();
        break;
      }

      case 49: {
        this.props.setRate(0.5);
        break;
      }
      case 50: {
        this.props.setRate(1);
        break;
      }
      case 51: {
        this.props.setRate(1.5);
        break;
      }
      case 52: {
        this.props.setRate(2);
        break;
      }

      case 68: //d
      case 100: {
        let shift = 1;
        this.video.pause();
        if (e.shiftKey) {
          shift = 5;
        }

        this.video.currentTime -= shift / this.fps + 0.00001;
        break;
      }
      case 70: //f
      case 102: {
        let shift = 1;
        this.video.pause();
        if (e.shiftKey) {
          shift = 5;
        }

        this.video.currentTime += shift / this.fps + 0.00001;
        break;
      }
      case 103: //g
      case 71: {
        const pauseTime = this.video.currentTime + 0.5 + 0.00001;
        const intervalId = setInterval(() => {
          if (this.video.paused) {
            clearInterval(intervalId);
          } else {
            if (this.video.currentTime >= pauseTime) this.video.pause();
          }
        }, 25);
        this.video.play();
        break;
      }
      case 104: //h
      case 72: {
        const pauseTime = this.video.currentTime + 1.0 + 0.00001;
        const intervalId = setInterval(() => {
          if (this.video.paused) {
            clearInterval(intervalId);
          } else {
            if (this.video.currentTime >= pauseTime) this.video.pause();
          }
        }, 25);
        this.video.play();
        break;
      }
      case 106: //j
      case 74: {
        const pauseTime = this.video.currentTime + 3.0 + 0.00001;
        const intervalId = setInterval(() => {
          if (this.video.paused) {
            clearInterval(intervalId);
          } else {
            if (this.video.currentTime >= pauseTime) this.video.pause();
          }
        }, 25);
        this.video.play();
        break;
      }
      case 107: //k
      case 75: {
        const pauseTime = this.video.currentTime + 5.0 + 0.00001;
        const intervalId = setInterval(() => {
          if (this.video.paused) {
            clearInterval(intervalId);
          } else {
            if (this.video.currentTime >= pauseTime) this.video.pause();
          }
        }, 25);
        this.video.play();
        break;
      }
      default:
        return 0;
    }
  };

  keyDown = (e) => {
    if (!this.videodata.loaded) {
      return;
    }

    e.stopPropagation();
    e.cancelBubble = true;

    switch (e.keyCode) {
      case 27: {
        // Esc key
        e.preventDefault();
        if (this.props.fullScreenMode) {
          this.props.toggleFullScreen();
        }
        break;
      }

      case 37: {
        // left arrow
        e.preventDefault();
        const secs = e.shiftKey ? 15 : 5;
        this.video.currentTime -= secs + 0.00001;
        break;
      }
      case 39: {
        // right arrow
        e.preventDefault();
        const secs = e.shiftKey ? 15 : 5;
        this.video.currentTime += secs + 0.00001;
        break;
      }
      default:
        return 0;
    }
  };

  render() {
    return <div />;
  }
}

export default KeyboardShortcuts;
