export const userConstants = {
  REQUEST: "USERS_REQUEST",
  ERORRS: "USERS_ERORRS",
  SET_CURRENT_USER: "SET_CURRENT_USER",
  CLEAR_ERRORS: "USERS_CLEAR_ERRORS",
  LOGOUT: "USERS_LOGOUT",
  SET_LOADING_FALSE: "USERS_SET_LOADING_FALSE",
  UPDATE_PROFILE_REQUEST: "USERS_UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_SUCCESS: "USERS_UPDATE_PROFILE_SUCCESS"
};
