import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import LoadingOverlay from "react-loading-overlay";

const StyledLoader = styled(LoadingOverlay)`
  max-height: calc(100vh - 6.8rem);
  overflow: ${(props) => (props.isActive ? "hidden" : "auto")};
  .MyLoader_overlay {
    z-index: 800;
    top: -3rem;
    background: ${(props) => props.background};
    height: ${(props) => props.height};
  }
  .MyLoader_content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 1.6rem;
  }
`;

export default function MyLoadingOverlay(props) {
  return (
    <StyledLoader
      active={props.isActive}
      spinner
      text={props.loaderMessage}
      classNamePrefix="MyLoader_"
    >
      {props.children}
    </StyledLoader>
  );
}

MyLoadingOverlay.propTypes = {
  isActive: PropTypes.bool,
  loaderMessage: PropTypes.string,
  background: PropTypes.string,
};

MyLoadingOverlay.defaultProps = {
  transY: "0px",
  background: "rgba(0, 0, 0, 0.7)",
  height: "calc(100% + 4.5rem)",
};
