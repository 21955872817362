import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Link, Redirect } from "react-router-dom";

import Background from "../../components/Form/Background";
import Button from "../../components/Form/Button";
import Input from "../../components/common/Form/MaterialInput";
import loader from "../../assets/img/loader.svg";
import { loginUser, clearValidationErrors } from "../../actions";
import { colors } from "../../components/themes/base";

const Wrapper = styled.div`
  display: flex;
  div:first-child {
    @media only screen and (min-width: ${(props) => props.mdBreakpoint}) {
      width: 68vw;
    }
    @media only screen and (max-width: ${(props) => props.mdBreakpoint}) {
      display: none;
    }
  }
  div:last-child {
    @media only screen and (max-width: ${(props) => props.mdBreakpoint}) {
      width: 70vw;
    }
  }

  .login-wrapper {
    background: ${(props) => props.background};
    width: 20vw;
    height: 100vh;
    font-family: ${(props) => props.fontFamily};
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: ${(props) => props.margin};
    div:first-child {
      @media only screen and (max-width: ${(props) => props.mdBreakpoint}) {
        width: 70vw;
      }
    }

    .title {
      max-width: 100%;
      color: #142945;
      display: block !important;
      font-weight: 600;
      font-size: 3rem;
      margin-bottom: 5rem;
    }

    a {
      color: #00b2e6;
      cursor: pointer;
      font-size: 1.4rem;
    }

    .forgot-password {
      margin-bottom: 2.5rem;
      text-align: right;
      a {
        text-decoration: none;
      }
    }

    .signup {
      margin-bottom: 6.3rem;
      font-size: 1.4rem;
      color: #000000;
      span {
        opacity: 0.5;
      }
    }

    .copyright {
      margin-top: 6.3rem;
      font-size: 1.2rem;
      color: #142945;
      opacity: 0.3;
    }
  }
`;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }

  onChange = (e) => {
    // clear validation errors
    this.props.clearValidationErrors();

    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleLogin = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    this.props.loginUser({ email, password }, this.props.history);
  };

  handleGoogleLogin = (e) => {
    e.preventDefault();
  };

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    }
    return (
      <Wrapper {...this.props.styles}>
        <div className="backgroundWrapper">
          <Background />
        </div>
        <div className="login-wrapper">
          <div className="title">Log in to your account</div>
          <Input
            label="Email Address"
            placeholder="Email Address"
            name="email"
            handleChange={this.onChange}
            value={this.state.email}
            error={this.props.errors.email}
          />
          <Input
            label="Enter Password"
            placeholder="Enter Password"
            type="password"
            name="password"
            handleChange={this.onChange}
            value={this.state.password}
            error={this.props.errors.password}
          />
          <span className="forgot-password">
            <Link to="/forgotPassword">Forgot Password?</Link>
          </span>
          <Button
            background={colors.primary}
            color="#fff"
            onClick={this.handleLogin}
            textTransform="none"
            fontSize="1.8rem"
            fontWeight="normal"
          >
            Log in&nbsp;&nbsp;
            {this.props.loading ? <img src={loader} alt="loader" /> : null}
          </Button>
          {/* <Button
            background="#F66457"
            color="#fff"
            onClick={this.handleGoogleLogin}
            marginBottom={5}
            textTransform="none"
            fontSize="1.8rem"
            fontWeight="normal"
          >
            Log in with Google&nbsp; &nbsp;
          </Button> */}
          {/* <div className="signup">
            <span>Need a Video Redactor account?&nbsp;</span>
            <Link to="/signup">Create an account</Link>
          </div> */}
          <div className="copyright">
            &copy;2023 All Rights Reserved. Suspect Technologies&reg; Ltd.
            <br />
          </div>
        </div>
      </Wrapper>
    );
  }
}

Login.defaultProps = {
  styles: {
    width: "30rem",
    background: "#FFFFFF",
    fontFamily: '"Muli", sans-serif',
    margin: "auto",
    mdBreakpoint: "900px",
  },
};

const mapStateToProps = (state) => ({
  errors: state.user.errors,
  isAuthenticated: state.user.isAuthenticated,
  loading: state.user.loading,
});

export default connect(mapStateToProps, { loginUser, clearValidationErrors })(
  Login
);
