import PropTypes from "prop-types";
import styled from "styled-components";

import { colors } from "../themes/base";

const ToolbarPropDivider = styled.div`
  box-sizing: border-box;
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  border-bottom: ${(props) => props.border};
`;

export default ToolbarPropDivider;

ToolbarPropDivider.propTypes = {
  height: PropTypes.string,
  margin: PropTypes.string,
  border: PropTypes.string,
};

ToolbarPropDivider.defaultProps = {
  height: "1px",
  margin: "auto 1rem",
  border: `0.3px dashed ${colors.s_extralight}`,
};
