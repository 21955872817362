import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { withRouter } from "react-router-dom";

import keys from "../../config/keys";
import {
  successNotification,
  warningNotification,
  errorNotification,
} from "../../actions";

import Image from "../common/Image";
import Loader from "../../components/Loader/Loader";

const headerStyle = {
  color: "#142945",
  fontFamily: "Muli",
  fontSize: "2.2rem",
  fontWeight: "700",
  letterSpacing: "0.18px",
  margin: "2.5rem 5rem",
  padding: "0 2rem",
};

const StyledPage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
  border-radius: 3px;
  margin: 2rem auto;
  background: ${(props) => props.background};

  .text-wrapper {
    font-size: 22px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .main-images {
    width: 15%;
    height: 70rem;
    min-height: 75vh;
    margin: 1rem 0;
    overflow: auto;
    text-align: center;

    .selected-button {
      background: rgba(63, 184, 247, 0.5);
    }

    button {
      background: transparent;
      width: 100%;
      height: 15.5rem;
      border: 0;
      padding: 0 1rem;
      overflow: hidden;
      &:focus {
        outline: 0;
      }
      &:not(.selected-button):hover {
        background: rgba(63, 184, 247, 0.2);
      }

      img {
        width: 9.6rem;
        height: 12.8rem;
        border-radius: 4px;
        margin: 1rem 0;
        object-fit: cover;
      }
    }
  }

  .match-images {
    width: 85%;

    .no-images-text {
      position: relative;
      font-size: 22px;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    .image-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 1rem 1rem;
      max-height: 70rem;
      overflow: auto;

      div {
        width: 9.6rem;
        margin: 0.6rem;
        text-align: center;
        height: 15.5rem;
        font-size: 15px;
        border: 2px solid rgba(63, 184, 247, 0.5);
        border-radius: 4px;
        img {
          width: 100%;
          height: 12.8rem;
          object-fit: cover;
          border-radius: 4px;
        }
        p {
          margin: 0;
        }
      }
    }
  }
`;

const RenderImages = (props) => {
  if (!props.similarFaces) {
    return;
  }

  const sFaceKeys = Object.keys(props.similarFaces);
  if (!sFaceKeys) {
    return;
  }

  const baseUrl = `${keys.SERVER_URL}/static/${props.orgName}`;
  return sFaceKeys.map((imgSrc) => {
    const accuracy = props.similarFaces[imgSrc]
      ? props.similarFaces[imgSrc]
      : 0;

    return (
      <div key={uuidv4()}>
        <Image src={`${baseUrl}/${imgSrc}`} />
        <p>{accuracy}%</p>
      </div>
    );
  });
};

const RenderClickableImages = (props) => {
  if (!props.faces || !props.faces.length) {
    return;
  }

  const baseUrl = `${keys.SERVER_URL}/static/${props.orgName}`;
  return props.faces.map((faceImg) => (
    <button
      key={uuidv4()}
      className={props.selectedUser === faceImg ? "selected-button" : ""}
      onClick={() => props.handleSelect(faceImg)}
    >
      <Image src={`${baseUrl}/${faceImg}`} />
    </button>
  ));
};

class GroupResult extends Component {
  state = {
    loading: false,
    groups: {},
    groupKeys: [],
    selectedUser: null,
    orgName: "",
  };

  async componentDidMount() {
    this.fetchImages();
  }

  fetchImages = async () => {
    this.setState({ loading: true });
    try {
      const { data } = await axios.get(
        `${keys.SERVER_URL}/group/${this.props.match.params.groupid}/result`
      );

      if (!data.result || !data.result.groups) {
        errorNotification({ title: "No data found" });
        return;
      }

      const groupKeys = Object.keys(data.result.groups);
      this.setState({
        groups: data.result.groups,
        groupKeys: groupKeys,
        selectedUser: groupKeys[0] ? groupKeys[0] : null,
        loading: false,
        orgName: data.orgName,
      });
    } catch (err) {
      errorNotification({ title: "Error while fetching the data" });
      this.setState({ loading: false });
    }
  };

  handleSelect = (face) => {
    this.setState({ selectedUser: face });
  };

  render() {
    const { state, props } = this;

    if (state.loading) {
      return (
        <div
          style={{
            width: "100%",
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      );
    }

    if (!state.groupKeys || state.groupKeys.length === 0) {
      return (
        <React.Fragment>
          <div style={headerStyle}>
            <span style={{ opacity: "0.5" }}>
              Group Faces {">"} {this.props.match.params.groupid}
            </span>
          </div>
          <StyledPage {...props}>
            <div className="text-wrapper">
              <p>No Images found</p>
            </div>
          </StyledPage>
        </React.Fragment>
      );
    }

    let similarFacesLength = 0;
    if (
      state.selectedUser &&
      state.groups &&
      state.groups[state.selectedUser]
    ) {
      similarFacesLength = Object.keys(state.groups[state.selectedUser]).length;
    }

    return (
      <div>
        <div style={headerStyle}>
          <span style={{ opacity: "0.5" }}>
            Group Faces {">"} {this.props.match.params.groupid}
          </span>
        </div>
        <StyledPage {...props}>
          <div className="main-images">
            <RenderClickableImages
              faces={state.groupKeys}
              orgName={state.orgName}
              selectedUser={state.selectedUser}
              handleSelect={this.handleSelect}
            />
          </div>

          <div className="match-images">
            {similarFacesLength !== 0 && (
              <div className="image-container">
                <RenderImages
                  similarFaces={state.groups[state.selectedUser]}
                  orgName={state.orgName}
                />
              </div>
            )}

            {similarFacesLength === 0 && (
              <div className="no-images-text">
                <p>No Images found</p>
              </div>
            )}
          </div>
        </StyledPage>
      </div>
    );
  }
}

const GroupResultWithRouter = withRouter(GroupResult);
export default connect(null, {
  successNotification,
  warningNotification,
  errorNotification,
})(GroupResultWithRouter);

GroupResultWithRouter.propTypes = {
  background: PropTypes.string,
  fontFamily: PropTypes.string,
};

GroupResultWithRouter.defaultProps = {
  background: "#ffffff",
  notes: "",
  fontFamily: '"Muli", sans-serif',
  imageWidth: 800,
  imageHeight: 500,
};
