import React from "react";

export default React.createContext({
  audioList: [],
  setAudioList: list => {},
  redactedaudioList: [],
  setRedactedAudioList: list => {},
  uploading: false,
  setUploading: status => {},
  upPrgs: 0,
  setUpPrgs: progress => {}
});
