import styled from "styled-components";
import PropTypes from "prop-types";

import { colors } from "../themes/base";

const SubProperty = styled.div`
  display: flex;

  position: relative;
  justify-content: ${(props) => props.justifyContent};
  align-items:${(props) => props.alignItems}
  position: relative;
  margin: 15px 10px;
  background: ${(props) => props.background};
  border: ${(props) =>
    props.selected ? `3px solid ${colors.primary}` : "none"};
`;

export default SubProperty;

SubProperty.propTypes = {
  background: PropTypes.string,
  border: PropTypes.string,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
};

SubProperty.defaultProps = {
  background: "none",
  border: "none",
  justifyContent: "space-between",
};
