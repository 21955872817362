import React, { Component } from "react";

import ManageUsers from "../../components/ManageUsers/ManageUsers";
import { connect } from "react-redux";
import {
  editProfile,
  fetchProfiles,
  showUsers,
  queryUser,
  showFilteredUsers,
} from "../../actions";

class ManageUsersComp extends Component {
  componentWillMount() {
    const noUsers =
      !this.props.usersOnPage || this.props.usersOnPage.length === 0;
    if (noUsers) this.props.fetchProfiles();
  }
  render() {
    const { history, editProfile, wusers, loading } = this.props;
    return (
      <ManageUsers
        usersOnPage={this.props.usersOnPage}
        filteredUsers={this.props.filteredUsersOnPage}
        filteredUsersPageCount={this.props.filteredUsersPageCount}
        pageCount={this.props.pageCount}
        query={this.props.query}
        history={history}
        loading={loading}
        queryUser={this.props.queryUser}
        users={wusers}
        addProfile={() => history.push("/facesearch/watchlist/add")}
        handleEditProfile={(id) => {
          history.push(`/facesearch/watchlist/view/${id}`);
          editProfile(id);
        }}
        showUsers={this.props.showUsers}
        showFilteredUsers={this.props.showFilteredUsers}
      />
    );
  }
}

function mapStateToProps({ wusers }) {
  return {
    query: wusers.query,
    filteredUsersOnPage: wusers.filteredUsersOnPage,
    filteredUsersPageCount: wusers.filteredUsersPageCount,
    loading: wusers.loading,
    pageCount: wusers.pageCount,
    usersOnPage: wusers.usersOnPage,
  };
}
export default connect(mapStateToProps, {
  editProfile,
  fetchProfiles,
  showUsers,
  queryUser,
  showFilteredUsers,
})(ManageUsersComp);
