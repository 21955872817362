import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors } from "../themes/base";

const StyledWrapper = styled.div`
  height: 34px;
  width: 100%;
  display: flex;
  padding: ${(props) => props.padding};
  border-radius: 2px;
  align-items: center;
  justify-content: ${(props) => props.justifyContent};
  background-color: ${(props) => props.background};

  color: ${colors.s_light};
  font-family: Muli;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 15px;
  text-transform: capitalize;

  .input-container {
    font-weight: bolder;
    color: ${colors.secondary};

    input {
      border: 0;
      width: 14px;
      font-size: 12px;
      text-align: center;
      font-weight: 400;
      background: transparent;
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      [type="number"] {
      }

      :focus {
        outline: 0;
      }
    }

    .sec-input {
      width: 30px;
      text-align: start;
    }
  }
`;

function handleChange(hr, min, sec) {
  return `${hr}:${min}:${sec}`;
}

function ToolbarTextInput(props) {
  const [hr, setHr] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  useEffect(() => {
    setHr(props.value.split(":")[0]);
    setMin(props.value.split(":")[1]);
    setSec(props.value.split(":")[2]);
  }, [props.value]);

  return (
    <StyledWrapper
      background={props.background}
      justifyContent={props.justifyContent}
      padding={props.padding}
    >
      <p>{props.label}</p>
      <div className="input-container">
        <input
          min={0}
          max={props.duration.split(":")[0]}
          type="number"
          value={hr}
          onChange={(e) => {
            setHr(e.target.value);
            const value = handleChange(e.target.value, min, sec);
            props.onChange(value);
          }}
        />
        :
        <input
          min={0}
          max={props.duration.split(":")[1]}
          type="number"
          value={min}
          onChange={(e) => {
            setMin(e.target.value);
            const value = handleChange(hr, e.target.value, sec);
            props.onChange(value);
          }}
        />
        :
        <input
          className="sec-input"
          min={0}
          max={props.duration.split(":")[2]}
          type="number"
          value={sec}
          onChange={(e) => {
            setSec(e.target.value);
            const value = handleChange(hr, min, e.target.value);
            props.onChange(value);
          }}
        />
      </div>
    </StyledWrapper>
  );
}

export default ToolbarTextInput;

ToolbarTextInput.propTypes = {
  padding: PropTypes.string,
  justifyContent: PropTypes.string,
  background: PropTypes.string,
  duration: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

ToolbarTextInput.defaultProps = {
  padding: "0 1rem",
  justifyContent: "space-between",
  background: colors.s_superlight,
  duration: "00:00:10.34",
  label: "Letter Spacing",
  value: "0.00",
  onChange: () => {},
  onClick: () => {},
};
