import React, { useEffect, useRef } from "react";
import { Text, Transformer } from "react-konva";

import { torgba } from "../utils";

function KonvaText(props) {
  const textRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (props.selected && props.visible) {
      trRef.current.nodes([textRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [props.selected, props.visible]);

  return (
    <React.Fragment>
      <Text
        draggable
        ref={textRef}
        {...props}
        fill={torgba(props.fill, props.opacity)}
        onDragEnd={() => {
          const node = textRef.current;
          const x = node.x() / props.sX;
          const y = node.y() / props.sY;
          const scaleX = node.scaleX() / props.sX;
          const scaleY = node.scaleY() / props.sY;
          const rotation = node.rotation();
          props.storeChanges({
            ...props,
            id: props.id,
            text: true,
            x,
            y,
            scaleX,
            scaleY,
            rotation,
          });
          props.handleTextChange(props.id, { x, y });
        }}
      />
      {props.selected && props.visible && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          borderStrokeWidth={1.5}
          borderStroke="#F40050"
          borderDash={[3]}
          anchorFill="#F40050"
          anchorStroke="white"
          anchorStrokeWidth={1}
          anchorCornerRadius={50}
          anchorSize={7}
          rotateAnchorOffset={25}
          onTransformEnd={() => {
            const node = textRef.current;
            const x = node.x() / props.sX;
            const y = node.y() / props.sY;
            const scaleX = node.scaleX() / props.sX;
            const scaleY = node.scaleY() / props.sY;
            const rotation = node.rotation();
            props.storeChanges({
              ...props,
              id: props.id,
              text: true,
              x,
              y,
              scaleX,
              scaleY,
              rotation,
            });
            props.handleTextChange(props.id, {
              scaleX,
              scaleY,
              rotation,
            });
          }}
        />
      )}
    </React.Fragment>
  );
}

export default KonvaText;
