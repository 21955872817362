import React from "react";
import Image from "../common/Image";

const styles = {
  conainerStyle: {
    width: "70%",
    overflowX: "auto",
    overflowY: "hidden",
    display: "flex",
    padding: "1rem 2rem",
    position: "relative",
    marginBottom: "5rem",
    justifyContent: "center"
  },
  imgStyle: {
    height: "13rem",
    maxWidth: "100%",
    objectFit: "cover",
    borderRadius: "3px",
    margin: "2rem 1.5rem",
    opacity: 1,
    transition: "all .3s"
  }
};

const Carousal = props => {
  const { images } = props;

  return (
    <div style={styles.conainerStyle}>
      {images.map((image, i) => (
        <Image
          key={i}
          style={styles.imgStyle}
          src={image.url ? image.url : image}
          alt=""
        />
      ))}
    </div>
  );
};

export default Carousal;
