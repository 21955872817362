import React from "react";
import Controls from "./Controls";
import KeyboardShortcuts from "./KeyboardShortcuts";

class VideoControls extends React.Component {
  state = {
    isPlaying: false,
    isMuted: false,
    duration: 0,
    currentTime: 0,
    currentVolume: 1,
    hasLoaded: false,
    buffered: 0,
    hover: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.video = document.getElementById("ff-video");
    if (!this.video) {
      return;
    }

    this.video.addEventListener("play", () => {
      if (this.mounted) this.setState({ isPlaying: true });
    });

    this.video.addEventListener("pause", () => {
      if (this.mounted) this.setState({ isPlaying: false });
    });

    this.video.addEventListener("loadedmetadata", () => {
      if (this.mounted)
        this.setState({
          duration: this.video.duration,
          hasLoaded: true,
        });
    });

    this.video.addEventListener("timeupdate", () => {
      if (this.state.hasLoaded) {
        const video = this.video;
        let currentTime = video.currentTime;
        if (currentTime > this.state.duration) {
          currentTime = this.state.duration;
        }
        const range = video.buffered;
        const buffered = range.end(0);

        if (this.mounted) this.setState({ currentTime, buffered });
      }
    });

    this.video.addEventListener("volumechange", () => {
      const isMuted = this.video.muted;
      if (isMuted) {
        if (this.mounted) this.setState({ currentVolume: 0 });
      } else {
        if (this.mounted) this.setState({ currentVolume: this.video.volume });
      }
    });

    const videoContainer = document.getElementById("video-container");
    if (!videoContainer) {
      return;
    }

    setTimeout(() => {
      videoContainer.addEventListener("mouseenter", () => {
        if (this.mounted) {
          this.setState({ hover: true });
        }
      });
      videoContainer.addEventListener("mouseleave", () => {
        if (this.mounted) {
          this.setState({ hover: false });
        }
      });
    }, 1000);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  play = () => {
    const { isPlaying } = this.state;
    if (isPlaying) {
      this.video.pause();
    } else {
      this.video.play();
    }
  };

  setCurrentTime = (time) => {
    this.setState({ currentTime: time });
    this.video.currentTime = time;
  };

  muteVideo = () => {
    const { isMuted } = this.state;
    const muted = !isMuted;
    if (isMuted) {
      this.video.muted = muted;
    } else {
      this.video.muted = muted;
    }
    this.setState({ isMuted: muted });
  };

  setVolume = (volume) => {
    this.setState({ currentVolume: volume });
    this.video.volume = volume;
  };

  fastForward = () => {
    if (this.video.currentTime + 2 <= this.video.duration) {
      this.video.currentTime = this.video.currentTime + 2;
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.props.videodata && this.props.videodata.loaded && (
          <Controls
            hidden={!this.props.fullScreenMode && !this.state.hover}
            play={this.play}
            mute={this.muteVideo}
            setCurrentTime={this.setCurrentTime}
            buffered={this.state.buffered}
            setVolume={this.setVolume}
            isPlaying={this.state.isPlaying}
            isMuted={this.state.isMuted}
            duration={this.state.duration}
            currentTime={this.state.currentTime}
            currentVolume={this.state.currentVolume}
            fullScreen={this.props.toggleFullScreen}
            hasLoaded={this.state.hasLoaded}
            zoomIn={this.zoomIn}
            zoomOut={this.zoomOut}
            fastForward={this.fastForward}
          />
        )}

        <KeyboardShortcuts
          fullScreenMode={this.props.fullScreenMode}
          toggleFullScreen={this.props.toggleFullScreen}
          videodata={this.props.videodata}
          play={this.play}
          setRate={this.props.setRate}
        />
      </React.Fragment>
    );
  }
}

export default VideoControls;
