import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { errorNotification } from "../../actions";

import { resetPassword, clearValidationErrors } from "../../actions";
import Background from "../../components/Form/Background";
import Button from "../../components/Form/Button";
import Input from "../../components/common/Form/MaterialInput";
import loader from "../../assets/img/loader.svg";

const Wrapper = styled.div`
  display: flex;
  .bg {
    @media only screen and (min-width: ${(props) =>
        `${props.mdBreakpoint}px`}) {
      width: 68vw;
    }
    @media only screen and (max-width: ${(props) =>
        `${props.mdBreakpoint}px`}) {
      display: none;
    }
  }
  .resetPassword {
    background: ${(props) => props.background};
    width: 20vw;
    height: 100vh;
    font-family: ${(props) => props.fontFamily};
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: ${(props) => props.margin};
    div:first-child {
      @media only screen and (max-width: ${(props) =>
          `${props.mdBreakpoint}px`}) {
        width: 70vw;
      }
    }
    @media only screen and (max-width: ${(props) =>
        `${props.mdBreakpoint}px`}) {
      width: 70vw;
    }
  }
  .header {
    max-width: 100%;
    display: block !important;
    font-weight: 600;
    font-size: 3rem;
    margin-bottom: 5rem;
  }
`;

class ResetPassword extends Component {
  state = {
    newPassword: "",
    confirmPassword: "",
    token: "",
  };

  componentDidMount() {
    this.setState({ token: this.props.match.params.token });
  }

  onChange = (e) => {
    // clear validation errors
    this.props.clearValidationErrors();

    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  resetPassword = (e) => {
    e.preventDefault();
    const { newPassword, confirmPassword, token } = this.state;
    if (newPassword !== confirmPassword) {
      this.props.errorNotification({
        title: "Passwords do not match",
        autoDismiss: 1,
      });
    } else if (newPassword.length === 0) {
      this.props.errorNotification({
        title: "Password cannot be empty",
        autoDismiss: 1,
      });
    } else if (newPassword.length < 6) {
      this.props.errorNotification({
        title: "Password must have atleast 6 characters",
        autoDismiss: 1,
      });
    } else {
      this.props.resetPassword(
        { token: token, password: newPassword },
        this.props.history
      );
    }
  };

  render() {
    return (
      <Wrapper {...this.props.styles}>
        <div className="bg">
          <Background />
        </div>
        <div className="resetPassword">
          <div className="header">Reset Password</div>
          <Input
            label="New Password"
            type="password"
            value={this.state.newPassword}
            placeholder="New Password"
            name="newPassword"
            handleChange={this.onChange}
          />
          <Input
            label="Confirm Password"
            type="password"
            value={this.state.confirmPassword}
            placeholder="Confirm Password"
            name="confirmPassword"
            handleChange={this.onChange}
          />
          <Button
            background="#00B2E6"
            color="#fff"
            onClick={this.resetPassword}
            textTransform="none"
            fontSize="1.8rem"
            fontWeight="normal"
          >
            Reset&nbsp;&nbsp;
            {this.props.loading ? <img src={loader} alt="loader" /> : null}
          </Button>
        </div>
      </Wrapper>
    );
  }
}

ResetPassword.defaultProps = {
  styles: {
    background: "#FFFFFF",
    fontFamily: '"Muli", sans-serif',
    margin: "auto",
    mdBreakpoint: 900,
  },
};

const mapStateToProps = (state) => ({
  loading: state.user.loading,
});

export default connect(mapStateToProps, {
  resetPassword,
  clearValidationErrors,
  errorNotification,
})(ResetPassword);
