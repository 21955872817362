import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import { colors } from "../themes/base";

const Card = styled.div`
  box-sizing: border-box;
  position: relative;
  height: 10rem;
  width: ${(props) => props.cardWidth};
  min-width: 30rem;
  margin-bottom: 1.7rem;
  box-shadow: 0 2px 10px 0 rgba(44, 137, 255, 0.06);
  background: ${(props) => props.background};
  border-radius: 3px;
`;

const Icon = styled.div`
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -2rem;
  left: 2rem;
  height: 6rem;
  width: 6rem;
  border-radius: 3px;
  background-color: ${colors.brand};
  font-size: 3rem;
`;

const Heading = styled.div`
  position: absolute;
  left: 11.5rem;
  top: 1.7rem;
  color: #27374a;
  font-family: ${(props) => `${props.fontFamily}`};
  font-size: 3.6rem;
  font-weight: 600;
  line-height: 4.5rem;
`;

const Subheading = styled.div`
  position: absolute;
  left: 11.5rem;
  top: 6rem;
  opacity: 0.5;
  color: #27374a;
  font-family: ${(props) => `${props.fontFamily}`};
  font-size: 1.4rem;
  line-height: 1.8rem;
`;

const Status = styled.div`
  position: absolute;
  height: 5rem;
  width: 2.3rem;
  top: 2.7rem;
  right: 4rem;
`;

const OverviewCard = (props) => {
  return (
    <Card {...props}>
      <Icon>{props.icon}</Icon>
      <Heading {...props}>{props.heading}</Heading>
      <Subheading {...props}>{props.subheading}</Subheading>
      <Status>{props.status}</Status>
    </Card>
  );
};

export default OverviewCard;

OverviewCard.propTypes = {
  fontFamily: PropTypes.string,
  icon: PropTypes.object,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  cardWidth: PropTypes.string,
  background: PropTypes.string,
};

OverviewCard.defaultProps = {
  fontFamily: '"Muli", sans-serif',
  cardWidth: "32.3%",
  background: "#fff",
};
