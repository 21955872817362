import React from 'react';
import styled, { keyframes } from 'styled-components';

const cssload_spin = keyframes`
  100% {
      transform: rotate(360deg);
      transform: rotate(360deg);
  }
`;

const StyledLoader = styled.div`
  width: 100%;
  height: 10px;
  text-align: center;

  .cssload-zenith {
    width: 10px;
    height: 10px;
    margin: 0 auto;
    border-radius: 50%;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    box-shadow: 1px 1px 0px ${props => props.color};
    animation: ${cssload_spin} 690ms infinite linear;
  }
`;

export default function ModalLoaderIcon() {
  return (
    <StyledLoader className="cssload-container">
      <div className="cssload-zenith"></div>
    </StyledLoader>
  );
}

ModalLoaderIcon.defaultProps = {
  color: '#000',
  transY: '0px'
};
