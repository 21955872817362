import { v4 as uuidv4 } from "uuid";
import SampleData from "./data.json";

import createFastContext from "../createFastContext";

export const { Provider: VideoSearchProvider, useStore: videoSearchUseStore } =
  createFastContext({
    cases: [
      {
        id: uuidv4(),
        name: "Test Case",
        notes: "This is notes for test case",
        createdAt: new Date(),
        videos: [...SampleData],
      },
    ],
  });
