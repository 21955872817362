import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Button from "../../components/common/Button.js";
import { ReactComponent as EmptySVG } from "../../assets/img/empty.svg";
import { colors } from "../themes/base.js";

const StyledNotFound = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  svg {
    margin-top: 4rem;
    display: block;
    pointer-events: none;
    max-height: 25rem;
  }

  .message {
    margin-top: 2rem;
    width: 100%;
    text-align: center;

    h4 {
      font-size: 3rem;
      font-weight: bold;
      margin: 0;
    }

    p {
      font-size: 1.8rem;
      color: grey;
    }

    button {
      margin: 0 auto;
      background: ${colors.brand};
      font-size: 1.6rem;
      box-shadow: 0 1rem 2rem 0 rgba(124, 160, 73, 0.15);
      color: #ffffff;
      padding: 1rem 2rem;
      text-transform: uppercase;
    }
  }
`;

export default function NotFound(props) {
  return (
    <StyledNotFound>
      <EmptySVG />
      <div className="message">
        <h4>{props.title}</h4>
        <p>{props.message}</p>
        {props.button && props.button.text ? (
          <Button height="40px" onClick={props.button.handleButtonClick}>
            {props.button.text}
          </Button>
        ) : null}
      </div>
    </StyledNotFound>
  );
}

NotFound.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  button: PropTypes.shape({
    handleButtonClick: PropTypes.func,
    text: PropTypes.string,
  }),
};
