import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { colors } from "../themes/base";

const StyledLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  transform: translate(${(props) => props.transX}, ${(props) => props.transY});

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    margin: ${(props) => props.margin};
    border: ${(props) => props.border} solid ${(props) => props.color};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) => props.color} transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default function Loader(props) {
  return (
    <StyledLoader className="lds-ring" {...props}>
      <div />
      <div />
      <div />
      <div />
    </StyledLoader>
  );
}

Loader.propTypes = {
  color: PropTypes.string,
  transY: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
  border: PropTypes.string,
};

Loader.defaultProps = {
  color: colors.brand1,
  transY: "0px",
  transX: "0px",
  width: "64px",
  height: "64px",
  margin: "3px",
  border: "3px",
};
