import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

import Image from "../../containers/common/Image";

const CardWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 27rem;
  width: ${props => props.width};
  min-width: 21.2rem;
  padding: 1rem;
  margin-bottom: 2rem;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(44, 137, 255, 0.06);
  font-family: ${props => props.fontFamily};
  border-radius: 0.3rem;
`;

const ProfilePic = styled.div`
  width: 100%;
  height: 16rem;
  border-radius: 3px;
  margin-bottom: 4px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
  }
`;

const Name = styled.div`
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2rem;
  color: #223539;
  font-family: ${props => props.fontFamily};
  margin: 4px 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Details = styled.div`
  opacity: 0.6;
  color: #223539;
  font-family: Muli;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.5rem;
  font-family: ${props => props.fontFamily};
  margin: 4px 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Place = styled.div`
  opacity: 0.6;
  color: #223539;
  font-family: Muli;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.5rem;
  font-family: ${props => props.fontFamily};
  margin: 4px 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const LastUpdated = styled.div`
  opacity: 0.3;
  color: #223539;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3rem;
  font-family: ${props => props.fontFamily};
  margin: 4px 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ProfileCard = props => {
  return (
    <CardWrapper {...props} onClick={props.handleEditProfile}>
      <ProfilePic>
        <Image src={props.profilePic} alt={props.name} type="wuser" />
      </ProfilePic>
      <Name {...props}>{props.name}</Name>
      <Details {...props}>{props.details}</Details>
      <Place {...props}>{props.place}</Place>
      <LastUpdated {...props}>Last Updated: {props.lastUpdated}</LastUpdated>
    </CardWrapper>
  );
};

export default ProfileCard;

ProfileCard.propTypes = {
  fontFamily: PropTypes.string,
  profilePic: PropTypes.string,
  name: PropTypes.string,
  details: PropTypes.string,
  lastUpdated: PropTypes.string,
  place: PropTypes.string,
  handleEditProfile: PropTypes.func,
  width: PropTypes.string
};

ProfileCard.defaultProps = {
  fontFamily: '"Muli", sans-serif',
  width: "18.7%"
};
