export const SET_CASE_NAME = "set_case_name";
export const SET_CASE_NOTES = "set_case_notes";
export const SET_CASE_FILES = "set_case_files";
export const FILTER_USER_LIST = "filter_users_list";
export const SET_CASE_UPLOAD_STATUS = "set_case_upload_status";
export const RESET_CASE_FORM = "reset_case_form";
export const SET_CASE_LIST = "set_case_list";
export const SET_USER_LIST = "set_users_list";
export const SET_SELECTED_CASE = "set_selected_case";
export const SET_SELECTED_USERS = "set_selected_users";
export const ADD_SELECTED_USER = "add_selected_user";
export const REMOVE_SELECTED_USER = "remove_selected_user";
export const LOAD_MORE_SELECTED_USERS_TO_SHOW =
  "load_more_selected_users_to_show";
export const SELECT_ALL = "select_all";
export const SET_PROGRESS = "set_progress";
export const SET_RESULT = "set_result";
export const SET_LOADER = "set_loader";
export const ADD_CASE_FILES = "add_case_files";
export const SET_VIDEO_FILES = "set_video_files";
export const APPEND_VIDEO_FILES = "append_video_files";
export const SET_AUDIO_FILES = "set_audio_files";
export const APPEND_AUDIO_FILES = "append_audio_files";
export const SET_OFFICER_NAME = "set_officer_name";
export const SET_VIDEO_REDACTION_PURPOSE = "set_video_redaction_purpose";
export const SET_RAW_VIDEOS = "set_raw_videos";
export const SET_REDACTED_VIDEOS = "set_redacted_videos";
export const RESET_FORM = "reset_form";
export const SET_UPLOADING = "set_uploading";
export const SET_FILES_UPLOADING_PROGRESS = "set_files_uploading_progress";
export const SET_FILES_UPLOADING_STATUS = "set_files_uploading_status";
export const SET_ENCODING = "set_encoding";
export const SET_FILES_ENCODING_PROGRESS = "set_files_encoding_progress";
export const REMOVE_LOADER = "remove_loader";
export const ADD_REQUEST = "add_request";
export const REMOVE_REQUEST = "remove_request";
export const SET_ENCODE_MODAL = "set_encode_modal";
export const SET_ENCODE_VIDEO_ID = "set_encode_video_id";
