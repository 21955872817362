import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const StyledButton = styled.a`
  box-sizing: border-box;
  width: ${props => `${props.width}px`};
  height: ${props => `${props.height}px`};
  border: ${props => props.border};
  border-radius: ${props => props.borderRadius};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  background-color: ${props => props.background};
  box-shadow: ${props => props.boxShadow};
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  font-family: ${props => props.fontFamily};
  text-transform: ${props => props.textTransform};
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &:visited {
    color: ${props => props.color};
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    pointer-events: none;
    color: grey;
    border: 1px solid #e4e7ee;
  }
`;

const HrefButton = props => (
  <StyledButton {...props} href={props.href} download>
    {props.children}
  </StyledButton>
);

export default HrefButton;

HrefButton.propTypes = {
  padding: PropTypes.string,
  background: PropTypes.string,
  border: PropTypes.string,
  boxShadow: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  fontFamily: PropTypes.string,
  onClick: PropTypes.func,
  margin: PropTypes.string,
  borderRadius: PropTypes.string,
  textTransform: PropTypes.string
};

HrefButton.defaultProps = {
  fontFamily: '"Muli", sans-serif',
  background: '#edeff2',
  color: '#000000',
  boxShadow: '0 1rem 2rem 0 rgba(124, 160, 73, 0.15)',
  padding: '0',
  fontSize: '1.2rem',
  border: '1px solid #EDEFF2',
  margin: '0px',
  borderRadius: '0.3rem',
  textTransform: 'unset'
};
