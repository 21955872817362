import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { successNotification, errorNotification } from "../../actions";

import keys from "../../config/keys";
import AudioContext from "../../context/Audio/AudioContext";
import DeleteVideoModal from "../../components/Modal/DeleteVideoModal";
import PlaceholderRawCard from "../../components/Placeholder/PlaceholderRawCard";
import AudioPlayerCard from "../../components/VideoCard/AudioPlayerCard";
import { ReactComponent as EmptySVG } from "../../assets/img/empty.svg";
import Button from "../../components/common/Button";
import { colors } from "../../components/themes/base";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 2rem;
  margin-left: 5rem;
  margin-right: 3rem;
  padding-bottom: 3rem;

  @media only screen and (min-width: 680px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media only screen and (min-width: 1020px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media only screen and (min-width: 1200px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;

const StyledNotFound = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    max-width: 30rem;
    transform: translateY(-20rem);
  }

  .message {
    transform: translateY(-45rem);
    text-align: center;

    h4 {
      font-size: 3rem;
      font-weight: bold;
      margin: 0;
    }

    p {
      font-size: 1.8rem;
      color: grey;
    }

    button {
      margin: 0 auto;
      background: ${colors.brand};
      font-size: 1.6rem;
      box-shadow: 0 1rem 2rem 0 rgba(124, 160, 73, 0.15);
      color: #ffffff;
      padding: 1rem 2rem;
      text-transform: uppercase;
    }
  }
`;

const NoAudioFound = (props) => (
  <StyledNotFound>
    <EmptySVG />
    <div className="message">
      <h4>No Audios</h4>
      <p>When you redact audios. They will appear here.</p>
      <Button
        height="50px"
        onClick={() => props.history.push("/video/raw/audio")}
      >
        Raw Audios
      </Button>
    </div>
  </StyledNotFound>
);

const PlaceHolderCards = () => (
  <Grid>
    {Array.from(Array(12).keys()).map((_, i) => (
      <PlaceholderRawCard key={i} />
    ))}
  </Grid>
);

const RedactedAudio = (props) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [audioId, setaudioId] = useState(null);
  const [audioName, setaudioName] = useState("");
  const [deleteStatus, setDeleteStatus] = useState(false);

  const { redactedaudioList, setRedactedAudioList } = useContext(AudioContext);

  useEffect(() => {
    fetchRedactedAudio();
    // eslint-disable-next-line
  }, []);

  const fetchRedactedAudio = () => {
    const url = `${keys.SERVER_URL}/audio/redacted`;
    axios
      .get(url, { requestId: "fetchRedactedAudio" })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.audioList.length === 0) {
            setRedactedAudioList(null);
          } else {
            setRedactedAudioList(res.data.audioList);
          }
        } else {
          props.errorNotification({
            title: "Unable to fetch the data",
            autoDismiss: 1,
          });
        }
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          props.errorNotification({
            title: "Unable to fetch the data",
            autoDismiss: 1,
          });
        }
      });
  };

  const handleDelete = (id) => {
    const url = `${keys.SERVER_URL}/audio/${id}`;

    axios
      .delete(url, { requestId: "deleteRedactedAudio" })
      .then((res) => {
        setDeleteStatus(false);
        if (res.status === 200) {
          fetchRedactedAudio();
          props.successNotification({
            title: "Deleted Successfully",
            autoDismiss: 1,
          });
        } else {
          props.errorNotification({
            title: "Unable to delete the audio",
            autoDismiss: 1,
          });
        }
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          props.errorNotification({
            title: "Unable to delete the audio",
            autoDismiss: 1,
          });
        }
      });
  };

  if (redactedaudioList === null) {
    return <NoAudioFound {...props} />;
  } else if (redactedaudioList.length === 0) {
    return (
      <div>
        <div
          style={{
            color: "#142945",
            fontFamily: "Muli",
            fontSize: "2.2rem",
            fontWeight: "700",
            letterSpacing: "0.18px",
            margin: "2.5rem 5rem",
          }}
        >
          Redacted Audios
        </div>
        <PlaceHolderCards />
      </div>
    );
  } else {
    return (
      <div className="">
        <DeleteVideoModal
          videoName={audioName}
          isVisible={deleteModal}
          handleCancel={() => setDeleteModal(false)}
          handleDelete={() => {
            setDeleteStatus(true);
            setDeleteModal(false);
            return handleDelete(audioId);
          }}
        />
        <div
          style={{
            color: "#142945",
            fontFamily: "Muli",
            fontSize: "2.2rem",
            fontWeight: "700",
            letterSpacing: "0.18px",
            margin: "2.5rem 5rem",
          }}
        >
          Redacted Audio
        </div>
        <Grid>
          {redactedaudioList.map((redactedAudio) => (
            <AudioPlayerCard
              key={redactedAudio._id}
              deleteStatus={
                redactedAudio._id === audioId ? deleteStatus : false
              }
              poster={redactedAudio.thumbURL}
              videoURL={`${redactedAudio.url}?t=${new Date().toISOString()}`}
              videoTitle={redactedAudio.name}
              timestamp={new Date(redactedAudio.last_modified).toGMTString()}
              redacted={true}
              handleDelete={() => {
                setaudioId(redactedAudio._id.toString());
                setaudioName(redactedAudio.name);
                setDeleteModal(true);
                return;
              }}
              href={redactedAudio.url}
            />
          ))}
        </Grid>
      </div>
    );
  }
};

RedactedAudio.propTypes = {
  redactedVideos: PropTypes.arrayOf(
    PropTypes.shape({
      videoURL: PropTypes.string,
      videoTitle: PropTypes.string,
      timestamp: PropTypes.string,
      handleSave: PropTypes.func,
      handleDelete: PropTypes.func,
    })
  ),
};

RedactedAudio.defaultProps = {};

export default connect(null, {
  successNotification,
  errorNotification,
})(RedactedAudio);
