import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import HrefButton from "../common/HrefButton";
import Button from "../common/Button";
import Controls from "./Controls";
import Loader from "../../components/Loader/ModalLoaderIcon";
import { colors } from "../themes/base";

const CardWrapper = styled.div`
  background: ${(props) => props.background};
  width: ${(props) => props.width};
  overflow: hidden;
  font-family: ${(props) => props.fontFamily};
  border: ${(props) => props.cardBorder};
  border-radius: 3px;
  color: ${(props) => props.cardFontColor};

  @media only screen and (max-width: 500px) {
    width: 80%;
    min-width: 80%;
  }

  .video-container {
    width: 100%;
    height: 21.5rem;
    position: relative;
    background: black;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    video {
      width: 100%;
      height: 21.5rem;
      object-fit: cover;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
    }

    &:hover .player-controls {
      display: flex;
    }

    .spinner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 21.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const VideoDetails = styled.div`
  box-sizing: border-box;
  padding: 1.6rem;
  width: 100%;

  .title-container {
    font-size: 1.8rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .timestamp {
    padding-top: 0.6rem;
    opacity: 0.5;
    font-size: 1.2rem;
  }
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 1.5rem 1.6rem 1.5rem;
  @media only screen and (max-width: 500px) {
    button {
      margin-bottom: 1rem;
    }
  }
  .buttonWrapper {
    display: flex;
    flex: 1;
    button:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
  .deleteBtn {
    ${(props) => (props.deleteStatus ? "pointer-events: none;" : "")};
  }
`;

const buttonProps = {
  fontSize: "1.1rem",
  boxShadow: "none",
  borderRadius: "3rem",
  padding: "0.7rem 1rem",
};

const EncoderButton = (props) => {
  if (props.isMP4) {
    return null;
  } else {
    if (props.deleteStatus) {
      return (
        <Button
          disabled
          border="1px solid #e4e7ee"
          background="#fff"
          color={colors.button}
          onClick={props.handleEncode}
          {...buttonProps}
        >
          Encoder
        </Button>
      );
    } else {
      return (
        <Button
          border="1px solid #e4e7ee"
          background="#fff"
          color={colors.button}
          onClick={props.handleEncode}
          {...buttonProps}
        >
          Encoder
        </Button>
      );
    }
  }
};

const ActionButtons = (props) => {
  const extension = props.videoTitle.split(".").pop();
  const isMP4 = extension === "mp4";

  if (props.redacted) {
    return (
      <ActionButtonsWrapper {...props}>
        <div className="buttonWrapper">
          {props.deleteStatus ? (
            <HrefButton
              disabled
              border="1px solid #e4e7ee"
              color={colors.button}
              background="#fff"
              margin="0 0.5rem 0 0"
              {...buttonProps}
            >
              Download
            </HrefButton>
          ) : (
            <HrefButton
              border="1px solid #e4e7ee"
              color={colors.button}
              background="#fff"
              margin="0 0.5rem 0 0"
              href={props.href}
              {...buttonProps}
            >
              Download
            </HrefButton>
          )}

          <Button
            className="deleteBtn"
            deleteStatus={props.deleteStatus}
            border="1px solid #e4e7ee"
            color={colors.button}
            background="#FFF"
            onClick={() => props.handleShareLink(props.href)}
            {...buttonProps}
          >
            Share Link
          </Button>
        </div>

        <Button
          className="deleteBtn"
          deleteStatus={props.deleteStatus}
          color="red"
          background="#F9FAFD"
          border="none"
          onClick={props.handleDelete}
          {...buttonProps}
        >
          {props.deleteStatus ? <Loader /> : "Delete"}
        </Button>
      </ActionButtonsWrapper>
    );
  }
  return (
    <ActionButtonsWrapper {...props}>
      <div className="buttonWrapper">
        {props.deleteStatus ? (
          <Button
            disabled
            border="1px solid #e4e7ee"
            background="#fff"
            color={colors.button}
            onClick={props.handleRedact}
            {...buttonProps}
          >
            Redact
          </Button>
        ) : (
          <Button
            border="1px solid #e4e7ee"
            background="#fff"
            color={colors.button}
            onClick={props.handleRedact}
            {...buttonProps}
          >
            Redact
          </Button>
        )}
        <EncoderButton {...props} isMP4={isMP4} />
        {props.deleteStatus ? (
          <HrefButton
            disabled
            border="1px solid #e4e7ee"
            background="#fff"
            color={colors.button}
            {...buttonProps}
          >
            Download
          </HrefButton>
        ) : (
          <HrefButton
            border="1px solid #e4e7ee"
            background="#fff"
            color={colors.button}
            href={props.href}
            {...buttonProps}
          >
            Download
          </HrefButton>
        )}
      </div>
      <Button
        className="deleteBtn"
        deleteStatus={props.deleteStatus}
        color="red"
        border="none"
        background="#F9FAFD"
        onClick={props.handleDelete}
        {...buttonProps}
      >
        {props.deleteStatus ? <Loader /> : "Delete"}
      </Button>
    </ActionButtonsWrapper>
  );
};

class VideoCard extends React.Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.state = {
      isPlaying: false,
      isMuted: false,
      duration: 0,
      currentTime: 0,
      currentVolume: 1,
      hasLoaded: false,
      buffered: 0,
    };
  }

  play = () => {
    const { isPlaying } = this.state;
    if (isPlaying) {
      this.videoRef.current.pause();
    } else {
      this.videoRef.current.play();
    }
  };

  setCurrentTime = (time) => {
    this.setState({ currentTime: time });
    this.videoRef.current.currentTime = time;
  };

  muteVideo = () => {
    const { isMuted } = this.state;
    const muted = !isMuted;
    if (isMuted) {
      this.videoRef.current.muted = muted;
    } else {
      this.videoRef.current.muted = muted;
    }
    this.setState({ isMuted: muted });
  };

  setVolume = (volume) => {
    this.setState({ currentVolume: volume });
    this.videoRef.current.volume = volume;
  };

  fullScreen = () => {
    if (this.videoRef.current.webkitRequestFullscreen) {
      this.videoRef.current.webkitRequestFullscreen();
    }
  };

  render() {
    const { props } = this;
    return (
      <CardWrapper {...props}>
        <div className="video-container">
          <video
            id={props.videoURL}
            src={props.videoURL}
            ref={this.videoRef}
            preload="none"
            poster={props.poster}
            onPlay={() => this.setState({ isPlaying: true })}
            onPause={() => this.setState({ isPlaying: false })}
            onLoadedMetadata={() =>
              this.setState({
                duration: this.videoRef.current.duration,
                hasLoaded: true,
              })
            }
            onTimeUpdate={() => {
              if (this.state.hasLoaded) {
                const video = this.videoRef.current;
                let currentTime = video.currentTime;
                if (currentTime > this.state.duration) {
                  currentTime = this.state.duration;
                }
                const range = video.buffered;
                const buffered = range.end(0);

                this.setState({ currentTime, buffered });
              }
            }}
            onVolumeChange={() => {
              const isMuted = this.videoRef.current.muted;
              if (isMuted) {
                this.setState({ currentVolume: 0 });
              } else {
                this.setState({ currentVolume: this.videoRef.current.volume });
              }
            }}
          />

          <Controls
            play={this.play}
            mute={this.muteVideo}
            setCurrentTime={this.setCurrentTime}
            buffered={this.state.buffered}
            setVolume={this.setVolume}
            isPlaying={this.state.isPlaying}
            isMuted={this.state.isMuted}
            duration={this.state.duration}
            currentTime={this.state.currentTime}
            currentVolume={this.state.currentVolume}
            fullScreen={this.fullScreen}
            hasLoaded={this.state.hasLoaded}
          />
        </div>

        <VideoDetails>
          <div className="title-container">
            <span className="title">{props.videoTitle}</span>
            {/* {props.starred ? (
              <span>
                <FaStar style={{ fill: "#F8C600" }} />
              </span>
            ) : (
              <span>
                <FaRegStar />
              </span>
            )} */}
          </div>

          {props.timestamp ? (
            <div className="timestamp">{props.timestamp}</div>
          ) : null}
        </VideoDetails>

        <ActionButtons {...props} />
      </CardWrapper>
    );
  }
}

export default VideoCard;

VideoCard.propTypes = {
  background: PropTypes.string,
  width: PropTypes.string,
  fontFamily: PropTypes.string,
  cardBorder: PropTypes.string,
  cardBoxShadow: PropTypes.string,
  cardFontColor: PropTypes.string,
  videoURL: PropTypes.string,
  videoTitle: PropTypes.string,
  poster: PropTypes.string,
  starred: PropTypes.bool,
  redacted: PropTypes.bool,
  timestamp: PropTypes.string,
  handleRedact: PropTypes.func,
  handleSave: PropTypes.func,
  handleEncode: PropTypes.func,
  handleDelete: PropTypes.func,
};

VideoCard.defaultProps = {
  width: "auto",
  background: "#FFFFFF",
  fontFamily: '"Muli", sans-serif',
  cardBorder: "1px solid rgba(20, 41, 69, 0.14)",
  cardFontColor: "#142945",
  starred: false,
  redacted: false,
};
