import React, { Component } from "react";
import styled from "styled-components";
import Modal from "react-modal";

import { icons } from "../themes/base";

const CloseButton = styled.button`
  border-radius: 50%;
  background: ##eae9e9;
  border: 0;
  height: 25px;
  width: 25px;
  cursor: pointer;
`;

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 9999999,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const ModalContent = styled.div`
  box-sizing: border-box;
  width: 50.5rem;
  padding: 2rem;
  color: #142945;
  font-size: 15px;
  font-family: Muli;

  .keys-text {
    font-weight: 700;
  }
`;

class KeyboardShortcuts extends Component {
  render() {
    return (
      <Modal
        isOpen={true}
        style={customStyles}
        contentLabel="Warning Modal"
        ariaHideApp={false}
      >
        <ModalContent>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h3 id="modal-name">Keyboard Shortcuts</h3>

            <CloseButton
              className="btn btn-default"
              type="button"
              onClick={() => this.props.setTool("video")}
            >
              X
            </CloseButton>
          </div>

          <table id="keyboard-shortcut-table">
            <tbody>
              <tr>
                <td className="keys-text">1</td>
                <td style={{ textAlign: "left" }}>play at 0.5x speed</td>
              </tr>
              <tr>
                <td className="keys-text">2</td>
                <td style={{ textAlign: "left" }}>play at 1x speed</td>
              </tr>
              <tr>
                <td className="keys-text">3</td>
                <td style={{ textAlign: "left" }}>play at 1.5x speed</td>
              </tr>
              <tr>
                <td className="keys-text">4</td>
                <td style={{ textAlign: "left" }}>play at 2x speed</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <br />
                </td>
              </tr>
              <tr>
                <td className="keys-text">Space bar</td>
                <td style={{ textAlign: "left" }}>play / pause</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <br />
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    style={{ height: 16 }}
                    src={icons.IC_RightArrow}
                    alt=""
                  />
                </td>
                <td style={{ textAlign: "left" }}>move 5 seconds forward</td>
              </tr>
              <tr>
                <td>
                  <img style={{ height: 16 }} src={icons.IC_LeftArrow} alt="" />
                </td>
                <td style={{ textAlign: "left" }}>move 5 seconds backwards</td>
              </tr>
              <tr>
                <td className="keys-text">
                  Shift &nbsp;
                  <img
                    style={{ height: 16 }}
                    src={icons.IC_RightArrow}
                    alt=""
                  />
                </td>
                <td style={{ textAlign: "left" }}>move 15 seconds forward</td>
              </tr>
              <tr>
                <td className="keys-text">
                  Shift &nbsp;
                  <img style={{ height: 16 }} src={icons.IC_LeftArrow} alt="" />
                </td>
                <td style={{ textAlign: "left" }}>move 15 seconds backwards</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <br />
                </td>
              </tr>
              <tr>
                <td className="keys-text">D</td>
                <td style={{ textAlign: "left" }}>move 1 frame backward</td>
              </tr>
              <tr>
                <td className="keys-text">F</td>
                <td style={{ textAlign: "left" }}>move 1 frame forward</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <br />
                </td>
              </tr>
              <tr>
                <td className="keys-text">Shift + D</td>
                <td style={{ textAlign: "left" }}>move 5 frames backward</td>
              </tr>
              <tr>
                <td className="keys-text">Shift + F</td>
                <td style={{ textAlign: "left" }}>move 5 frames forward</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <br />
                </td>
              </tr>
              <tr>
                <td className="keys-text">G</td>
                <td style={{ textAlign: "left" }}>
                  play for 0.5 seconds, before pausing
                </td>
              </tr>
              <tr>
                <td className="keys-text">H</td>
                <td style={{ textAlign: "left" }}>
                  play for 1 second, before pausing
                </td>
              </tr>
              <tr>
                <td className="keys-text">J</td>
                <td style={{ textAlign: "left" }}>
                  play for 3 seconds, before pausing
                </td>
              </tr>
              <tr>
                <td className="keys-text">K</td>
                <td style={{ textAlign: "left" }}>
                  play for 5 seconds, before pausing
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <br />
                </td>
              </tr>
              <tr>
                <td className="keys-text">V</td>
                <td style={{ textAlign: "left" }}>
                  rewind 0.5 seconds, then play at quarter speed
                </td>
              </tr>
              <tr>
                <td className="keys-text">B</td>
                <td style={{ textAlign: "left" }}>
                  rewind 1 second, then play at quarter speed
                </td>
              </tr>
              <tr>
                <td className="keys-text">N</td>
                <td style={{ textAlign: "left" }}>
                  rewind 3 seconds, then play at quarter speed
                </td>
              </tr>
              <tr>
                <td className="keys-text">M</td>
                <td style={{ textAlign: "left" }}>
                  rewind 5 seconds, then play at quarter speed
                </td>
              </tr>
            </tbody>
          </table>
        </ModalContent>
      </Modal>
    );
  }
}

export default KeyboardShortcuts;
